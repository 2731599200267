import { AddAdvertisementModal } from "components/AddAdvertisementModal";
import { AdvertDataType } from "components/AddAdvertisementModal/types";
import { Box } from "components/Box";
import { CompleteDataModal } from "components/CompleteDataModal";
import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import { Router } from "components/Router/Router";
import { ShareAdvertisementModal } from "components/ShareAdvertisementModal";
import { useNavbar } from "hooks/navbar";
import { useProfile } from "hooks/profile";
import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { useSelector } from "react-redux";
import { Switch, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { routes } from "routes";
import { addAdvertisement } from "services/advertisements";
import { getNewNotifications, getNotifications } from "services/notifications";
import { IRootState } from "store/currentUser/types";
import styled from "styled-components";
import { INotification } from "types/notifications";
import { confirmEmailInfo } from "utilities/confirmEmailInfo";
import { eventBus } from "utilities/eventBus";

const Body = styled(Box)`
  min-height: 90vh;
`;

export const App: React.FC = () => {
  const history = useHistory();
  const [logged, setLogged] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);
  const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const [isAddAdvertisementModalOpen, setIsAddAdvertisementModalOpen] =
    useState(false);
  const [isShareAdvertisementModalOpen, setIsShareAdvertisementModalOpen] =
    useState<{ isOpen: boolean; id?: number }>({
      isOpen: false,
      id: undefined,
    });
  const [completeDataModalIsOpen, setCompleteDataModalIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const currentUser = useSelector((state: IRootState) => state.currentUser);

  const { handleMyProfileClick, handleLogout, navbarLinks } = useNavbar({
    logged,
  });

  const { user } = useProfile(true);

  useEffect(() => {
    const { confirmed_at, profile } = currentUser;
    if (confirmed_at) {
      setEmailConfirmed(true);
    }
    if (profile && !profile.registration_completed) {
      history.push("/register/profile");
    }
  }, [logged, currentUser]);

  const companyExist = !!(
    user?.data?.profile?.company_name && user?.data?.profile?.company_nip
  );

  const profileDropdownItems = [
    { label: "Mój profil", onClick: handleMyProfileClick },
    { label: "Wyloguj", onClick: handleLogout },
  ];

  const handleAddAdvertisementSubmit = async (advert: AdvertDataType) => {
    try {
      const response = await addAdvertisement({ advertisement: advert });
      eventBus.dispatch("addNewAdvertisement", response);
      setIsAddAdvertisementModalOpen(false);
    } catch (error) {
      toast.error("Nie udało się dodać ogłoszenia.");
    }
  };

  const retrieveNotifications = async () => {
    const notificationLength = 5;
    const { data } = await getNotifications(notificationLength);
    setNotifications(data);
  };

  const redirectCompleteFirmData = () => {
    setCompleteDataModalIsOpen(false);
    history.push("/profile/settings/company-data");
  };

  const openModal = () => {
    if (companyExist) setIsAddAdvertisementModalOpen(true);
    else setCompleteDataModalIsOpen(true);
  };

  const getNewNotificationsData = async () => {
    const { data } = await getNewNotifications();
    setNewNotifications(data);
  };

  useEffect(() => {
    if (currentUser?.token) {
      setLogged(true);
      retrieveNotifications();
    } else {
      setLogged(false);
    }
  }, [currentUser]);

  useEffect(() => {
    eventBus.on(
      "showAdvertisementModal",
      (data: { isOpen: boolean; id?: number }) => {
        setIsShareAdvertisementModalOpen(data);
      }
    );
  }, []);

  useEffect(() => {
    getNewNotificationsData();
  }, [logged, currentUser]);

  return (
    <>
      <Navbar
        isEmailConfirmed={isEmailConfirmed}
        confirmEmailInfo={confirmEmailInfo}
        notification={notification}
        avatarUrl={currentUser ? currentUser.profile?.avatar : null}
        logged={logged}
        links={navbarLinks}
        profileDropdownItems={profileDropdownItems}
        onAddAdvertisementClick={openModal}
        onLogoClick={() => history.push("/")}
        newNotifications={newNotifications}
        getNewNotifications={getNewNotificationsData}
        notifications={notifications}
        goToNotificationsPage={() => history.push("/notifications")}
        moveToLogin={() => history.push("/login")}
        notificationRedirect={(path) => {
          history.push(`/${path}`);
        }}
      />
      <CompleteDataModal
        isOpen={completeDataModalIsOpen}
        onCancelClick={() => setCompleteDataModalIsOpen(false)}
        redirectToFirData={redirectCompleteFirmData}
      />
      <AddAdvertisementModal
        isOpen={isAddAdvertisementModalOpen}
        onCancelClick={() => setIsAddAdvertisementModalOpen(false)}
        onSubmit={handleAddAdvertisementSubmit}
      />
      <ShareAdvertisementModal
        url={`${window.location.protocol}//${window.location.hostname}/advertisement/${isShareAdvertisementModalOpen.id}`}
        isOpen={isShareAdvertisementModalOpen.isOpen}
        onCancelClick={() => {
          setIsShareAdvertisementModalOpen({
            isOpen: false,
            id: undefined,
          });
        }}
      />

      <Body py={22}>
        <Container>
          <Switch>
            <Router routes={routes} />
          </Switch>
        </Container>
        <ToastContainer position="bottom-right" />
      </Body>
      <Footer />
    </>
  );
};
