import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { ChatMessage } from "components/ChatMessage/ChatMessage";
import { ChatUsersList } from "components/ChatUsersList";
import { ContactsListModal } from "components/ContactsListModal";
import { Flex } from "components/Flex";
import { TextareaField } from "components/Textarea";
import { Typography } from "components/Typography";
import { IUsersListItem } from "components/UsersList/UsersList";
import { useChat } from "hooks/chat";
import { useContacts } from "hooks/contacts";
import { usePrevious } from "hooks/usePrevious";
import { ReactComponent as SendIcon } from "icons/send.svg";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IRootState } from "store/rootReducer";
import styled from "styled-components";
import { IPagination } from "types/pagination";
import { goToUserInfo } from "utilities/goToUserInfoHelper";
import { rgba } from "utilities/rgba";

export interface IConversation {
  id: number;
  seen: boolean;
  users: Array<IUser>;
}

interface IUser {
  id: number;
  profile: IProfile;
}

interface IProfile {
  first_name: string;
  last_name: string;
}

interface IMessage {
  id: number;
  conversation_id: number;
  content: string;
  created_at: string;
  sender: IUser;
  type: "to" | "from";
}

interface IResponseValue {
  from_negotiation: boolean;
  method: "pull_conversations" | "pull_messages" | "send_message";
  data: IMessage[] | IConversation[] | IMessage;
  pagination: IPagination;
}

interface IGradient {
  visible: boolean;
}
const Gradient = styled.div<IGradient>`
  ${({ theme, visible }) => `
    opacity: ${visible ? `100%` : "0%"};
    z-index: ${visible ? `10` : "-1"};
    transition: .2s linear;
    transition-delay: 0;
    position: absolute;
    top: 0;
    left: 16px;
    width: calc(100% - 32px);
    border-radius: 16px;
    height: 150px;
    background: linear-gradient(
      to bottom,
      ${rgba(theme.palette.neutral.white, 1)} 0%,
      ${rgba(theme.palette.neutral.white, 0)} 100%
    );
  `}
`;
const MessagesWindow = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[8]}px;
    padding-bottom: 0;
    min-height: 300px;
    // max-height: 430px;
    overflow-y: auto;
    overscroll-behavior: contain;
    margin-bottom: ${theme.space[4]}px;
  `}
`;
const EmptyMessageWindow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const perPage = 15;
const inverse = true;

export const MessagesView = () => {
  const history = useHistory();
  const currentUser = useSelector((state: IRootState) => state.currentUser);

  const [conversationID, setConversationID] = useState<number>(0);
  const [enableFetchMore, setEnableFetchMore] = useState(false);
  const [visibleGradient, setVisibleGradient] = useState<boolean>(false);
  const [conversations, setConversation] = useState<Array<IConversation>>([]);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const [contactList, setContactList] = useState<IUsersListItem[]>([]);

  const { contacts, retrieveContacts } = useContacts({});

  const { register, handleSubmit } = useForm();

  const {
    cable,
    recentlyReceivedWsData,
    messages,
    setMessages,
    parseMessageData,
  } = useChat();

  const filterUserInConversation = (
    conversationsList: IConversation[]
  ): IConversation[] =>
    conversationsList.map((conversation: IConversation) => ({
      id: conversation.id,
      seen: conversation.seen,
      users: conversation.users.filter((user) => user.id !== currentUser.id),
    }));

  const sendMessage = (content: string) => {
    if (!cable) return;
    cable.perform("send_message", {
      conversation_id: conversationID,
      content,
    });
  };

  const getConversations = () => {
    setTimeout(() => {
      if (!cable) return;
      cable.perform("pull_conversations", {
        page: 1,
        per_page: 15,
      });
    }, 1000);
  };

  useEffect(() => {
    retrieveContacts({ page: 1 });
  }, []);

  useEffect(() => {
    getConversations();
  }, [cable]);

  const handleWsMessageReceive = ({
    method,
    data,
    pagination,
    from_negotiation,
  }: IResponseValue): void => {
    if (from_negotiation) return;

    switch (method) {
      case "pull_conversations": {
        const conversationList = filterUserInConversation(
          data as IConversation[]
        );
        setConversation(conversationList);
        break;
      }
      case "pull_messages": {
        const oldMessages = parseMessageData(data as IMessage[], currentUser);
        setMessages((prevState) => [...prevState, ...oldMessages]);
        setTotalCount(pagination.count);
        if (pagination.current === 1) {
          setEnableFetchMore(true);
        }
        break;
      }
      case "send_message": {
        const messageData = data as IMessage;
        const newMessage = {
          ...messageData,
          type: messageData.sender.id === currentUser.id ? "to" : "from",
        } as IMessage;
        getConversations();
        if (messageData.conversation_id === conversationID) {
          setMessages((prevState) => [newMessage, ...prevState]);
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (recentlyReceivedWsData?.data) {
      handleWsMessageReceive(recentlyReceivedWsData);
    }
  }, [recentlyReceivedWsData, conversationID]);

  useEffect(() => {
    setMessages([]);
    setCurrentPage(1);
    setEnableFetchMore(false);
    if (!cable) return;
    cable.perform("pull_messages", {
      conversation_id: conversationID,
      per_page: perPage,
      page: 1,
    });
  }, [conversationID]);

  useEffect(() => {
    if (!cable) return;
    if (currentPage === 1) return;
    cable.perform("pull_messages", {
      conversation_id: conversationID,
      per_page: perPage,
      page: currentPage,
    });
  }, [currentPage]);

  useEffect(() => {
    setHasMore(!(messages.length >= totalCount));
  }, [messages.length, totalCount]);

  const fetchMoreData = () => {
    if (!enableFetchMore) return;
    setCurrentPage((prevState) => prevState + 1);
  };

  const onSelectedConversation = (id: number) => {
    setConversationID(id);
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const isVisible = !!e.currentTarget.scrollTop;
    setVisibleGradient(isVisible);
  };

  const onSubmit = async (data: { message: string }) => {
    try {
      await sendMessage(data.message);
    } catch (err) {
      toast.error("Nie udało się wsyłać wiadomości.");
    }
  };

  const handleTextareaMessage = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const element = e.target as HTMLInputElement;
    const keyPressed = e.key;
    if (keyPressed === "Enter") {
      sendMessage(element.value);
      (element as HTMLInputElement).value = element.defaultValue;
    }
  };

  const filterConversations = () => {
    const contactsWithoutConversation = contacts.filter((contact) => {
      return !conversations.find((conversation) => {
        return contact?.id === conversation.users[0]?.id;
      });
    });
    setContactList(contactsWithoutConversation);
  };

  const createConversation = (newConversation: IUsersListItem) => {
    if (!cable) return;
    const userId = newConversation.id;
    cable.perform("create_conversation", {
      user_ids: [currentUser.id, userId],
    });
    getConversations();
    setOpenModal(false);
  };

  useEffect(() => {
    filterConversations();
  }, [conversations]);

  return (
    <>
      <Box mb={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Typography variant="h1" color="primary" mb={3}>
            Rozmowy
          </Typography>
          <Button label="Nowa wiadomość" onClick={() => setOpenModal(true)} />
        </Flex>
      </Box>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <ChatUsersList
              items={conversations}
              onSelectedConversation={onSelectedConversation}
            />
          </Box>
        </Col>
        <Col sm={8}>
          <Card>
            {conversationID ? (
              <>
                <MessagesWindow
                  id="scrollableComponent"
                  onScroll={handleScroll}
                >
                  {messages.length === 0 ? (
                    <EmptyMessageWindow>
                      <Typography variant="h1" color="secondary">
                        Brak wiadomości od tego użytkownika.
                      </Typography>
                    </EmptyMessageWindow>
                  ) : (
                    <>
                      <Gradient visible={visibleGradient} />
                      <InfiniteScroll
                        dataLength={messages.length}
                        next={fetchMoreData}
                        inverse={inverse}
                        hasMore={hasMore}
                        loader={<p>loading...</p>}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                        height={430}
                      >
                        {messages.map((message: IMessage) => (
                          <ChatMessage
                            goToUserInfo={goToUserInfo(history)}
                            {...message}
                          />
                        ))}
                      </InfiniteScroll>
                    </>
                  )}
                </MessagesWindow>
                <Box mx={7} mb={8}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextareaField
                      onKeyPress={handleTextareaMessage}
                      name="message"
                      placeholder="Tu wpisz swoją wiadomość..."
                      icon={<SendIcon />}
                      ref={register}
                    />
                  </form>
                </Box>
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
      <ContactsListModal
        contacts={contactList}
        isOpen={openModal}
        buttonLabel="Zacznij konwersację"
        handleAction={createConversation}
        onCancelClick={() => setOpenModal(false)}
      />
    </>
  );
};
