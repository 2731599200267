import { Button, Typography } from "components";
import { Box } from "components/Box";
import { List } from "components/List";
import React, { useState } from "react";

import { useProfile } from "../../../hooks/profile";
import { ButtonContainer } from "../ProfileInfo";
import { ExpectationsAndPreferencesForm } from "./ExpectationsAndPreferencesForm";

export const ExpectationsAndPreferencesView: React.FC = () => {
  const [variant, setVariant] = useState(0);
  const {
    user,
    lookingFor,
    investmentsSpecializations,
    mostImportantFeatures,
    expectations,
  } = useProfile();

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2" color="primary">
          Oczekiwania i preferencje
        </Typography>
      </Box>
      {variant === 0 && (
        <>
          <Typography variant="body2" color="secondary">
            Do czego potrzebujesz naszej platformy?
          </Typography>
          <Typography variant="body" mb={4}>
            <List data={lookingFor} />
          </Typography>

          <Typography variant="body2" color="secondary">
            W jakiego rodzaju inwestycjach się specjalizujesz?
          </Typography>
          <Typography variant="body" mb={4}>
            <List data={investmentsSpecializations} />
          </Typography>

          <Typography variant="body2" color="secondary">
            Co jest dla Ciebie najważniejsze w ogłoszeniach inwestycyjnych?
          </Typography>
          <Typography variant="body" mb={4}>
            <List data={mostImportantFeatures} />
          </Typography>

          <Typography variant="body2" color="secondary">
            Czego oczekujesz od sieci znajomych inwestorów?
          </Typography>
          <Typography variant="body" mb={6}>
            <List data={expectations} />
          </Typography>

          <ButtonContainer>
            <Button
              label="Edytuj"
              onClick={() => {
                setVariant(1);
              }}
            />
          </ButtonContainer>
        </>
      )}
      {variant === 1 && user && (
        <ExpectationsAndPreferencesForm
          currentUser={user.data}
          onCloseForm={setVariant}
        />
      )}
    </>
  );
};
