import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Person } from "components/Person";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";

interface IConversation {
  id: number;
  seen: boolean;
  users: Array<IUser>;
}
interface IProfile {
  first_name: string;
  last_name: string;
}
interface IUser {
  id: number;
  profile: IProfile;
}
export interface IChatUsersList {
  items: Array<IConversation>;
  onSelectedConversation?: (id: number) => void;
}
interface IStyledSelectedBox {
  selected: boolean;
}
const More = styled.div`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    margin-left: ${theme.space[2]}px;
    cursor: pointer;
  `}
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 5px 0;
`;
const StyledSelectedBox = styled(Box)<IStyledSelectedBox>`
  ${({ theme, selected }) => `
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color:${theme.palette.neutral.veryLight};
    }
    ${
      selected
        ? `
      background-color:${theme.palette.neutral.veryLight};
    `
        : null
    }
  `};
`;

const UnreadIndicator = styled.div`
  ${({ theme }) => `
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
  `}
`;

const Dotted = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const ChatUsersList: React.FC<IChatUsersList> = ({
  items,
  onSelectedConversation,
}) => {
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (id: number) => {
    setSelected(id);
    if (onSelectedConversation) {
      onSelectedConversation(id);
    }
  };

  return (
    <Card>
      <Box p={6}>
        {items &&
          items.map((item) => (
            <StyledSelectedBox
              selected={selected === item.id}
              p={2}
              pl={2}
              key={item.id}
              onClick={() => handleSelect(item.id)}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {item.users.map((user) => (
                  <Person
                    firstName={user.profile.first_name}
                    lastName={user.profile.last_name}
                    description={
                      <Moment locale="pl" fromNow>
                        2021-02-21T12:59-0500
                      </Moment>
                    }
                  />
                ))}
                <Flex alignItems="center">
                  {!item.seen && <UnreadIndicator />}
                  <More>
                    <StyledFlex
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Dotted />
                      <Dotted />
                      <Dotted />
                    </StyledFlex>
                  </More>
                </Flex>
              </Flex>
            </StyledSelectedBox>
          ))}
      </Box>
    </Card>
  );
};
