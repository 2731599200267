import { Button, Typography } from "components";
import { Box } from "components/Box";
import { TextField } from "components/TextField";
import { useProfile } from "hooks/profile";
import React, { useState } from "react";
import { ButtonContainer, FormContainer } from "views/Profile/ProfileInfo";

export const LoginDataView = () => {
  const [variant, setVariant] = useState(0);
  const { user } = useProfile();

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2" color="primary">
          Dane logowania
        </Typography>
      </Box>
      {variant === 0 && (
        <>
          <Typography variant="body2" color="secondary">
            Adres e-mail
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={600}>
            {user?.data?.email}
          </Typography>

          <Typography variant="body2" color="secondary">
            Hasło
          </Typography>
          <Typography variant="h3" mb={6} fontWeight={600}>
            ***********
          </Typography>

          <ButtonContainer>
            <Button
              onClick={() => {
                setVariant(2);
              }}
              label="Zmień hasło"
              variant="secondary"
            />
          </ButtonContainer>
        </>
      )}
      {variant === 1 && (
        <FormContainer>
          <Box mb={6}>
            <TextField label="Adres e-mail" name="email" />
          </Box>

          <ButtonContainer>
            <Button
              label="Zapisz"
              onClick={() => {
                setVariant(0);
              }}
            />
            <Button
              onClick={() => {
                setVariant(0);
              }}
              label="Anuluj"
              variant="secondary"
            />
          </ButtonContainer>
        </FormContainer>
      )}
      {variant === 2 && (
        <FormContainer>
          <Box mb={3}>
            <TextField label="Hasło" name="password" />
          </Box>
          <Box mb={3}>
            <TextField label="Powtórz hasło" name="password_confirm" />
          </Box>
          <Box mb={6}>
            <TextField label="Nowe hasło" name="new_password" />
          </Box>

          <ButtonContainer>
            <Button
              label="Zapisz"
              onClick={() => {
                setVariant(0);
              }}
            />
            <Button
              onClick={() => {
                setVariant(0);
              }}
              label="Anuluj"
              variant="secondary"
            />
          </ButtonContainer>
        </FormContainer>
      )}
    </>
  );
};
