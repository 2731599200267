import { Button } from "components";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { FieldLabel as Label } from "components/FieldLabel";
import { Flex } from "components/Flex";
import { Select } from "components/Select";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ISearchParams } from "services/search";
import styled from "styled-components";

import { getFilterVariant } from "../../utilities/filterAdvertisement.config";

interface IFilters {
  filters?: ISearchParams;
  handleSetFilters: (newFilters: ISearchParams) => void;
}

const FormRow = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-start;
    margin: 0 -${theme.space[1]}px;
  `}
`;

const StyledCard = styled(Card)`
  overflow: visible !important;
`;

const FormCol = styled.div`
  ${({ theme }) => `
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: 0 ${theme.space[1]}px;
  `}
`;
const StyledFlex = styled(Flex)`
  width: 100%;
`;
const EmptyLabel = styled.div`
  margin-top: 20px;
`;

export const Filters: React.FC<IFilters> = ({ filters, handleSetFilters }) => {
  const { register, handleSubmit, reset, control } = useForm<ISearchParams>({
    defaultValues: filters,
  });

  const onSubmit = (data: ISearchParams) => {
    handleSetFilters(data);
  };

  useEffect(() => {
    reset();
  }, [filters?.selected_category]);

  return (
    <StyledCard>
      <Box p={8} py={6}>
        <Box mb={4}>
          <Typography variant="h2" color="primary">
            Filtry
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {getFilterVariant(filters?.selected_category).map((filter) => (
            <Box mb={2}>
              <FormRow>
                {filter.map((field) =>
                  field.type === "text" ? (
                    <StyledFlex flexDirection="column">
                      {field.label ? (
                        <Label>{field.label}</Label>
                      ) : (
                        <EmptyLabel />
                      )}
                      <FormCol key={field.label}>
                        <TextField
                          placeholder={field?.placeholder}
                          name={field.name}
                          ref={register}
                        />
                      </FormCol>
                    </StyledFlex>
                  ) : (
                    field.options && (
                      <StyledFlex flexDirection="column">
                        {field.label ? (
                          <Label>{field.label}</Label>
                        ) : (
                          <EmptyLabel />
                        )}
                        <FormCol>
                          <Controller
                            control={control}
                            name={field.name}
                            render={({ value, onChange }) => {
                              return (
                                <Select
                                  placeholder={field.placeholder}
                                  options={field.options!}
                                  search
                                  name={field.name}
                                  selected={value}
                                  onSelected={(val) => {
                                    onChange(val);
                                  }}
                                />
                              );
                            }}
                          />
                        </FormCol>
                      </StyledFlex>
                    )
                  )
                )}
              </FormRow>
            </Box>
          ))}

          <Box mt={4}>
            <Button label="Zastosuj filtry" variant="primary" type="submit" />
          </Box>
        </form>
      </Box>
    </StyledCard>
  );
};
