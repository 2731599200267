import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Person } from "components/Person";
import React from "react";
import styled from "styled-components";

export interface IUsersListItem {
  id: number;
  user: {
    id?: number;
    firstName: string;
    lastName: string;
    description: string;
    avatar: string | null;
  };
}

export interface IUsersList {
  items: Array<IUsersListItem>;
  extraRender?: (item: IUsersListItem) => React.ReactNode;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[2]}px;

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const More = styled.div`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    margin-left: ${theme.space[2]}px;
    cursor: pointer;
  `}
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 5px 0;
`;

const Dotted = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const UsersList: React.FC<IUsersList> = ({ items, extraRender }) => {
  return (
    <div>
      {items &&
        items.map((item) => (
          <StyledCard key={item.id}>
            <Box p={4} pl={6}>
              <Flex alignItems="center" justifyContent="space-between">
                <Person {...item.user} />
                <Flex alignItems="center">
                  {extraRender ? extraRender(item) : null}
                  <More>
                    <StyledFlex
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Dotted />
                      <Dotted />
                      <Dotted />
                    </StyledFlex>
                  </More>
                </Flex>
              </Flex>
            </Box>
          </StyledCard>
        ))}
    </div>
  );
};
