import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Select } from "components/Select";
import { TextField } from "components/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { AdvertisementCategoryType } from "types/advertisement";
import * as yup from "yup";

import { INameAndOfferTypeFormData } from "./types";

const typeOptions: Array<{
  value: AdvertisementCategoryType;
  label: string;
}> = [
  { value: "apartment", label: "Mieszkanie" },
  { value: "tenement_house", label: "Kamienica" },
  { value: "service_premises", label: "Lokal usługowy" },
  { value: "warehouse", label: "Magazyny i hale" },
  { value: "plot", label: "Działka pod inwestycję" },
];

interface INameAndOfferTypeStep {
  onCancelClick: () => void;
  onSubmit: (data: INameAndOfferTypeFormData) => void;
}

export const NameAndOfferTypeStep: React.FC<INameAndOfferTypeStep> = ({
  onCancelClick,
  onSubmit,
}) => {
  const { register, handleSubmit, errors, formState } =
    useForm<INameAndOfferTypeFormData>({
      mode: "onChange",
      resolver: yupResolver(
        yup.object().shape({
          name: yup.string().required(),
          category: yup.string().required(),
        })
      ),
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <TextField
          name="name"
          ref={register()}
          label="Nazwa oferty"
          error={errors.name?.message}
        />
      </Box>
      <Select
        name="category"
        ref={register()}
        label="Typ obiektu"
        search
        selected={["apartment" as AdvertisementCategoryType]}
        options={typeOptions}
      />
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick}
          />
          <Button type="submit" label="Dalej" disabled={!formState.isValid} />
        </ButtonsGroup>
      </Box>
    </form>
  );
};
