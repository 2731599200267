import { GoogleMap, Marker } from "@react-google-maps/api";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Card } from "components/Card";
import { Checkbox } from "components/Checkbox";
import { ConfirmModal } from "components/ConfirmModal";
import { FileUpload } from "components/FileUpload";
import { Flex } from "components/Flex";
import { Select } from "components/Select";
import { TextareaField } from "components/Textarea";
import { IHint, TextField } from "components/TextField/TextField";
import { Typography } from "components/Typography";
import { generateDataForFields } from "database/category";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editAdvertisement, fetchAdvertisement } from "services/advertisements";
import styled from "styled-components";
import { AdvertisementCategoryType, IAdvertisement } from "types/advertisement";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export interface IOption {
  value: string;
  label: string;
}
interface IVariant {
  name: string;
  label: string;
  options?: Array<IOption>;
  value?: string | number | boolean;
  type: "text" | "dropdown" | "check";
}
export interface ILocalizationFormData {
  address: string;
  longitude: number;
  latitude: number;
}

const LeftCol = styled(Col)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.veryLight};
    padding: ${theme.space[8]}px;
    padding-left: ${theme.space[11]}px !important;
  `}
`;
const RightCol = styled(Col)`
  ${({ theme }) => `
    padding: ${theme.space[8]}px;
    padding-left: ${theme.space[16]}px !important;
    padding-right: ${theme.space[16]}px !important;
  `}
`;
const Img = styled.img`
  ${({ theme }) => `
    height: 100px;
    margin: ${theme.space[1]}px;
  `}
`;

const typeOptions: Array<{
  value: AdvertisementCategoryType;
  label: string;
}> = [
  { value: "apartment", label: "Mieszkanie" },
  { value: "tenement_house", label: "Kamienica" },
  { value: "service_premises", label: "Lokal usługowy" },
  { value: "warehouse", label: "Magazyny i hale" },
  { value: "plot", label: "Działka pod inwestycję" },
];

const centerLatLng = {
  lat: -35.745,
  lng: -38.523,
};

const defaultValues = {
  address: "",
  area: 0,
  building_conditions: "",
  canalization: false,
  description: "",
  description_of_the_roi_calculation_method: "",
  development_plan: false,
  dimension_x: 0,
  dimension_y: 0,
  electricity: false,
  finished: false,
  floor: false,
  gas: false,
  id: 0,
  images: [],
  latitude: 0,
  legal_status_of_the_facility: "",
  longitude: 0,
  max_area_of_apartments: null,
  min_area_of_apartments: null,
  mutual_contacts_count: 0,
  my_negotiation: null,
  name: "",
  negotiations_count: 0,
  number_of_apartments: null,
  number_of_apartments_on_floor: null,
  number_of_floors: null,
  number_of_plot: null,
  price: 0,
  roi: "",
  voivodship: "",
  water: null,
  year_of_construction: null,
};

export const Edit = () => {
  const history = useHistory();
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [interiorImages, setInteriorImages] = useState<Array<File>>([]);
  const [advertisement, setAdvertisement] =
    useState<IAdvertisement | null>(null);
  const [fields, setFields] = useState<any>([]);

  const { id } = useParams<{ id: string }>();

  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
  });

  const getAdvertisament = async () => {
    const parsedID = parseInt(id, 10);
    try {
      const adv = await fetchAdvertisement({ id: parsedID });
      setAdvertisement(adv);
      if (adv) {
        setFields(generateDataForFields(adv.category));
      }
    } catch (err) {
      toast.error("Nie udało się pobrać danych.");
    }
  };

  const { register, handleSubmit, errors, control, reset } = useForm<any>({
    defaultValues,
  });

  const handleLocalizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const hints: Array<IHint> = data.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id,
  }));

  const handleSelect = async (hint: IHint) => {
    setValue(hint.label, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address: hint.label });
      const { lat, lng } = await getLatLng(results[0]);
      if (!map) return;
      if (advertisement) {
        map.panTo({
          lat: advertisement.latitude,
          lng: advertisement.longitude,
        });
      } else {
        map.panTo({ lat, lng });
      }
      map.setZoom(14);
      if (advertisement) {
        setMarker({
          lat: advertisement.latitude,
          lng: advertisement.longitude,
        });
      } else {
        setMarker({ lat, lng });
      }
    } catch (error) {
      toast.error("Nie udało się wybrać lokalizacji.");
    }
  };

  const onLoadMap = React.useCallback(function callback(mapObj) {
    const bounds = new window.google.maps.LatLngBounds();
    mapObj.fitBounds(bounds);
    setMap(mapObj);
  }, []);

  const onUnmountMap = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const onSubmit = (formData: any) => {
    const updatedData = {
      ...formData,
      ...{ address: value, longitude: marker.lng, latitude: marker.lat },
      ...{ images: [...interiorImages] },
    };
    try {
      editAdvertisement(id, updatedData);
      toast.success("Twoje zmiany zostało zapisane.");
    } catch (err) {
      toast.error("Nie udało się zapisać zmian.");
    }
  };

  const handleFileUpload =
    (setter: React.Dispatch<React.SetStateAction<File[]>>) =>
    (files: Array<File>) => {
      setter(files);
    };

  const handleCloseAdvertisement = () => {
    try {
      setIsOpen(false);
      editAdvertisement(id, { finished: true });
      toast.success("Ogłoszenie zostało zamknięte pomyślnie.");
      history.push("/advertisements/finished?page=1");
    } catch (err) {
      toast.error("Nie udało się zamknąć ogłoszenia.");
    }
  };

  useEffect(() => {
    getAdvertisament();
  }, []);

  useEffect(() => {
    reset({ ...defaultValues, ...advertisement });
  }, [advertisement]);

  const renderFildView = (fieldData: IVariant): any => {
    if (advertisement) {
      // eslint-disable-next-line dot-notation
    }
    switch (fieldData.type) {
      case "check": {
        return (
          <Checkbox
            name={fieldData.name}
            ref={register}
            label={fieldData.label}
          />
        );
      }
      case "dropdown": {
        return (
          <Controller
            control={control}
            name={fieldData.name}
            render={(field) => {
              return (
                <Select
                  label={fieldData.label}
                  options={fieldData.options!}
                  ref={register}
                  name={fieldData.name}
                  selected={advertisement?.voivodship}
                  onSelected={(val) => {
                    field.onChange(val);
                  }}
                />
              );
            }}
          />
        );
      }
      case "text":
        return (
          <TextField
            ref={register}
            label={fieldData.label}
            name={fieldData.name}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" mb={3}>
        <Typography variant="h1" color="primary" mb={3}>
          {advertisement?.name} - edycja
        </Typography>

        <Button
          label="Przenieś do zakończonych"
          onClick={() => setIsOpen(true)}
        />
      </Flex>

      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftCol sm={4}>
              <Typography variant="h3" color="primary">
                Nazwa i typ oferty.
              </Typography>
            </LeftCol>
            <RightCol sm={8}>
              <Flex mb={3}>
                <TextField name="name" ref={register} label="Nazwa oferty" />
              </Flex>
              {advertisement?.category && (
                <Select
                  ref={register}
                  name="category"
                  label="Typ obiektu"
                  search
                  selected={
                    advertisement?.category as AdvertisementCategoryType
                  }
                  options={typeOptions}
                />
              )}
            </RightCol>
          </Row>
          <Row>
            <LeftCol sm={4}>
              <Typography variant="h3" color="primary">
                Dane podstawowe
              </Typography>
            </LeftCol>
            <RightCol sm={8}>
              {fields.map((fieldData: any) => (
                <Box mb={3}>
                  {advertisement?.id && renderFildView(fieldData)}
                </Box>
              ))}
            </RightCol>
          </Row>
          <Row>
            <LeftCol sm={4}>
              <Typography variant="h3" color="primary">
                Opis oferty
              </Typography>
            </LeftCol>
            <RightCol sm={8}>
              <Box mb={6}>
                <TextareaField
                  name="description"
                  ref={register()}
                  label="Opis oferty"
                  error={errors.description?.message}
                />
              </Box>
              <ButtonsGroup>
                <Button type="submit" label="Zapisz zmiany" />
              </ButtonsGroup>
            </RightCol>
          </Row>
          {/* <Row>
            <LeftCol sm={4}>
              <Typography variant="h3" color="primary">
                Lokalizacja
              </Typography>
            </LeftCol>
            <RightCol sm={8}>
              <Box mb={4}>
                <TextField
                  label="Lokalizacja"
                  onChange={handleLocalizationChange}
                  onHintClick={handleSelect}
                  hints={hints}
                  value={value}
                />
              </Box>
              <GoogleMap
                mapContainerStyle={{ height: 245 }}
                center={centerLatLng}
                zoom={10}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                }}
                onLoad={onLoadMap}
                onUnmount={onUnmountMap}
              >
                <>
                  <Marker position={{ lat: marker.lat, lng: marker.lng }} />
                </>
              </GoogleMap>
            </RightCol>
          </Row> */}
          {/* <Row>
            <LeftCol sm={4}>
              <Typography variant="h3" color="primary">
                Zdjęcia
              </Typography>
            </LeftCol>
            <RightCol sm={8}>
              <Box mb={6}>
                <Typography variant="h3" mb={3}>
                  Zdjęcia
                </Typography>
                <FileUpload onFile={handleFileUpload(setInteriorImages)} />
                {advertisement &&
                  advertisement.images.map((image: string) => (
                    <Img src={image} alt={image} key={image} />
                  ))}
              </Box>
              <ButtonsGroup>
                <Button type="submit" label="Zapisz zmiany" />
              </ButtonsGroup>
            </RightCol>
          </Row> */}
        </form>
      </Card>
      <ConfirmModal
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
        handleConfirm={handleCloseAdvertisement}
      />
    </>
  );
};
