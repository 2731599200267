import { Router } from "components/Router/Router";
import { Typography } from "components/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { IRoute } from "types/route";
import { AdvertisementsView } from "views/Advertisements";
import { AdvertisementsCurrentView } from "views/Advertisements/Current";
import { Edit } from "views/Advertisements/Edit";
import { AdvertisementsFinishedView } from "views/Advertisements/Finished";
import { ContactsView } from "views/Contacts";
import { ContactsInvitationsView } from "views/Contacts/Invitations";
import { ContactsMightKnowView } from "views/Contacts/MightKnow";
import { ContactsMyView } from "views/Contacts/My";
import { ContactsWantToHightenInvestitionsLevelView } from "views/Contacts/WantToHightenInvestitionsLevel";
import { ContactsWantToScaleView } from "views/Contacts/WantToScale";
import { HomeView } from "views/Home";
import { LoginView } from "views/Login";
import { MessagesView } from "views/Messages";
import { ChatInvestor } from "views/NegotiationProcess/ChatInvestor";
import { NegotiationsView } from "views/Negotiations";
import { NegotiationsCurrentView } from "views/Negotiations/Current";
import { NegotiationsFinishedView } from "views/Negotiations/Finished";
import { NotificationsView } from "views/Notifications";
import { OtherProfileView } from "views/OtherProfile";
import { OtherProfileAdvertisementsView } from "views/OtherProfile/OtherProfileAdvertisements";
import { OtherProfileContactsView } from "views/OtherProfile/OtherProfileContacts";
import { OtherProfileInfoView } from "views/OtherProfile/OtherProfileInfo";
import { PasswordResetView } from "views/PasswordReset";
import { NewPasswordView } from "views/PasswordReset/NewPassword";
import { ProfileView } from "views/Profile";
import { ProfileInfoView } from "views/Profile/ProfileInfo";
import { ProfileSettingsView } from "views/Profile/Settings";
import { ExpectationsAndPreferencesView } from "views/Profile/Settings/ExpectationsAndPreferences";
import { ExperienceAndSpecializationView } from "views/Profile/Settings/ExperienceAndSpecialization";
import { FirmDataView } from "views/Profile/Settings/FirmData";
import { LoginDataView } from "views/Profile/Settings/LoginData";
import { PersonalDataView } from "views/Profile/Settings/PersonalData";
import { RegisterView } from "views/Register";
import { RegisterConfirmation } from "views/RegisterConfirmation/RegisterConfirmation";
import { RegisterProfileView } from "views/RegisterProfile";
import { Search } from "views/Search";
import { SingleAdView } from "views/SingleAd";
import { SingleNegotiationView } from "views/SingleNegotiation";
import { SinglePublicAdView } from "views/SinglePublicAd";

export const routes: Array<IRoute> = [
  {
    path: "/",
    component: HomeView,
    exact: true,
  },
  {
    path: "/login",
    component: LoginView,
    exact: true,
  },
  {
    path: "/register",
    component: RegisterView,
    exact: true,
  },
  {
    path: "/register/confirmation/:token",
    component: RegisterConfirmation,
    exact: true,
  },
  {
    path: "/register/profile",
    component: RegisterProfileView,
    exact: true,
    private: true,
  },
  {
    path: "/notifications",
    component: NotificationsView,
    exact: true,
    private: true,
  },
  {
    path: "/profiles/:id",
    component: OtherProfileView,
    private: true,
    routes: [
      {
        path: "/profiles/:id/info/",
        component: OtherProfileInfoView,
        exact: true,
      },
      {
        path: "/profiles/:id/contacts/",
        component: OtherProfileContactsView,
        exact: true,
      },
      {
        path: "/profiles/:id/advertisements/",
        component: OtherProfileAdvertisementsView,
        exact: true,
      },
    ],
  },
  {
    path: "/advertisements/:id/edit",
    component: Edit,
    private: true,
  },
  {
    path: "/profile",
    component: ProfileView,
    private: true,
    routes: [
      {
        path: "/profile/info",
        component: ProfileInfoView,
        exact: true,
      },
      {
        path: "/profile/settings",
        component: ProfileSettingsView,
        routes: [
          {
            path: "/profile/settings/login-data",
            component: LoginDataView,
            exact: true,
          },
          {
            path: "/profile/settings/personal-data",
            component: PersonalDataView,
            exact: true,
          },
          {
            path: "/profile/settings/company-data",
            component: FirmDataView,
            exact: true,
          },
          {
            path: "/profile/settings/experience-and-specialization",
            component: ExperienceAndSpecializationView,
            exact: true,
          },
          {
            path: "/profile/settings/expectations-and-preferences",
            component: ExpectationsAndPreferencesView,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    path: "/messages",
    component: MessagesView,
    exact: true,
    private: true,
  },
  {
    path: "/password-reset",
    component: PasswordResetView,
    exact: true,
  },
  {
    path: "/password-reset/new-password",
    component: NewPasswordView,
    exact: true,
  },
  {
    path: "/search",
    component: Search,
  },
  {
    path: "/advertisements",
    component: AdvertisementsView,
    private: false,
    routes: [
      {
        path: "/advertisements/current",
        component: AdvertisementsCurrentView,
        exact: true,
      },
      {
        path: "/advertisements/finished",
        component: AdvertisementsFinishedView,
        exact: true,
      },
    ],
  },
  {
    path: "/advertisement/:id",
    component: SingleAdView,
    exact: true,
  },
  {
    path: "/advertisement/:id/public",
    component: SinglePublicAdView,
    exact: true,
  },
  {
    path: "/advertisement/:id/negotiations",
    component: SingleNegotiationView,
    exact: true,
    private: true,
  },
  {
    path: "/advertisement/:id/negotiations/:negotiationID/chat",
    component: ChatInvestor,
    private: true,
    exact: true,
  },
  {
    path: "/negotiations",
    component: NegotiationsView,
    private: true,
    routes: [
      {
        path: "/negotiations/current",
        component: NegotiationsCurrentView,
      },
      {
        path: "/negotiations/finished",
        component: NegotiationsFinishedView,
      },
    ],
  },
  {
    path: "/contacts",
    component: ContactsView,
    private: true,
    routes: [
      {
        path: "/contacts/my",
        component: ContactsMyView,
        exact: true,
      },
      {
        path: "/contacts/might-know",
        component: ContactsMightKnowView,
        exact: true,
      },
      {
        path: "/contacts/want-to-highten-investitions-level",
        component: ContactsWantToHightenInvestitionsLevelView,
        exact: true,
      },
      {
        path: "/contacts/want-to-scale",
        component: ContactsWantToScaleView,
        exact: true,
      },
      {
        path: "/contacts/invitations",
        component: ContactsInvitationsView,
        exact: true,
      },
    ],
  },
  {
    path: "/about",
    component: () => (
      <>
        <Typography variant="h1" color="primary">
          About us
        </Typography>
        <Typography variant="body">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat non
          libero eos vitae sapiente pariatur nisi reprehenderit velit incidunt
          placeat, voluptatem sint at cupiditate aut quas, hic, exercitationem
          animi est.
        </Typography>
      </>
    ),
    exact: true,
  },
  {
    path: "/parp-info",
    component: () => (
      <>

        <Typography variant="h1" color="primary">
          OPRACOWANIE ALGORYTMU OPTYMALIZUJĄCEGO PROCES ŁĄCZENIA INWESTYCJI Z INWESTORAMI <br />
          POPRZEZ PROFILOWANIE OFERT Z ZASTOSOWANIEM W BRANŻY NIERUCHOMOŚCI
        </Typography>
        <Typography variant="body">
        <br />
        Przedmiotem projektu jest opracowanie algorytmu, który pozwoli na optymalizującej procesu łączenia inwestycji z inwestorami poprzez profilowanie ofert w branży nieruchomości.<br /><br />
        Celem projektu jest wprowadzenie opracowanego algorytmu optymalizującego proces łączenia inwestycji z inwestorami poprzez profilowanie ofert, który ma na celu w znacznym stopniu usprawnić procesy wewnętrzne organizacji.<br /><br />
        Projekt jest współfinansowany w ramach Europejskiego Funduszu Rozwoju Regionalnego ze środków Programu Operacyjnego Inteligentny Rozwój 2014-2020.<br /><br />
        Oś priorytetowa II: Wsparcie otoczenia i potencjału przedsiębiorstw do prowadzenia działalności B+R+I<br /><br />
        Działanie 2.3 Proinnowacyjne usługi dla przedsiębiorstw<br /><br />
        Poddziałanie 2.3.2 Bony na innowacje dla MŚP<br /><br />
        Beneficjent: JACEK KUSIAK K INVESTMENTS<br /><br />
        Całkowita wartość projektu: 484 989,62 PLN
        Wartość dofinansowania: 335 155,40 PLN<br /><br />
        Okres realizacji: 01/03/2020 - 31/12/2020
        </Typography>
      </>
    ),
    exact: true,
  },
];
