import "moment/locale/pl";

import { Avatar, AvatarI } from "components/Avatar/Avatar";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { TypographyType } from "components/Typography/Typography";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { IGoToUserInfo } from "utilities/goToUserInfoHelper";

interface IMedia extends IGoToUserInfo {
  avatar?: AvatarI["url"];
  firstName?: string;
  lastName?: string;
  userId?: number;
  date: Date;
  content: string;
  color?: TypographyType["color"];
}

const WrapperAvatar = styled.div`
  margin-right: 16px;
`;

const Date = styled(Typography).attrs({
  variant: "body2" as TypographyType["variant"],
})`
  opacity: 0.7;
`;

interface IStyledFlex {
  setPointer?: boolean;
}
const StyledFlex = styled(Flex)<IStyledFlex>`
  ${({ theme, setPointer }) => `
    ${setPointer ? `cursor: pointer;` : `cursor: auto;`}
  `}
`;

export const Media: React.FC<IMedia> = ({
  avatar,
  firstName = "Użytkownik",
  lastName = "ukryty",
  userId,
  date,
  content,
  color,
  goToUserInfo,
}) => (
  <StyledFlex
    setPointer={!!userId}
    onClick={() => goToUserInfo && goToUserInfo(userId)}
  >
    <WrapperAvatar>
      <Avatar size="small" url={avatar} />
    </WrapperAvatar>
    <div>
      <Typography variant="h2" color={color}>
        {firstName} {lastName}
      </Typography>
      <Date variant="body2">
        <Moment locale="pl" fromNow>
          {date}
        </Moment>
      </Date>

      <Typography variant="body">{content}</Typography>
    </div>
  </StyledFlex>
);
