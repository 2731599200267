import { Button, Card, Typography } from "components";
import { Box } from "components/Box";
import { Comments } from "components/Comments";
import { ContactsListModal } from "components/ContactsListModal";
import { Gallery } from "components/Gallery";
import { InfoGrid } from "components/InfoGrid";
import { Map } from "components/Map";
import { Person } from "components/Person";
import { useComments } from "hooks/comments";
import { useContacts } from "hooks/contacts";
import { ReactComponent as FavoriteIcon } from "icons/like_on.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as FavoriteEmptyIcon } from "icons/star.svg";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addAdvertisementToFavourite,
  fetchAdvertisement,
  IFetchAdvertisementResponse,
  removeAdvertisementFromFavourite,
  shareWithFriends,
} from "services/advertisements";
import { createNegotiaion } from "services/negotiations";
import { IRootState } from "store/rootReducer";
import styled from "styled-components";
import { AdvertisementCategoryType } from "types/advertisement";
import { confirmEmailInfo } from "utilities/confirmEmailInfo";
import { eventBus } from "utilities/eventBus";
import { generateAdvertisamentsArrayData } from "utilities/generateAdvertisametDataArray";
import { goToUserInfo } from "utilities/goToUserInfoHelper";

interface ParamTypes {
  id: string;
}

const ButtonsContainer = styled.div`
  ${({ theme }) => `
      margin-top: ${theme.space[8]}px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      & > * {
        margin-left: ${theme.space[4]}px;
      }
  `}
`;

export const SingleAdView: React.FC = () => {
  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const parsedId = parseInt(id, 10);

  const [data, setData] = useState<IFetchAdvertisementResponse["data"] | null>(
    null
  );
  const [disabledNegotiationButton, setDisabledNegotiationButton] =
    useState<boolean>(false);

  const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  const [contactsModalIsOpen, setContactsModalIsOpen] = useState(false);

  const { handleSubmitComment, items, setItems } = useComments({
    advertisementId: Number(id),
    comments: [],
  });

  const { contacts, retrieveContacts } = useContacts({});

  useEffect(() => {
    if (!currentUser.id) {
      history.push(`${history.location.pathname}/public`);
    }
  }, [history, storedUser]);


  useEffect(() => {
    retrieveContacts({ page: 1 });
    fetchAdvertisement({ id: parsedId }).then((response) => {
      setData(response);
      const isMyNegotiation = !!response.my_negotiation?.id;
      setDisabledNegotiationButton(isMyNegotiation);
      setItems(response.comments);
    });
  }, []);

  useEffect(() => {

      setEmailConfirmed(true);
  }, []);

  const onAddFavouriteClick = async (adId: number) => {
    try {
      const response = await addAdvertisementToFavourite({
        advertisement_id: adId,
      });
      setData((prevState) => {
        return prevState
          ? { ...prevState, favourite_advertisement: response }
          : null;
      });
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else {
        toast.error("Nie udało się dodać do ulubionych.");
      }
    }
  };

  const onRemoveFavouriteClick = async (adId: number) => {
    try {
      await removeAdvertisementFromFavourite({
        advertisement_id: adId,
      });
      setData((prevState) => {
        return prevState
          ? { ...prevState, favourite_advertisement: null }
          : null;
      });
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else {
        toast.error("Nie udało się usunąć z ulubionych.");
      }
    }
  };

  // const handleOpenContactModal = () => {};

  const handleInvested = async () => {
    try {
      setDisabledNegotiationButton(true);
      const response = (await createNegotiaion(id)) as any;
      if (response.data.id) {
        setTimeout(() => {
          history.push(
            `/advertisement/${response.data.advertisement_id}/negotiations/${response.data.id}/chat`
          );
        }, 1000);
      }
    } catch (err) {
      toast.error("Coś poszło nie tak.");
    }
  };

  const shareWithUsers = async (users: any) => {
    try {
      await shareWithFriends(id, [users.id]);
      history.go(0);
      toast.success("Ogłoszenie zostało udostępnione.");
    } catch (err) {
      toast.error("Nie udało się udostępnić ogłoszenia.");
    }
  };

  const toggleFavourite = () => {
    if (!data) return false;
    return data?.favourite_advertisement
      ? onRemoveFavouriteClick(data?.favourite_advertisement?.id)
      : onAddFavouriteClick(data.id);
  };

  if (Number.isNaN(parsedId)) return <h1>Nieprawidłowy identyfikator</h1>;
  return (
    data && (
      <>
        <Typography variant="h1" color="primary" mb={2}>
          {data.name}
        </Typography>
        <Row>
          <Col sm={8}>
            <Box mb={4}>
              <Card>
                <Box p={8}>
                  <Gallery items={data.images} />
                  <InfoGrid
                    data={generateAdvertisamentsArrayData(
                      data.category as AdvertisementCategoryType,
                      data as IFetchAdvertisementResponse["data"]
                    )}
                  />
                  <Box py={4}>
                    <Typography variant="body">{data.description}</Typography>
                  </Box>
                  <Map lat={data.latitude} lng={data.longitude} />
                  <ButtonsContainer>
                    <Box m={1}>
                      <Button
                        onClick={toggleFavourite}
                        variant="secondary"
                        icon={
                          data?.favourite_advertisement ? (
                            <FavoriteIcon />
                          ) : (
                            <FavoriteEmptyIcon />
                          )
                        }
                      />
                    </Box>
                    <Box m={1}>
                      <Button
                        onClick={() => {
                          eventBus.dispatch("showAdvertisementModal", {
                            isOpen: true,
                            id,
                          });
                        }}
                        variant="secondary"
                        icon={<ShareIcon />}
                        fullWidth
                      />
                    </Box>

                    {data.user.id !== currentUser.id ? (
                      <>
                        <Box m={1}>
                          <Button
                            label="Udostępnij znajomemu"
                            isDisabled={!isEmailConfirmed}
                            onClick={
                              isEmailConfirmed
                                ? () => setContactsModalIsOpen(true)
                                : confirmEmailInfo
                            }
                          />
                        </Box>
                        <Box m={1}>
                          <Button
                            disabled={disabledNegotiationButton}
                            label="Chcę zainwestować sam"
                            variant="primary"
                            isDisabled={!isEmailConfirmed}
                            onClick={
                              isEmailConfirmed
                                ? () => handleInvested()
                                : confirmEmailInfo
                            }
                          />
                        </Box>
                      </>
                    ) : null}
                  </ButtonsContainer>
                </Box>
              </Card>
            </Box>
          </Col>
          <Col sm={4}>
            <Box mb={4}>
              <Card>
                <Box p={8}>
                  <Box pb={4}>
                    <Typography variant="h2" color="primary">
                      Wystawiający ofertę
                    </Typography>
                  </Box>
                  {/* TODO: replace !description! with meaningful data from the API */}
                  <Person
                    id={data.user.id}
                    firstName={data.user.profile.first_name || undefined}
                    lastName={data.user.profile.last_name || undefined}
                    avatar={data.user.avatar || undefined}
                    goToUserInfo={goToUserInfo(history)}
                    description={data?.user?.profile?.description}
                  />
                </Box>
              </Card>
            </Box>
            <Comments items={items} onSubmitCallback={handleSubmitComment} />
          </Col>
        </Row>
        <ContactsListModal
          contacts={contacts}
          buttonLabel="Udostępnij"
          isOpen={contactsModalIsOpen}
          handleAction={shareWithUsers}
          onCancelClick={() => setContactsModalIsOpen(false)}
        />
      </>
    )
  );
};
