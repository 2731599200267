import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { LoginLayout } from "components/LoginLayout";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import qs from "qs";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordReset } from "services/passwordReset";
import styled from "styled-components";

interface INewPasswordView {
  location: {
    search: string;
  };
}
interface INewPassword {
  password: string;
  passwordConfirmation: string;
}

const StyledFlex = styled(Flex)`
  ${({ theme }) => `
    height: 100%;
    padding-bottom: ${theme.space[8]}px;
  `}
`;
const Form = styled.form`
  height: 100%;
`;

export const NewPasswordView: React.FC<INewPasswordView> = ({ location }) => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const history = useHistory();
  const { register, errors, handleSubmit, watch } = useForm<INewPassword>();
  const { token } = qs.parse(location.search.slice(1));
  const newPassword = watch("password");

  const onSubmit = async ({ password, passwordConfirmation }: INewPassword) => {
    try {
      setSuccess(true);
      await passwordReset({
        token: token as string,
        password,
        passwordConfirmation,
      });
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
        setSuccess(false);
      }
    }
  };

  const backToLoginPage = () => {
    try {
      history.push("/login");
    } catch (err) {
      toast.error(err.error);
    }
  };

  return (
    <LoginLayout>
      <>
        <Box mb={2}>
          <Typography variant="h1" color="primary">
            Resetowanie hasła
          </Typography>
        </Box>
        {success ? (
          <StyledFlex flexDirection="column" justifyContent="space-between">
            <>
              <Typography variant="body">
                Twoje hasło zostało poprawnie zmienione.
              </Typography>
              <Button label="Wróć do logowania" onClick={backToLoginPage} />
            </>
          </StyledFlex>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <StyledFlex flexDirection="column" justifyContent="space-between">
              <div>
                <Box mb={3}>
                  <TextField
                    name="password"
                    type="password"
                    ref={register({
                      required: true,
                      minLength: 6,
                      maxLength: 128,
                    })}
                    error={errors.password && "Hasło jest nieprawidłowe"}
                    label="Nowe hasło"
                    placeholder="Nowe hasło"
                  />
                </Box>
                <TextField
                  name="passwordConfirmation"
                  ref={register({
                    required: true,
                    validate: {
                      comfirm: (value) => value === newPassword,
                    },
                  })}
                  error={errors.passwordConfirmation && "Hasła są niezgldne."}
                  type="password"
                  label="Powtórz nowe hasło"
                  placeholder="Powtórz nowe hasło"
                />
              </div>
              <Button type="submit" label="Resetuj hasło" fullWidth />
            </StyledFlex>
          </Form>
        )}
      </>
    </LoginLayout>
  );
};
