import { GoogleMap, Marker } from "@react-google-maps/api";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { IHint, TextField } from "components/TextField/TextField";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { ILocalizationFormData } from "./types";

interface ILocalizationStep {
  onCancelClick?: () => void;
  onSubmit?: (data: ILocalizationFormData) => void;
}

const centerLatLng = {
  lat: -35.745,
  lng: -38.523,
};

export const LocalizationStep: React.FC<ILocalizationStep> = ({
  onCancelClick,
  onSubmit,
}) => {
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
  });

  const onLoad = useCallback(function callback(mapObj) {
    const bounds = new window.google.maps.LatLngBounds();
    mapObj.fitBounds(bounds);
    setMap(mapObj);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (hint: IHint) => {
    setValue(hint.label, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: hint.label });
      const { lat, lng } = await getLatLng(results[0]);
      if (!map) return;
      map.panTo({ lat, lng });
      map.setZoom(14);
      setMarker({ lat, lng });
      setIsNextButtonDisabled(false);
    } catch (error) {
      toast.error("Nie udało się wybrać lokalizacji.");
    }
  };

  const hints: Array<IHint> = data.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id,
  }));

  const handleSubmit = () => {
    if (!onSubmit) return;
    onSubmit({ address: value, longitude: marker.lng, latitude: marker.lat });
  };

  return (
    <>
      <Box mb={4}>
        <TextField
          label="Lokalizacja"
          onChange={handleInputChange}
          onHintClick={handleSelect}
          hints={hints}
          value={value}
        />
      </Box>
      <GoogleMap
        mapContainerStyle={{ height: 245 }}
        center={centerLatLng}
        zoom={10}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          <Marker position={{ lat: marker.lat, lng: marker.lng }} />
        </>
      </GoogleMap>
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick && onCancelClick}
          />
          <Button
            type="submit"
            label="Dalej"
            disabled={isNextButtonDisabled}
            onClick={handleSubmit}
          />
        </ButtonsGroup>
      </Box>
    </>
  );
};
