import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.min.css";

import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { IPerson, Person } from "components/Person/Person";
import { Typography } from "components/Typography";
import { categoryTypeOptions } from "constants/advertisementHelpers";
import { voivodships } from "database/voivodship";
import { useProfile } from "hooks/profile";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as FavoriteIcon } from "icons/like_on.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as FavoriteEmptyIcon } from "icons/star.svg";
import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { eventBus } from "utilities/eventBus";
import { rgba } from "utilities/rgba";

type setIsShareAdvertisementModalOpenType = (data: {
  isOpen: boolean;
  id: number;
}) => void;

interface IMyNegotiation {
  advertisement_id: number;
  conversation_id: number;
  finished: boolean;
  id: number;
  price: null;
  state: string;
  user_id: number;
}

export interface IInvestment {
  favourite: number | null;
  id: number;
  view: "horizontal" | "vertical";
  images: Array<string>;
  name: string;
  price: number;
  roi: string;
  area: number;
  localization: string;
  type: string;
  legalStatus?: number | string;
  owner?: IPerson;
  onShowAdvertisementClick?: (id: number) => void;
  onShowNegotiationsClick?: (id: number, negotiationId?: number) => void;
  onAddFavouriteClick?: (id: number) => void;
  onRemoveFavouriteClick?: (id: number, investmentId: number) => void;
  setIsShareAdvertisementModalOpen?: setIsShareAdvertisementModalOpenType;
  handleOpenToHandlePrice?: (id: number) => void;
  showFavourite?: boolean;
  showShare?: boolean;
  negotiations_count?: number;
  my_negotiation?: IMyNegotiation;
  my?: boolean;
  myAdvertisements?: boolean;
  currentUserId?: number;
  editInvestment?: (id: number) => void;
  description?: string;
}

interface IImages {
  view: IInvestment["view"];
}

interface IValue {
  view: IInvestment["view"];
  label: string;
  currentUserId?: number;
  ownerId?: number;
  isHover?: boolean;
}

interface IStyledBox {
  view: IInvestment["view"];
}

interface IBackground {
  url: string;
}

interface IItem {
  label: string;
  value: React.ReactNode;
}

const Images = styled.div<IImages>`
  ${({ view }) => `
		${
      view === "horizontal"
        ? `
					width: 33%;
				`
        : `
					width: 100%;
					height: 192px;
				`
    }
	`};
`;

const StyledSwiper = styled(Swiper)`
  height: 100%;

  .swiper-pagination {
    &-bullet {
      ${({ theme }) => `
				background-color: transparent;
				opacity: 1;
				border: 1px solid ${theme.palette.neutral.white};
				margin: 0 ${theme.space[2]}px !important;

				&-active {
					background-color: ${theme.palette.neutral.white};
				}
			`}
    }
  }
`;
const Background = styled.div<IBackground>`
  ${({ url }) => `
		display: flex;
		width: 100%;
		height: 100%;
		background-image: url("${url}");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	`};
`;
const Value = styled(Flex)<IValue>`
  ${({ theme, view, label, currentUserId, ownerId, isHover }) => `
		${
      view === "horizontal"
        ? `
				width: 33%;
				&:nth-child(-n + 3) {
					margin-bottom: ${theme.space[2]}px;
				}
				`
        : `
          position: relative;
					width: 50%;
					border-radius: ${theme.space[2]}px;
					margin-left: -${theme.space[3]}px;
					padding: ${theme.space[1]}px ${theme.space[3]}px;

          .editIcon {
            display: none;
          }
          ${
            isHover &&
            `
          &:hover {
          ${
            currentUserId === ownerId && label === "Cena"
              ? `
            background-color: ${theme.palette.neutral.veryLight};
            
              .editIcon {
                display: flex;
              }
            `
              : null
          }
					}
          `
          }
				`
    }
	`}
`;
const StyledBox = styled(Box)<IStyledBox>`
  ${({ view }) => `
		${
      view === "horizontal"
        ? `
					width: 66%;
				`
        : `
					width: 100%;
				`
    }
	`}
`;
const Line = styled.span`
  ${({ theme }) => `
		display: flex;
		background-color: ${rgba(theme.palette.neutral.dark, 0.2)};
		margin: ${theme.space[4]}px 0;
		width: 100%;
		height: 1px;
	`};
`;
const Icon = styled.div<{ onClick: () => void }>`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  cursor: pointer;
`;
const ShareIconWrapper = styled.div`
  cursor: pointer;
`;

// SwiperCore.use([Pagination]);

export const Investment: React.FC<IInvestment> = ({
  favourite,
  id,
  view,
  images,
  name,
  price,
  roi,
  area,
  localization,
  type,
  legalStatus,
  negotiations_count,
  owner,
  onShowAdvertisementClick,
  onShowNegotiationsClick,
  onAddFavouriteClick,
  onRemoveFavouriteClick,
  handleOpenToHandlePrice,
  setIsShareAdvertisementModalOpen,
  showFavourite,
  showShare = true,
  my_negotiation,
  my,
  myAdvertisements,
  description,
  currentUserId,
  editInvestment,
}) => {
  const { user } = useProfile();
  const isLoggedIn = !!(user?.data && user?.data.id);

  const itemsData: Array<IItem> = [
    {
      label: "Cena",
      value: (
        <NumberFormat
          value={price}
          displayType="text"
          thousandSeparator=" "
          suffix=" PLN"
        />
      ),
    },
    {
      label: "ROI",
      // eslint-disable-next-line
      value: `${roi ? roi : "-"}`,
    },
    {
      label: "Powierzchnia",
      // eslint-disable-next-line
      value: `${area ? area + "m²" : "-"}`,
    },
    {
      label: "Województwo",
      // eslint-disable-next-line
      value: `${
        localization
          ? voivodships.find((voivodship) => voivodship.value === localization)
              ?.label
          : "-"
      }`,
    },
    {
      label: "Typ",
      // eslint-disable-next-line
      value: `${
        type ? categoryTypeOptions.find((el) => el.value === type)?.label : "-"
      }`,
    },
    {
      label: "Stan prawny",
      // eslint-disable-next-line
      value: `${legalStatus ? legalStatus : "-"}`,
    },
  ];

  const renderItem = ({ label, value }: IItem) => (
    <Value
      key={label}
      currentUserId={currentUserId}
      ownerId={owner?.id}
      label={label}
      view={view}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      isHover={!!handleOpenToHandlePrice}
    >
      <Flex flexDirection="column">
        <Typography variant="body2">{label}:</Typography>
        <Typography variant="h3">{value}</Typography>
      </Flex>
      {view === "vertical" && handleOpenToHandlePrice && (
        <Icon className="editIcon" onClick={() => handleOpenToHandlePrice(id)}>
          <EditIcon />
        </Icon>
      )}
    </Value>
  );

  const toggleFavourite = () => {
    return favourite
      ? onRemoveFavouriteClick && onRemoveFavouriteClick(favourite, id)
      : onAddFavouriteClick && onAddFavouriteClick(id);
  };

  const renderMyButtons = () => {
    if (myAdvertisements && negotiations_count && onShowNegotiationsClick) {
      return (
        <Button
          onClick={() => onShowNegotiationsClick(id, my_negotiation?.id)}
          label="Przejdź do negocjacji"
        />
      );
    }
    if (!myAdvertisements && my && onShowNegotiationsClick) {
      return (
        <Button
          onClick={() => onShowNegotiationsClick(id, my_negotiation?.id)}
          label="Przejdź do negocjacji"
        />
      );
    }
    return null;
  };

  const person = owner ?? {
    firstName: "Użytkownik",
    lastName: "ukryty",
  };

  return (
    <Card>
      <Flex flexDirection={view === "horizontal" ? "row" : "column"}>
        <Images view={view}>
          <StyledSwiper spaceBetween={30} pagination={{ clickable: true }}>
            {images &&
              images.map((imageUrl) => (
                <SwiperSlide key={imageUrl}>
                  <Background url={imageUrl} />
                </SwiperSlide>
              ))}
          </StyledSwiper>
        </Images>

        <StyledBox view={view} p={8} pt={5}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography variant="h2" color="primary" mb={3}>
              {name}
            </Typography>
            {showShare && (
              <ShareIconWrapper
                onClick={() => {
                  eventBus.dispatch("showAdvertisementModal", {
                    isOpen: true,
                    id,
                  });
                }}
              >
                <ShareIcon />
              </ShareIconWrapper>
            )}
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            {itemsData.map(renderItem)}
          </Flex>

          {/* {view === "vertical" && onShowAdvertisementClick && (
            <Flex mt={4} justifyContent="flex-end">
              <Button
                onClick={() => onShowAdvertisementClick(id)}
                label="Przejdź do oferty"
                variant="primary"
              />
            </Flex>
          )} */}

          <Line />
          {view === "horizontal" ? (
            <Flex justifyContent="flex-end" mt={4}>
              {my ? (
                <>
                  <Box mr="auto">
                    <Person {...person} investment />
                  </Box>
                  {editInvestment && (
                    <Box mr={2}>
                      <Button
                        onClick={() => editInvestment(id)}
                        icon={<EditIcon />}
                        variant="secondary"
                      />
                    </Box>
                  )}
                  {onShowAdvertisementClick && (
                    <Box mr={2}>
                      <Button
                        onClick={() => onShowAdvertisementClick(id)}
                        label="Pokaż ogłoszenie"
                        variant="secondary"
                      />
                    </Box>
                  )}
                  <Box mr={2}>
                    {renderMyButtons()}
                  </Box>
                </>
              ) : (
                <>
                  <Box mr="auto">
                    <Person {...person} investment />
                  </Box>
                  <Box mr={2}>
                    {isLoggedIn && (
                      <Button
                        onClick={toggleFavourite}
                        variant="secondary"
                        icon={
                          favourite ? <FavoriteIcon /> : <FavoriteEmptyIcon />
                        }
                      />
                    )}
                  </Box>
                  {onShowAdvertisementClick && (
                    <Button
                      onClick={() => onShowAdvertisementClick(id)}
                      label="Przejdź do oferty"
                      variant="primary"
                    />
                  )}
                </>
              )}
            </Flex>
          ) : (
            <>
              <Flex justifyContent="flex-end" alignItems="center" my={4}>
                {my ? (
                  <>
                    {editInvestment && (
                      <Box mr={2}>
                        <Button
                          onClick={() => editInvestment(id)}
                          icon={<EditIcon />}
                          variant="secondary"
                        />
                      </Box>
                    )}
                    {onShowAdvertisementClick && (
                      <Box mr={2}>
                        <Button
                          onClick={() => onShowAdvertisementClick(id)}
                          label="Pokaż ogłoszenie"
                          variant="secondary"
                        />
                      </Box>
                    )}
                    <Box mr={2}>
                      {renderMyButtons()}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box mr={2}>
                      {isLoggedIn && (
                        <Button
                          onClick={toggleFavourite}
                          variant="secondary"
                          icon={
                            favourite ? <FavoriteIcon /> : <FavoriteEmptyIcon />
                          }
                        />
                      )}
                    </Box>
                    {onShowAdvertisementClick && (
                      <Button
                        onClick={() => onShowAdvertisementClick(id)}
                        label="Przejdź do oferty"
                        variant="primary"
                      />
                    )}
                  </>
                )}
              </Flex>
              <Person {...person} investment description={description} />
            </>
          )}
        </StyledBox>
      </Flex>
    </Card>
  );
};
