import { Button, Card, Typography } from "components";
import { Box } from "components/Box";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Comments } from "components/Comments";
import { Gallery } from "components/Gallery";
import { InfoGrid } from "components/InfoGrid";
import { Map } from "components/Map";
import { Person } from "components/Person";
import { RequireAuthModal } from "components/RequireAuthModal";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchPublicAdvertisement,
  IFetchPublicAdvertisementResponse,
} from "services/advertisements";
import { IPublicAdvertisement } from "types/advertisement";
import { confirmEmailInfo } from "utilities/confirmEmailInfo";
import { eventBus } from "utilities/eventBus";
import { generateAdvertisamentsArrayData } from "utilities/generateAdvertisametDataArray";

interface ParamTypes {
  id: string;
}
interface IComments {
  id: number;
  date: Date;
  content: string;
}

export const SinglePublicAdView: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const parsedId = parseInt(id, 10);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  const [data, setData] = useState<
    IFetchPublicAdvertisementResponse["data"] | null
  >(null);
  const [comments, setComments] = useState<IComments[]>([]);

  const currentUser = useSelector((state: IRootState) => state.currentUser);

  const parsedCommentsData = (
    responseComments: IPublicAdvertisement["comments"]
  ) =>
    responseComments.map((comment) => ({
      id: comment.id,
      date: new Date(comment.created_at),
      content: comment.message,
    }));

  useEffect(() => {
    fetchPublicAdvertisement({ id: parsedId })
      .then((response) => {
        setData(response);
        const parsedComments = parsedCommentsData(response.comments);
        setComments(parsedComments);
      })
      .catch(() => {
        toast.error("Nie udało się pobrać danych.");
      });
  }, []);

  useEffect(() => {
    const { confirmed_at } = currentUser;
    if (confirmed_at) {
      setEmailConfirmed(true);
    }
  }, [currentUser]);

  const closeAuthModal = () => {
    setOpenModal(false);
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const goToRegister = () => {
    history.push("/register");
  };

  if (Number.isNaN(parsedId)) return <h1>Nieprawidłowy identyfikator</h1>;
  return (
    data && (
      <>
        <Row>
          <Col sm={8}>
            <Typography variant="h1" color="primary" mb={2}>
              {data.name}
            </Typography>
          </Col>
          <Col sm={4}>
            <Typography variant="h1" color="primary">
              Wystawiający ofertę
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Box mb={4}>
              <Card>
                <Box p={8}>
                  <Gallery items={data.images} />
                  <InfoGrid
                    data={generateAdvertisamentsArrayData(
                      data.category,
                      data as IFetchPublicAdvertisementResponse["data"]
                    )}
                  />
                  <Box py={4}>
                    <Typography variant="body">{data.description}</Typography>
                  </Box>
                  <Map lat={data.dimension_x} lng={data.dimension_y} />
                  <Box mt={8}>
                    <ButtonsGroup>
                      <Button
                        onClick={() => {
                          eventBus.dispatch("showAdvertisementModal", {
                            isOpen: true,
                            id,
                          });
                        }}
                        variant="secondary"
                        icon={<ShareIcon />}
                        fullWidth
                      />
                      <Button
                        label="Chcę zainwestować"
                        variant="primary"
                        // isDisabled={!isEmailConfirmed}
                        onClick={
                          // isEmailConfirmed
                          //   ? () => setOpenModal(true)
                          //   : () => confirmEmailInfo
                          () => setOpenModal(true)
                        }
                      />
                    </ButtonsGroup>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Col>
          <Col sm={4}>
            <Box mb={4}>
              <Card>
                <Box p={8}>
                  <Person />
                </Box>
              </Card>
            </Box>
            <Comments items={comments} />
          </Col>
        </Row>
        <RequireAuthModal
          isOpen={openModal}
          closeAuthModal={closeAuthModal}
          goToLogin={goToLogin}
          goToRegister={goToRegister}
        />
      </>
    )
  );
};
