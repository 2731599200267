import { Flex } from "components/Flex";
import { Pagination } from "components/Pagination";
import { RecommendedContacts } from "components/RecommendedContacts";
import { useSearchContext } from "contexts/SearchContactContext";
import { useContacts } from "hooks/contacts";
import { usePagination } from "hooks/pagination";
import React, { useRef } from "react";

export const ContactsMightKnowView = () => {
  const { query } = useSearchContext();
  const {
    contacts,
    handleAddUserClick,
    retrieveContacts,
    pagination,
  } = useContacts({
    scope: "mutual_contacts",
    filterQuery: query,
  });
  const usersListRef = useRef<HTMLDivElement>(null);

  const { handleChangePage } = usePagination({
    paginatedElRef: usersListRef,
    updateFn: (pageNumber) => {
      retrieveContacts({
        page: pageNumber,
      });
    },
  });

  return (
    <div ref={usersListRef}>
      <RecommendedContacts
        contacts={contacts}
        onAddUserClick={handleAddUserClick}
      />
      <Flex justifyContent="flex-end" mt={6}>
        <Pagination
          current={pagination.current}
          pages={pagination.pages}
          onPageClick={handleChangePage}
        />
      </Flex>
    </div>
  );
};
