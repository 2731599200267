import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { ChagnePriceModal } from "components/ChangePriceModal";
import { ChatMessage } from "components/ChatMessage/ChatMessage";
import { Flex } from "components/Flex";
import { Investment } from "components/Investment";
import { IPerson, Person } from "components/Person/Person";
import { SummaryInvestorModal } from "components/SummaryInvestorModal";
import { TextareaField } from "components/Textarea";
import { ThanksfullInvestorModal } from "components/ThanksfullInvestorModal";
import { Typography } from "components/Typography";
import { useChat } from "hooks/chat";
import { usePrevious } from "hooks/usePrevious";
import { ReactComponent as SendIcon } from "icons/send.svg";
import React from "react";
import { Col, Row } from "react-grid-system";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchAdvertisement,
  IFetchAdvertisementResponse,
} from "services/advertisements";
import {
  fetchNegotiation,
  INegotiation,
  updateNegotiations,
} from "services/negotiations";
import { IRootState } from "store/rootReducer";
import styled from "styled-components";
import { IPagination } from "types/pagination";
import { goToUserInfo } from "utilities/goToUserInfoHelper";

interface ParamTypes {
  id: string;
  negotiationID: string;
}
interface IUser {
  id: number;
  profile: IProfile;
}
interface IProfile {
  first_name: string;
  last_name: string;
}
interface IMessage {
  id: number;
  conversation_id: number;
  content: string;
  created_at: string;
  sender: IUser;
  type: "to" | "from";
}
interface IResponseValue {
  from_negotiation: boolean;
  method: "pull_conversations" | "pull_messages" | "send_message";
  data: IMessage[] | IMessage;
  pagination: IPagination;
}
interface IContentThanksfullModal {
  title: string;
  body: string;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[8]}px;
  `}
`;
const StyledTypography = styled(Typography)`
  font-size: 14px;
`;
const MessagesWindow = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[8]}px;
    overflow-y: auto;
    overscroll-behavior: contain;
    // height: 550px;
    display: flex;
    flex-direction: column-reverse;
  `}
`;
const TopBar = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    left: 16px;
    width: calc(100% - 47px);
    border-top-left-radius: 16px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: -10px 8px 24px -10px ${theme.palette.neutral.medium};
    z-index: 10;
  `}
`;

const perPage = 15;
const inverse = true;

export const ChatInvestor = () => {
  const { id, negotiationID } = useParams<ParamTypes>();
  const parsedId = parseInt(id, 10);
  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const [isOpen, setIsOpen] = React.useState(false);
  const [summaryIsOpen, setSummaryIsOpen] = React.useState(false);
  const [accepterIsOpen, setAcceptedIsOpen] = React.useState(false);
  const [thanksIsOpen, setThanksIsOpen] = React.useState(false);
  const [contentThanksfullModal, setContentThanksfullModal] =
    React.useState<IContentThanksfullModal>({ title: "", body: "" });
  const [conversationID, setConversationID] = React.useState<number>(0);

  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [enableFetchMore, setEnableFetchMore] = React.useState(false);

  const [advertisement, setAdvertisement] =
    React.useState<IFetchAdvertisementResponse["data"]>();
  const [negotiation, setNegotiation] = React.useState<INegotiation | null>(
    null
  );

  const [oldPrice, setOldPrice] = React.useState<number>(0);

  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const {
    cable,
    recentlyReceivedWsData,
    messages,
    setMessages,
    parseMessageData,
  } = useChat();

  React.useEffect(() => {
    setMessages([]);
    setCurrentPage(1);
    setEnableFetchMore(false);
    if (!cable) return;
    cable.perform("pull_messages", {
      conversation_id: negotiation?.data.conversation?.id,
      page: 1,
      per_page: perPage,
    });
  }, [cable, negotiation]);

  const getNegotiation = async () => {
    await fetchNegotiation(`${negotiationID}`).then((res) => {
      setNegotiation(res);
      if (res.data.price) {
        setConversationID(res.data.conversation.id);
        setOldPrice(res.data.price);
      }
    });
  };

  const parseAdvCreator = (
    user: IFetchAdvertisementResponse["data"]["user"]
  ): IPerson => {
    return {
      id: user.id,
      avatar: user?.avatar || "",
      firstName: user?.profile?.first_name || "",
      lastName: user?.profile?.last_name || "",
      investment: true,
      description: user?.profile?.description,
      goToUserInfo: goToUserInfo(history),
    };
  };

  const sendMessage = (content: string) => {
    if (!cable) return;
    cable.perform("send_message", {
      conversation_id: negotiation?.data.conversation?.id,
      content,
    });
  };

  const onSubmit = async (data: { message: string }) => {
    try {
      if (negotiation) await sendMessage(data.message);
    } catch (err) {
      toast.error("Nie udało się wysłać wiadomości.");
    }
  };

  const handleTextareaMessage = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const element = e.target as HTMLInputElement;
    if (e.key === "Enter") {
      if (negotiation) sendMessage(element.value);
      (element as HTMLInputElement).value = element.defaultValue;
    }
  };

  const handleWsMessageReceive = ({
    method,
    data,
    pagination,
  }: IResponseValue): void => {
    switch (method) {
      case "pull_messages": {
        const oldMessages = parseMessageData(data as IMessage[], currentUser);
        setMessages((prevState) => [...prevState, ...oldMessages]);
        setTotalCount(pagination.count);
        if (pagination.current === 1) {
          setEnableFetchMore(true);
        }
        break;
      }
      case "send_message": {
        const messageData = data as IMessage;
        const newMessage = {
          ...messageData,
          type: messageData.sender.id === currentUser.id ? "to" : "from",
        } as IMessage;
        setMessages((prevState) => [newMessage, ...prevState]);
        break;
      }
      default:
        break;
    }
  };

  const handleOpenToHandlePrice = () => {
    setIsOpen(true);
  };

  const onCancelClick = () => {
    setIsOpen(false);
  };

  const handleChangePrice = async (newPrice: number) => {
    if (!negotiation?.data.id || !advertisement?.id) return;

    try {
      await updateNegotiations({
        id: negotiation.data.id.toString(),
        advertisementId: advertisement.id,
        price: newPrice,
      });
      setIsOpen(false);
      getNegotiation();
      toast.success("Cena została zmieniona.");
    } catch (err) {
      toast.error("Nie udało się zmienić ceny.");
    }
  };

  const handleOpenSummaryModal = () => {
    setSummaryIsOpen(true);
  };

  const cancelSummaryInvestorModal = () => {
    setSummaryIsOpen(false);
  };

  const createOffer = async (
    errorConfirm1: boolean,
    errorConfirm2: boolean
  ) => {
    if (errorConfirm1 || errorConfirm2) {
      return;
    }
    if (!negotiation?.data.id || !advertisement?.id) return;
    try {
      await updateNegotiations({
        id: negotiation.data.id.toString(),
        advertisementId: advertisement.id,
        state: "offered",
      });
      setSummaryIsOpen(false);
      await getNegotiation();
      setContentThanksfullModal({
        title: "Dziękujemy za złożenie oferty",
        body: "Teraz musisz poczekać aż wystawiający zaakceptuję twojego deala. Zwykle trwa to maksymalnie 24 godziny od momentu złożenia oferty.",
      });
      setThanksIsOpen(true);
      toast.success("Oferta została złożona.");
    } catch (err) {
      toast.error("Nie udało się zmienić ceny.");
    }
  };

  const handleCloseThanksModal = () => {
    setThanksIsOpen(false);
  };

  const accepedOffer = async (
    errorConfirm1: boolean,
    errorConfirm2: boolean
  ) => {
    if (errorConfirm1 || errorConfirm2) {
      return;
    }
    if (!negotiation?.data.id || !advertisement?.id) return;
    try {
      await updateNegotiations({
        id: negotiation.data.id.toString(),
        advertisementId: advertisement.id,
        state: "accepted",
      });
      setAcceptedIsOpen(false);
      getNegotiation();
      setContentThanksfullModal({
        title: "Dziękujemy za akceptację oferty",
        body: "Dalsze kroki to: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi massa ipsum, volutpat sed orci sit amet, sagittis eleifend nibh. Vivamus placerat, orci eu iaculis fringilla, neque mi tincidunt odio, fringilla cursus erat dui blandit sem.",
      });
      setThanksIsOpen(true);
      toast.success("Oferta została zaakceptowana.");
    } catch (err) {
      toast.error("Nie udało się zaakceptować oferty.");
    }
  };

  React.useEffect(() => {
    fetchAdvertisement({ id: parsedId }).then((response) => {
      setAdvertisement(response);
      getNegotiation();
    });
  }, []);

  React.useEffect(() => {
    if (recentlyReceivedWsData?.data) {
      handleWsMessageReceive(recentlyReceivedWsData as IResponseValue);
    }
  }, [recentlyReceivedWsData]);

  React.useEffect(() => {
    if (!cable) return;
    if (currentPage === 1) return;
    cable.perform("pull_messages", {
      conversation_id: negotiation?.data.conversation?.id,
      per_page: perPage,
      page: currentPage,
    });
  }, [currentPage]);

  React.useEffect(() => {
    setHasMore(!(messages.length >= totalCount));
  }, [messages.length, totalCount]);

  const fetchMoreData = () => {
    if (!enableFetchMore) return;
    setCurrentPage((prevState: number) => prevState + 1);
  };

  const renderOwnerButton = (state: string) => {
    switch (state) {
      case "created":
        return null;
      case "offered":
        return (
          <Button
            label="Zaakceptuj ofertę"
            color="primary"
            onClick={() => setAcceptedIsOpen(true)}
          />
        );
      case "accepted":
        return (
          <Typography variant="body" color="primary">
            Oferta została zakończona.
          </Typography>
        );
      default:
        return null;
    }
  };

  const renderInvestorButton = (state: string) => {
    switch (state) {
      case "created":
        return (
          <Button
            label="Kupuję"
            color="primary"
            onClick={handleOpenSummaryModal}
          />
        );
      case "offered":
        return (
          <Typography variant="body" color="primary">
            Oczekiwanie na potwierdzenie od wystawiającego
          </Typography>
        );
      case "accepted":
        return (
          <Typography variant="body" color="primary">
            Offerta została zaakceptowana.
          </Typography>
        );
      default:
        return null;
    }
  };

  const renderStateButtons = ({
    data: { advertisement_creator, state },
  }: INegotiation) => {
    if (advertisement_creator.id === currentUser?.preference?.id) {
      return renderOwnerButton(state);
    }
    return renderInvestorButton(state);
  };

  return (
    <>
      <Typography variant="h1" color="primary" mb={2}>
        Moje ogłoszenie
      </Typography>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <StyledCard>
              <Box p={8}>
                <Typography variant="h2" color="primary" mb={3}>
                  Inwestor
                </Typography>
                {negotiation && (
                  <Person
                    {...parseAdvCreator(negotiation.data.user)}
                    investment={false}
                  />
                )}
              </Box>
            </StyledCard>

            {advertisement && (
              <Investment
                favourite={null}
                id={advertisement.id}
                view="vertical"
                images={advertisement.images}
                name={advertisement.name}
                price={negotiation?.data.price ? negotiation.data.price : 0}
                roi={advertisement.roi}
                area={advertisement.area}
                localization={advertisement.voivodship}
                type={advertisement.category}
                owner={parseAdvCreator(advertisement.user)}
                handleOpenToHandlePrice={handleOpenToHandlePrice}
                showShare={false}
                currentUserId={currentUser.id}
                description={negotiation?.advertisement_creator?.description}
                legalStatus={advertisement.legal_status_of_the_facility}
              />
            )}
          </Box>
        </Col>

        <Col>
          <Card>
            <MessagesWindow style={{ paddingTop: "100px" }}>
              <>
                <TopBar>
                  <Typography variant="h2" color="primary" px={8} py={5} pb={1}>
                    Rozmowa z inwestorem
                  </Typography>
                  <Typography variant="body1" color="red" px={8} py={5} pt={1}>
                    Jeżeli nie widać konwersacji proszę odśwież stronę lub przejdź do rozmów
                  </Typography>
                </TopBar>
                {negotiation?.data.conversation.id ? (
                  <InfiniteScroll
                    dataLength={messages.length}
                    next={fetchMoreData}
                    inverse={inverse}
                    hasMore={hasMore}
                    loader={<p>loading...</p>}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                    height={550}
                  >
                    {messages.map((message: IMessage) => (
                      <ChatMessage {...message} />
                    ))}
                  </InfiniteScroll>
                ) : null}
              </>
            </MessagesWindow>
            <Box mx={7} mb={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextareaField
                  onKeyPress={handleTextareaMessage}
                  name="message"
                  placeholder="Tu wpisz swoją wiadomość..."
                  icon={<SendIcon />}
                  ref={register}
                />
              </form>
            </Box>
            <Flex mr={8} mb={8} justifyContent="flex-end">
              {negotiation && renderStateButtons(negotiation)}
            </Flex>
          </Card>
        </Col>
      </Row>

      <ChagnePriceModal
        oldPrice={oldPrice}
        isOpen={isOpen}
        onCancelClick={onCancelClick}
        handleChangePrice={handleChangePrice}
      />

      {negotiation && (
        <SummaryInvestorModal
          isOpen={summaryIsOpen}
          onCancelClick={cancelSummaryInvestorModal}
          advertisement={advertisement}
          price={negotiation?.data.price}
          investor={negotiation.data.user}
          createOffer={createOffer}
        />
      )}

      {negotiation && (
        <SummaryInvestorModal
          isOpen={accepterIsOpen}
          onCancelClick={() => setAcceptedIsOpen(false)}
          advertisement={advertisement}
          price={negotiation?.data.price}
          investor={negotiation.data.user}
          acceptOffer={accepedOffer}
        />
      )}

      <ThanksfullInvestorModal
        isOpen={thanksIsOpen}
        onCancelClick={handleCloseThanksModal}
        content={contentThanksfullModal}
      />
    </>
  );
};
