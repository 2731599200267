import { Avatar, AvatarI } from "components/Avatar/Avatar";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { TypographyType } from "components/Typography/Typography";
import React from "react";
import styled from "styled-components";
import { IGoToUserInfo } from "utilities/goToUserInfoHelper";

export interface IPerson extends IGoToUserInfo {
  avatar?: AvatarI["url"];
  firstName?: string;
  lastName?: string;
  description?: string | React.ReactNode;
  investment?: boolean;
  id?: number;
}

const Description = styled(Typography).attrs({
  variant: "body2" as TypographyType["variant"],
})`
  opacity: 0.7;
`;

interface IStyledFlex {
  setPointer?: boolean;
}
const StyledFlex = styled(Flex)<IStyledFlex>`
  ${({ theme, setPointer }) => `
    ${setPointer ? `cursor: pointer;` : `cursor: auto;`}
  `}
`;

export const Person: React.FC<IPerson> = ({
  avatar,
  firstName = "Użytkownik",
  lastName = "ukryty",
  description,
  investment,
  id,
  goToUserInfo,
}) => (
  <StyledFlex
    setPointer={!!id}
    alignItems="center"
    onClick={() => goToUserInfo && goToUserInfo(id)}
  >
    <Avatar size="small" url={avatar} />
    <Box ml={investment ? 4 : 2}>
      {investment && (
        <Description variant="body2">Wystawiana przez</Description>
      )}
      <Typography variant="h3">
        {firstName} {lastName}
      </Typography>
      {description && <Description variant="body2">{description}</Description>}
    </Box>
  </StyledFlex>
);
