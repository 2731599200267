import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "components";
import { Box } from "components/Box";
import {
  investmentsLevelsFields,
  investmentsSpecializationsFields,
  realEstateExperienceInMonthsFields,
} from "constants/profileFormFields";
import { cities } from "database/cities";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IUser } from "types/user";

import { Checkbox } from "../../../components/Checkbox";
import { Radio } from "../../../components/Radio";
import { Select } from "../../../components/Select";
import { profileExperienceAndSpecializationSchema } from "../../../schemas/profileSettings";
import { updateProfileAction } from "../../../store/currentUser/actions";
import { StateI } from "../../../store/currentUser/types";
import { ButtonContainer, FormContainer } from "../ProfileInfo";

interface IFormData {
  /* eslint-disable camelcase */
  real_estate_experience_in_months: number | string;
  real_estate_activity_cities: Array<string>;
  investments_specialization: Array<string>;
  investments_levels: Array<string>;
  /* eslint-enable camelcase */
}

interface IExperienceAndSpecializationForm {
  currentUser: IUser;
  onCloseForm: (val: number) => void;
}

export const ExperienceAndSpecializationForm: React.FC<IExperienceAndSpecializationForm> =
  ({ currentUser, onCloseForm }) => {
    const dispatch = useDispatch();

    const [defaultValues, setDefaultValues] = useState<IFormData>({
      real_estate_experience_in_months: "0",
      real_estate_activity_cities: [],
      investments_specialization: [],
      investments_levels: [],
    });

    const { register, handleSubmit, reset } = useForm<IFormData>({
      defaultValues,
      resolver: yupResolver(profileExperienceAndSpecializationSchema),
    });

    const onSubmit = async (data: IFormData) => {
      setDefaultValues({ ...defaultValues, ...data });
      const dataToSubmit = {
        ...data,
        real_estate_experience_in_months: Number(
          data.real_estate_experience_in_months
        ),
      };
      dispatch(updateProfileAction(dataToSubmit));
      onCloseForm(0);
    };

    useEffect(() => {
      const str = String(currentUser?.profile.real_estate_experience_in_months);
      const dataNew = {
        ...currentUser?.profile,
        real_estate_experience_in_months: str,
      };
      setDefaultValues({ ...defaultValues, ...dataNew });
    }, [currentUser]);
    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues]);

    return (
      <>
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
              <Typography variant="h3" color="secondary">
                Jak długo zajmujesz się nieruchomościami
              </Typography>
            </Box>
            {realEstateExperienceInMonthsFields.map(({ label, value }) => (
              <Box mb={2} key={value}>
                <Radio
                  name="real_estate_experience_in_months"
                  value={value}
                  label={label}
                  ref={register}
                />
              </Box>
            ))}
            <Box mb={2} mt={4}>
              <Typography variant="h3" color="secondary">
                Miasta, w których działasz na rynku nieruchomości:
              </Typography>
            </Box>
            <Box mb={3}>
              <Select
                multi
                search
                label="Wyszukaj miasto"
                // todo ask about defaultValues instead of currentUser && ask about async setState
                selected={currentUser?.profile?.real_estate_activity_cities?.map(
                  (el) => el
                )}
                options={cities}
                name="real_estate_activity_cities"
                ref={register}
              />
            </Box>

            <Box mb={3}>
              <Typography variant="h3" color="secondary">
                W jakiego rodzaju inwestycjach się specjalizujesz?
              </Typography>
            </Box>
            {investmentsSpecializationsFields.map(({ label, value }) => (
              <Box mb={2} key={label}>
                <Checkbox
                  label={label}
                  value={value}
                  ref={register}
                  name="investments_specialization"
                />
              </Box>
            ))}

            <Box mb={3} mt={4}>
              <Typography variant="h3" color="secondary">
                Na jakim poziomie inwestycji działasz?
              </Typography>
            </Box>
            {investmentsLevelsFields.map(({ label, value }) => (
              <Box mb={2} key={label}>
                <Checkbox
                  label={label}
                  value={value}
                  ref={register}
                  name="investments_levels"
                />
              </Box>
            ))}

            <Box mt={6}>
              <ButtonContainer>
                <Button type="submit" label="Zapisz" />
                <Button
                  onClick={() => {
                    onCloseForm(0);
                  }}
                  label="Anuluj"
                  variant="secondary"
                />
              </ButtonContainer>
            </Box>
          </form>
        </FormContainer>
      </>
    );
  };
