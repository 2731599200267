import { Card } from "components/Card";
import { LatestNotifications } from "components/LatestNotifications";
import { useDropdown } from "hooks/dropdown";
import React from "react";
import styled from "styled-components";
import { INotification } from "types/notifications";

interface INotificationsDropdown {
  goToNotificationsPage: () => void;
  notificationRedirect: (path: string) => void;
  notifications: INotification[];
  children: React.ReactNode;
}

const Root = styled.div`
  position: relative;
`;
const WrapperChildren = styled.div`
  cursor: pointer;
`;
const WrapperDropdown = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: ${theme.space[2]}px;
    min-width: 360px;
    z-index: 1000;
  `}
`;

export const NotificationsDropdown: React.FC<INotificationsDropdown> = ({
  goToNotificationsPage,
  notificationRedirect,
  notifications,
  children,
}) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);
  return (
    <Root>
      <WrapperChildren ref={buttonRef} role="button" aria-hidden="true">
        {children}
      </WrapperChildren>
      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card>
            <LatestNotifications
              notifications={notifications}
              goToNotificationsPage={() => {
                setIsVisible(false);
                goToNotificationsPage();
              }}
              notificationRedirect={(path) => {
                setIsVisible(false);
                notificationRedirect(path);
              }}
            />
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
