import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

interface IThanksfullInvestorModal {
  isOpen: boolean;
  onCancelClick: () => void;
  content: {
    title: string;
    body: string;
  };
}

const StyledBox = styled(Box)`
  width: 550px;
`;

export const ThanksfullInvestorModal: React.FC<IThanksfullInvestorModal> = ({
  isOpen,
  onCancelClick,
  content,
}) => {
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <StyledBox p={8}>
        <Typography variant="h1" color="primary" mb={3}>
          {content.title}
        </Typography>
        <Typography variant="body" mb={4}>
          {content.body}
        </Typography>
        <Flex justifyContent="flex-end">
          <Button label="Wróć do oferty" onClick={onCancelClick} />
        </Flex>
      </StyledBox>
    </Modal>
  );
};
