import { USER_INVITATION_URL, USERS_ME_URL } from "constants/paths";
import { IUser } from "types/user";
import { fetchAPI } from "utilities/fetchAPI";

export interface IFetchCurrentUserResponse {
  data: IUser;
}

export const fetchCurrentUser = async () => {
  const userData = await fetchAPI<IFetchCurrentUserResponse>(USERS_ME_URL, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return userData;
};

export const inviteUser = async (email: string) => {
  return fetchAPI(USER_INVITATION_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  });
};
