import { Button, Typography } from "components";
import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import {
  lookingForFields,
  mostImportantFields,
  networkingForFields,
  specializedInFields,
} from "constants/profileFormFields";
import React from "react";
import { useForm } from "react-hook-form";

interface IProfileFormThirdStep {
  onSubmit: (data: IProfileFormThirdStepData) => any;
  onBackClick: () => void;
  defaultValues: IProfileFormThirdStepData;
}

export interface IProfileFormThirdStepData {
  /* eslint-disable camelcase */
  looking_for_single_investor: boolean;
  looking_for_group_of_investors: boolean;
  looking_for_single_investor_investments: boolean;
  looking_for_group_investors_investments: boolean;
  looking_for_network_of_contacts: boolean;
  specialized_in_ready_investments: boolean;
  specialized_in_rebuilding_investments: boolean;
  the_most_important_is_price: boolean;
  the_most_important_is_location: boolean;
  the_most_important_is_year_of_construction: boolean;
  the_most_important_is_area: boolean;
  the_most_important_is_roi: boolean;
  the_most_important_are_photos: boolean;
  networking_for_scaling_through_regions: boolean;
  networking_for_creating_a_bigger_investments: boolean;
  networking_for_different_reason: boolean;
  /* eslint-enable camelcase */
}

export const ProfileFormThirdStep: React.FC<IProfileFormThirdStep> = ({
  onSubmit: onSubmitCallback,
  onBackClick,
  defaultValues,
}) => {
  const { register, handleSubmit, watch } = useForm<IProfileFormThirdStepData>({
    defaultValues,
  });

  const onSubmit = async (data: IProfileFormThirdStepData) => {
    onSubmitCallback(data);
  };

  console.log(watch());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" color="primary" mb={2}>
        Oczekiwania i preferencje
      </Typography>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Do czego potrzebna jest Tobie nasza platforma?
        </Typography>
        {lookingForFields.map(({ label, value }) => (
          <Box mb={2}>
            <Checkbox label={label} name={value} ref={register} />
          </Box>
        ))}
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          W jakiego rodzaju inwestycjach się specjalizujesz?
        </Typography>
        {specializedInFields.map(({ label, value }) => (
          <Box mb={2}>
            <Checkbox label={label} name={value} ref={register} />
          </Box>
        ))}
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Co jest dla Ciebie najważniejsze w ogłoszeniach inwestycyjnych?
        </Typography>
        {mostImportantFields.map(({ label, value }) => (
          <Box mb={2}>
            <Checkbox label={label} name={value} ref={register} />
          </Box>
        ))}
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Czego oczekujesz od sieci znajomych inwestorów?
        </Typography>
        {networkingForFields.map(({ label, value }) => (
          <Box mb={2}>
            <Checkbox label={label} name={value} ref={register} />
          </Box>
        ))}
      </Box>
      <Flex justifyContent="space-between" mt={6}>
        <Button
          type="button"
          onClick={onBackClick}
          variant="secondary"
          label="Wstecz"
        />
        <Button type="submit" label="Utwórz konto" />
      </Flex>
    </form>
  );
};
