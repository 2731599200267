import { IComments } from "components/Comments/Comments";
import { useState } from "react";
import { toast } from "react-toastify";
import { addCommentToAdvertisement } from "services/advertisements";
import { IAdvertisement } from "types/advertisement";

interface IUseComments {
  advertisementId: number;
  comments: IAdvertisement["comments"];
}

const parseComments = (comments: IAdvertisement["comments"]) => {
  return comments.map(({ id, message, user, created_at }) => ({
    id,
    avatar: user.avatar,
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    userId: user.id,
    content: message,
    date: new Date(created_at),
  }));
};

export const useComments = ({ advertisementId, comments }: IUseComments) => {
  const [items, setItems] = useState<IAdvertisement["comments"]>(comments);
  const handleSubmitComment: IComments["onSubmitCallback"] = async ({
    message,
  }) => {
    try {
      const response = await addCommentToAdvertisement({
        id: advertisementId.toString(),
        message,
      });
      setItems((prevState) => [
        ...prevState,
        {
          id: response.id,
          message: response.message,
          user: response.user,
          created_at: response.created_at,
        },
      ]);
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else {
        toast.error("Nie udało się dodać komentarza.");
      }
    }
  };

  return {
    items: parseComments(items),
    handleSubmitComment,
    setItems,
  };
};
