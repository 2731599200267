import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import React from "react";
import styled from "styled-components";

interface IPolicyModal {
  onCancelClick: () => void;
  isOpen: boolean;
}

const StyledBox = styled(Box)`
  width: 550px;

  @media only screen and (max-width: 1000px) {
    width: 300px;
  }
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 12px;
  cursor: pointer;
`;

export const PolicyModal: React.FC<IPolicyModal> = ({
  isOpen,
  onCancelClick,
}) => (
  <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
    <StyledBox p={6}>
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h1" color="primary">
          Polityka prywatności portalu Investments4you
        </Typography>
        <StyledCloseIcon onClick={onCancelClick} />
      </Flex>
      <Typography variant="body2">
        Postanowienia Ogólne
        <br />
        <br />
        1. Administratorem danych jest K Investments Jacek Kusiak, ul. Puławska
        257 lok 90, 02-769 Warszawa, NIP 7171514703 . Ochrona danych odbywa się
        zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich
        przechowywanie ma miejsce na zabezpieczonych serwerach.
        <br />
        2. Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak
        jak zostało to opisane w Polityce Prywatności (jeżeli wynika to
        bezpośrednio z opisu).
        <br />
        3. Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik”
        zastąpiony został określeniem „Ty”, „Administrator” – „My”. Termin
        „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE)
        2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
        związku z przetwarzaniem danych osobowych i w sprawie swobodnego
        przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        <br />
        4. Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym
        celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji
        (SSL).
        <br />
        5. Dane osobowe podane w formularzu rejestracyjnym są traktowane jako
        poufne.
        <br />
        6. Przetwarzaniem danych zajmuje się Portal Investments4you
        <br />
        Administrator Danych
        <br />
        1. Korzystanie z narzędzia wymaga przetwarzania danych osobowych
        Użytkownika w zakresie: imienia oraz adresu e-mail. Dane będą
        przetwarzane przez Administratora w zakresie niezbędnym do zawarcia i
        realizacji umowy. Dane transakcyjne, w tym dane osobowe, mogą zostać
        przekazane na rzecz tpay.com - Krajowy Integrator Płatności spółkę
        akcyjną z siedzibą w Poznaniu, ul. Święty Marcin 73/6, 61-808 Poznań w
        zakresie niezbędnym do obsługi płatności za zamówienie. Klientowi
        przysługuje prawo dostępu do treści swoich danych oraz ich poprawiania.
        Udostępnienie danych jest dobrowolne, a jednocześnie konieczne do
        korzystania z serwisu.
        <br />
        2. Dane osobowe przetwarzane są:
        <br />
        a. zgodnie z przepisami dotyczącymi ochrony danych osobowych
        <br />
        b. zgodnie z wdrożoną Polityką Prywatności
        <br />
        c. w zakresie i celu niezbędnym do nawiązania, ukształtowania treści
        Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług
        świadczonych drogą elektroniczną
        <br />
        d. w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów
        (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i
        wolności osoby, której dane dotyczą: w zakresie i celu zgodnym ze zgodą
        wyrażoną przez Ciebie, jeśli zapisałeś się na newsletter.
        <br />
        3. Każda osoba, której dane dotyczą (jeżeli jesteśmy ich
        administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub
        ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do
        organu nadzorczego.
        <br />
        4. Kontakt z osobą nadzorującą przetwarzanie danych osobowych jest pod
        adresem e-mail: biuro@investments4you.biz
        <br />
        5. Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu
        Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia
        ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo
        unijne bądź prawa międzynarodowego obligują nas do retencji danych.
        <br />
        6. Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych
        jego danych podmiotom upoważnionym na podstawie właściwych przepisów
        prawa (np. organom ścigania).
        <br />
        7. Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody
        bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych
        osobowych.
        <br />
        8. Usługodawca nieudostępniania danych osobowych innym podmiotom aniżeli
        upoważnionym na podstawie właściwych przepisów prawa.
        <br />
        9. Wdrożyliśmy pseudonimizację, szyfrowanie danych oraz mamy wprowadzoną
        kontrolę dostępu dzięki czemu minimalizujemy skutki ewentualnego
        naruszenia bezpieczeństwa danych.
        <br />
        10. Administrtor zabezpiecza dane osobowe przed ich udostępnieniem
        osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
        przetwarzaniem z naruszeniem ustawy oraz zmianą, utratą, uszkodzeniem
        lub zniszczeniem.
        <br />
        <br />
        Pliki cookies
        <br />
        <br />
        1. Witryna https://investments4you.pl używa cookies. Są to niewielkie
        pliki tekstowe wysyłane przez serwer www i przechowywane przez
        oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie
        połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego
        łączy się użytkownik. Parametry pozwalają na odczytanie informacji w
        nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają
        więc korzystanie z wcześniej odwiedzonych witryn. Gromadzone informacje
        dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju
        systemu operacyjnego, dostawcy usług internetowych, informacji o czasie
        i dacie, lokalizacji oraz informacji przesyłanych do witryny za
        pośrednictwem formularza kontaktowego.
        <br />
        2. Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
        użytkownicy korzystają z naszych witryn, aby usprawniać funkcjonowanie
        serwisu zapewniając bardziej efektywną i bezproblemową nawigację.
        Monitorowania informacji o użytkownikach dokonujemy korzystając z
        narzędzia Google Analytics, które rejestruje zachowanie użytkownika na
        stronie. Cookies identyfikuje użytkownika, co pozwala na dopasowanie
        treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego
        preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego
        reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy standard
        wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie
        wewnątrz firmy [nazwa] w celu optymalizacji działań.
        <br />
        3. Na naszej witrynie wykorzystujemy następujące pliki cookies:
        <br />
        a. „niezbędne” pliki cookies, umożliwiające korzystanie z usług
        dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
        wykorzystywane do usług wymagających uwierzytelniania w ramach serwisu
        <br />
        b. pliki cookies służące do zapewnienia bezpieczeństwa, np.
        wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
        ramach serwisu
        <br />
        c. „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o
        sposobie korzystania ze stron internetowych serwisu
        <br />
        d. „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych
        przez użytkownika ustawień i personalizację interfejsu użytkownika, np.
        w zakresie wybranego języka lub regionu, z którego pochodzi użytkownik,
        rozmiaru czcionki, wyglądu strony internetowej itp.
        <br />
        e. „reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom
        treści reklamowych bardziej dostosowanych do ich zainteresowań.
        <br />
        4. Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia
        opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce
        internetowej. Instrukcja zarządzania plikami cookies jest dostępna na
        stronie http://www.allaboutcookies.org/manage-cookies Dodatkowe dane
        osobowe, jak adres e-mail i imię, zbierane są jedynie w miejscach, w
        których użytkownik wypełniając formularz wyraźnie wyraził na to zgodę.
        Powyższe dane zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych
        do wykonania danej funkcji i poinformowaniu użytkownika o zmianach w
        ramach platformy lub pojawieniu się nowych materiałów video w panelu
        klienta.
      </Typography>
    </StyledBox>
  </Modal>
);
