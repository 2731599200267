import {
  FormWizardLegend,
  FormWizardLegendI,
} from "components/FormWizardLegend/FormWizardLegend";
import { LoginLayout } from "components/LoginLayout";
import {
  IProfileFormFirstStepData,
  ProfileFormFirstStep,
} from "components/ProfileForm/ProfileFormFirstStep";
import {
  IProfileFormSecondStepData,
  ProfileFormSecondStep,
} from "components/ProfileForm/ProfileFormSecondStep";
import {
  IProfileFormThirdStepData,
  ProfileFormThirdStep,
} from "components/ProfileForm/ProfileFormThirdStep";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updatePreferencesAction,
  updateProfileAction,
} from "store/currentUser/actions";

type IFormData = IProfileFormFirstStepData &
  IProfileFormSecondStepData &
  IProfileFormThirdStepData;

const formWizardLegendInitialSteps: FormWizardLegendI["steps"] = [
  {
    label: "Dane osobowe i firmy",
    step: 1,
    active: true,
    disabled: false,
  },
  {
    label: "Doświadczenie",
    step: 2,
    active: false,
    disabled: true,
  },
  {
    label: "Preferencje",
    step: 3,
    active: false,
    disabled: true,
  },
];

export const ProfileForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [stepNumber, setStepNumber] = useState(1);
  const [formWizardLegendSteps, setFormWizardLegendSteps] = useState<
    FormWizardLegendI["steps"]
  >(formWizardLegendInitialSteps);

  const [formData, setFormData] = useState<IFormData>({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    postal_code: "",
    telephone_number: "",
    company_name: "",
    company_nip: "",
    company_regon: "",
    company_address: "",
    company_city: "",
    company_postal_code: "",
    real_estate_experience_in_months: undefined,
    real_estate_activity_cities: "",
    investments_specialization: [],
    investments_levels: [],
    looking_for_single_investor: false,
    looking_for_group_of_investors: false,
    looking_for_single_investor_investments: false,
    looking_for_group_investors_investments: false,
    looking_for_network_of_contacts: false,
    specialized_in_ready_investments: false,
    specialized_in_rebuilding_investments: false,
    the_most_important_is_price: false,
    the_most_important_is_location: false,
    the_most_important_is_year_of_construction: false,
    the_most_important_is_area: false,
    the_most_important_is_roi: false,
    the_most_important_are_photos: false,
    networking_for_scaling_through_regions: false,
    networking_for_creating_a_bigger_investments: false,
    networking_for_different_reason: false,
  });

  const handleCancel = () => {
    setStepNumber(stepNumber === 3 ? 2 : 1);
  };

  const handleNext = (
    data:
      | IProfileFormFirstStepData
      | IProfileFormSecondStepData
      | IProfileFormThirdStepData
  ) => {
    setFormData((prevState) => ({ ...prevState, ...data }));
    setStepNumber((prevState) => prevState + 1);
  };

  const renderForm = () => {
    switch (stepNumber) {
      case 1: {
        return (
          <ProfileFormFirstStep
            defaultValues={formData}
            onSubmit={handleNext}
          />
        );
      }
      case 2: {
        return (
          <ProfileFormSecondStep
            onBackClick={handleCancel}
            defaultValues={formData}
            onSubmit={handleNext}
          />
        );
      }
      case 3: {
        return (
          <ProfileFormThirdStep
            onBackClick={handleCancel}
            defaultValues={formData}
            onSubmit={handleNext}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        updateProfileAction({
          ...formData,
          // FIXME
          // real_estate_activity_cities should use <Select> field instead of <Input>
          // when <Select> component is implemented
          real_estate_activity_cities: [
            ...formData.real_estate_activity_cities,
          ],
          registration_completed: true
        })
      );

      await dispatch(updatePreferencesAction(formData));
    } finally {
      history.push("/profile/info");
    }
  };

  useEffect(() => {
    setFormWizardLegendSteps((prevState) =>
      prevState.map((step) => ({
        ...step,
        active: step.step === stepNumber,
        disabled: step.step > stepNumber,
      }))
    );
    if (stepNumber === 4) {
      setTimeout(() => {
        handleSubmit();
      }, 1000);
    }
  }, [stepNumber]);

  return (
    <LoginLayout
      renderInLeftColumn={<FormWizardLegend steps={formWizardLegendSteps} />}
    >
      {renderForm()}
    </LoginLayout>
  );
};
