import { Button, Card, Typography } from "components";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Investment } from "components/Investment";
import { Person } from "components/Person";
import { IPerson } from "components/Person/Person";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchAdvertisement,
  IFetchAdvertisementResponse,
} from "services/advertisements";
import { fetchNegotiations } from "services/negotiations";
import styled from "styled-components";

interface ParamTypes {
  id: string;
}
export interface INegotiation {
  id: number;
  advertisement_id: number;
  finished: boolean;
  mutual_contacts_count: number;
  price: number | null;
  user: {
    id: number;
    avatar: string | null;
    profile: {
      first_name: string;
      last_name: string;
    };
  };
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    padding: ${theme.space[3]}px ${theme.space[4]}px ${theme.space[3]}px ${theme.space[6]}px;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space[2]}px;

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;
const StyledBox = styled(Box)`
  min-width: 200px;
`;
const More = styled.div`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    margin-left: ${theme.space[3]}px;
    cursor: pointer;
  `}
`;
const Dotted = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.neutral.dark};
`;
const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 5px 0;
`;

const parseAdvCreator = (
  user: IFetchAdvertisementResponse["data"]["user"]
): IPerson => {
  return {
    avatar: user.avatar || "",
    firstName: user.profile.first_name || "",
    lastName: user.profile.last_name || "",
    investment: true,
  };
};

export const SingleNegotiationView: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const parsedId = parseInt(id, 10);
  const [advertisement, setAdvertisement] =
    useState<IFetchAdvertisementResponse["data"]>();
  const [negotiations, setNegotiations] = useState<INegotiation[]>();

  useEffect(() => {
    fetchAdvertisement({ id: parsedId }).then((response) => {
      setAdvertisement(response);
    });

    fetchNegotiations({ advertisementId: parsedId })
      .then((response) => {
        setNegotiations(response.data);
      })
      .catch((e) => {
        toast.error(
          typeof e === "string" ? e : "Nie udało się pobrać negocjacje."
        );
      });
  }, []);

  const moveToInvestorChat = (negotiation: INegotiation) => {
    const negotiationID = negotiation.id;
    history.push(`/advertisement/${id}/negotiations/${negotiationID}/chat`);
  };

  return (
    <>
      <Typography variant="h1" color="primary" mb={2}>
        Moje ogłoszenia
      </Typography>
      <Row>
        <Col sm={4}>
          {advertisement && (
            <Investment
              favourite={null}
              id={advertisement.id}
              view="vertical"
              images={advertisement.images}
              name={advertisement.name}
              price={advertisement.price}
              roi={advertisement.roi}
              area={advertisement.area}
              localization={advertisement.voivodship}
              type={advertisement.category}
              owner={parseAdvCreator(advertisement.user)}
              showShare={false}
              legalStatus={advertisement.legal_status_of_the_facility}
            />
          )}
        </Col>
        <Col sm={8}>
          <Typography variant="h2" color="primary" mb={2}>
            Negocjację
          </Typography>
          {negotiations &&
            negotiations.map((negotiation) => (
              <StyledCard>
                <StyledFlex flexDirection="row" alignItems="center">
                  <StyledBox mr={8}>
                    <Person
                      avatar={
                        negotiation.user?.avatar
                          ? negotiation.user?.avatar
                          : undefined
                      }
                      firstName={negotiation.user.profile.first_name}
                      lastName={negotiation.user.profile.last_name}
                    />
                  </StyledBox>
                  <Flex flexDirection="column">
                    <Typography variant="body2">Negocjowana cena</Typography>
                    <Typography variant="h3">
                      {(
                        <NumberFormat
                          value={negotiation.price}
                          displayType="text"
                          thousandSeparator=" "
                          suffix=" PLN"
                        />
                      ) || "0"}{" "}
                      PLN
                    </Typography>
                  </Flex>
                </StyledFlex>
                <StyledFlex flexDirection="row" alignItems="center">
                  <Button
                    label="Zobacz"
                    variant="secondary"
                    onClick={() => moveToInvestorChat(negotiation)}
                  />
                  <More>
                    <StyledFlex
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Dotted />
                      <Dotted />
                      <Dotted />
                    </StyledFlex>
                  </More>
                </StyledFlex>
              </StyledCard>
            ))}
        </Col>
      </Row>
    </>
  );
};
