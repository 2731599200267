import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { UserProfile } from "components/UserProfile";
import { useUserProfileContext } from "contexts/profileContext";
import React from "react";
import { sendInvitationToUser, updateInvitation } from "services/contacts";
import { IContactInfo } from "services/otherProfile";
import { parseProfileToUserProfile } from "utilities/parseProfileToUserProfile";

const CONNECTED = "connected" as IContactInfo;
const NOT_CONNECTED = "not_connected" as IContactInfo;
const INVITED_BY_MY = "invited_by_me" as IContactInfo;
const INVITED_BY_USER = "invited_by_user" as IContactInfo;

export const OtherProfileInfoView = () => {
  const { userProfile, setUserProfile } = useUserProfileContext();

  const {
    activityCities,
    mostImportantFeatures,
    specializations,
    investmentsLevels,
    lookingFor,
    investmentsSpecializations,
    expectations,
  } = parseProfileToUserProfile(userProfile);

  const sentInvitation = async () => {
    if (!userProfile) return;
    await sendInvitationToUser(userProfile?.data.id);
    setUserProfile({
      ...userProfile,
      contact_info: INVITED_BY_MY,
    });
  };

  const confirmOrRejectInvitation = async (state: IContactInfo) => {
    if (!userProfile || !userProfile.received_invitation_id) return;
    await updateInvitation({
      id: userProfile.received_invitation_id,
      state,
      recipientId: userProfile.data.id,
    });
    if (state === "accepted")
      setUserProfile({
        ...userProfile,
        contact_info: CONNECTED,
      });
    else if (state === "rejected")
      setUserProfile({
        ...userProfile,
        contact_info: NOT_CONNECTED,
      });
  };

  const renderInviteButton = (type?: IContactInfo) => {
    switch (type) {
      case CONNECTED:
        return (
          <Button label="Jesteście znajomymi" variant="primary" disabled />
        );
      case INVITED_BY_MY:
        return (
          <Button label="Wysłano zaproszenie" variant="primary" disabled />
        );
      case NOT_CONNECTED:
        return (
          <Button
            label="Wyślij zaproszenie"
            variant="primary"
            onClick={sentInvitation}
          />
        );
      case INVITED_BY_USER:
        return (
          <>
            <Flex>
              <Box mr={2}>
                <Button
                  label="Akceptuj zaproszenie"
                  color="primary"
                  onClick={() => confirmOrRejectInvitation("accepted")}
                />
              </Box>
              <Button
                label="Odrzuć"
                variant="secondary"
                onClick={() => confirmOrRejectInvitation("rejected")}
              />
            </Flex>
          </>
        );
      default:
        return null;
    }
  };

  if (!userProfile) return null;

  return (
    <Card>
      <UserProfile
        user={userProfile}
        activityCities={activityCities}
        mostImportantFeatures={mostImportantFeatures}
        specializations={specializations}
        investmentsLevels={investmentsLevels}
        lookingFor={lookingFor}
        investmentsSpecializations={investmentsSpecializations}
        expectations={expectations}
        renderInviteButton={renderInviteButton}
      />
    </Card>
  );
};
