import { NEW_NOTIFICATIONS_URL, NOTIFICATIONS_URL } from "constants/paths";
import { INotification } from "types/notifications";
import { fetchAPI } from "utilities/fetchAPI";

export const getNotifications = async (perPage = 15) => {
  const response = await fetchAPI<{ data: INotification[]; paginations: any }>(
    `${NOTIFICATIONS_URL}?per_page=${perPage}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getNewNotifications = async () => {
  const response = await fetchAPI(`${NEW_NOTIFICATIONS_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response;
};

export const setNotificationsAsSeen = async () => {
  const response = await fetchAPI(`${NOTIFICATIONS_URL}?set_as_seen=true`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response;
};
