// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from "history";

export interface IGoToUserInfo {
  goToUserInfo?: (id?: number) => void;
}

export const goToUserInfo = (history: History) => {
  return (id?: number): void => {
    if (id) history.push(`/profiles/${id}/info/`);
  }
};
