import checkmarkIcon from "icons/checkmark.svg";
import React, { LiHTMLAttributes as DivHTMLAttributes } from "react";
import styled from "styled-components";

interface StepI extends DivHTMLAttributes<HTMLDivElement> {
  label?: string;
  step: number;
  active?: boolean;
  disabled?: boolean;
}

export interface FormWizardLegendI {
  steps: Array<StepI>;
}

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Step = styled.div<StepI>`
  ${({ theme, step, active, disabled }) => `
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    color: ${theme.palette.primary.main};
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: ${theme.palette.primary.dark};
    }

    &::before {
      content: '';
      margin-right: 8px;
      height: 24px;
      width: 24px;
      border: 1px solid ${theme.palette.primary.main};
      border-radius: 50%;

      background-image: url('${checkmarkIcon}');
      background-size: 13px;
      background-repeat: no-repeat;
      background-position: 60% 60%;

      font-size: 12px;
      text-align: center;
      line-height: 23px;
    }

    ${
      active &&
      `
        font-weight: 700;
        &::before {
          content: '${step}';
          color: ${theme.palette.neutral.white};
          background-color: ${theme.palette.primary.main};
          background-image: none;
        }
      `
    };

    ${
      disabled &&
      `
        pointer-events: none;
        font-weight: 300;
        color: ${theme.palette.neutral.medium};
        &::before {
          content: '${step}';
          color: ${theme.palette.neutral.white};
          background-color: ${theme.palette.neutral.medium};
          border-color: ${theme.palette.neutral.medium};
          background-image: none;
        }
      `
    }
  `}
`;

export const FormWizardLegend: React.FC<FormWizardLegendI> = ({ steps }) => (
  <List>
    {steps.map(({ label, step, ...props }, index) => {
      return (
        <Step step={step} {...props}>
          {label}
        </Step>
      );
    })}
  </List>
);
