import { Card, Typography } from "components";
import { Box } from "components/Box";
import { useWindowWidth } from "hooks/useWindowWidth";
import React from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";

import logoImg from "../../logoI4U.png";

interface ILoginLayout {
  content?: string;
  renderInLeftColumn?: React.ReactElement;
  children: React.ReactNode;
}

const StyledTypography = styled(Typography)`
  font-size: 14px;
`;
const Logo = styled.img`
  ${({ theme }) => `
    margin-bottom: ${theme.space[4]}px;
  `};
`;
const StyledBox = styled(Box)`
  height: 100%;
`;
const LeftCardData = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.veryLight};
  `};
  padding: 32px;
  min-width: 260px;
  height: 100%;
`;

export const LoginLayout: React.FC<ILoginLayout> = ({
  content,
  children,
  renderInLeftColumn,
}) => {
  const windowWidth = useWindowWidth();

  return (
    <Row justify="center">
      <Col xs={windowWidth > 1000 ? 10 : 12}>
        <Card>
          <Row>
            <Col xs={12} md={4}>
              <LeftCardData>
                <Logo src={logoImg} alt="logo investment4u" />
                <StyledTypography variant="body" color="secondary" mb={4}>
                  {content}
                </StyledTypography>
                {renderInLeftColumn}
              </LeftCardData>
            </Col>
            <Col xs={12} md={8}>
              <StyledBox py={8} px={windowWidth > 1000 ? 16 : 4}>
                {children}
              </StyledBox>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
