import { theme } from "config";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  isDisabled?: boolean;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  isDisabled?: boolean;
}

const { palette } = theme;

const colorVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.neutral.dark,
};

const colorHoverVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.neutral.dark,
};

const colorDisabledVariantsMap = {
  primary: palette.neutral.white,
  secondary: rgba(palette.neutral.dark, 0.4),
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.neutral.veryLight,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.primary.dark,
  secondary: palette.neutral.light,
};

const backgroundColorDisabledVariantsMap = {
  primary: palette.neutral.medium,
  secondary: palette.neutral.veryLight,
};

const StyledButton = styled.button<StyledButtonI>`
  ${({ variant, icon, fullWidth, isDisabled }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;
    min-height: 40px;
    ${fullWidth ? `width: 100%;` : ""};
    border-radius: 8px;
    font-weight: ${variant === "primary" ? 600 : 400};
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    border: 0;
    outline: none;
    cursor: pointer;
    ${icon ? "width: 40px;" : ""}
    ${!icon ? "padding: 3px 20px;" : ""}

    &:hover {
      color: ${colorHoverVariantsMap[variant]};
      background-color: ${backgroundColorHoverVariantsMap[variant]};
    }

    &:disabled {
      color: ${colorDisabledVariantsMap[variant]};
      background-color: ${backgroundColorDisabledVariantsMap[variant]};
    }
    opacity: ${isDisabled ? "0.4" : 1}
  `}
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  variant = "primary",
  type = "button",
  disabled = false,
  isDisabled = false,
  fullWidth,
  onClick,
}) => (
  <StyledButton
    /* eslint-disable react/button-has-type */
    // prevent eslint error that react/button-has-type
    // rule causes when button's type prop is dynamically assigned
    type={type}
    /* eslint-enable react/button-has-type */
    icon={icon}
    variant={variant}
    disabled={disabled}
    isDisabled={isDisabled}
    fullWidth={fullWidth}
    onClick={onClick}
  >
    {label}
    {icon}
  </StyledButton>
);
