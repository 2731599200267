import { SearchContactContextProvider } from "contexts/SearchContactContext";
import React from "react";
import { IRoute } from "types/route";

import { Contacts } from "./Contacts";

export const ContactsView: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes }) => (
  <SearchContactContextProvider>
    <Contacts routes={routes} />
  </SearchContactContextProvider>
);
