import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const errorMessage = "Invalid email or password.";

export const useLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT_USER",
    });
    history.push("/");
  };

  const logout = (err: string) => {
    const { error } = JSON.parse(err);
    if (error === errorMessage) {
      handleLogout();
      toast.error("Zostałeś wylogowany z serwisu.");
    }
  };

  return { logout };
};
