import { RouteWithSubRoutes } from "components/RouteWithSubRoutes/RouteWithSubRoutes";
import React from "react";
import { Switch } from "react-router-dom";
import { IRoute } from "types/route";

interface IProps {
  routes: Array<IRoute>;
}

export const Router: React.FC<IProps> = ({ routes }) => {
  return (
    <Switch>
      {routes
        ? routes.map((route: IRoute) => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))
        : null}
    </Switch>
  );
};
