import { ADVERTISEMENTS_URL, PUBLIC_ADVERTISEMENTS_URL } from "constants/paths";
import qs from "qs";
import { IAdvertisement } from "types/advertisement";
import { fetchAPI } from "utilities/fetchAPI";

export type IFetchAdvertisementsResponse = {
  pagination: {
    current: number;
    previous: null;
    next: null;
    /* eslint-disable camelcase */
    per_page: number;
    pages: number;
    count: number;
  };
  data: Array<IAdvertisement>;
};

export interface ISearchParams {
  order_by?: string;
  order_sort?: string;
  my_advertisements?: boolean;
  my_negotiations?: boolean;
  finished_status?: boolean;
  selected_category?: string;
  lowest_price?: number;
  highest_price?: number;
  lowest_roi?: string;
  highest_roi?: string;
  cities?: string;
  lowest_area?: number;
  highest_area?: number;
  lowest_number_of_apartments?: number;
  highest_number_of_apartments?: number;
  lowest_number_of_floors?: number;
  highest_number_of_floors?: number;
  lowest_number_of_apartments_on_floor?: number;
  highest_number_of_apartments_on_floor?: number;
  legal_status_of_the_facility?: string;
  years_of_construction_from?: number;
  years_of_construction_to?: number;
  voivodships?: string;
  my_favourite_advertisements?: boolean;
  page?: number;
}

export const search = async ({
  order_by,
  order_sort,
  my_advertisements,
  my_negotiations,
  finished_status,
  selected_category,
  lowest_price,
  highest_price,
  lowest_roi,
  highest_roi,
  lowest_area,
  highest_area,
  lowest_number_of_apartments,
  cities,
  highest_number_of_apartments,
  lowest_number_of_floors,
  highest_number_of_floors,
  lowest_number_of_apartments_on_floor,
  highest_number_of_apartments_on_floor,
  legal_status_of_the_facility,
  years_of_construction_from,
  years_of_construction_to,
  voivodships,
  my_favourite_advertisements,
  page,
}: /* eslint-enable camelcase */
ISearchParams = {}): Promise<any> => {
  const query = qs.stringify(
    {
      order_by,
      order_sort,
      my_advertisements,
      my_negotiations,
      selected_category,
      finished_status,
      lowest_price,
      highest_price,
      lowest_roi,
      highest_roi,
      lowest_area,
      cities,
      highest_area,
      lowest_number_of_apartments_on_floor,
      highest_number_of_apartments_on_floor,
      lowest_number_of_apartments,
      highest_number_of_apartments,
      lowest_number_of_floors,
      highest_number_of_floors,
      legal_status_of_the_facility,
      years_of_construction_from,
      years_of_construction_to,
      voivodships,
      page,
      filter: my_favourite_advertisements
        ? "my_favourite_advertisements"
        : undefined,
    },
    { skipNulls: true }
  );
  // TODO: add actual query params
  const searchData = await fetchAPI<IFetchAdvertisementsResponse>(
    `${ADVERTISEMENTS_URL}?${query}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return searchData;
};

export const searchPublic = async ({
  order_by,
  order_sort,
  my_advertisements,
  my_negotiations,
  finished_status,
  selected_category,
  lowest_price,
  highest_price,
  lowest_roi,
  highest_roi,
  lowest_area,
  cities,
  highest_area,
  lowest_number_of_apartments,
  highest_number_of_apartments,
  lowest_number_of_floors,
  highest_number_of_floors,
  lowest_number_of_apartments_on_floor,
  highest_number_of_apartments_on_floor,
  legal_status_of_the_facility,
  years_of_construction_from,
  years_of_construction_to,
  voivodships,
  page,
  my_favourite_advertisements,
}: /* eslint-enable camelcase */
ISearchParams = {}): Promise<any> => {
  const query = qs.stringify(
    {
      order_by,
      order_sort,
      my_advertisements,
      my_negotiations,
      selected_category,
      finished_status,
      lowest_price,
      highest_price,
      lowest_roi,
      highest_roi,
      lowest_area,
      cities,
      highest_area,
      lowest_number_of_apartments_on_floor,
      highest_number_of_apartments_on_floor,
      lowest_number_of_apartments,
      highest_number_of_apartments,
      lowest_number_of_floors,
      highest_number_of_floors,
      legal_status_of_the_facility,
      years_of_construction_from,
      years_of_construction_to,
      voivodships,
      page,
      filter: my_favourite_advertisements
        ? "my_favourite_advertisements"
        : undefined,
    },
    { skipNulls: true }
  );
  // TODO: add actual query params
  const searchData = await fetchAPI<IFetchAdvertisementsResponse>(
    `${PUBLIC_ADVERTISEMENTS_URL}?${query}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return searchData;
};
