import { GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";

interface IMap {
  lat: number;
  lng: number;
}

export const Map: React.FC<IMap> = ({ lat, lng }) => {
  return (
    <>
      <GoogleMap
        mapContainerStyle={{ height: 245 }}
        center={{ lat, lng }}
        zoom={14}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </>
  );
};
