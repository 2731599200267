import styled from "styled-components";

export const ButtonsGroup = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: flex-end;

    > * {
      margin-right: ${theme.space[6]}px;
    
      &:last-child {
        margin-right: 0;
      }
    }
  `}
`;
