import {store} from "store";

import {http} from "./http";

export const fetchAPI = async <T>(url: string, options?: RequestInit) => {
  const state = store.getState();

  return http<T>(url, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${state.currentUser.token}`,
    },
  });
};
