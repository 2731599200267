import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { FileUpload } from "components/FileUpload";
import { Typography } from "components/Typography";
import React from "react";
import { useState } from "react";

import { IPhotosData } from "./types";

interface IPhotosStep {
  onCancelClick?: () => void;
  onSubmit?: (data: {
    interiorImages: File[];
    blueprintsImages: File[];
    environmentImages: File[];
  }) => void;
}

export const PhotosStep: React.FC<IPhotosStep> = ({
  onCancelClick,
  onSubmit,
}) => {
  const [interiorImages, setInteriorImages] = useState<Array<File>>([]);
  const [blueprintsImages, setBlueprintsImages] = useState<Array<File>>([]);
  const [environmentImages, setEnvironmentImages] = useState<Array<File>>([]);

  const handleFileUpload =
    (setter: React.Dispatch<React.SetStateAction<File[]>>) =>
    (files: Array<File>) => {
      setter(files);
    };

  const handleSubmit = () => {
    if (!onSubmit) return;
    onSubmit({
      interiorImages: [...interiorImages],
      blueprintsImages: [...blueprintsImages],
      environmentImages: [...environmentImages],
    });
  };

  return (
    <>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Zdjęcia wnętrza
        </Typography>
        <FileUpload onFile={handleFileUpload(setInteriorImages)} />
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Rzuty 2D/3D
        </Typography>
        <FileUpload onFile={handleFileUpload(setBlueprintsImages)} />
      </Box>
      <Typography variant="h3" mb={3}>
        Budynek, klatka, otoczenie
      </Typography>
      <FileUpload onFile={handleFileUpload(setEnvironmentImages)} />
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick && onCancelClick}
          />
          <Button type="submit" label="Dalej" onClick={handleSubmit} />
        </ButtonsGroup>
      </Box>
    </>
  );
};
