import { Box } from "components/Box";
import { Investment } from "components/Investment";
import { useInvestments } from "hooks/investments";
import { useWindowWidth } from "hooks/useWindowWidth";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAdvertisements } from "services/advertisements";
import { IAdvertisement } from "types/advertisement";

interface ParamsTypes {
  id: string;
}

export const OtherProfileAdvertisementsView = () => {
  const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([]);

  const params = useParams<ParamsTypes>();
  const userId = parseInt(params.id, 10);

  const windowWidth = useWindowWidth();

  const getAdvertisements = async () => {
    const response = await fetchAdvertisements({ user_id: userId });
    setAdvertisements(response.data);
  };

  useEffect(() => {
    getAdvertisements();
  }, []);

  const { investments } = useInvestments({
    advertisements,
    view: "horizontal",
    isPrivate: true,
  });

  return (
    <>
      {investments.length
        ? investments.map((investment) => (
            <Box key={investment.id} mb={4}>
              <Investment
                {...investment}
                view={windowWidth < 1000 ? "vertical" : "horizontal"}
              />
            </Box>
          ))
        : null}
    </>
  );
};
