import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { TextareaField } from "components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { IOfferDescriptionFormData } from "./types";

interface IOfferDescriptionStep {
  onCancelClick?: () => void;
  onSubmit?: (data: IOfferDescriptionFormData) => void;
}

export const OfferDescriptionStep: React.FC<IOfferDescriptionStep> = ({
  onCancelClick,
  onSubmit,
}) => {
  const { register, handleSubmit, errors, formState } =
    useForm<IOfferDescriptionFormData>({
      mode: "onChange",
      resolver: yupResolver(
        yup.object().shape({
          description: yup.string().required(),
        })
      ),
    });

  return (
    <form onSubmit={onSubmit && handleSubmit(onSubmit)}>
      <TextareaField
        name="description"
        ref={register()}
        label="Opis oferty"
        rows={200}
        error={errors.description?.message}
      />
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick && onCancelClick}
          />
          <Button type="submit" label="Dalej" disabled={!formState.isValid} />
        </ButtonsGroup>
      </Box>
    </form>
  );
};
