import { Box } from "components/Box";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { ReactComponent as ConfirmedIcon } from "icons/confirmed.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import React, { useState } from "react";
import styled from "styled-components";

import { Avatar } from "../Avatar/Avatar";
import { Button } from "../Button";
import { ButtonsGroup } from "../ButtonsGroup";
import { TextField } from "../TextField";
import { IUsersListItem } from "../UsersList/UsersList";

interface IContactsListModal {
  contacts: IUsersListItem[];
  isOpen: boolean;
  buttonLabel: string;
  handleAction: (user: IUsersListItem) => void;
  onCancelClick: () => void;
}

const Content = styled.div`
  ${({ theme }) => `
     width: 554px;
     padding: ${theme.space[6]}px ${theme.space[4]}px;

     @media only screen and (max-width: 1000px) {
      width: 300px;
    }
  `};
`;

const FlexCentered = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: inline-block;
`;
const StyledBox = styled(Box)`
  max-height: 400px;
  overflow: auto;
`;

const PersonDataContainer = styled.div``;
const PersonRow = styled.div<{ selected: boolean }>`
  padding: 8px 12px;
  display: flex;
  justify-content: left;
  align-items: center;
  ${({ selected, theme }) => `
   & > ${FlexCentered} {
    margin-left: 8px;
    & > ${IconWrapper} {
      display: ${selected ? "block" : "none"};
      color: ${
        selected ? theme.palette.primary.main : theme.palette.neutral.dark
      };
    }
   }
   &:hover {
     background-color: ${theme.palette.neutral.veryLight};
     border-radius: 8px;
     & > ${FlexCentered} {
       & > ${IconWrapper} {
         cursor: pointer;
         display: block;
       }
      }
     }
  `}
`;

export const ContactsListModal: React.FC<IContactsListModal> = ({
  contacts,
  isOpen,
  buttonLabel,
  handleAction,
  onCancelClick,
}) => {
  const [selectedContact, setSelectedContact] = useState<IUsersListItem | null>(
    null
  );

  const handleSelectContact = async (contact: IUsersListItem) => {
    setSelectedContact(contact);
  };

  const onSubmit = () => {
    if (selectedContact) handleAction(selectedContact);
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <>
        <Content>
          <Box px={2}>
            <Typography variant="h1" color="primary" mb={4}>
              Twoi znajomi
            </Typography>
            <TextField placeholder="Szukaj znajomych" icon={<SearchIcon />} />
          </Box>
          <StyledBox py={2}>
            {contacts &&
              contacts.map((contact) => (
                <PersonRow
                  onClick={() => {
                    handleSelectContact(contact);
                  }}
                  selected={selectedContact?.id === contact.id}
                >
                  <Avatar size="small" />
                  <FlexCentered>
                    <PersonDataContainer>
                      <Typography variant="h3">
                        {contact.user.firstName} {contact.user.lastName}
                      </Typography>
                    </PersonDataContainer>
                    <IconWrapper>
                      <ConfirmedIcon />
                    </IconWrapper>
                  </FlexCentered>
                </PersonRow>
              ))}
          </StyledBox>
          <Box mt={4}>
            <ButtonsGroup>
              <Button
                type="button"
                label="Anuluj"
                variant="secondary"
                onClick={onCancelClick}
              />
              <Button onClick={onSubmit} label={buttonLabel} />
            </ButtonsGroup>
          </Box>
        </Content>
      </>
    </Modal>
  );
};
