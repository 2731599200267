import { Typography } from "components";
import { Box } from "components/Box";
import { Menu } from "components/Menu";
import { Router } from "components/Router/Router";
import { useMenu } from "hooks/menu";
import { useProfile } from "hooks/profile";
import React from "react";
import { Col, Row } from "react-grid-system";
import { IRoute } from "types/route";

export const ProfileView: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  const { user } = useProfile(true);

  const menuItems = useMenu([
    {
      label: "O użytkowniku",
      path: "/profile/info",
    },
    // {
    //   label: "Referencje",
    //   path: "/references",
    // },
    {
      label: "Ustawienia profilu",
      path: "/profile/settings",
      items: [
        {
          label: "Dane logowania",
          path: "/profile/settings/login-data",
        },
        {
          label: "Dane osobowe",
          path: "/profile/settings/personal-data",
        },
        {
          label: "Dane firmy",
          path: "/profile/settings/company-data",
        },
        {
          label: "Doświadczenie i specjalizacja",
          path: "/profile/settings/experience-and-specialization",
        },
        {
          label: "Oczekiwania i preferencje",
          path: "/profile/settings/expectations-and-preferences",
        },
      ],
    },
  ]);

  return (
    <>
      <Typography variant="h1" color="primary" mb={3}>
        Profil użytkownika: {user?.data?.profile?.first_name}{" "}
        {user?.data.profile?.last_name}
      </Typography>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <Menu items={menuItems} />
          </Box>
        </Col>
        <Col sm={8}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
