import { AvatarI } from "components/Avatar/Avatar";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Media } from "components/Media";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import { ReactComponent as SendIcon } from "icons/send.svg";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { goToUserInfo } from "utilities/goToUserInfoHelper";

interface IComment {
  id: number;
  avatar?: AvatarI["url"];
  firstName?: string;
  lastName?: string;
  date: Date;
  content: string;
  items?: Array<IComment>;
}

interface ISendCommentFormData {
  message: string;
}
export interface IComments {
  items: Array<IComment>;
  onSubmitCallback?: (data: ISendCommentFormData) => any;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
			padding: ${theme.space[6]}px ${theme.space[6]}px ${theme.space[8]}px;
	`};
`;

const StyledSendIcon = styled(SendIcon)`
  cursor: pointer;
`;

export const Comments: React.FC<IComments> = ({ items, onSubmitCallback }) => {
  const { register, reset, handleSubmit } = useForm<ISendCommentFormData>();

  const onSubmit = (data: ISendCommentFormData) => {
    if (!onSubmitCallback) return;
    onSubmitCallback(data);
    reset();
  };

  const history = useHistory();

  return (
    <StyledCard>
      <Typography color="primary" mb="3" variant="h2">
        Komentarze
      </Typography>
      <Box mb={4}>
        {items.map((comment) => (
          <div key={comment.id}>
            <Box p={2}>
              <Media {...comment} goToUserInfo={goToUserInfo(history)} />
            </Box>
            <Box ml={10}>
              {comment.items &&
                comment.items.map((item) => (
                  <Box key={item.id} p={2}>
                    <Media {...item} goToUserInfo={goToUserInfo(history)} />
                  </Box>
                ))}
            </Box>
          </div>
        ))}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          placeholder="Napisz komentarz"
          icon={<StyledSendIcon onClick={handleSubmit(onSubmit)} />}
          name="message"
          ref={register({
            minLength: 1,
          })}
        />
      </form>
    </StyledCard>
  );
};
