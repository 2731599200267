import { loginUser, registerUser } from "services/auth";
import { updatePreferences } from "services/preferences";
import { updateProfile } from "services/profile";
import { fetchCurrentUser } from "services/users";
import { IUserPreference, IUserProfileAvatarUpload } from "types/user";

import { parseLoginUserResponse } from "./parsers";

interface ILoginUserPayload {
  email: string;
  password: string;
}

export const getCurrentUserAction = () => {
  return async (dispatch: any) => {
    const response = await fetchCurrentUser();

    dispatch({
      type: "GET_CURRENT_USER",
      payload: response.data,
    });
  };
};

export const loginUserAction = (userData: ILoginUserPayload) => {
  return async (dispatch: any) => {
    const response = await loginUser(userData);
    const user = parseLoginUserResponse(response);

    dispatch({
      type: "LOGIN_USER",
      payload: user,
    });
  };
};

export const logoutUserAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: "LOGOUT_USER",
    });
  };
};

export const registerUserAction = (userData: ILoginUserPayload) => {
  return async (dispatch: any) => {
    const response = await registerUser(userData);
    const user = parseLoginUserResponse(response);

    dispatch({
      type: "LOGIN_USER",
      payload: user,
    });
  };
};

export const updateProfileAction = (
  userProfile: Partial<IUserProfileAvatarUpload>
) => {
  return async (dispatch: any) => {
    if (userProfile.avatar && typeof userProfile.avatar === "string") {
      // eslint-disable-next-line no-param-reassign
      delete userProfile.avatar;
    }
    console.log("userProfile", userProfile);
    const response = await updateProfile(userProfile);

    dispatch({
      type: "UPDATE_PROFILE",
      payload: response.data.profile,
    });
  };
};

export const updatePreferencesAction = (
  userPreferences: Partial<IUserPreference>
) => {
  return async (dispatch: any) => {
    const response = await updatePreferences(userPreferences);

    dispatch({
      type: "UPDATE_PREFERENCES",
      payload: response.data.preference,
    });
  };
};
