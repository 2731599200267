import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { Box } from "components/Box";
import { TextField } from "components/TextField";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { profilePersonalDataSchema } from "schemas/profileSettings";
import { updateProfileAction } from "store/currentUser/actions";
import { StateI } from "store/currentUser/types";
import styled from "styled-components";
import { IUser } from "types/user";

import { ButtonContainer, FormContainer } from "../ProfileInfo";

interface IFormData {
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  postal_code: string;
  telephone_number: string;
  avatar: File | string | Record<string, unknown>;
  description: string;
}

interface IPersonalDataForm {
  currentUser: IUser;
  onCloseForm: (val: number) => void;
  setAvatarUrl: (url: string) => void;
}

const HiddenInput = styled.input`
  display: none;
`;

export const PersonalDataForm: React.FC<IPersonalDataForm> = ({
  currentUser,
  onCloseForm,
  setAvatarUrl,
}) => {
  const dispatch = useDispatch();
  const avatarRef = useRef<HTMLInputElement>(null);

  const [defaultValues, setDefaultValues] = useState<IFormData>({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    postal_code: "",
    telephone_number: "",
    avatar: {},
    description: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues,
    resolver: yupResolver(profilePersonalDataSchema),
  });

  const onSubmit = async (data: IFormData) => {
    setDefaultValues({ ...defaultValues, ...data });
    dispatch(updateProfileAction(data));
    onCloseForm(0);
  };

  const clickHiddenInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (!ref || !ref.current) return;
    ref.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setAvatarUrl(URL.createObjectURL(event.target.files[0]));
    setValue("avatar", event.target.files[0]);
  };

  useEffect(() => {
    const { avatar } = currentUser?.profile;
    const preparedData = {
      ...currentUser?.profile,
      avatar: avatar === null ? {} : avatar,
    };
    setDefaultValues({ ...defaultValues, ...preparedData });
  }, [currentUser]);
  useEffect(() => {
    reset(defaultValues);
    register("avatar");
    setValue("avatar", defaultValues.avatar);
  }, [defaultValues]);

  return (
    <>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HiddenInput
            ref={avatarRef}
            type="file"
            name="avatar"
            onChange={handleImageUpload}
          />
          <Box mb={3}>
            <Button
              label="Zmień zdjęcie"
              variant="secondary"
              onClick={() => clickHiddenInput(avatarRef)}
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="Imię"
              name="first_name"
              ref={register}
              error={errors?.first_name?.message}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Nazwisko"
              name="last_name"
              ref={register}
              error={errors?.last_name?.message}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Adres"
              name="address"
              ref={register}
              error={errors?.address?.message}
            />
          </Box>
          <Row>
            <Col sm={5}>
              <Box mb={3}>
                <TextField
                  label="Kod pocztowy"
                  name="postal_code"
                  ref={register}
                  error={errors?.postal_code?.message}
                />
              </Box>
            </Col>
            <Col sm={7}>
              <Box mb={3}>
                <TextField
                  label="Miejscowość"
                  name="city"
                  ref={register}
                  error={errors?.city?.message}
                />
              </Box>
            </Col>
          </Row>
          <Box mb={3}>
            <TextField
              label="Nr telefonu"
              name="telephone_number"
              ref={register}
              minLength={9}
              error={errors?.telephone_number?.message}
            />
          </Box>
          <Box mb={7}>
            <TextField
              label="Opis (maksymalnie 300 znaków)"
              name="description"
              ref={register}
              error={errors?.description?.message}
              maxLength={300}
            />
          </Box>

          <ButtonContainer>
            <Button type="submit" label="Zapisz" />
            <Button
              onClick={() => {
                onCloseForm(0);
              }}
              label="Anuluj"
              variant="secondary"
            />
          </ButtonContainer>
        </form>
      </FormContainer>
    </>
  );
};
