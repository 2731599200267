import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Flex } from "components/Flex";
import { Map } from "components/Map";
import { Typography } from "components/Typography";
import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { generateAdvertisamentsArrayData } from "utilities/generateAdvertisametDataArray";

import { formatDate } from "../../utilities/date";
import { AdvertDataType } from "./types";

interface ISummary {
  advert: AdvertDataType;
  onCancelClick: () => void;
  onSubmit: (advert: AdvertDataType) => void;
  isSubmitButtonDisabled: boolean;
}

interface IStyledFlex {
  half?: boolean;
}

const StyledFlex = styled(Flex)<IStyledFlex>`
  ${({ theme, half }) => `
    margin-bottom: ${theme.space[4]}px;
    ${half ? ` width: 50%; ` : ` width: 100%; `}
  `}
`;

const PreviewArea = styled.div`
  ${({ theme }) => `
  margin-top: ${theme.space[3]}px;
  margin-bottom: ${theme.space[4]}px;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
  column-gap: ${theme.space[4]}px;
  row-gap: ${theme.space[4]}px;
  
  `}
`;

const ThumbnailContainer = styled.div`
  position: relative;
`;

const Thumbnail = styled.img`
  height: clamp(72px, 15vw, 200px);
  width: 100%;
  border-radius: 4px;
  margin: 0 auto;
  object-fit: cover;
`;

const imageTypes = {
  interiorImages: "Zdjęcia wnętrza",
  blueprintsImages: "Rzuty 2D/3D",
  environmentImages: "Budynek, klatka, otoczenie"
};

export const SummaryStep: React.FC<ISummary> = ({
                                                  advert,
                                                  onCancelClick,
                                                  onSubmit,
                                                  isSubmitButtonDisabled
                                                }) => {
  const renderImages = () => {
    if (advert.imagesToShow === undefined) return;
    const allImages = Object.keys(advert.imagesToShow).map((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const images = Object.keys(advert.imagesToShow[key]).map(
        (subKey: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const img = advert.imagesToShow[key][subKey];
          return (
            <ThumbnailContainer>
              <Thumbnail src={URL.createObjectURL(img)} />
            </ThumbnailContainer>
          );
        }
      );

      function getImageType(type: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return imageTypes[type];
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Object.keys(advert.imagesToShow[key]).length < 1 ? null : (
        <>
          <Typography variant="h3">{getImageType(key)}</Typography>
          <PreviewArea>{images}</PreviewArea>
        </>
      );
    });

    // eslint-disable-next-line consistent-return
    return allImages.length < 0 ? null : (
      <StyledFlex flexDirection="column">{allImages}</StyledFlex>
    );
  };

  return (
    <>
      <StyledFlex flexDirection="column">
        <Typography variant="body2">Nazwa oferty</Typography>
        <Typography variant="h3">{advert.name}</Typography>
      </StyledFlex>

      <StyledFlex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {generateAdvertisamentsArrayData(
          advert.category,
          advert as AdvertDataType
        ).map(({ label, content }) => {
          return (
            <StyledFlex half flexDirection="column">
              <Typography variant="body2">{label}</Typography>
              {
                // eslint-disable-next-line no-nested-ternary
                label === "Cena:"
                  ? <NumberFormat
                    value={advert.price}
                    displayType="text"
                    thousandSeparator=" "
                    suffix=" PLN"
                  />
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  : label === "Rok budowy:"
                    ? <Typography variant="h3">{typeof content === "object"
                      ? formatDate(content)
                      : content.toString()
                    }</Typography>
                    : <Typography variant="h3">{content}</Typography>
              }

            </StyledFlex>
          );
        })}
        <StyledFlex half flexDirection="column">
          <Typography variant="body2">Wymiary:</Typography>
          <Typography variant="h3">
            {advert.dimension_x}x{advert.dimension_y}
          </Typography>
        </StyledFlex>
      </StyledFlex>

      <StyledFlex flexDirection="column">
        <Typography variant="body2">Opis sposobu wyliczenia ROI</Typography>
        <Typography variant="h3">
          {advert.description_of_the_roi_calculation_method}
        </Typography>
      </StyledFlex>

      <StyledFlex flexDirection="column">
        <Typography variant="body2">Opis</Typography>
        <Typography variant="h3">{advert.description}</Typography>
      </StyledFlex>

      <StyledFlex flexDirection="column">
        <Typography variant="body2">Lokalizacja</Typography>
        <Map lat={advert.latitude} lng={advert.longitude} />
      </StyledFlex>
      {renderImages()}
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick}
          />
          <Button
            type="submit"
            label="Zatwierdź"
            disabled={isSubmitButtonDisabled}
            onClick={() => onSubmit(advert)}
          />
        </ButtonsGroup>
      </Box>
    </>
  );
};
