import { IOption } from "components/Select";
import { cities } from "database/cities";
import { generateSelectRangeOptions } from "utilities/generateSelectRangeOptions";

interface IVariant {
  label?: string;
  options?: Array<IOption>;
  name: string;
  type: "text" | "dropdown";
  placeholder?: string;
}

const voivodshipsOptions = [
  {
    value: "dolnoslaskie",
    label: "Dolnośląskie",
  },
  {
    value: "kujawsko-pomorskie",
    label: "Kujawsko pomorskie",
  },
  {
    value: "lubelskie",
    label: "Lubelskie",
  },
  {
    value: "lubuskie",
    label: "Lubuskie",
  },
  {
    value: "lodzkie",
    label: "Łódzkie",
  },
  {
    value: "malopolskie",
    label: "Małopolskie",
  },
  {
    value: "mazowieckie",
    label: "Mazowieckie",
  },
  {
    value: "opolskie",
    label: "Opolskie",
  },
  {
    value: "podkarpackie",
    label: "Podkarpackie",
  },
  {
    value: "podlaskie",
    label: "Podlaskie",
  },
  {
    value: "pomorskie",
    label: "Pomorskie",
  },
  {
    value: "slaskie",
    label: "Śląskie",
  },
  {
    value: "swietokrzyskie",
    label: "Świętokrzyskie",
  },
  {
    value: "warminsko-mazurskie",
    label: "Warmińsko mazurskie",
  },
  {
    value: "wielkopolskie",
    label: "Wielkopolskie",
  },
  {
    value: "zachodniopomorskie",
    label: "Zachodniopomorskie",
  },
];

export const getFilterVariant = (
  variant: string | undefined
): Array<Array<IVariant>> => {
  switch (variant) {
    case "apartment":
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Ilość pokoi",
            name: "lowest_number_of_apartments",
            type: "dropdown",
            options: generateSelectRangeOptions(1, 10),
          },
          {
            name: "highest_number_of_apartments",
            type: "dropdown",
            options: generateSelectRangeOptions(1, 10),
          },
        ],
        [
          {
            label: "Rok budowy",
            name: "years_of_construction_from",
            type: "dropdown",
            options: generateSelectRangeOptions(1970, 2021),
          },
          {
            name: "years_of_construction_to",
            type: "dropdown",
            options: generateSelectRangeOptions(1970, 2021),
          },
        ],
        [
          {
            label: "Powierzchnia (m²)",
            name: "lowest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(25, 350),
          },
          {
            name: "highest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(25, 350),
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];
    case "tenement_house":
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Ilość pięter",
            name: "lowest_number_of_floors",
            type: "dropdown",
            options: generateSelectRangeOptions(0, 40),
          },
          {
            name: "highest_number_of_floors",
            type: "dropdown",
            options: generateSelectRangeOptions(0, 40),
          },
        ],
        [
          {
            label: "Ilość mieszkań",
            name: "lowest_number_of_apartments",
            type: "dropdown",
            options: generateSelectRangeOptions(10, 100),
          },
          {
            name: "highest_number_of_apartments",
            type: "dropdown",
            options: generateSelectRangeOptions(10, 100),
          },
        ],
        [
          {
            label: "Rok budowy",
            name: "years_of_construction_from",
            type: "dropdown",
            options: generateSelectRangeOptions(1970, 2021),
          },
          {
            name: "years_of_construction_to",
            type: "dropdown",
            options: generateSelectRangeOptions(1970, 2021),
          },
        ],
        [
          {
            label: "Powierzchnia budynku",
            name: "lowest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
          {
            name: "highest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];
    case "service_premises":
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Rodzaj",
            name: "type_of_local",
            type: "text",
          },
        ],
        [
          {
            label: "Powierzchnia",
            name: "lowest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
          {
            name: "highest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];
    case "warehouse":
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Rodzaj",
            name: "type_of_local",
            type: "text",
          },
        ],
        [
          {
            label: "Powierzchnia",
            name: "lowest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
          {
            name: "highest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];
    case "plot":
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Rodzaj",
            name: "type_of_local",
            type: "text",
          },
        ],
        [
          {
            label: "Powierzchnia",
            name: "lowest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
          {
            name: "highest_area",
            type: "dropdown",
            options: generateSelectRangeOptions(400, 1000),
          },
        ],
        [
          {
            // FIXME: no corresponding single field in the API
            label: "Dostępność mediów",
            name: "",
            type: "text",
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];

    default:
      return [
        [
          {
            label: "Cena (PLN)",
            name: "lowest_price",
            type: "text",
            placeholder: "od",
          },
          {
            name: "highest_price",
            type: "text",
            placeholder: "do",
          },
        ],
        [
          {
            label: "Lokalizacja",
            name: "voivodships",
            type: "dropdown",
            options: voivodshipsOptions,
          },
          {
            label: "Miasto",
            name: "cities",
            type: "dropdown",
            options: cities,
          },
        ],
      ];
  }
};
