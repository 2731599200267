export const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/signup`;
export const REGISTER_CONFIRMATION_URL = `${API_URL}/confirmation`;
export const PROFILE_URL = `${API_URL}/profile`;
export const PREFERENCES_URL = `${API_URL}/preference`;
export const PASSWORD_RESET = `${API_URL}/password`;
export const USERS_ME_URL = `${API_URL}/users/me`;
export const OTHER_PROFILE = (userId: string) => `${API_URL}/users/${userId}`;
export const getNegotiationsUrl = (advertisementId: string) =>
  `${API_URL}/posts/${advertisementId}/negotiations`;
export const getNegotiationUrl = (id: string) =>
  `${API_URL}/negotiations/${id}`;
export const CREATE_NEGOTIATION = `${API_URL}/negotiations`;
export const ADVERTISEMENTS_URL = `${API_URL}/posts`;
export const getAdvertisementCommentsUrl = (advertisementId: string) =>
  `${API_URL}/posts/${advertisementId}/comments`;
export const PUBLIC_ADVERTISEMENTS_URL = `${API_URL}/public/posts`;
export const CONTACTS_URL = `${API_URL}/contacts`;
export const RECOMMENDED_CONTACTS_URL = `${API_URL}/recommended_contacts`;
export const CONTACT_INVITATIONS_URL = `${API_URL}/contact_invitations`;
export const CONTACT_INVITATION_URL = `${API_URL}/contact_invitation`;
export const USER_INVITATION_URL = `${API_URL}/users/invite`;
export const NOTIFICATIONS_URL = `${API_URL}/notifications`;
export const NEW_NOTIFICATIONS_URL = `${NOTIFICATIONS_URL}/show_all_not_seen`;
