import { IInvestment } from "components/Investment/Investment";
import { investmentsSpecializationsFields } from "constants/profileFormFields";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IAdvertisement, IPublicAdvertisement } from "types/advertisement";
import { goToUserInfo } from "utilities/goToUserInfoHelper";

import {
  addAdvertisementToFavourite,
  removeAdvertisementFromFavourite,
} from "../services/advertisements";

interface IProps {
  advertisements: Array<IAdvertisement | IPublicAdvertisement>;
  view: IInvestment["view"];
  isPrivate?: boolean;
}

export const useInvestments = ({ advertisements, view, isPrivate }: IProps) => {
  const [investments, setInvestments] = useState<IInvestment[]>([]);
  const history = useHistory();

  const onShowAdvertisementClick = (id: number) => {
    if (isPrivate) {
      history.push(`/advertisement/${id}`);
    } else {
      history.push(`/advertisement/${id}/public`);
    }
  };

  const onShowNegotiationsClick = (id: number) => {
    history.push(`/advertisement/${id}/negotiations`);
  };

  const getUpdatedInvestmentsAfterLike = (
    oldInvestments: IInvestment[],
    toggleFav: { id: number | null; advertisement_id: number }
  ) => {
    return oldInvestments.map((investment) => {
      return {
        ...investment,
        favourite:
          investment.id !== toggleFav.advertisement_id
            ? investment.favourite
            : toggleFav.id,
      };
    });
  };

  const onAddFavouriteClick = async (id: number) => {
    try {
      const response = await addAdvertisementToFavourite({
        advertisement_id: id,
      });
      setInvestments((prevState) => {
        return getUpdatedInvestmentsAfterLike(prevState, response);
      });
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else {
        toast.error("Nie udało się dodać do ulubionych.");
      }
    }
  };

  const onRemoveFavouriteClick = (id: number, investmentId: number) => {
    try {
      removeAdvertisementFromFavourite({
        advertisement_id: id,
      });
      setInvestments((prevState) => {
        return getUpdatedInvestmentsAfterLike(prevState, {
          id: null,
          advertisement_id: investmentId,
        });
      });
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else {
        toast.error("Nie udało się usunąć z ulubionych.");
      }
    }
  };

  useEffect(() => {
    if (advertisements.length < 1) {
      setInvestments([]);
      return;
    }
    if (isPrivate) {
      const advertisementsData = advertisements as IAdvertisement[];

      setInvestments(
        advertisementsData.map(
          ({
            favourite_advertisement,
            id,
            name,
            price,
            roi,
            area,
            voivodship,
            category,
            user,
            images,
            legal_status_of_the_facility,
            negotiations_count,
            my_negotiation,
          }) => ({
            images,
            favourite: favourite_advertisement
              ? favourite_advertisement.id
              : null,
            id,
            name,
            price,
            roi,
            area,
            localization: voivodship,
            type: category,
            negotiations_count,
            my_negotiation,
            legalStatus: legal_status_of_the_facility,
            owner: {
              avatar: user.avatar,
              firstName: user.profile.first_name ?? "-",
              lastName: user.profile.last_name ?? "-",
              id: user.id,
              goToUserInfo: goToUserInfo(history),
            },
            view,
            onShowAdvertisementClick,
            onShowNegotiationsClick,
            onAddFavouriteClick,
            onRemoveFavouriteClick,
          })
        )
      );
    } else {
      const advertisementsData = advertisements as IPublicAdvertisement[];

      setInvestments(
        advertisementsData.map(
          ({
            id,
            name,
            price,
            roi,
            area,
            voivodship,
            category,
            legal_status_of_the_facility,
            images,
            negotiations_count,
            my_negotiation,
          }) => ({
            favourite: null,
            id,
            name,
            price,
            roi,
            area,
            images,
            localization: voivodship,
            negotiations_count,
            legalStatus: legal_status_of_the_facility,
            type: category,
            view,
            my_negotiation,
            onShowAdvertisementClick,
            onShowNegotiationsClick,
            onAddFavouriteClick,
            onRemoveFavouriteClick,
          })
        )
      );
    }
  }, [advertisements]);

  return { investments };
};
