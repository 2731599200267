import { Box } from "components/Box";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import { ReactComponent as ContactAddIcon } from "icons/contact-add.svg";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IContact } from "types/contact";

import { sendInvitationToUser } from "../../services/contacts";
import { Avatar } from "../Avatar/Avatar";
import { Button } from "../Button";

export interface IContactSuggestions {
  items?: IContact[];
  onProfileClick: (id: number) => void;
  goToMyContactsPage: () => void;
}
export interface IContactSuggestionsItems {
  fullName: string;
  avatar: string;
  contacts: number;
}

const Root = styled.div`
  width: 100%;
`;

const Person = styled.div`
  padding: 8px 12px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  & > div:nth-child(2) {
    margin-left: 8px;
    & > div:nth-child(2) {
      display: none;
    }
  }
  ${({ theme }) => `
    &:hover {
      background-color: ${theme.palette.neutral.veryLight};
      border-radius: 8px;
      & > div:nth-child(2) {
        & > div:nth-child(2) {
          cursor: pointer;
          display: block;
        }
      }
    }
  `}
`;

const Title = styled(Typography).attrs({
  variant: "h2",
  color: "primary",
})`
  ${({ theme }) => `
    padding: 0px ${theme.space[3]}px;
  `}
  margin-bottom: 10px;
`;

const FlexCentered = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: inline-block;
`;

export const ContactSuggestions: React.FC<IContactSuggestions> = ({
  items,
  onProfileClick,
  goToMyContactsPage,
}) => {
  const [recentlyAdded, setRecentlyAdded] = useState<Array<number>>([]);

  const handleAddContact = async (contact: IContact) => {
    try {
      await sendInvitationToUser(contact.id);
      setRecentlyAdded((prevState) => prevState.concat([contact.id]));
      toast.success(
        `Zaprosiłeś użytkownika ${contact.first_name} ${contact.last_name} do kontaktów.`
      );
    } catch (error) {
      toast.error("Nie udało się wysłać zaproszenia do kontaktów.");
    }
  };

  return (
    <Root>
      <Card>
        <Box px={6} py={5}>
          <Title>Propozycje kontaktów</Title>
          {items &&
            items.slice(0, 10).map((contact) => (
              <Person
                key={contact.id}
                onClick={() => onProfileClick(contact.id)}
              >
                <Avatar size="small" url={contact.avatar} />
                <FlexCentered>
                  <div>
                    <Typography variant="h3">
                      {contact.first_name} {contact.last_name}
                    </Typography>
                    <Typography variant="body2">
                      {contact.mutual_contacts_count} wspólnych kontaktów
                    </Typography>
                  </div>
                  <div>
                    {!recentlyAdded.includes(contact.id) && (
                      <IconWrapper
                        onClick={(event) => {
                          event.stopPropagation();
                          handleAddContact(contact);
                        }}
                      >
                        <ContactAddIcon />
                      </IconWrapper>
                    )}
                  </div>
                </FlexCentered>
              </Person>
            ))}
          <Box px={3} mt={2}>
            <Button
              onClick={goToMyContactsPage}
              label="Zobacz więcej"
              variant="secondary"
            />
          </Box>
        </Box>
      </Card>
    </Root>
  );
};
