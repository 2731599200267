import "react-image-lightbox/style.css";

import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ theme }) => `
        width: 100%;
        display: grid;
        grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
        column-gap: ${theme.space[4]}px;
        row-gap: ${theme.space[4]}px;
        margin-bottom: ${theme.space[6]}px;
    `}
`;

export interface IGallery {
  items: Array<string>;
}

const Cell = styled.div`
  &:first-child {
    grid-area: 1 / 1 / 4 / 4;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  &:first-child {
    border-radius: 4px;
  }
`;

export const Gallery: React.FC<IGallery> = ({ items }) => {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const toggleFullscreen = (index?: number) => {
    if (isFullscreen) setPhotoIndex(0);
    if (index) setPhotoIndex(index);
    setFullscreen((prevState) => !prevState);
  };

  const nextPhoto = () => {
    setPhotoIndex((prevState) => (prevState + 1) % items.length);
  };

  const prevPhoto = () => {
    setPhotoIndex((prevState) => (prevState + items.length - 1) % items.length);
  };

  return (
    <Wrapper>
      {items.map((photo, index) => (
        <Cell key={photo}>
          <Photo
            src={photo}
            key={photo}
            onClick={() => toggleFullscreen(index)}
          />
        </Cell>
      ))}
      {isFullscreen && (
        <Lightbox
          mainSrc={items[photoIndex]}
          nextSrc={items[(photoIndex + 1) % items.length]}
          prevSrc={items[(photoIndex + items.length - 1) % items.length]}
          onCloseRequest={() => toggleFullscreen()}
          onMoveNextRequest={nextPhoto}
          onMovePrevRequest={prevPhoto}
        />
      )}
    </Wrapper>
  );
};
