import { createConsumer } from "channels/consumer";
import { useEffect, useState } from "react";
import { IPagination } from "types/pagination";

export interface IConversation {
  id: number;
  seen: boolean;
  users: Array<IUser>;
}
interface IUser {
  id: number;
  profile: IProfile;
}
interface IProfile {
  first_name: string;
  last_name: string;
}
interface IMessage {
  id: number;
  conversation_id: number;
  content: string;
  created_at: string;
  sender: IUser;
  type: "to" | "from";
}
interface IResponseValue {
  method: "pull_conversations" | "pull_messages" | "send_message";
  data: IMessage[] | IConversation[] | IMessage;
  pagination: IPagination;
  from_negotiation: boolean;
}

export const useChat = () => {
  const [cable, setCable] = useState<any>(null);
  const [messages, setMessages] = useState<Array<IMessage>>([]);
  const [recentlyReceivedWsData, setRecentlyReceivedWsData] =
    useState<IResponseValue | null>(null);

  const parseMessageData = (data: IMessage[], currentUser: IUser): IMessage[] =>
    data.map((msg: IMessage) => ({
      id: msg.id,
      conversation_id: msg.conversation_id,
      content: msg.content,
      sender: {
        id: msg.sender.id,
        profile: {
          first_name: msg.sender.profile.first_name,
          last_name: msg.sender.profile.last_name,
        },
      },
      created_at: msg.created_at,
      type: msg.sender.id === currentUser.id ? "to" : "from",
      seller: false,
    }));

  useEffect(() => {
    const consumer = createConsumer();
    const cab = consumer.subscriptions.create("ChatChannel", {
      received(response) {
        try {
          const data: IResponseValue = JSON.parse(response);
          setRecentlyReceivedWsData(data);
        } catch (err) {
          console.error(err);
        }
      },
    });
    setCable(cab);
  }, []);

  return {
    cable,
    recentlyReceivedWsData,
    messages,
    setMessages,
    parseMessageData,
  };
};
