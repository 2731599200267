import { Typography } from "components";
import { Box } from "components/Box";
import { Menu } from "components/Menu";
import { Router } from "components/Router";
import { TextField } from "components/TextField";
import { useSearchContext } from "contexts/SearchContactContext";
import { useMenu } from "hooks/menu";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import React from "react";
import { Col, Row } from "react-grid-system";
import { IRoute } from "types/route";

import { InviteUserPanel } from "./InviteUserPanel";

export const Contacts: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  const { query, setQuery } = useSearchContext();
  const menuItems = useMenu([
    {
      label: "Moje kontakty",
      path: `/contacts/my`,
    },
    {
      label: "Osoby, które możesz znać",
      path: `/contacts/might-know`,
    },
    {
      label: "Chcą wejść na wyższy poziom inwestycji",
      path: `/contacts/want-to-highten-investitions-level`,
    },
    {
      label: "Chcą skalować swoją działalność",
      path: `/contacts/want-to-scale`,
    },
    {
      label: "Zaproszenia do kontaktu",
      path: `/contacts/invitations`,
    },
  ]);

  const handleSearchContactChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Row justify="between">
        <Col>
          <Typography variant="h1" color="primary" mb={3}>
            Kontakty
          </Typography>
        </Col>
        <Col sm={4}>
          <Box mb={4}>
            <TextField
              placeholder="Szukaj kontaktu..."
              icon={<SearchIcon />}
              onChange={handleSearchContactChange}
              value={query}
              transparent
            />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Menu items={menuItems} />
          <Box my={6}>
            <InviteUserPanel />
          </Box>
        </Col>
        <Col sm={8}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
