import { IContactInvitations } from "components/ContactInvitations/ContactInvitations";
import { IUsersListItem } from "components/UsersList/UsersList";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchContactIvitiations,
  IFetchContactInvitationsResponse,
  updateInvitation,
} from "services/contacts";
import { IPagination } from "types/pagination";

const parseContactInvitations = (
  contacts: IFetchContactInvitationsResponse["data"]
): IContactInvitations["contacts"] =>
  contacts.map(({ sender, id }) => ({
    id,
    user: {
      id: sender.id,
      firstName: sender.first_name,
      lastName: sender.last_name,
      avatar: sender.avatar, 
      description: `${sender.mutual_contacts_count} wspólnych kontaktów`,
    },
  }));

export const useContactInvitations = () => {
  const [contacts, setContacts] = useState<Array<IUsersListItem>>([]);
  const [pagination, setPagination] = useState<IPagination>({
    current: 0,
    previous: null,
    next: null,
    per_page: 0,
    pages: 0,
    count: 0,
  });

  const retrieveContacts = async ({ page }: { page: number }) => {
    try {
      const response = await fetchContactIvitiations({ page });
      setContacts(parseContactInvitations(response.data));

      setPagination(response.pagination);
    } catch (error) {
      toast.error("Nie udało się pobrać kontaktów.");
    }
  };

  const handleAcceptInvitationClick = async (item: IUsersListItem) => {
    if (!item.user.id) return;

    try {
      await updateInvitation({
        id: item.id,
        recipientId: item.user.id,
        state: "accepted",
      });
      setContacts((prevState) =>
        prevState.filter(({ id }) => {
          return item.id !== id;
        })
      );
      toast.success(
        `Zaakceptowałeś zaproszenie do kontaktów od użytkownika ${item.user.firstName} ${item.user.lastName}.`
      );
    } catch (error) {
      toast.error("Nie udało się zaakceptować zaproszenia do kontaktów.");
    }
  };

  const handleIgnoreInvitationClick = async (item: IUsersListItem) => {
    if (!item.user.id) return;

    try {
      await updateInvitation({
        id: item.id,
        recipientId: item.user.id,
        state: "rejected",
      });
      setContacts((prevState) =>
        prevState.filter(({ id }) => {
          return item.id !== id;
        })
      );
      toast.info(
        `Odrzuciłeś zaproszenie do kontaktów od użytkownika ${item.user.firstName} ${item.user.lastName}.`
      );
    } catch (error) {
      toast.error("Nie udało się odrzucić zaproszenia do kontaktów.");
    }
  };

  return {
    contacts,
    pagination,
    retrieveContacts,
    handleAcceptInvitationClick,
    handleIgnoreInvitationClick,
  };
};
