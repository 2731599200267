import {
  ActionType,
  GET_NEGOTIATIONS,
  IFetchNegotiationsResponse,
} from "./types";

const initialState = {
  /* eslint-disable camelcase */
  pagination: {
    current: 0,
    previous: null,
    next: null,
    per_page: 0,
    pages: 0,
    count: 0,
  },
  data: [],
};

export const negotiations = (
  state: IFetchNegotiationsResponse = initialState,
  { type, payload }: ActionType
) => {
  switch (type) {
    case GET_NEGOTIATIONS:
      return payload;
    default:
      return state;
  }
};
