import { ContactInvitations } from "components/ContactInvitations";
import { Flex } from "components/Flex";
import { Pagination } from "components/Pagination";
import { useContactInvitations } from "hooks/contactInvitations";
import { usePagination } from "hooks/pagination";
import React, { useEffect, useRef } from "react";

export const ContactsInvitationsView = () => {
  const {
    contacts,
    pagination,
    retrieveContacts,
    handleAcceptInvitationClick,
    handleIgnoreInvitationClick,
  } = useContactInvitations();

  const usersListRef = useRef<HTMLDivElement>(null);

  const { handleChangePage } = usePagination({
    paginatedElRef: usersListRef,
    updateFn: (pageNumber) => {
      retrieveContacts({
        page: pageNumber,
      });
    },
  });

  return (
    <div ref={usersListRef}>
      <ContactInvitations
        contacts={contacts}
        onAcceptUserClick={handleAcceptInvitationClick}
        onIgnoreUserClick={handleIgnoreInvitationClick}
      />
      <Flex justifyContent="flex-end" mt={6}>
        <Pagination
          current={pagination.current}
          pages={pagination.pages}
          onPageClick={handleChangePage}
        />
      </Flex>
    </div>
  );
};
