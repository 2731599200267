import { Button, Typography } from "components";
import { Box } from "components/Box";
import { realEstateExperienceInMonthsFields } from "constants/profileFormFields";
import { cities } from "database/cities";
import { investmentType } from "database/investmentType";
import React, { useState } from "react";

import { List } from "../../../components/List";
import { useProfile } from "../../../hooks/profile";
import { ButtonContainer } from "../ProfileInfo";
import { ExperienceAndSpecializationForm } from "./ExperienceAndSpecializationForm";

interface IOption {
  value: string;
  label: string;
}

export const ExperienceAndSpecializationView: React.FC = () => {
  const [variant, setVariant] = useState(0);
  const { user, activityCities, specializations, investmentsLevels } =
    useProfile();

  const translateValue = (baseObject: Array<IOption>, data: Array<string>) =>
    baseObject
      .filter((option: IOption) => data.includes(option.value))
      .map((opt: IOption) => opt.label);

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2" color="primary">
          Doświadczenie i specjalizacja
        </Typography>
      </Box>
      {variant === 0 && (
        <>
          <Typography variant="body2" color="secondary">
            Jak długo zajmujesz się nieruchomościami
          </Typography>
          {user &&
            realEstateExperienceInMonthsFields.map((el) => {
              if (
                Number(el.value) ===
                user?.data?.profile?.real_estate_experience_in_months
              ) {
                return (
                  <Typography
                    key={el.value}
                    variant="h3"
                    mb={4}
                    fontWeight={400}
                  >
                    {el.label}
                  </Typography>
                );
              }
              return <div key={el.value} />;
            })}

          <Typography variant="body2" color="secondary">
            Miasta, w których działasz na rynku nieruchomości:
          </Typography>
          <Typography variant="body" mb={4}>
            <List data={translateValue(cities, activityCities)} />
          </Typography>

          <Typography variant="body2" color="secondary">
            W jakiego rodzaju inwestycjach się specjalizujesz?
          </Typography>
          <Typography variant="body" mb={4}>
            <List data={translateValue(investmentType, specializations)} />
          </Typography>

          <Typography variant="body2" color="secondary">
            Na jakim poziomie inwestycji działasz?
          </Typography>
          <Typography variant="body" mb={6}>
            <List data={investmentsLevels} />
          </Typography>

          <ButtonContainer>
            <Button
              label="Edytuj"
              onClick={() => {
                setVariant(1);
              }}
            />
          </ButtonContainer>
        </>
      )}
      {variant === 1 && user && (
        <ExperienceAndSpecializationForm
          currentUser={user.data}
          onCloseForm={setVariant}
        />
      )}
    </>
  );
};
