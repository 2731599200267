import { LOGIN_URL, REGISTER_CONFIRMATION_URL, REGISTER_URL } from "constants/paths";
import { IUser } from "types/user";
import { http } from "utilities/http";

interface ILoginUser {
  email: string;
  password: string;
}

export interface ILoginUserResponse {
  data: IUser;
  token: string;
}

export const loginUser = async (user: ILoginUser) => {
  const userData = await http<ILoginUserResponse>(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });
  return userData;
};

interface IRegisterUser {
  email: string;
  password: string;
  invited_by_id?: string;
}

export interface IRegisterUserResponse {
  data: IUser;
  token: string;
}

export const registerUser = async (user: IRegisterUser) => {
  const invited_by_id = user.invited_by_id
    ? `?invited_by_id=${user.invited_by_id}`
    : "";
  const userData = await http<IRegisterUserResponse>(
    REGISTER_URL + invited_by_id,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ user }),
    }
  );

  return userData;
};

export const confirmRegistration = async (token: string) => {
  const response = await http(
    `${REGISTER_CONFIRMATION_URL}/?confirmation_token=${token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
  return response
};
