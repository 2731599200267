import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { confirmRegistration } from "../../services/auth";

export const RegisterConfirmation = () => {
  const { token } = useParams<{token: string}>();
  const [error, setError] = useState(false)
  const history = useHistory();

  useEffect(() => {
    confirmRegistration(token)
      .then(response => {
        history.push("/login")
        // history.push("/register/profile")
      })
      .catch(err => {
        const errorMessage = JSON.parse(err)
        if (errorMessage && errorMessage.message && errorMessage.message === 'User confirmed') history.push("/login")
      })
  }, [token])

  return <>
    {error && <h2>Wystąpił błąd</h2>}
  </>
};
