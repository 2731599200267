import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

interface IChangePriceModal {
  isOpen: boolean;
  onCancelClick: () => void;
  handleChangePrice: (newPrice: number) => void;
  oldPrice: number;
}

const StyledBox = styled(Box)`
  max-width: 550px;
`;
const WrapperTextField = styled(Box)`
  width: 100%;
`;
const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const ChagnePriceModal: React.FC<IChangePriceModal> = ({
  isOpen,
  onCancelClick,
  handleChangePrice,
  oldPrice,
}) => {
  const TextFieldRef = React.useRef<HTMLInputElement>(null);

  const getNewPriceValue = () => {
    if (TextFieldRef && TextFieldRef.current) {
      handleChangePrice(parseInt(TextFieldRef.current.value, 10));
    }
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <StyledBox p={8}>
        <Typography variant="h1" color="primary" mb={3}>
          Zmień cenę oferty
        </Typography>
        <StyledTypography variant="body2" mb={3}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi massa
          ipsum, volutpat sed orci sit amet, sagittis eleifend nibh.
        </StyledTypography>
        <Flex mb={8}>
          <WrapperTextField mr={8}>
            <TextField
              value={oldPrice}
              unit="PLN"
              label="Stara cena"
              disabled
            />
          </WrapperTextField>
          <TextField
            ref={TextFieldRef}
            type="number"
            unit="PLN"
            label="Nowa cena"
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <Box mr={6}>
            <Button
              label="Anuluj"
              variant="secondary"
              onClick={onCancelClick}
            />
          </Box>
          <Button label="Zmień cenę" onClick={getNewPriceValue} />
        </Flex>
      </StyledBox>
    </Modal>
  );
};
