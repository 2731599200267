import { UsersList } from "components/UsersList";
import { IUsersList, IUsersListItem } from "components/UsersList/UsersList";
import { ReactComponent as ContactAddIcon } from "icons/contact-add.svg";
import React from "react";
import styled from "styled-components";

export interface IRecommendedContacts {
  contacts: IUsersList["items"];
  onAddUserClick: (contact: IUsersListItem) => any;
}

const AddUserButton = styled(ContactAddIcon)`
  cursor: pointer;
`;

export const RecommendedContacts: React.FC<IRecommendedContacts> = ({
  contacts,
  onAddUserClick,
}) => {
  const renderButtons: IUsersList["extraRender"] = (contact) => {
    return <AddUserButton onClick={() => onAddUserClick(contact)} />;
  };

  return <UsersList items={contacts} extraRender={renderButtons} />;
};
