import { PREFERENCES_URL } from "constants/paths";
import { IUser, IUserPreference } from "types/user";
import { fetchAPI } from "utilities/fetchAPI";

export interface IUpdatePreferencesResponse {
  data: IUser;
}

export const updatePreferences = async (data: Partial<IUserPreference>) => {
  const userData = await fetchAPI<IUpdatePreferencesResponse>(PREFERENCES_URL, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      preference: data,
    }),
  });

  return userData;
};
