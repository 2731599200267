import { ErrorHelperText } from "components/ErrorHelperText";
import { FieldLabel as Label } from "components/FieldLabel";
import { ReactComponent as CheckmarkIcon } from "icons/checkmark.svg";
import { ReactComponent as DownIcon } from "icons/down.svg";
import { ReactComponent as UpIcon } from "icons/up.svg";
import React, {
  RefObject,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

export interface ISelect extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  multi?: boolean;
  selected?: Array<string> | string;
  options: Array<IOption>;
  onSelected?: (data: Array<string> | string) => any;
  search?: boolean;
  transparent?: boolean;
}

interface IInputContainer {
  error?: ISelect["error"];
  expanded: boolean;
  transparent?: boolean;
}

interface ICheckbox {
  checked: boolean;
}

export interface IOption {
  value: string;
  label: string;
}

interface IWrapper {
  disabled?: ISelect["disabled"];
}
interface IOptionsWrapper {
  search?: boolean;
}
type IExpanded = boolean;

const Wrapper = styled.div<IWrapper>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  width: 100%;
  position: relative;
`;

const InputContainer = styled.div<IInputContainer>`
  ${({ theme, error, expanded, transparent }) => `
    height: 40px;

    color: ${theme.palette.text.primary};
    border: 1px solid
      ${error ? theme.palette.accent.red : theme.palette.neutral.medium};
    ${
      expanded
        ? `
          border-color: ${theme.palette.primary.main};
          `
        : ""
    }
    border-radius: 4px;

    ${
      expanded
        ? `
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
      `
        : ""
    }
    outline: none;
    background-color: ${
      transparent ? "transparent" : "theme.palette.neutral.white"
    };
    padding-left: ${theme.space[4]}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
  `}
`;

const StyledDownIcon = styled(DownIcon)`
  margin-left: auto;
`;

const StyledUpIcon = styled(UpIcon)`
  margin-left: auto;
`;

const OptionsWrapper = styled.div<IOptionsWrapper>`
  ${({ theme, search }) => `
    position: absolute;
    width: calc(100%);
    top: 100%;
    z-index: 1;
    max-height: 250px;
    overflow: auto;
    border: 1px solid ${theme.palette.primary.main};
    border-top: none;
    ${
      search &&
      `
      margin-top: 40px;
    `
    }
  `}
`;

const Option = styled.div`
  ${({ theme }) => `
    overflow: hidden;
    height: 36px;
    color: ${theme.palette.text.primary};
    border-top: none;
    border-bottom: none;
    outline: none;
    background-color: ${theme.palette.neutral.white};
    padding: 0 ${theme.space[4]}px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: ${theme.palette.primary.main};
    }
    &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    };
    `}
`;

const Checkbox = styled.div<ICheckbox>`
  ${({ theme, checked }) => `
  background-color: ${checked ? theme.palette.primary.main : "transparent"};
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.palette.neutral.medium};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  & svg {
    user-select: none;
    fill: ${theme.palette.neutral.white};
  }
`}
`;

const HiddenSelect = styled.select`
  display: none;
`;

const ClickDetector = styled.div``;

const SearchInput = styled.input`
  ${({ theme }) => `
  position: absolute;
  color: ${theme.palette.text.primary};
  border-radius: unset;
  width: 100%;
  padding: 8px 12px;
  height: 100%;
  outline: unset;
  border: 1px solid ${theme.palette.primary.main};
  border-top: unset;
  z-index: 1;
  `}
`;

export const Select = React.forwardRef<HTMLSelectElement, ISelect>(
  (
    {
      label,
      options,
      onSelected,
      error,
      name,
      disabled,
      multi,
      selected,
      search,
      transparent = false,
    },
    ref
  ) => {
    const [isExpanded, setExpanded] = useState<IExpanded>(false);
    const initialValue = selected ?? (multi ? [] : "");
    const [value, setValue] = useState<Array<string> | string>(initialValue);
    const [searchValue, setSearchValue] = useState<string>("");

    const initialRender = useRef(false);

    useEffect(() => {
      if (!initialRender.current) {
        initialRender.current = true;
      } else if (onSelected) {
        onSelected(value);
      }
    }, [value]);

    const clickDetectorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const wrapper = clickDetectorRef.current;
      const detectClick = (event: Event) => {
        if (wrapper && !wrapper.contains(event.target as Node)) {
          setExpanded(false);
          document.removeEventListener("mousedown", detectClick);
        }
      };

      if (isExpanded) {
        document.addEventListener("mousedown", detectClick);
      } else {
        document.removeEventListener("mousedown", detectClick);
      }
      return () => document.removeEventListener("mousedown", detectClick);
    }, [isExpanded]);

    const isItemSelected = (option: IOption) => {
      if (multi && Array.isArray(value)) {
        return value.filter((item) => item === option.value).length > 0;
      }

      if (!multi && typeof value === "string") {
        return value === option.value;
      }

      return false;
    };

    const handleExpand = () => {
      if (!disabled) setExpanded((prevState) => !prevState);
    };

    const handleSelect = (
      event: React.MouseEvent<HTMLDivElement>,
      option: IOption
    ) => {
      event.stopPropagation();
      if (multi) {
        if (isItemSelected(option)) {
          setValue((prevState) => {
            const typedPrevState = prevState as Array<string>;

            return [...typedPrevState.filter((item) => item !== option.value)];
          });
        } else {
          setValue((prevState) => [...prevState, option.value]);
        }
      } else {
        setValue(option.value);
        setExpanded(false);
      }
    };

    const displayValue = multi
      ? options
          .filter((option) => value.includes(option.value))
          .map(
            (item, index) =>
              item.label + (index === value.length - 1 ? "" : ", ")
          )
      : options.find((option) => value === option.value)?.label;

    return (
      <Wrapper disabled={disabled}>
        <HiddenSelect
          disabled={disabled}
          ref={ref}
          multiple={multi}
          name={name}
          value={value}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option value="" />
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </HiddenSelect>
        {label && <Label error={error}>{label}</Label>}
        <ClickDetector ref={clickDetectorRef}>
          <InputContainer
            expanded={isExpanded}
            onClick={handleExpand}
            error={error}
            transparent={transparent}
          >
            {value.length === 0 ? `wybierz...` : displayValue}
            {isExpanded ? <StyledUpIcon /> : <StyledDownIcon />}
          </InputContainer>
          {isExpanded && (
            <>
              {search ? (
                <SearchInput
                  placeholder="Szukaj..."
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              ) : null}
              <OptionsWrapper search={search}>
                {options
                  .filter((option) => {
                    return search
                      ? option.label
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      : option;
                  })
                  .map((option) => {
                    if (!multi) {
                      return (
                        <Option
                          onClick={(event) => handleSelect(event, option)}
                        >
                          {option.label}
                        </Option>
                      );
                    }
                    return (
                      <Option onClick={(event) => handleSelect(event, option)}>
                        <Checkbox checked={isItemSelected(option)}>
                          {isItemSelected(option) ? <CheckmarkIcon /> : null}
                        </Checkbox>
                        {option.label}
                      </Option>
                    );
                  })}
              </OptionsWrapper>
            </>
          )}
        </ClickDetector>
        {error && !isExpanded && <ErrorHelperText>{error}</ErrorHelperText>}
      </Wrapper>
    );
  }
);
