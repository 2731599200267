import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOtherUserProfile } from "services/otherProfile";
import { parseProfileToUserProfile } from "utilities/parseProfileToUserProfile";

import { getCurrentUserAction } from "../store/currentUser/actions";
import { IRootState } from "../store/currentUser/types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProfile = (getUser = false) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IRootState) => state.currentUser);

  if (getUser) {
    useEffect(() => {
      dispatch(getCurrentUserAction());
    }, []);
  }

  const userProfile = {
    data: currentUser,
  } as IOtherUserProfile;

  return parseProfileToUserProfile(userProfile);
};
