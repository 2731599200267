import React, { useContext, useState } from "react";
import { IOtherUserProfile } from "services/otherProfile";

export interface IProfile {
  userProfile: IOtherUserProfile | null;
  setUserProfile: (user: IOtherUserProfile) => void;
}

const ProfileContext = React.createContext<IProfile | undefined>(undefined);

export const ProfileContextProvider: React.FC = ({ children }) => {
  const [userProfile, setUserProfile] = useState<IOtherUserProfile | null>(
    null
  );
  return (
    <ProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
export const useUserProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error(
      "useSearchContext must be inside a ProfileContextProvider with a value"
    );
  }
  return context;
};
