import { ErrorHelperText } from "components/ErrorHelperText";
import { FieldLabel as Label } from "components/FieldLabel";
import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface ITextareaField
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  unit?: string;
  icon?: React.ReactNode;
}

interface StyledTextareaI {
  error?: ITextareaField["error"];
  unit?: ITextareaField["unit"];
  icon?: ITextareaField["icon"];
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const InputWrapper = styled.div`
  position: relative;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledTextarea = styled.textarea<StyledTextareaI>`
  ${({ theme, error, unit, icon }) => `
    display: block;
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ""};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ""};
    font-size: 14px;
    font-family: inherit;
    color: ${theme.palette.text.primary};
    border: 1px solid ${
      error ? theme.palette.accent.red : theme.palette.neutral.medium
    };
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

export const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaField
>(({ label, error, unit, icon, disabled, ...props }, ref) => (
  <div>
    {label ? (
      <Label error={error} disabled={disabled}>
        {label}
      </Label>
    ) : null}
    <InputWrapper>
      <StyledTextarea
        ref={ref}
        error={error}
        unit={unit}
        icon={icon}
        disabled={disabled}
        {...props}
      />
      {unit && <Unit>{unit}</Unit>}
      {icon && <Icon>{icon}</Icon>}
    </InputWrapper>
    {error && <ErrorHelperText>{error}</ErrorHelperText>}
  </div>
));
