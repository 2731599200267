import { PASSWORD_RESET } from "constants/paths";
import { http } from "utilities/http";

interface IPasswordReset {
  token: string;
  password: string;
  passwordConfirmation: string;
}
interface ISendEmail {
  email: string;
}
export const sendEmail = async ({ email }: ISendEmail) => {
  const emailData = await http(PASSWORD_RESET, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user: { email } }),
  });
  return emailData;
};

export const passwordReset = async ({
  token,
  password,
  passwordConfirmation,
}: IPasswordReset) => {
  const passwordData = await http(PASSWORD_RESET, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      user: {
        reset_password_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
  });
  return passwordData;
};
