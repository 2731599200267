import {
  fetchAdvertisements,
  IAddAdvertisementResponse,
} from "services/advertisements";
import { AppThunk } from "store";
import { AdvertisementFilterType } from "types/advertisement";

import { ADD_ADVERTISEMENT, GET_ADVERTISEMENTS } from "./types";

interface IGetAdvertisements {
  finished_status?: boolean;
  my_advertisements?: boolean;
  my_negotiations?: boolean;
  filter?: AdvertisementFilterType;
  page?: number;
}

export const getAdvertisements =
  (args: IGetAdvertisements): AppThunk =>
  async (dispatch) => {
    const response = await fetchAdvertisements(args);

    dispatch({
      type: GET_ADVERTISEMENTS,
      payload: response,
    });
  };

export const addAdvertisement =
  (args: IAddAdvertisementResponse): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: ADD_ADVERTISEMENT,
      payload: args,
    });
  };
