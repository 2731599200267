import "moment/locale/pl";

import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { IGoToUserInfo } from "utilities/goToUserInfoHelper";
import { rgba } from "utilities/rgba";

interface IUser {
  avatar: string;
  id: number;
  profile: IProfile;
}

interface IProfile {
  first_name: string;
  last_name: string;
}

export interface IChatMessage extends IGoToUserInfo {
  content: string;
  created_at: string;
  sender: IUser;
  seller?: boolean;
  type: "to" | "from";
}

interface IWrapperChatMessage {
  type: IChatMessage["type"];
}

interface IFieldMessage {
  type: IChatMessage["type"];
}

const WrapperChatMessage = styled.div<IWrapperChatMessage>`
  ${({ theme: { space }, type }) => `
    display: flex;
    margin-bottom: ${space[2]}px;
    ${
      type === "from"
        ? `
        padding-right: ${space[12]}px;
      `
        : ""
    };
    
    ${
      type === "to"
        ? `
        flex-direction: row-reverse;
        padding-left: ${space[12]}px;
      `
        : ""
    };

  `}
`;
const FieldMessage = styled.div<IFieldMessage>`
  ${({ theme, type }) => `
    position: relative;
    width: calc(100% - 80px);
    border-radius: 8px;
    background-color: ${theme.palette.neutral.veryLight};
    margin-left: ${theme.space[2]}px;
    margin-right: 0;
    padding: ${theme.space[2]}px ${theme.space[6]}px ${theme.space[3]}px;

    &::before {
      content: '';
      position: absolute;
      top: 25px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 3px 3px 0;
      border-color: transparent ${
        theme.palette.neutral.veryLight
      } transparent transparent;
    }

    ${
      type === "to"
        ? `
        margin-left: 0;
        margin-right: ${theme.space[2]}px;

        &::before {
          left: auto;
          right: -3px;
          border-width: 3px 0 3px 3px;
          border-color: transparent transparent transparent ${theme.palette.neutral.veryLight};
        }
      `
        : ""
    }
	`}
`;

const Date = styled(Typography).attrs({
  variant: "body2",
})`
  ${({ theme }) => `
    color: ${rgba(theme.palette.text.primary, 0.7)}
  `}
`;

interface IStyledTypography {
  setPointer?: boolean;
}

const StyledTypography = styled(Typography)<IStyledTypography>`
  ${({ theme, setPointer }) => `
    ${setPointer ? `cursor: pointer;` : `cursor: auto;`}
  `}
`;

export const ChatMessage: React.FC<IChatMessage> = ({
  type,
  sender,
  seller,
  created_at,
  content,
  goToUserInfo,
}) => (
  <WrapperChatMessage type={type}>
    <Box mt={2}>
      <Avatar size="small" url={sender.avatar} />
    </Box>
    <FieldMessage type={type}>
      <StyledTypography
        variant="h3"
        mb={1}
        setPointer={!!sender.id}
        onClick={() => goToUserInfo && goToUserInfo(sender.id)}
      >
        {sender.profile.first_name} {sender.profile.last_name}
        {seller && <span> (Wystawiający ofertę)</span>}
      </StyledTypography>
      <Date variant="body2">
        <Moment locale="pl" fromNow>
          {created_at}
        </Moment>
      </Date>
      <Typography variant="body">{content}</Typography>
    </FieldMessage>
  </WrapperChatMessage>
);
