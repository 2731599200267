import { Box } from "components/Box";
import { Card } from "components/Card";
import qs from "qs";
import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ISearchParams } from "services/search";
import styled from "styled-components";
import { getFilterVariant } from "utilities/filterAdvertisement.config";
import { cleanFilters } from "views/Search";

import { Button } from "../Button";
import { Menu } from "../Menu";
import { Select } from "../Select";
import { TextField } from "../TextField";

const Root = styled.div`
  width: 100%;
`;

const MenuCol = styled(Col)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.veryLight};
  `};
`;
const StyledCard = styled(Card)`
  overflow: visible;
`;

const FormRow = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-end;
    margin: 0 -${theme.space[1]}px;
  `}
`;

const FormCol = styled.div`
  ${({ theme }) => `
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding: 0 ${theme.space[1]}px;
  `}
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const SearchAdvertisement: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, reset, control } = useForm<ISearchParams>();
  const [filters, setFilters] = useState<ISearchParams>({
    selected_category: "",
  });

  const handleSetCategory = (category: ISearchParams["selected_category"]) => {
    reset();
    setFilters(() => {
      return {
        selected_category: category,
      };
    });
  };

  const menuItems = [
    { label: "Wszystkie ogłoszenia", category: "" },
    { label: "Mieszkanie/mieszkania", category: "apartment" },
    { label: "Kamienica", category: "tenement_house" },
    { label: "Lokal usługowy", category: "service_premises" },
    { label: "Magazyny i hale", category: "warehouse" },
    { label: "Działka pod inwestycję", category: "plot" },
  ].map(({ label, category }) => ({
    label,
    onClick: () => handleSetCategory(category),
    current: filters?.selected_category === category,
  }));

  const onSubmit = (newFilters: ISearchParams) => {
    const allFilters = {
      ...newFilters,
      selected_category: filters.selected_category,
    };
    history.push(`search?${qs.stringify(cleanFilters(allFilters))}`);
  };

  return (
    <Root>
      <StyledCard>
        <Row>
          <MenuCol lg={3.9} md={12}>
            <Box pb={1}>
              <Menu
                title="Szukaj ogłoszeń"
                card={false}
                colorHighlight={false}
                items={menuItems}
              />
            </Box>
          </MenuCol>
          <Col>
            <Box py={5} pr={9} pl={5}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {getFilterVariant(filters?.selected_category).map((filter) => (
                  <Box mb={2}>
                    <FormRow>
                      {filter.map((field) =>
                        field.type === "text" ? (
                          <FormCol key={field.label}>
                            <TextField
                              name={field.name}
                              label={field.label}
                              ref={register}
                            />
                          </FormCol>
                        ) : (
                          field.options && (
                            <FormCol>
                              <Controller
                                control={control}
                                name={field.name}
                                render={({ value, onChange }) => {
                                  return (
                                    <Select
                                      label={field.label}
                                      options={field.options!}
                                      search
                                      name={field.name}
                                      selected={value}
                                      onSelected={(val) => {
                                        if (val) {
                                          onChange(val);
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />
                            </FormCol>
                          )
                        )
                      )}
                    </FormRow>
                  </Box>
                ))}

                <FlexBox mt={4}>
                  <Button
                    label="Pokaż ogłoszenia"
                    variant="primary"
                    type="submit"
                  />
                </FlexBox>
              </form>
            </Box>
          </Col>
        </Row>
      </StyledCard>
    </Root>
  );
};
