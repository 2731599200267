import { IAdvertisement } from "types/advertisement";

export const GET_ADVERTISEMENTS = "GET_ADVERTISEMENTS";
export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT";

export interface IAdvertisementsState {
  pagination: {
    /* eslint-disable camelcase */
    current: number;
    previous: null;
    next: null;
    per_page: number;
    pages: number;
    count: number;
    /* eslint-enable camelcase */
  };
  data: Array<IAdvertisement>;
}
export interface IAdvertisementState {
  data: IAdvertisement;
}

interface GetAdvertisementsActionType {
  type: typeof GET_ADVERTISEMENTS;
  payload: IAdvertisementsState;
}
interface AddAdvertisementActionType {
  type: typeof ADD_ADVERTISEMENT;
  payload: IAdvertisementState;
}

export type AdvertisementsActionTypes = GetAdvertisementsActionType | AddAdvertisementActionType;
