// eslint-disable-next-line simple-import-sort/sort
import { Dropdown } from "components/Dropdown";
import { NotificationsDropdown } from "components/NotificationsDropdown";
import { ReactComponent as NotificationIcon } from "icons/notification.svg";
import { ReactComponent as MenuIcon } from "icons/menu.svg";
import React, { useState } from "react";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { INotification } from "types/notifications";
import { Box } from "components/Box";
import { useWindowWidth } from "hooks/useWindowWidth";
import { setNotificationsAsSeen } from "services/notifications";
import logoImg from "../../logoI4U.png";
import { Avatar } from "../Avatar";
import { Button } from "../Button";

export interface INavbar {
  avatarUrl: string | null;
  logged: boolean;
  links: Array<{
    to: string;
    match?: boolean;
    label: string;
  }>;
  profileDropdownItems: Array<{
    label: string;
    onClick: () => void;
  }>;
  notifications: INotification[];
  notification?: boolean;
  newNotifications?: INotification[];
  onAddAdvertisementClick: () => void;
  onLogoClick: () => void;
  goToNotificationsPage: () => void;
  moveToLogin: () => void;
  notificationRedirect: (path: string) => void;
  isEmailConfirmed: boolean;
  confirmEmailInfo: () => void;
  getNewNotifications: () => void;
}

interface IList {
  logged: INavbar["logged"];
}

interface IListElement {
  active?: boolean;
  label: string;
  notification?: boolean;
}

const StyledNavbar = styled.div`
  ${({ theme }) => `
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    display: block;
    background-color: ${theme.palette.neutral.white};
    width: 100%;
    min-height: 72px;
    box-shadow: 0 8px 24px -8px ${theme.palette.neutral.medium};
    padding: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
  `}
`;

const StyledWrapper = styled(Container)<{ isVisible?: boolean }>`
  ${({ isVisible = true }) => `
    display: ${isVisible ? "flex" : "none"};
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  `}
`;

const StyledContainer = styled(Container)<{ isVisible?: boolean }>`
  ${({ isVisible = true }) => `
    display: ${isVisible ? "flex" : "none"};
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    @media only screen and (min-width: 1000px) {
      display: flex;
    }
  `}
`;

const Logo = styled.div`
  cursor: pointer;
  min-width: 180px;
  height: 32px;
  background-image: url(${logoImg});
`;

const List = styled.ul<IList>`
  ${({ theme, logged }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0 ${theme.space[6]}px;
    padding: 0;
    ${
      logged &&
      `
        flex: auto;
      `
    }

    @media only screen and (max-width: 1000px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  `}

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const WrapperMainSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    justify-content: center;
    width: 100%;
  }
`;

const WrapperNotifivationIcon = styled.div`
  ${({ theme }) => `
    margin: 0 ${theme.space[4]}px 0 ${theme.space[2]}px;
    cursor: pointer;
  `}
`;

const ListElement = styled.li<IListElement>`
  ${({ theme, active, label, notification }) => `
    text-align: center;
    position: relative;
    color: ${theme.palette.text.primary};
    cursor: pointer;
    min-width: 110px;
    &:last-child {
      margin-right: 0;
    }
    ${active && `color: ${theme.palette.primary.main}`};
      ${
        label === "Rozmowy" && notification
          ? `
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #F55963;
          }
        `
          : null
      }
    }

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
    display: inline-block;
    padding: ${theme.space[2]}px 0;
    margin-right: ${theme.space[4]}px;
    color: inherit;
    text-decoration: none;
  `}
`;

const MenuButtonWrapper = styled(Box)`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const newNotificationsStyle = {
  boxShadow: "rgb(241 8 25) 16px -45px 12px -35px inset",
  MozBoxShadow: "rgb(241 8 25) 16px -45px 12px -35px inset",
  WebkitBoxShadow: "rgb(241 8 25) 16px -45px 12px -35px inset",
  borderRadius: "100px",
  MozBorderRadius: "100px",
  WebkitBorderRadius: "100px",
};

export const Navbar: React.FC<INavbar> = ({
  logged,
  links,
  profileDropdownItems,
  notifications,
  notification,
  onAddAdvertisementClick,
  onLogoClick,
  goToNotificationsPage,
  moveToLogin,
  notificationRedirect,
  newNotifications,
  getNewNotifications,
  avatarUrl,
  isEmailConfirmed,
  confirmEmailInfo,
}) => {
  const [expanded, setExpanded] = useState(false);

  const windowWidth = useWindowWidth();

  const handleMenuButtonClick = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <StyledNavbar>
      <StyledWrapper>
        <StyledContainer>
          <Logo onClick={onLogoClick} />
          <MenuButtonWrapper ml={2}>
            <Button icon={<MenuIcon />} onClick={handleMenuButtonClick} />
          </MenuButtonWrapper>
        </StyledContainer>
        <StyledContainer isVisible={expanded}>
          <List logged={logged}>
            {links.map(({ to, match, label }) => (
              <ListElement
                key={to}
                active={match}
                label={label}
                notification={notification}
                onClick={handleMenuButtonClick}
              >
                <StyledLink to={to}>{label}</StyledLink>
              </ListElement>
            ))}
          </List>
          <WrapperMainSet>
            {logged ? (
              <>
                <Button
                  label="Dodaj ogłoszenie"
                  isDisabled={!isEmailConfirmed}
                  onClick={
                    isEmailConfirmed
                      ? onAddAdvertisementClick
                      : confirmEmailInfo
                  }
                />

                {windowWidth > 1000 ? (
                  <NotificationsDropdown
                    goToNotificationsPage={goToNotificationsPage}
                    notifications={notifications}
                    notificationRedirect={(path) => {
                      notificationRedirect(path);
                    }}
                  >
                    <WrapperNotifivationIcon
                      onClick={() => {
                        setNotificationsAsSeen();
                        getNewNotifications();
                      }}
                    >
                      <NotificationIcon
                        style={
                          newNotifications.length > 0
                            ? newNotificationsStyle
                            : {}
                        }
                      />
                    </WrapperNotifivationIcon>
                  </NotificationsDropdown>
                ) : (
                  <WrapperNotifivationIcon>
                    <NotificationIcon
                      onClick={() => {
                        goToNotificationsPage();
                        handleMenuButtonClick();
                      }}
                    />
                  </WrapperNotifivationIcon>
                )}

                <Dropdown
                  handleMenuButtonClick={handleMenuButtonClick}
                  items={profileDropdownItems}
                >
                  <Avatar size="small" url={avatarUrl || undefined} />
                </Dropdown>
              </>
            ) : (
              <Button
                variant="primary"
                label="Logowanie/rejestracja"
                onClick={() => {
                  moveToLogin();
                  handleMenuButtonClick();
                }}
              />
            )}
          </WrapperMainSet>
        </StyledContainer>
      </StyledWrapper>
    </StyledNavbar>
  );
};
