import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

interface IComplateDataModal {
  isOpen: boolean;
  onCancelClick: () => void;
  redirectToFirData: () => void;
}

const StyledBox = styled(Box)`
  width: 550px;

  @media only screen and (max-width: 1000px) {
    width: 300px;
  }
`;

export const CompleteDataModal: React.FC<IComplateDataModal> = ({
  redirectToFirData,
  onCancelClick,
  isOpen,
}) => {
  //  <a>Kliknij tutaj aby uzupełnić dane do firmy </a>
  return (
    <Modal onCancelClick={onCancelClick} isOpen={isOpen}>
      <StyledBox p={8}>
        <Typography variant="h1" color="primary" mb={3}>
          Brak danych o firmie
        </Typography>
        <Typography variant="body" mb={4}>
          Aby dodać ogłoszenie potrzebujesz wprowadzić dane firmy. Wykorzystamy
          je do wystawienia faktury za prowizję w przypadku kiedy uda się
          znaleźć inwestora.
        </Typography>
        <ButtonsGroup>
          <Button label="Uzupełnij dane" onClick={redirectToFirData} />
          <Button label="Anuluj" variant="secondary" onClick={onCancelClick} />
        </ButtonsGroup>
      </StyledBox>
    </Modal>
  );
};
