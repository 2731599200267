import { Card } from "components/Card";
import { ReactComponent as NextIcon } from "icons/next.svg";
import { ReactComponent as PreviousIcon } from "icons/previous.svg";
import React from "react";
import styled from "styled-components";

interface IPagination {
  current: number;
  pages: number;
  onPageClick: (pageNumber: number) => void;
}

interface IPageNumber {
  active?: boolean;
}

const IconWrapper = styled.div`
  margin-top: 7px;
  margin-bottom: 9px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
    height: 40px;
  `}
  & > div:first-child {
    margin-left: 13px;
  }
  & > div:last-child {
    margin-right: 16px;
  }
  margin: 10px;
`;

const PageNumber = styled.div<IPageNumber>`
  ${({ theme, active }) => `
    color: ${active ? theme.palette.primary.main : theme.palette.text.primary};
    font-weight: ${active && "bold"};
  `};
  cursor: pointer;
  text-align: center;
  margin: 9px 0 11px;
  height: 20px;
  width: 40px;
`;

export const Pagination: React.FC<IPagination> = ({
  current,
  pages,
  onPageClick,
}) => {
  let leftSide = 3;
  let rightSide = 3;
  let from: number;
  let to: number;
  const previous = current - 1 < 1 ? false : current - 1;
  const next = current + 1 > pages ? false : current + 1;

  if (pages > 7) {
    const previousCount = current - 1;
    const remaining = pages - current;

    if (previousCount < 3) {
      leftSide = previousCount;
      rightSide += 3 - previousCount;
    }
    from = current - leftSide;

    if (remaining < 3) {
      rightSide = remaining;
    }
    to = current + rightSide;
  } else {
    from = 1;
    to = pages;
  }
  if (current - from > 3) from = current - 3;

  const mapPageNumber = () => {
    const data = [];
    for (let i = from; i <= to; i += 1) {
      data.push(
        <PageNumber
          onClick={() => {
            onPageClick(i);
          }}
          key={i}
          active={i === current}
        >
          {i}
        </PageNumber>
      );
    }
    return data;
  };

  return (
    <>
      {pages > 1 && (
        <StyledCard>
          {previous && (
            <IconWrapper
              onClick={() => {
                onPageClick(previous);
              }}
            >
              <PreviousIcon />
            </IconWrapper>
          )}
          {mapPageNumber()}
          {next && (
            <IconWrapper
              onClick={() => {
                onPageClick(next);
              }}
            >
              <NextIcon />
            </IconWrapper>
          )}
        </StyledCard>
      )}
    </>
  );
};
