import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

export interface RadioI extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
}

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const Control = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid ${theme.palette.neutral.medium};
    border-radius: 50%;

    &::after {
      content: '';
      display: block;
      visibility: hidden;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: ${theme.palette.neutral.white};
      border-radius: 50%;
    }
  `}
`;

interface IInput {
  error?: RadioI["error"];
}

const Input = styled.input<IInput>`
  ${({ theme, error }) => `
    position: relative;
    opacity: 0;
    width: 16px;
    height: 16px;
    position: absolute;

    + ${Control} {
      ${error ? `border-color: ${theme.palette.accent.red}` : ""}
    }

    &:checked {
      + ${Control} {
        background-color: ${theme.palette.primary.main};
        border-color: ${
          error ? theme.palette.accent.red : theme.palette.primary.main
        };

        &::after {
          visibility: visible;
        }
      }
    }

    &:disabled {
      + ${Control} {
        opacity: 0.4;

        background-color: ${theme.palette.neutral.medium};
        border-color: ${theme.palette.neutral.medium};
      }
    }
  `}
`;

export const Radio = React.forwardRef<HTMLInputElement, RadioI>(
  ({ label, error = false, ...props }, ref) => {
    return (
      <Label>
        <Input error={error} type="radio" {...props} ref={ref} />
        <Control />
        {label}
      </Label>
    )
  }
);
