import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonsGroup } from "components/ButtonsGroup";
import { Checkbox } from "components/Checkbox";
import { IOption, Select } from "components/Select";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import { generateDataForFields } from "database/category";
import { voivodships } from "database/voivodship";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AdvertisementCategoryType } from "types/advertisement";
import * as yup from "yup";

import { IBasicDataFormData } from "./types";

interface IBasicDataStep {
  onCancelClick: () => void;
  onSubmit: (data: IBasicDataFormData) => void;
  type: AdvertisementCategoryType;
}

interface IVariant {
  name: string;
  label: string;
  options?: Array<IOption>;
  type: "text" | "dropdown" | "check";
}

export const BasicDataStep: React.FC<IBasicDataStep> = ({
  onCancelClick,
  onSubmit,
  type,
}) => {
  const fields = generateDataForFields(type);

  const isRequired = (fieldName: string) =>
    fields.find((field: IVariant) => field.name === fieldName);

  const { register, handleSubmit, errors, formState, control } =
    useForm<IBasicDataFormData>({
      mode: "onChange",
      resolver: yupResolver(
        yup.object().shape({
          price: isRequired("price")
            ? yup.number().required().typeError("Podaj poprawną cenę")
            : yup.number().required().typeError("Podaj poprawną cenę"),
          year_of_construction: isRequired("year_of_construction")
            ? yup
                .date()
                .required()
                .typeError("Podaj poprawną datę (mm.dd.yyyy)")
            : yup.date().typeError("Podaj poprawną datę (mm.dd.yyyy)"),
          number_of_apartments: isRequired("number_of_apartments")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          area: isRequired("area")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          floor: isRequired("floor")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          number_of_apartments_on_floor: isRequired(
            "number_of_apartments_on_floor"
          )
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          legal_status_of_the_facility: isRequired(
            "legal_status_of_the_facility"
          )
            ? yup.string().required("To pole jest wymagane")
            : yup.string(),
          roi: isRequired("roi")
            ? yup.string().required("To pole jest wymagane")
            : yup.string(),
          description_of_the_roi_calculation_method: isRequired(
            "description_of_the_roi_calculation_method"
          )
            ? yup.string().required("To pole jest wymagane")
            : yup.string(),
          number_of_floors: isRequired("number_of_floors")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          min_area_of_apartments: isRequired("min_area_of_apartments")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          max_area_of_apartments: isRequired("max_area_of_apartments")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          type_of_local: isRequired("type_of_local")
            ? yup.string().required()
            : yup.string(),
          dimension_x: isRequired("dimension_x")
            ? yup.number().required()
            : yup.number(),
          dimension_y: isRequired("dimension_y")
            ? yup.number().required().typeError("Podaj poprawną liczbę")
            : yup.number().typeError("Podaj poprawną liczbę"),
          voivodship: isRequired("voivodship")
            ? yup.string().required()
            : yup.string(),
          // FIXME
          // development_plan shouldn't be boolean
          development_plan: yup.boolean(),
          number_of_plot: isRequired("number_of_plot")
            ? yup.string().required()
            : yup.string(),
          water: yup.boolean(),
          gas: yup.boolean(),
          electricity: yup.boolean(),
          canalization: yup.boolean(),
          building_conditions: yup.boolean(),
        })
      ),
    });

  const renderFildView = (fieldData: IVariant): any => {
    switch (fieldData.type) {
      case "check": {
        return (
          <Checkbox
            name={fieldData.name}
            ref={register}
            label={fieldData.label}
          />
        );
      }
      case "dropdown": {
        return (
          <Controller
            control={control}
            name={fieldData.name}
            render={({ value, onChange }) => {
              return (
                <Select
                  search
                  label={fieldData.label}
                  options={fieldData.options!}
                  name={fieldData.name}
                  selected={
                    voivodships.find((opt) => opt.value === value)?.label
                  }
                  onSelected={(val) => {
                    onChange(val);
                  }}
                />
              );
            }}
          />
        );
      }
      case "text":
        return (
          <TextField
            name={fieldData.name}
            ref={register}
            label={fieldData.label}
            error={errors[fieldData.name as keyof IBasicDataFormData]?.message}
          />
        );

      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((fieldData) => (
        <Box mb={3}>{renderFildView(fieldData)}</Box>
      ))}
      <Box mt={8}>
        <ButtonsGroup>
          <Button
            type="button"
            label="Anuluj"
            variant="secondary"
            onClick={onCancelClick}
          />
          <Button type="submit" label="Dalej" disabled={!formState.isValid} />
        </ButtonsGroup>
      </Box>
    </form>
  );
};
