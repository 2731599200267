import { rgba } from "utilities/rgba";

import { breakpoints } from "./breakpoints";

const palettePrimaryMain = "#007CFB";

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      dark: "#005EC0",
    },
    neutral: {
      dark: "#545D61",
      medium: "#BEC9D5",
      light: "#DFE7F0",
      veryLight: "#F1F5F9",
      white: "#fff",
    },
    accent: {
      blue: "#26B9E7",
      tiel: "#32D1CD",
      green: "#2EC17A",
      lightGreen: "#89E42D",
      yellow: "#F5D73F",
      orange: "#F5843F",
      red: "#FB5C00",
      purple: "#B657EE",
    },
    text: {
      primary: "#545D61",
      secondary: rgba("#545d61", 0.7),
      red: "#800615",
    },
    background: "#eaeff4",
  },
  textStyles: {
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    body: {
      fontSize: 16,
      lineHeight: "20px",
    },
    h3: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 20,
      lineHeight: "28px",
      fontWeight: 700,
    },
  },
  space: new Array(25).fill(null).map((value, index) => index * 4),
  // those are needed here for styled-system to work correctly
  breakpoints: breakpoints.map((breakpoint) => `${breakpoint}px`),
};
