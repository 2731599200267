import { OTHER_PROFILE } from "constants/paths";
import { IUser } from "types/user";
import { fetchAPI } from "utilities/fetchAPI";

export interface IOtherUserProfile {
  contact_info?: IContactInfo;
  data: IUser;
  received_invitation_id?: number;
}
export type IContactInfo = "created" | "accepted" | "rejected" | "cancelled";

export const getOtherUserPforileInfo = async (userId: string) => {
  const otherUserProfile = await fetchAPI<IOtherUserProfile>(
    OTHER_PROFILE(userId),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return otherUserProfile;
};
