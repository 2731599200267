import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { Box } from "components/Box";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IUser } from "types/user";

import { TextField } from "../../../components/TextField";
import { profileFirmDataSchema } from "../../../schemas/profileSettings";
import { updateProfileAction } from "../../../store/currentUser/actions";
import { StateI } from "../../../store/currentUser/types";
import { ButtonContainer, FormContainer } from "../ProfileInfo";

interface IFormData {
  /* eslint-disable camelcase */
  company_name: string;
  company_nip: string;
  company_regon: string;
  company_address: string;
  company_city: string;
  company_postal_code: string;
  /* eslint-enable camelcase */
}

interface IPersonalDataForm {
  currentUser: IUser;
  onCloseForm: (val: number) => void;
}

export const FirmDataForm: React.FC<IPersonalDataForm> = ({
  currentUser,
  onCloseForm,
}) => {
  const dispatch = useDispatch();

  const [defaultValues, setDefaultValues] = useState<IFormData>({
    company_name: "",
    company_nip: "",
    company_regon: "",
    company_address: "",
    company_city: "",
    company_postal_code: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues,
    resolver: yupResolver(profileFirmDataSchema),
  });

  const onSubmit = async (data: IFormData) => {
    setDefaultValues({ ...defaultValues, ...data });
    dispatch(updateProfileAction(data));
    onCloseForm(0);
  };

  useEffect(() => {
    setDefaultValues({ ...defaultValues, ...currentUser?.profile });
  }, [currentUser]);
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <TextField
              label="Nazwa firmy"
              name="company_name"
              ref={register}
              error={errors?.company_name?.message}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="NIP"
              name="company_nip"
              ref={register}
              error={errors?.company_nip?.message}
              maxLength={10}
              minLength={10}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Regon"
              name="company_regon"
              ref={register}
              error={errors?.company_regon?.message}
              maxLength={9}
              minLength={9}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Adres"
              name="company_address"
              ref={register}
              error={errors?.company_address?.message}
            />
          </Box>
          <Row>
            <Col sm={5}>
              <Box mb={6}>
                <TextField
                  label="Kod pocztowy"
                  name="company_postal_code"
                  ref={register}
                  error={errors?.company_postal_code?.message}
                />
              </Box>
            </Col>
            <Col sm={7}>
              <Box mb={6}>
                <TextField
                  label="Miejscowość"
                  name="company_city"
                  ref={register}
                  error={errors?.company_city?.message}
                />
              </Box>
            </Col>
          </Row>

          <ButtonContainer>
            <Button type="submit" label="Zapisz" />
            <Button
              onClick={() => {
                onCloseForm(0);
              }}
              label="Anuluj"
              variant="secondary"
            />
          </ButtonContainer>
        </form>
      </FormContainer>
    </>
  );
};
