import { UserProfile } from "components/UserProfile";
import React from "react";
import { IOtherUserProfile } from "services/otherProfile";
import styled from "styled-components";

import { useProfile } from "../../hooks/profile";

export const ButtonContainer = styled.div`
  ${({ theme }) => `
    button:first-child {
      margin-right: ${theme.space[5]}px;
    }
  `}
`;
export const FormContainer = styled.div`
  max-width: 360px;
`;

export const ProfileInfoView = () => {
  const {
    user,
    activityCities,
    mostImportantFeatures,
    specializations,
    investmentsLevels,
    lookingFor,
    investmentsSpecializations,
    expectations,
  } = useProfile();

  const userProfile = {
    data: user?.data,
  } as IOtherUserProfile;

  return (
    <UserProfile
      user={userProfile}
      activityCities={activityCities}
      mostImportantFeatures={mostImportantFeatures}
      specializations={specializations}
      investmentsLevels={investmentsLevels}
      lookingFor={lookingFor}
      investmentsSpecializations={investmentsSpecializations}
      expectations={expectations}
    />
  );
};
