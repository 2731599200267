import { Configuration } from "react-grid-system";

import { breakpoints } from "./breakpoints";

export const containerWidths = [540, 740, 960, 1170];

export const gridConfig = {
  maxScreenClass: "xl" as Configuration["maxScreenClass"],
  defaultScreenClass: "xl" as Configuration["defaultScreenClass"],
  gutterWidth: 32,
  containerWidths,
  breakpoints,
};
