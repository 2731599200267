import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { FormWizardLegend } from "components/FormWizardLegend";
import { FormWizardLegendI } from "components/FormWizardLegend/FormWizardLegend";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { useWindowWidth } from "hooks/useWindowWidth";
import { ReactComponent as InfoIcon } from "icons/info_white.svg";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { BasicDataStep } from "./BasicDataStep";
import { LocalizationStep } from "./LocalizationStep";
import { NameAndOfferTypeStep } from "./NameAndOfferTypeStep";
import { OfferDescriptionStep } from "./OfferDescriptionStep";
import { PhotosStep } from "./PhotosStep";
import { SummaryStep } from "./SummaryStep";
import {
  AdvertDataType,
  IBasicDataFormData,
  ILocalizationFormData,
  INameAndOfferTypeFormData,
  IOfferDescriptionFormData,
  IPhotosData,
} from "./types";

interface IAddAdvertisementModal {
  isOpen: boolean;
  onCancelClick: () => void;
  onSubmit: (advert: AdvertDataType) => void;
}

const LeftCol = styled.div`
  ${({ theme }) => `
    width: 360px;
    background-color: ${theme.palette.neutral.veryLight};
  `};
`;

const RightCol = styled.div`
  ${({ theme }) => `
    width: 488px;
    padding: ${theme.space[12]}px;

    @media only screen and (max-width: 1000px) {
      padding: ${theme.space[2]}px;
    }
  `};
`;
const InfoBox = styled(Box)`
  ${({ theme }) => `
position: relative;
  border-radius: 10px;
  color: ${theme.palette.neutral.white};
  background-color: ${theme.palette.primary.main};
`};
`;
const StyledInfoIcon = styled(InfoIcon)`
  position: absolute;
  left: 20px;
`;

const formWizardLegendInitialSteps: FormWizardLegendI["steps"] = [
  {
    label: "Nazwa i typ oferty",
    step: 1,
    active: true,
    disabled: false,
  },
  {
    label: "Dane podstawowe",
    step: 2,
    active: false,
    disabled: true,
  },
  {
    label: "Opis oferty",
    step: 3,
    active: false,
    disabled: true,
  },
  {
    label: "Lokalizacja",
    step: 4,
    active: false,
    disabled: true,
  },
  {
    label: "Zdjęcia",
    step: 5,
    active: false,
    disabled: true,
  },
  {
    label: "Podsumowanie",
    step: 6,
    active: false,
    disabled: true,
  },
];

export const AddAdvertisementModal: React.FC<IAddAdvertisementModal> = ({
  isOpen,
  onCancelClick,
  onSubmit,
}) => {
  const [formWizardLegendSteps, setFormWizardLegendSteps] = useState<
    FormWizardLegendI["steps"]
  >(formWizardLegendInitialSteps);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const [stepNumber, setStepNumber] = useState(1);
  const [nameAndOfferTypeData, setNameAndOfferTypeData] =
    useState<INameAndOfferTypeFormData>();
  const [basicDataData, setBasicDataData] =
    useState<Partial<IBasicDataFormData>>();
  const [offerDescriptionData, setOfferDescriptionData] =
    useState<IOfferDescriptionFormData>();
  const [localizationData, setLocalizationData] =
    useState<ILocalizationFormData>();
  const [photosData, setPhotosData] = useState<{
    interiorImages: File[];
    blueprintsImages: File[];
    environmentImages: File[];
  }>();

  const windowWidth = useWindowWidth();

  const advert = {
    ...nameAndOfferTypeData,
    ...basicDataData,
    ...offerDescriptionData,
    ...localizationData,
    imagesToShow: photosData,
  } as AdvertDataType;

  const handleCancel = () => {
    setStepNumber(1);
    setNameAndOfferTypeData(undefined);
    setBasicDataData(undefined);
    setOfferDescriptionData(undefined);
    setLocalizationData(undefined);
    onCancelClick();
  };

  const handleNext = () => {
    setStepNumber((prevState) => prevState + 1);
  };

  useEffect(() => {
    setFormWizardLegendSteps((prevState) =>
      prevState.map((step) => ({
        ...step,
        active: step.step === stepNumber,
        disabled: step.step > stepNumber,
      }))
    );
  }, [stepNumber]);

  const handleNameAndOfferTypeStepSubmit = (
    data: INameAndOfferTypeFormData
  ) => {
    setNameAndOfferTypeData(data);
    handleNext();
  };

  const handleBasicDataStepSubmit = (data: Partial<IBasicDataFormData>) => {
    setBasicDataData(data);
    handleNext();
  };

  const handleOfferDescriptionStepSubmit = (
    data: IOfferDescriptionFormData
  ) => {
    setOfferDescriptionData(data);
    handleNext();
  };

  const handleLocalizationStepSubmit = (data: ILocalizationFormData) => {
    setLocalizationData(data);
    handleNext();
  };

  const handlePhotosStepSubmit = (data: {
    interiorImages: File[];
    blueprintsImages: File[];
    environmentImages: File[];
  }) => {
    setPhotosData(data);
    handleNext();
  };

  const handleSubmit = async () => {
    setIsSubmitButtonDisabled(true);
    let images: File[] = [];
    if (advert.imagesToShow) {
      images = images.concat(advert.imagesToShow.blueprintsImages);
      images = images.concat(advert.imagesToShow.environmentImages);
      images = images.concat(advert.imagesToShow.interiorImages);
      advert.images = images;
      delete advert.imagesToShow;
    }
    try {
      await onSubmit(advert);
      toast.success("Ogłoszenie zostało dodane.");
      setIsSubmitButtonDisabled(false);
    } catch (error) {
      toast.error("Nie udało się dodać ogłoszenie.");
    }
    handleCancel();
  };

  const renderForm = () => {
    switch (stepNumber) {
      case 1: {
        return (
          <NameAndOfferTypeStep
            onCancelClick={handleCancel}
            onSubmit={handleNameAndOfferTypeStepSubmit}
          />
        );
      }
      case 2: {
        if (!nameAndOfferTypeData?.category) return null;

        return (
          <BasicDataStep
            onCancelClick={handleCancel}
            onSubmit={handleBasicDataStepSubmit}
            type={nameAndOfferTypeData.category}
          />
        );
      }
      case 3: {
        return (
          <OfferDescriptionStep
            onCancelClick={handleCancel}
            onSubmit={handleOfferDescriptionStepSubmit}
          />
        );
      }
      case 4: {
        return (
          <LocalizationStep
            onCancelClick={handleCancel}
            onSubmit={handleLocalizationStepSubmit}
          />
        );
      }
      case 5: {
        return (
          <PhotosStep
            onCancelClick={handleCancel}
            onSubmit={handlePhotosStepSubmit}
          />
        );
      }
      case 6: {
        return (
          <SummaryStep
            advert={advert}
            onCancelClick={handleCancel}
            onSubmit={handleSubmit}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Flex flexWrap={windowWidth > 1000 ? "nowrap" : "wrap"}>
        <LeftCol>
          <Box p={8}>
            <Typography variant="h1" color="primary" mb={4}>
              Nowe ogłoszenie
            </Typography>
            <FormWizardLegend steps={formWizardLegendSteps} />
          </Box>
        </LeftCol>
        <RightCol>
          <InfoBox mb={4} py={3} pr={4} pl={14}>
            <Flex alignItems="center" justifyContent="space-between">
              <StyledInfoIcon />
              <Typography variant="body2">
                Serdecznie witamy w portalu Investments4you i dziękujemy za chęć
                wstawienia u nas ogłoszenia. Proszę pamiętać, że od każdej
                zrealizowanej inwestycji, połączenia inwestycji z inwestorami
                portal pobiera 1% prowizji od kwoty brutto transakcji. W tym
                celu portal poprosi Państwa o uzupełnienie danych dotyczących
                przyszłej faktury. Prowizja zostanie pobrana w momencie, gdy
                transakcja dojdzie do skutku. Bez uzupełnienia danych o fakturze
                nie ma możliwości wstawienia ogłoszenia. Dziękujemy za
                zrozumienie. Zespół Investments4you.
              </Typography>
            </Flex>
          </InfoBox>
          {renderForm()}
        </RightCol>
      </Flex>
    </Modal>
  );
};
