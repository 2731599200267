import {AdvertisementCategoryType} from "types/advertisement";

export const categoryTypeOptions: Array<{
  value: AdvertisementCategoryType;
  label: string;
}> = [
  { value: "apartment", label: "Mieszkanie" },
  { value: "tenement_house", label: "Kamienica" },
  { value: "service_premises", label: "Lokal usługowy" },
  { value: "warehouse", label: "Magazyny i hale" },
  { value: "other", label: "Inne" },
  { value: "plot", label: "Działka pod inwestycję" },
];
