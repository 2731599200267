import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { IRootState } from "store/currentUser/types";
import { IRoute } from "types/route";

export const RouteWithSubRoutes = (route: IRoute): JSX.Element => {
  const { path, routes, private: priv } = route;

  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const authenticated = currentUser?.token;

  return !authenticated && priv ? (
    <Redirect to="/login" />
  ) : (
    <Route
      path={path}
      render={(props) => <route.component {...props} routes={routes} />}
    />
  );
};
