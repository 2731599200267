import { PROFILE_URL } from "constants/paths";
import { serialize } from "object-to-formdata";
import { IUser, IUserProfileAvatarUpload } from "types/user";
import { fetchAPI } from "utilities/fetchAPI";

export interface IUpdateProfileResponse {
  data: IUser;
}

export const updateProfile = async (
  data: Partial<IUserProfileAvatarUpload>
) => {
  const userData = await fetchAPI<IUpdateProfileResponse>(PROFILE_URL, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(data, {}, new FormData(), "profile"),
  });

  return userData;
};
