import { investmentsSpecializationsFields } from "constants/profileFormFields";
import { IOtherUserProfile } from "services/otherProfile";

export const parseProfileToUserProfile = (user: IOtherUserProfile | null) => {
  const activityCities = user?.data?.profile?.real_estate_activity_cities ?? [];

  const mostImportantFeatures = [
    ...(user?.data?.preference?.the_most_important_are_photos
      ? ["Zdjęcia"]
      : []),
    ...(user?.data?.preference?.the_most_important_is_location
      ? ["Lokalizacja"]
      : []),
    ...(user?.data?.preference?.the_most_important_is_price ? ["Cena"] : []),
    ...(user?.data?.preference?.the_most_important_is_roi ? ["ROI"] : []),
    ...(user?.data?.preference?.the_most_important_is_year_of_construction
      ? ["Rok budowy"]
      : []),
  ];

  const specializations = user?.data?.profile?.investments_specialization ?? [];

  const investmentsLevels = user?.data?.profile?.investments_levels ?? [];

  const lookingFor = [
    ...(user?.data?.preference?.looking_for_single_investor_investments
      ? [
          "Miewa okazje inwestycyjne, poszukuję finansowania przez pojedynczego Inwestora",
        ]
      : []),
    ...(user?.data?.preference?.looking_for_group_investors_investments
      ? [
          "Miewa okazje inwestycyjne, poszukuję finansowania przez grupę Inwestorów",
        ]
      : []),
    ...(user?.data?.preference?.looking_for_single_investor
      ? ["Miewa środki finansowe, poszukuję okazji inwestycyjnych dla siebie"]
      : []),
    ...(user?.data?.preference?.looking_for_group_investors_investments
      ? [
          "Miewa środki finansowe, poszukuję okazji inwestycyjnych jako współinwestor",
        ]
      : []),
    ...(user?.data?.preference?.looking_for_network_of_contacts
      ? ["Chce budować sieć znajomych inwestorów"]
      : []),
  ];

  const investmentsSpecializations = [
    ...(user?.data?.preference?.specialized_in_ready_investments
      ? ["Gotowce inwestycyjne"]
      : []),
    ...(user?.data?.preference?.specialized_in_rebuilding_investments
      ? ["Inwestycje do aranżacji lub przebudowy"]
      : []),
  ];

  const expectations = [
    ...(user?.data?.preference?.networking_for_scaling_through_regions
      ? [
          "Chce skalować swoją  działalność na nowe miasta i regiony wspólnie z nowymi znajomymi",
        ]
      : []),
    ...(user?.data?.preference?.networking_for_creating_a_bigger_investments
      ? [
          "Chce wejść na wyższy poziom finansowy inwestycji niż dotychczas wspólnie z nowymi znajomymi",
        ]
      : []),
  ];

  return {
    user,
    activityCities,
    mostImportantFeatures,
    specializations,
    investmentsLevels,
    lookingFor,
    investmentsSpecializations,
    expectations,
  };
};
