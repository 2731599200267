import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import React from "react";
import styled from "styled-components";

interface IRegulationModal {
  onCancelClick: () => void;
  isOpen: boolean;
}

const StyledBox = styled(Box)`
  width: 550px;

  @media only screen and (max-width: 1000px) {
    width: 300px;
  }
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 12px;
  cursor: pointer;
`;

export const RegulationModal: React.FC<IRegulationModal> = ({
  onCancelClick,
  isOpen,
}) => {
  return (
    <Modal onCancelClick={onCancelClick} isOpen={isOpen}>
      <StyledBox p={6}>
        <Flex justifyContent="space-between" alignItems="center" mb={5}>
          <Typography variant="h1" color="primary">
            Regulamin portalu Investments4you
          </Typography>
          <StyledCloseIcon onClick={onCancelClick} />
        </Flex>
        <Typography variant="body2">
          Postanowienia Ogólne
          <br />
          <br />
          Niniejszy Regulamin jest wzorcem umownym i określa warunki, zasady
          oraz sposób świadczenia Usługi przez Usługodawcę na rzecz Użytkownika.
          Klikając przycisk „Zarejestruj” akceptujesz Regulamin. Użyte w
          Regulaminie definicje należy rozumieć w następujący sposób:
          <br />
          <br />
          1. Usługodawca – K Investments Jacek Kusiak, ul. Puławska 257 lok 90,
          02-769 Warszawa, NIP 7171514703
          <br />
          2. Regulamin – niniejszy dokument.
          <br />
          3. Narzędzie internetowe – system dostępny poprzez Internet,
          działający pod adresem https://investments4you.pl umożliwiający
          świadczenie Usługi.
          <br />
          4. Treści – ogłoszenia publikowane przy użyciu Narzędzie
          internetowego.
          <br />
          5. Usługa – usługa świadczona drogą elektroniczną za pośrednictwem
          Narzędzia internetowego przez Usługodawcę na rzecz Użytkownika zgodnie
          z przepisami prawa oraz na zasadach określonych w Regulaminie.
          <br />
          6. Umowa – dokument o świadczeniu Usługi zawarta pomiędzy
          Użytkownikiem, a Usługodawcą na zasadach określonych w Regulaminie.
          <br />
          7. Firma – osoba fizyczna prowadząca we własnym imieniu działalność
          gospodarczą albo osoba fizyczna reprezentująca osobę prawną bądź
          jednostkę organizacyjną nie posiadająca osobowości prawnej, która
          zgodnie z prawem jest zdolna do zawarcia Umowy z Usługodawcą.
          <br /> 8. Osoba fizyczna – osoba posiadająca pełną zdolność do
          czynności prawnych, która zawarła Umowę niezwiązaną z prowadzoną przez
          tę osobę działalnością gospodarczą albo wykonywanym zawodem.
          <br />
          9. Użytkownik – Firma lub Osoba fizyczna, która korzysta z Usług
          udostępnianych przez Portal Investments4you na mocy niniejszego
          Regulaminu.
          <br />
          10. Student - osoba, która korzysta z usług.
          <br />
          11. Konto – dokument elektroniczny przypisany do Użytkownika, powstały
          w wyniku wypełnienia Formularza rejestracyjnego oraz spełnienia innych
          wymogów niezbędnych, by rozpocząć i kontynuować pracę w, o których
          mowa w niniejszym Regulaminie.
          <br />
          12. Rejestracja – formularz elektroniczny, którego wypełnienie przez
          Użytkownika stanowi jeden z warunków korzystania z Usług świadczonych
          drogą elektroniczną przez Portal Investments4you.
          <br />
          13. Login – adres e-mail Użytkownika.
          <br />
          14. Hasło – ciąg znaków literowych, cyfrowych oraz znaków specjalnych,
          znany jedynie osobie uprawnionej do pracy w Narzędziu internetowym.
          <br />
          15. Opłata miesięczna( wkrótce)– miesięczne wynagrodzenie rozliczane w
          cyklu rocznym z tytułu przyznanego dostępu na okres jednego roku do
          materiałów, w wysokości określonej na stronie
          https://investments4you.pl
          <br />
          16. Opłata jednorazowa – jednorazowe wynagrodzenie za znalezienie
          inwestora lub inwestycji 1% od kwoty brutto transakcji. Wystawiana
          jest Faktura VAT na podstawie pozostawionych danych firmowych w
          portalu
          <br />
          17. Oprogramowanie – autorskie skrypty w oparciu, o które działa
          Narzędzie internetowe, będące własnością Usługodawcy.
          <br />
          18. Okres rozliczeniowy – okres świadczenia Usługi dostępu do
          materiałów
          <br />
          19. Notyfikacje – elektroniczna forma powiadomień, na temat Usługi,
          przesyłana do Użytkownika zgodnie z Polityką Prywatności.
          <br />
          20. Polityka Prywatności – określa sposób przetwarzania danych w
          Narzędziu internetowym.
          <br />
          21. Cena – informacja podawana przy każdym produkcie ile kosztuje
          opisana w Regulaminie, stanowiąca integralną część Umowy.
          <br />
          22. Cookies – niewielkie informacje tekstowe, wysyłane przez serwer
          www i zapisywane po stronie Użytkownika.
          <br />
          23. Adres e-mail – adres poczty elektronicznej (aktywny adres e-mail)
          wskazany podczas Rejestracji.
          <br />
          <br />
          Rodzaje i zakres usług
          <br />
          <br />
          1. Usługodawca świadczy usługi płatne.
          <br />
          2. W ramach Usług płatnych Użytkownik, po dokonaniu rejestracji, ma
          możliwość skorzystania z dostępu do detali ogłoszeń . W skład wchodzi:
          o niegraniczona możliwość oglądania detali ogłoszen o korzystanie ze
          wsparcia supportu: biuro@investments4you.biz
          <br />
          3. Cena za Usługę została określona w Cenniku, dostępnym na stronie
          https://investments4you.pl
          <br />
          4. Zasady dotyczące Płatności zostały opisane w działach Płatności
          niniejszego Regulaminu.
          <br />
          <br />
          Zmiany regulaminu
          <br />
          <br />
          Usługodawca zastrzega sobie prawo do zmiany Regulaminu. Każda taka
          zmiana wchodzi w życie po otrzymaniu powiadomienia, które może zostać
          przekazane przez Poratl Investments4you poprzez: zamieszczenie
          informacji na stronie https://investments4you.pl lub e-mail. Jeżeli
          zmiana zostanie ogłoszona poprzez zamieszczenie na stronie
          internetowej, uznaje się, że wchodzi w życie z chwilą opublikowania
          jej. Jeżeli zmiana zostanie przesłana pocztą elektroniczną, uważa się,
          że zaczyna obowiązywać w momencie wysłania wiadomości na Adres e-mail.
          Dalsze korzystanie z Narzędzia internetowego po otrzymaniu
          powiadomienia o takich zmianach będzie definitywnie uznawane za
          akceptację wszelkich zmian w Regulaminie.
          <br />
          <br />
          Zawieranie i rozwiązanie umowy
          <br />
          <br />
          1. Rozpoczęcie korzystania przez Użytkownika z Usług o charakterze
          płatnym możliwe jest jedynie w przypadku uprzedniego zapoznania się i
          akceptacji postanowień zawartych w Regulaminie, a także po dokonaniu
          rejestracji, polegającej na wypełnieniu formularza rejestracyjnego.
          Warunkiem rozpoczęcia pracy w Narzędziu internetowym jest także
          wyrażenie zgody przetwarzanie danych osobowych przez Usługodawcą, o
          których mowa w Dziale Przetwarzanie i Ochrona Danych Osobowych. Łączne
          spełnienie warunków opisanych w niniejszym punkcie jest równoznaczne z
          zawarciem Umowy o świadczeniu Usług drogą elektroniczną, której
          przedmiotem jest korzystanie z Portalu Investments4you.
          <br />
          2. Usługodawca ma prawo rozwiązać umowę o świadczenie usług bez
          zachowania terminu wypowiedzenia i zaprzestać świadczenia Usług w
          następujących przypadkach: o naruszenia przez Użytkownika istotnych
          postanowień Regulaminu o umieszczania przez Użytkownika treści
          niezgodnych z obowiązującymi przepisami prawa lub dobrymi obyczajami o
          wykorzystywania przez Użytkownika Narzędzia internetowego niezgodnie z
          jego przeznaczeniem o kiedy uzna to za stosowne, bez konieczności
          podania konkretnej przyczyny.
          <br />
          3. Rozwiązanie umowy przez Usługobiorcę może nastąpić w dowolnym
          momencie na adres biuro@investments4you.biz z adresu e-mail
          powiązanego z kontem.
          <br />
          4. Usługodawca nie jest zobowiązany do przechowywania treści
          Użytkownika i może usunąć Konto i treść natychmiast po rozwiązaniu
          Umowy.
          <br />
          <br />
          Płatności
          <br />
          <br />
          Opłata jednorazowa – jednorazowe wynagrodzenie za znalezienie
          inwestora lub inwestycji 1% od kwoty brutto transakcji. Wystawiana
          jest Faktura VAT na podstawie pozostawionych danych firmowych w
          portalu.
          <br />
          Inne możliwości płatności( wkrótce)
          <br />
          1. Usługobiorca ma możliwość opłacenia dostępu, o którym mowa w
          rozdziale Rodzaje i zakres usług, przy pomocy systemu płatności
          on-line (płatności kartami procesowane są przez t-pay).
          <br />
          2. Opłaty wnoszone są za ogłoszenia lub zrealizowane transakcje
          <br />
          3. Okres rozliczeniowy kończy się w dniu poprzedzającym datę, która
          odpowiada dacie rozpoczęcia korzystania z przyznanego dostępu przez
          Usługobiorcę.
          <br />
          4. Usługobiorca zgadza się na automatyczne obciążanie s karty
          kredytowej lub debetowej za całość należnych opłat w danym Okresie
          Rozliczeniowym. Jednocześnie Usługobiorca wyraża zgodę na
          wykorzystanie przez Usługodawcę zewnętrznych podmiotów pośredniczących
          w procesie płatności, a także ujawnienie im danych dotyczących
          transakcji.
          <br />
          5. Wynagrodzenie za każdy kolejny Okres Rozliczeniowy będzie pobierane
          automatycznie, do momentu rezygnacji z rocznej subskrypcji przez
          Użytkownika.
          <br />
          6. Rezygnacja może odbyć się w dowolnym momencie poprzez samodzielne
          odpięcie karty kredytowej lub debetowej z panelu administracyjnego.
          <br />
          7. Po zaksięgowaniu płatności, Usługobiorca otrzyma fakturę VAT pocztą
          elektroniczną na wskazany adres.
          <br />
          8. W przypadku niewniesienia Opłaty przez Użytkownika, Usługodawca
          zastrzega sobie prawo do zablokowania możliwości oglądania ogłoszeń.
          <br />
          9. Usługobiorca ma możliwość odstąpienia od umowy i wystąpienia o
          zwrot poniesionej opłaty transakcyjnej w ramach 30 dni gwarancji
          zadowolenia liczonych od momentu dokonania zakupu i płatności za
          pomocą serwisu tpay.
          <br />
          10. Opłata transakcyjna, której wielkość została określona w Cenniku,
          dostępnym na stronie https://investments4you.pl nie podlega zwrotowi
          dla Usługobiorcy w przypadku minięcia terminu 30 dni gwarancji
          satysfakcji.
          <br />
          <br />
          Zmiany Cennika
          <br />
          <br />
          Usługodawca zastrzega sobie prawo do zmiany Cennika. Każda taka zmiana
          wchodzi w życie po otrzymaniu powiadomienia, które może zostać
          przekazane przez Portal Investments4you poprzez: zamieszczenie
          informacji na stronie https://investments4you.pl lub e-mail. Jeżeli
          zmiana zostanie ogłoszona poprzez zamieszczenie na stronie
          internetowej, uznaje się, że wchodzi w życie z chwilą opublikowania
          jej. Jeżeli zmiana zostanie przesłana pocztą elektroniczną, uważa się,
          że zaczyna obowiązywać w momencie wysłania wiadomości na Adres e-mail.
          Dalsze korzystanie z Narzędzia internetowego po otrzymaniu
          powiadomienia o takich zmianach będzie definitywnie uznawane za
          akceptację wszelkich zmian w Cenniku.
          <br />
          <br />
          Reklamacje
          <br />
          <br />
          1. Wszelkie ewentualne reklamacje Użytkownik powinien zgłosić za
          pośrednictwem poczty elektronicznej na adres e-mail:
          biuro@investments4you.biz
          <br />
          2. Usługodawca rozpatrzy zgłoszenie reklamacyjne w terminie do 14 dni
          od dnia otrzymania zgłoszenia. Przetwarzanie i ochrona danych
          osobowych Polityka prywatności Portalu Investments4you wyjaśnia jakie
          i w jakim celu Dane Osobowe są zbierane i kto jest ich
          Administratorem.
          <br />
          <br />
          Prawo do publikowanych treści
          <br />
          <br />
          1. Właścicielem treści opublikowanych za pośrednictwem Narzędzie
          internetowego jest Usługobiorca.
          <br />
          2. Poprzez publikację treści Użytkownik udziela pozwolenia Portalowi
          Investments4you na ich udostępnianie, ale nie pozbywa się do nich
          żadnych praw własności.
          <br />
          <br />
          Odpowiedzialność za prawa autorskie
          <br />
          <br />
          1. Portal Investments4you szanuje własność intelektualną innych.
          Dlatego niezwłocznie rozpatrzy i zbada zawiadomienia o domniemanym
          naruszeniu oraz podejmie odpowiednie działania zgodnie z
          obowiązującymi przepisami dotyczącymi własności intelektualnej.
          Zawiadomienia należy składać na adres: biuro@investments4you.biz
          <br />
          2. Usługodawca uniemożliwi dostęp do Narzędzia internetowego dla
          Użytkowników, będącymi wielokrotnymi sprawcami naruszenia. Żaden zwrot
          pieniędzy nie zostanie przyznany.
          <br />
          <br />
          Wyłączenie z odpowiedzialności
          <br />
          <br />
          1. Użytkownik przyjmuje do wiadomości i zgadza się, że korzystanie z
          witryny internetowej i jej zawartości ponosi całkowicie na własne
          ryzyko.
          <br />
          2. Usługodawca nie składa żadnych oświadczeń i w najszerszym zakresie
          dozwolonym przez prawo zrzeka się wszelkich gwarancji, w tym między
          innymi gwarancji nienaruszania praw własności intelektualnej, innych
          praw własności oraz w odniesieniu do ważności informacji lub treści.
          <br />
          3. Ponadto Usługodawca nie udziela żadnych gwarancji za dokładność,
          rzetelność, kompletność i aktualność treści opublikowanych za pomocą
          Narzędzia internetowego.
        </Typography>
      </StyledBox>
    </Modal>
  );
};
