import { Box } from "components/Box";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import React, { useRef } from "react";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Button } from "../Button";
import { TextField } from "../TextField";

interface IShareAdvertisementModal {
  url: string;
  isOpen: boolean;
  onCancelClick: () => void;
}

const Content = styled.div`
  ${({ theme }) => `
     width: 554px;
     padding: ${theme.space[6]}px ${theme.space[4]}px;

     @media only screen and (max-width: 1000px) {
      width: 300px;
    }
  `};
`;

export const ShareAdvertisementModal: React.FC<IShareAdvertisementModal> = ({
  isOpen,
  url,
  onCancelClick,
}) => {
  const urlRef = useRef<any>(null);

  const copyUrl = () => {
    urlRef.current.select();
    document.execCommand("copy");
    toast.success(`Skopiowano!`);
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Content>
        <Box px={2}>
          <Typography variant="h1" color="primary" mb={4}>
            Udostępnij ofertę
          </Typography>
          <Typography variant="body" color="secondary" mb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            pulvinar, velit cursus ornare sollicitudin, metus mauris venenatis
            ex, sed accumsan lectus dui vitae purus.
          </Typography>
          <Row align="end">
            <Col>
              <TextField
                ref={urlRef}
                label="Link do udostępnienia"
                value={url}
              />
            </Col>
            <Col sm={3.5}>
              <Box m={2}>
                <Button
                  type="button"
                  label="Kopiuj link"
                  variant="secondary"
                  onClick={copyUrl}
                />
              </Box>
            </Col>
          </Row>
        </Box>
      </Content>
    </Modal>
  );
};
