interface IConditionalWrapper {
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
  children: JSX.Element;
}

export const ConditionalWrapper: React.FC<IConditionalWrapper> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);
