import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Typography } from "components";
import { Box } from "components/Box";
import { TextField } from "components/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { inviteUser } from "services/users";
import styled from "styled-components";
import * as yup from "yup";

const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

interface IInviteForm {
  email: string;
}

export const InviteUserPanel = () => {
  const { register, handleSubmit, errors, reset } = useForm<IInviteForm>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  const onSubmit = async (data: { email: string }) => {
    inviteUser(data.email)
      .then(() => {
        toast.success("Użytkownik został zaproszony");
      })
      .catch((error) => {
        toast.error(error.error);
      })
      .finally(() => {
        reset();
      });
  };

  return (
    <Card>
      <Box py={8} px={8}>
        <Typography variant="h2" color="primary">
          Zaproś znajomych do serwisu!
        </Typography>
        <Box mt={4} mb={5}>
          <StyledTypography variant="body" color="secondary">
            Wysyłaj do swoich znajomych wiadomości z zaproszeniem do serwisu i rozszerzaj swoją sieć kontaktów, 
            abyście razem mogli inwestować w nieruchomości i dzielić się przydatną wiedzą.
          </StyledTypography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            placeholder="Adres e-mail"
            name="email"
            error={errors.email && "Email jest nieprawidłowy"}
            ref={register}
          />
          <Box mt={4}>
            <Button type="submit" label="Zaproś" variant="primary" />
          </Box>
        </form>
      </Box>
    </Card>
  );
};
