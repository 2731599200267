import { Button, Typography } from "components";
import { Box } from "components/Box";
import React, { useState } from "react";

import { useProfile } from "../../../hooks/profile";
import { ButtonContainer } from "../ProfileInfo";
import { FirmDataForm } from "./FirmDataForm";

export const FirmDataView: React.FC = () => {
  const [variant, setVariant] = useState(0);
  const { user } = useProfile();

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2" color="primary">
          Dane firmy
        </Typography>
      </Box>
      {variant === 0 && (
        <>
          <Typography variant="body2" color="secondary">
            Nazwa firmy
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.company_name}
          </Typography>

          <Typography variant="body2" color="secondary">
            NIP
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.company_nip}
          </Typography>

          <Typography variant="body2" color="secondary">
            Regon
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.company_regon}
          </Typography>

          <Typography variant="body2" color="secondary">
            Adres
          </Typography>
          <Typography variant="h3" mb={6} fontWeight={400}>
            {`${user?.data?.profile?.company_address}, ${user?.data?.profile?.company_postal_code} ${user?.data?.profile?.company_city}`}
          </Typography>

          <ButtonContainer>
            <Button
              label="Edytuj"
              onClick={() => {
                setVariant(1);
              }}
            />
          </ButtonContainer>
        </>
      )}
      {variant === 1 && user && (
        <FirmDataForm currentUser={user.data} onCloseForm={setVariant} />
      )}
    </>
  );
};
