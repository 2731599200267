import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import ListIcon from "icons/confirmed_white.svg";
import React from "react";
import styled from "styled-components";

interface IJoinToService {
  goToLoginPage: () => void;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `}
`;
const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.white};
    margin-bottom: ${theme.space[3]}px;
  `}
`;
const List = styled.ul`
  ${({ theme }) => `
    list-style: none;
    padding: 0;
    margin-bottom: ${theme.space[11]}px;
  `}
`;
const Item = styled.li`
  ${({ theme }) => `
    position: relative;
    padding-left: ${theme.space[6]}px;  
  `}

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    background-image: url("${ListIcon}");
    width: 24px;
    height: 24px;
  }
`;

export const JoinToService: React.FC<IJoinToService> = ({ goToLoginPage }) => {
  return (
    <StyledCard>
      <Box px={8} pt={5} pb={8}>
        <StyledTypography variant="h2">Dołącz do serwisu!</StyledTypography>
        <StyledTypography variant="body">
          To wyjątkowe, dedykowane sprawdzonym inwestorom miejsce, pozwalające w
          sposób bezpieczny i wygodny wyszukiwać nowe obiekty i grunty, a
          następnie negocjować warunki współpracy.
        </StyledTypography>

        <StyledTypography variant="body">Jeżeli chcesz:</StyledTypography>
        <List>
          <Item>
            <StyledTypography variant="body">
              znaleźć lub zaoferować doskonałe miejsce do inwestowania;
            </StyledTypography>
          </Item>
          <Item>
            <StyledTypography variant="body">
              dołączyć do grona sprawdzonych partnerów;
            </StyledTypography>
          </Item>
          <Item>
            <StyledTypography variant="body">
              w nowoczesny i bezpieczny sposób prowadzić negocjacje biznesowe.
              To miejsce jest dla Ciebie!
            </StyledTypography>
          </Item>
        </List>
        <Button
          label="Zarejestruj się w Investments4you."
          variant="secondary"
          fullWidth
          onClick={goToLoginPage}
        />
      </Box>
    </StyledCard>
  );
};
