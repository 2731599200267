import {
  CONTACT_INVITATION_URL,
  CONTACT_INVITATIONS_URL,
  CONTACTS_URL,
  RECOMMENDED_CONTACTS_URL,
} from "constants/paths";
import qs from "qs";
import { IContact } from "types/contact";
import { IPagination } from "types/pagination";
import { fetchAPI } from "utilities/fetchAPI";

export interface IFetchContactsResponse {
  pagination: IPagination;
  data: Array<{
    contact: IContact;
  }>;
}

interface IFetchContacts {
  page?: number;
  user_id?: number;
}

export const fetchContacts = async ({ user_id, page }: IFetchContacts) => {
  const query = qs.stringify({ user_id, page });

  const response = await fetchAPI<IFetchContactsResponse>(
    `${CONTACTS_URL}?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

interface IFetchRecommendedContacts {
  scope: string;
  page?: number;
}

export interface IFetchRecommendedContactsResponse {
  pagination: IPagination;
  data: Array<IContact>;
}

export const fetchRecommendedContacts = async ({
  scope,
  page,
}: IFetchRecommendedContacts) => {
  const query = qs.stringify({
    scope,
    page,
  });

  const path = `${RECOMMENDED_CONTACTS_URL}?${query}`;

  const response = await fetchAPI<IFetchRecommendedContactsResponse>(path, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

export interface IFetchContactInvitationsResponse {
  pagination: {
    current: number;
    previous: null;
    next: null;
    per_page: number;
    pages: number;
    count: number;
  };
  data: Array<{
    id: number;
    state: "created";
    created_at: null;
    sender: IContact;
  }>;
}

export const fetchContactIvitiations = async ({ page }: { page: number }) => {
  const query = qs.stringify({
    page,
  });

  const path = `${CONTACT_INVITATIONS_URL}?${query}`;

  const response = await fetchAPI<IFetchContactInvitationsResponse>(path, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

export interface ISendInvitationToUserResponse {
  data: {
    id: number;
    sender_id: number;
    recipient_id: number;
    state: "created";
  };
}

export const sendInvitationToUser = async (userId: number) => {
  const response = await fetchAPI<ISendInvitationToUserResponse>(
    CONTACT_INVITATIONS_URL,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        invitation: {
          recipient_id: userId,
        },
      }),
    }
  );

  return response;
};

interface IUpdateInvitation {
  id: number;
  recipientId: number;
  state: "created" | "accepted" | "rejected" | "cancelled";
}

export const updateInvitation = async ({
  id,
  state,
  recipientId,
}: IUpdateInvitation) => {
  const response = await fetchAPI<ISendInvitationToUserResponse>(
    `${CONTACT_INVITATION_URL}/${id}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        invitation: {
          recipient_id: recipientId,
          state,
        },
      }),
    }
  );

  return response;
};
