import { ReactComponent as PhotoIcon } from "icons/photo.svg";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { arrayBufferToBase64 } from "utilities/arrayBufferToBase64";
import { rgba } from "utilities/rgba";

export interface IFileUpload {
  onFile?: (files: Array<File>) => any;
}

const Wrapper = styled.div`
  ${({ theme }) => `
  width: 100%;
  padding: ${theme.space[16]}px;
  background-color: ${theme.palette.neutral.veryLight};
  display: flex;
  border: dashed 1px #B7CBCF;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  & svg {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    margin-bottom: ${theme.space[2]}px;
  }
`}
`;

const PreviewArea = styled.div`
  ${({ theme }) => `
  margin-top: ${theme.space[4]}px;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
  column-gap: ${theme.space[4]}px;
  row-gap: ${theme.space[4]}px;
  
  `}
`;

const ThumbnailContainer = styled.div`
  position: relative;
`;

const Thumbnail = styled.img`
  height: clamp(72px, 15vw, 200px);
  width: 100%;
  background-color: lightgray;
  border-radius: 4px;
  margin: 0 auto;
  object-fit: cover;
`;

const Curtain = styled.div`
  ${({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${rgba(theme.palette.neutral.dark, 0.7)};
  opacity: 0;
  transition: 0.2s;
  border-radius: 4px;
  &:hover {
    opacity: 1;
  }

`}
`;

const DeleteButton = styled.div`
  ${({ theme }) => `
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: black;
  border: 2px solid ${theme.palette.neutral.white};
  border-radius: 50%;
  cursor: pointer;
  color: ${theme.palette.neutral.white};
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
`}
`;

export const FileUpload: React.FC<IFileUpload> = ({ onFile }) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [thumbnails, setThumbnails] = useState<Array<string>>([]);

  useEffect(() => {
    if (onFile) onFile(files);
  }, [files]);

  const handleDrop = (acceptedFiles: Array<File>) => {
    const thumbnailBuffer: Array<string> = [];
    acceptedFiles.forEach((file, index) => {
      file.arrayBuffer().then((result) => {
        thumbnailBuffer.push(arrayBufferToBase64(result));
        if (index + 1 === acceptedFiles.length)
          setThumbnails([...thumbnails, ...thumbnailBuffer]);
      });
    });
    setFiles([...files, ...acceptedFiles]);
  };

  const handleDelete = (index: number) => {
    const newThumbs = Array.from(thumbnails);
    const newFiles = Array.from(files);
    newThumbs.splice(index, 1);
    newFiles.splice(index, 1);
    setThumbnails(newThumbs);
    setFiles(newFiles);
  };

  return (
    <>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <Wrapper {...getRootProps()}>
            <PhotoIcon />
            Przeciągnij pliki ze zdjęciami tutaj
            <input
              {...getInputProps()}
              type="file"
              multiple
              accept="image/png, image/jpeg"
            />
          </Wrapper>
        )}
      </Dropzone>
      <PreviewArea>
        {files.map((_, index) => (
          <ThumbnailContainer>
            <Thumbnail src={`data:image/jpg;base64,${thumbnails[index]}`} />
            <Curtain>
              <DeleteButton onClick={() => handleDelete(index)}>x</DeleteButton>
            </Curtain>
          </ThumbnailContainer>
        ))}
      </PreviewArea>
    </>
  );
};
