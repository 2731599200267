import { AdvertDataType } from "components/AddAdvertisementModal/types";
import { IGridItem } from "components/InfoGrid";
import { categoryTypeOptions } from "constants/advertisementHelpers";
import { voivodships } from "database/voivodship";
import React from "react";
import NumberFormat from "react-number-format";
import {
  IFetchAdvertisementResponse,
  IFetchPublicAdvertisementResponse,
} from "services/advertisements";
import { AdvertisementCategoryType } from "types/advertisement";

const getTranslatedObjectCategoryType = (
  category: AdvertisementCategoryType
) => {
  const item = categoryTypeOptions.find((el) => el.value === category);
  return !item ? "Inne" : item.label;
};

export const generateAdvertisamentsArrayData = (
  type: string,
  data:
    | IFetchAdvertisementResponse["data"]
    | IFetchPublicAdvertisementResponse["data"]
    | AdvertDataType
): Array<IGridItem> => {
  switch (type) {
    case "apartment":
      return [
        {
          label: "Typ obiektu:",
          content: getTranslatedObjectCategoryType(data.category),
        },
        {
          label: "Cena:",
          content: (
            <NumberFormat
              value={data.price}
              displayType="text"
              thousandSeparator=" "
              suffix=" PLN"
            />
          ),
        },
        { label: "Rok budowy:", content: data.year_of_construction },
        {
          label: "Ilość pokoi:",
          content: data.number_of_apartments,
        },
        { label: "Powierzchnia:", content: `${data.area} m²` },
        { label: "Piętro:", content: data.floor },
        {
          label: "Ilość mieszkań na piętrze:",
          content: data.number_of_apartments_on_floor,
        },
        {
          label: "Stan prawny obiektu:",
          content: data.legal_status_of_the_facility,
        },
        {
          label: "Województwo:",
          content: voivodships.find((voiv) => voiv.value === data.voivodship)
            ?.label,
        },
        {
          label: "Miasto:",
          content: data.city ? data.city : "-",
        },
        {
          label: "ROI:",
          content: data.roi,
          tooltip: data.description_of_the_roi_calculation_method,
        },
      ];
    case "tenement_house":
      return [
        {
          label: "Typ obiektu:",
          content: getTranslatedObjectCategoryType(data.category),
        },
        {
          label: "Cena:",
          content: (
            <NumberFormat
              value={data.price}
              displayType="text"
              thousandSeparator=" "
              suffix=" PLN"
            />
          ),
        },
        { label: "Rok budowy:", content: data.year_of_construction },
        { label: "Powierzchnia budynku:", content: `${data.area} m²` },
        { label: "Ilość pięter:", content: data.number_of_floors },
        { label: "Ilość mieszkań:", content: data.number_of_apartments },
        {
          label: "Ilość mieszań na piętrze:",
          content: data.number_of_apartments_on_floor,
        },
        {
          label: "Powierzchnia mieszkań od:",
          content: data.min_area_of_apartments,
        },
        {
          label: "Powierzchnia mieszkań do:",
          content: data.max_area_of_apartments,
        },
        {
          label: "Stan prawny obiektu:",
          content: data.legal_status_of_the_facility,
        },
        {
          label: "Województwo:",
          content: voivodships.find((voiv) => voiv.value === data.voivodship)
            ?.label,
        },
        {
          label: "Miasto:",
          content: data.city ? data.city : "-",
        },
        {
          label: "ROI:",
          content: data.roi,
          tooltip: data.description_of_the_roi_calculation_method,
        },
      ];
    case "service_premises":
      return [
        {
          label: "Typ obiektu:",
          content: getTranslatedObjectCategoryType(data.category),
        },
        {
          label: "Cena:",
          content: (
            <NumberFormat
              value={data.price}
              displayType="text"
              thousandSeparator=" "
              suffix=" PLN"
            />
          ),
        },
        { label: "Powierzchnia:", content: `${data.area} m²` },
        {
          label: "Wymiary",
          content: `${data.dimension_x}x${data.dimension_y}`,
        },
        {
          label: "Stan prawny obiektu:",
          content: data.legal_status_of_the_facility,
        },
        {
          label: "Województwo:",
          content: voivodships.find((voiv) => voiv.value === data.voivodship)
            ?.label,
        },
        {
          label: "Miasto:",
          content: data.city ? data.city : "-",
        },
        {
          label: "ROI:",
          content: data.roi,
          tooltip: data.description_of_the_roi_calculation_method,
        },
      ];
    case "warehouse":
      return [
        {
          label: "Typ obiektu:",
          content: getTranslatedObjectCategoryType(data.category),
        },
        {
          label: "Cena:",
          content: (
            <NumberFormat
              value={data.price}
              displayType="text"
              thousandSeparator=" "
              suffix=" PLN"
            />
          ),
        },
        { label: "Powierzchnia:", content: `${data.area} m²` },
        {
          label: "Wymiary",
          content: `${data.dimension_x}x${data.dimension_y}`,
        },
        {
          label: "Województwo:",
          content: voivodships.find((voiv) => voiv.value === data.voivodship)
            ?.label,
        },
        {
          label: "Miasto:",
          content: data.city ? data.city : "-",
        },
        {
          label: "Stan prawny obiektu:",
          content: data.legal_status_of_the_facility,
        },
        {
          label: "ROI:",
          content: data.roi,
          tooltip: data.description_of_the_roi_calculation_method,
        },
      ];
    case "plot":
      return [
        {
          label: "Typ obiektu:",
          content: getTranslatedObjectCategoryType(data.category),
        },
        {
          label: "Cena:",
          content: (
            <NumberFormat
              value={data.price}
              displayType="text"
              thousandSeparator=" "
              suffix=" PLN"
            />
          ),
        },
        { label: "Powierzchnia:", content: `${data.area} m²` },
        {
          label: "Istniejąca dokumentacja:",
          content: data.development_plan ? "Tak" : "Nie",
        },
        { label: "Woda:", content: data.water ? "Tak" : "Nie" },
        { label: "Gas:", content: data.gas ? "Tak" : "Nie" },
        { label: "Prąd:", content: data.electricity ? "Tak" : "Nie" },
        { label: "Kanalizacja:", content: data.canalization ? "Tak" : "Nie" },
        {
          label: "Stan prawny obiektu:",
          content: data.legal_status_of_the_facility,
        },
        {
          label: "Województwo:",
          content: voivodships.find((voiv) => voiv.value === data.voivodship)
            ?.label,
        },
        {
          label: "Miasto:",
          content: data.city ? data.city : "-",
        },
        {
          label: "ROI:",
          content: data.roi,
          tooltip: data.description_of_the_roi_calculation_method,
        },
      ];
    default:
      return [];
  }
};
