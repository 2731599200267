import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const numberRegExp = /^^[0-9]*$/;

export const profilePersonalDataSchema = yup.object().shape({
  /* eslint-disable camelcase */
  first_name: yup.string().required("Podaj imię"),
  last_name: yup.string().required("Podaj nazwisko"),
  address: yup.string().required("Podaj adres"),
  city: yup.string().required("Podaj miejscowość"),
  postal_code: yup.string().required("Podaj kod pocztowy"),
  telephone_number: yup
    .string()
    .required("Podaj numer telefonu")
    .matches(phoneRegExp, "Podaj prawidłowy numer telefonu"),
  description: yup.string(),
  /* eslint-enable camelcase */
});

export const profileFirmDataSchema = yup.object().shape({
  /* eslint-disable camelcase */
  company_name: yup.string().required("Podaj nazwę firmy"),
  company_nip: yup
    .string()
    .required("Podaj NIP")
    .matches(numberRegExp, "podaj poprawny NIP"),
  company_regon: yup
    .string()
    .required("Podaj regon")
    .matches(numberRegExp, "podaj poprawny regon"),
  company_address: yup.string().required("Podaj adres"),
  company_city: yup.string().required("Podaj miejscowość"),
  company_postal_code: yup.string().required("Podaj kod pocztowy"),
  /* eslint-enable camelcase */
});

export const profileExperienceAndSpecializationSchema = yup.object().shape({
  /* eslint-disable camelcase */
  real_estate_experience_in_months: yup.number(),
  real_estate_activity_cities: yup.array().of(yup.string()).required().min(1),
  investments_specialization: yup.array().of(yup.string()).required().min(1),
  investments_levels: yup.array().of(yup.string()).required().min(1),
  /* eslint-enable camelcase */
});

export const profileExpectationsAndPreferencesSchema = yup.object().shape({
  /* eslint-disable camelcase */
  looking_for_single_investor: yup.boolean(),
  looking_for_group_of_investors: yup.boolean(),
  looking_for_single_investor_investments: yup.boolean(),
  looking_for_group_investors_investments: yup.boolean(),
  looking_for_network_of_contacts: yup.boolean(),
  specialized_in_ready_investments: yup.boolean(),
  specialized_in_rebuilding_investments: yup.boolean(),
  the_most_important_is_price: yup.boolean(),
  the_most_important_is_location: yup.boolean(),
  the_most_important_is_year_of_construction: yup.boolean(),
  the_most_important_is_area: yup.boolean(),
  the_most_important_is_roi: yup.boolean(),
  the_most_important_are_photos: yup.boolean(),
  networking_for_scaling_through_regions: yup.boolean(),
  networking_for_creating_a_bigger_investments: yup.boolean(),
  networking_for_different_reason: yup.boolean(),
  /* eslint-enable camelcase */
});
