import actioncable from "actioncable";

const API_WS_URL = process.env.REACT_APP_WS_API_URL;

const getWebSocketURL = () => {
  const user_token = localStorage.getItem("persist:token")!;
  const { token } = JSON.parse(user_token);
  const url = `${API_WS_URL}${token.substring(1, token.length - 1)}`;

  return url;
};

export const createConsumer = () =>
  actioncable.createConsumer(getWebSocketURL());
