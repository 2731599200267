import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import React from "react";
import styled from "styled-components";

export type IGridItem = {
  label: string;
  content: React.ReactNode;
  tooltip?: string;
};

interface IInfoGrid {
  data: Array<IGridItem>;
}

const Wrapper = styled(Box)`
  ${({ theme }) => `
        display: grid;
        grid-template: auto / 1fr 1fr 1fr;
        border-radius: 8px;
        background-color: ${theme.palette.neutral.veryLight};
        row-gap: ${theme.space[4]}px;
        column-gap: ${theme.space[4]}px;

        @media only screen and (max-width: 1000px) {
          grid-template: auto / 1fr 1fr;
        }
    `}
`;
const Cell = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tooltip = styled.div<{ content?: string }>`
  ${({ theme, content }) => `
      position: absolute;
      display: inline;
      transform: translateY(-15%);
      cursor: pointer;
      &::after {
        content: "${content}";
        position: absolute;
        bottom: calc(100% + 5px);
        transform: translateX(-55%);
        width: 200px;
        color: ${theme.palette.neutral.white};
        padding: ${theme.space[4]}px;
        border-radius: 10px;
        background-color: ${theme.palette.neutral.dark};
        transition: 0.3s;
        opacity: 0;
        pointer-events: none;
      }
      &:hover::after, &:hover::before {
        opacity: 1;
      }
      &::before {
        bottom: 100%;
        transform: translateX(70%);
        content: '';
        position: absolute;
        opacity: 0;
        transition: 0.3s;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${theme.palette.neutral.dark};
        pointer-events: none;
      }
`}
`;

export const InfoGrid: React.FC<IInfoGrid> = ({ data }) => {
  return (
    <Wrapper p={8}>
      {data.map((cell: IGridItem) => (
        <Cell key={cell.label}>
          <Typography variant="body2">
            {cell.label}
            {cell.tooltip && (
              <Tooltip content={cell.tooltip}>
                <InfoIcon />
              </Tooltip>
            )}
          </Typography>
          <Typography variant="h3">{cell.content}</Typography>
        </Cell>
      ))}
    </Wrapper>
  );
};
