import { Avatar, AvatarI } from "components/Avatar/Avatar";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Typography, TypographyType } from "components/Typography/Typography";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";

interface IProfile {
  first_name: string;
  last_name: string;
}
interface IRelatedUser {
  avatar: string;
  id: number;
  profile: IProfile;
}
export interface INotification {
  created_at: string;
  message: string;
  related_user: IRelatedUser;
}

const Description = styled(Typography)`
  font-size: 14px;
`;

const Date = styled(Typography).attrs({
  variant: "body2" as TypographyType["variant"],
})`
  margin-left: auto;
`;

export const Notification: React.FC<INotification> = ({
  related_user,
  created_at,
  message,
}) => (
  <Card>
    <Box p={6}>
      <Flex>
        <Avatar size="small" url={related_user?.avatar}/>
        <Box mx={2} flex="1">
          <Flex alignItems="center" flex={1}>
            <Typography variant="body" mb={1} fontWeight={600}>
              {related_user?.profile?.first_name}{" "}
              {related_user?.profile?.last_name}
            </Typography>
            <Date>
              <Moment locale="pl" fromNow>
                {created_at}
              </Moment>
            </Date>
          </Flex>
          <Description variant="body">{message}</Description>
        </Box>
      </Flex>
    </Box>
  </Card>
);
