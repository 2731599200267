import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Proszę podać prawidłowy adres email.")
    .required("Proszę podać adres email."),
  password: yup
    .string()
    .min(6, "Hasło musi zawierać min 6 znaków.")
    .max(128, "Hasło musi zawierać max 128 znaków.")
    .required("Proszę podać hasło."),
});
