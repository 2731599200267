import {
  ADD_ADVERTISEMENT,
  AdvertisementsActionTypes,
  GET_ADVERTISEMENTS,
  IAdvertisementsState,
} from "./types";

const initialState: IAdvertisementsState = {
  pagination: {
    /* eslint-disable camelcase */
    current: 0,
    previous: null,
    next: null,
    per_page: 0,
    pages: 0,
    count: 0,
    /* eslint-enable camelcase */
  },
  data: [],
};

export const advertisements = (
  state = initialState,
  action: AdvertisementsActionTypes
): IAdvertisementsState => {
  switch (action.type) {
    case GET_ADVERTISEMENTS:
      return action.payload;
    case ADD_ADVERTISEMENT: {
      const stateData = [...state.data]
      if (state.data.length > 14) stateData.pop()
      return {
        ...state,
        data: [action.payload.data, ...stateData],
      };
    }
    default:
      return state;
  }
};
