import { Button, Typography } from "components";
import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import { Radio } from "components/Radio";
import { Select } from "components/Select";
import { TextField } from "components/TextField";
import {
  investmentsLevelsFields,
  investmentsSpecializationsFields,
  realEstateExperienceInMonthsFields,
} from "constants/profileFormFields";
import { cities } from "database/cities";
import React from "react";
import { useForm } from "react-hook-form";

interface IProfileFormSecondStep {
  defaultValues: IProfileFormSecondStepData;
  onSubmit: (data: IProfileFormSecondStepData) => any;
  onBackClick: () => void;
}

export interface IProfileFormSecondStepData {
  /* eslint-disable camelcase */
  real_estate_experience_in_months?: number;
  real_estate_activity_cities: string;
  investments_specialization: Array<string>;
  investments_levels: Array<string>;
  /* eslint-enable camelcase */
}

export const ProfileFormSecondStep: React.FC<IProfileFormSecondStep> = ({
  onSubmit: onSubmitCallback,
  onBackClick,
  defaultValues,
}) => {
  const { register, handleSubmit } = useForm<IProfileFormSecondStepData>({
    defaultValues,
  });

  const onSubmit = async (data: IProfileFormSecondStepData) => {
    onSubmitCallback(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" color="primary" mb={2}>
        Doświadczenie i specjalizacja
      </Typography>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Jak długo zajmujesz się nieruchomościami
        </Typography>
        {realEstateExperienceInMonthsFields.map(({ label, value }) => (
          <Box mb={2} key={value}>
            <Radio
              name="real_estate_experience_in_months"
              value={value}
              label={label}
              ref={register}
            />
          </Box>
        ))}
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Miasta, w których działasz na rynku nieruchomości:
        </Typography>
        {/* <TextField name="real_estate_activity_cities" ref={register} /> */}
        <Select
          multi
          search
          label="Wyszukaj miasto"
          selected={[]}
          // todo ask about defaultValues instead of currentUser && ask about async setState
          options={cities}
          name="real_estate_activity_cities"
          ref={register}
        />
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          W jakiego rodzaju inwestycjach się specjalizujesz?
        </Typography>
        {investmentsSpecializationsFields.map(({ label, value }) => (
          <Box mb={2} key={value}>
            <Checkbox
              label={label}
              value={value}
              name="investments_specialization"
              ref={register}
            />
          </Box>
        ))}
      </Box>
      <Box mb={6}>
        <Typography variant="h3" mb={3}>
          Na jakim poziomie inwestycji działasz?
        </Typography>
        {investmentsLevelsFields.map(({ label, value }) => (
          <Box mb={2} key={value}>
            <Checkbox
              label={label}
              value={value}
              name="investments_levels"
              ref={register}
            />
          </Box>
        ))}
      </Box>
      <Flex justifyContent="space-between" mt={6}>
        <Button
          type="button"
          onClick={onBackClick}
          variant="secondary"
          label="Wstecz"
        />
        <Button type="submit" label="Następny krok" />
      </Flex>
    </form>
  );
};
