import { ILoginUserResponse, IRegisterUserResponse } from "services/auth";
import { IUserWithToken } from "types/user";

export const parseLoginUserResponse = (
  user: ILoginUserResponse
): IUserWithToken => {
  const { data, token } = user;

  return {
    ...data,
    token,
  };
};

export const parseRegisterUserResponse = (
  user: IRegisterUserResponse
): IUserWithToken => {
  const { data, token } = user;

  return {
    ...data,
    token,
  };
};
