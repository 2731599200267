import { IUsersListItem } from "components/UsersList/UsersList";
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from "history";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchContacts,
  fetchRecommendedContacts,
  sendInvitationToUser,
} from "services/contacts";
import { IContact } from "types/contact";
import { IPagination } from "types/pagination";
import { goToUserInfo } from "utilities/goToUserInfoHelper";

const parseContacts = (
  contacts: Array<IContact>,
  history?: History
): Array<IUsersListItem> => {
  return contacts.map(
  ({ id, first_name, last_name, mutual_contacts_count, avatar }) => ({
      id,
      user: {
        id,
        firstName: first_name,
        lastName: last_name,
        avatar,
        description: `${mutual_contacts_count} wspólnych kontaktów`,
        goToUserInfo: history ? goToUserInfo(history) : undefined,
      },
    })
  );
};

interface IUseContacts {
  scope?: "bigger_investments" | "scaling" | "mutual_contacts";
  filterQuery?: string;
  userId?: number;
}

export const useContacts = ({ scope, filterQuery, userId }: IUseContacts) => {
  const history = useHistory();
  const [contacts, setContacts] = useState<Array<IUsersListItem>>([]);
  const [pagination, setPagination] = useState<IPagination>({
    current: 0,
    previous: null,
    next: null,
    per_page: 0,
    pages: 0,
    count: 0,
  });

  const retrieveContacts = async ({ page }: { page: number }) => {
    try {
      if (scope) {
        const response = await fetchRecommendedContacts({ scope, page });
        setContacts(parseContacts(response.data, history));

        setPagination(response.pagination);
      } else {
        const response = await fetchContacts({ user_id: userId, page });
        const contactsData = response.data.map(({ contact }) => contact);
        setContacts(parseContacts(contactsData, history));
        setPagination(response.pagination);
      }
    } catch (error) {
      toast.error("Nie udało się pobrać kontaktów.");
    }
  };

  const handleAddUserClick = async (contact: IUsersListItem) => {
    try {
      await sendInvitationToUser(contact.id);
      setContacts((prevState) =>
        prevState.filter(({ id }) => {
          return contact.id !== id;
        })
      );
      toast.success(
        `Zaprosiłeś użytkownika ${contact.user.firstName} ${contact.user.lastName} do kontaktów.`
      );
    } catch (error) {
      toast.error("Nie udało się wysłać zaproszenia do kontaktów.");
    }
  };

  const con = filterQuery
    ? contacts.filter((contact) => {
        const fullName = [contact.user.firstName, contact.user.lastName].join(
          " "
        );
        const query = filterQuery.toLowerCase();

        return fullName.toLowerCase().includes(query);
      })
    : contacts;

  return {
    contacts: con,
    pagination,
    handleAddUserClick,
    retrieveContacts,
  };
};
