import { Typography } from "components/Typography";
import { ReactComponent as CheckmarkIcon } from "icons/checkmark.svg";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

export interface CheckboxI extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
}

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const Control = styled.div`
  ${({ theme }) => `
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid ${theme.palette.neutral.medium};
    border-radius: 4px;
    position: absolute;
    top: 3px;
  `}
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  ${({ theme }) => `
    display: none;
    position: absolute;
    top: 5px;
    left: 4px;
    fill: ${theme.palette.neutral.white};
  `}
`;
const StyledTypography = styled(Typography)`
  padding-left: 25px;
`;
interface IInput {
  error?: CheckboxI["error"];
}

const Input = styled.input<IInput>`
  ${({ theme, error }) => `
    position: relative;
    opacity: 0;
    width: 16px;
    height: 16px;
    position: absolute;

    + ${Control} {
      ${error ? `border-color: ${theme.palette.accent.red}` : ""};
    }

    &:checked {
      + ${Control} {
        background-color: ${theme.palette.primary.main};
        border-color: ${
          error ? theme.palette.accent.red : theme.palette.primary.main
        };

        ${StyledCheckmarkIcon} {
          display: block;
        }
      }
    }

    &:disabled {
      + ${Control} {
        opacity: 0.4;

        background-color: ${theme.palette.neutral.medium};
        border-color: ${theme.palette.neutral.medium};
      }
    }
  `}
`;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxI>(
  ({ label, error, ...props }, ref) => (
    <Label>
      <Input type="checkbox" error={error} {...props} ref={ref} />
      <Control>
        <StyledCheckmarkIcon />
      </Control>
      <StyledTypography variant="body">{label}</StyledTypography>
    </Label>
  )
);
