import { Avatar } from "components/Avatar/Avatar";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Typography, TypographyType } from "components/Typography/Typography";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { INotification } from "types/notifications";

interface ILatestNotifications {
  notifications?: INotification[];
  goToNotificationsPage: () => void;
  notificationRedirect: (path: string) => void;
}

const Title = styled(Typography).attrs({
  variant: "h2",
  color: "primary",
})`
  ${({ theme }) => `
    padding: 0px ${theme.space[3]}px;
  `}
  margin-bottom: 10px;
`;
const Description = styled(Typography)`
  font-size: 14px;
`;
const Date = styled(Typography).attrs({
  variant: "body2" as TypographyType["variant"],
})`
  margin-left: auto;
`;
const StyledFlex = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};

    &:hover {
      background-color: ${theme.palette.neutral.veryLight};
    }
  `}
`;

export const LatestNotifications: React.FC<ILatestNotifications> = ({
  notifications,
  goToNotificationsPage,
  notificationRedirect,
}) => {
  return (
    <Card>
      <Box px={6} py={5}>
        <Title>Ostatnie powiadomienia</Title>
        {notifications &&
          notifications.map((notification: INotification) => (
            <StyledFlex
              onClick={() => {
                notificationRedirect(notification.front_path);
              }}
              py={2}
              px={3}
            >
              <Avatar size="small" url={notification?.related_user?.avatar}/>
              <Box mx={2} flex="1">
                <Flex alignItems="center" flex={1}>
                  <Typography variant="h3" mb={1} fontWeight={600}>
                    {notification?.related_user?.profile.first_name}{" "}
                    {notification?.related_user?.profile.last_name}
                  </Typography>
                  <Date>
                    <Moment locale="pl" fromNow>
                      {notification?.created_at}
                    </Moment>
                  </Date>
                </Flex>
                <Description variant="body">
                  {notification?.message}
                </Description>
              </Box>
            </StyledFlex>
          ))}
        <Box px={3} mt={2}>
          <Button
            onClick={goToNotificationsPage}
            label="Zobacz więcej"
            variant="secondary"
          />
        </Box>
      </Box>
    </Card>
  );
};
