import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface IUsePagination {
  updateFn: (page: number) => any;
  paginatedElRef: React.RefObject<any>;
}

export const usePagination = ({ updateFn, paginatedElRef }: IUsePagination) => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState<number | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const pageNumberString = urlParams.get("page");
    const pageNumber = parseInt(pageNumberString ?? "1", 10);

    setPage(pageNumber);
  }, []);

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);

    if (paginatedElRef.current !== null) {
      paginatedElRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (page) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page.toString());
      history.replace(`?${queryParams.toString()}`);

      updateFn(page);
    }
  }, [page]);

  return {
    handleChangePage,
  };
};
