import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "components";
import { Box } from "components/Box";
import {
  lookingForFields,
  mostImportantFields,
  networkingForFields,
  specializedInFields,
} from "constants/profileFormFields";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IUser } from "types/user";

import { Checkbox } from "../../../components/Checkbox";
import { profileExpectationsAndPreferencesSchema } from "../../../schemas/profileSettings";
import { updatePreferencesAction } from "../../../store/currentUser/actions";
import { StateI } from "../../../store/currentUser/types";
import { ButtonContainer, FormContainer } from "../ProfileInfo";

interface IFormData {
  /* eslint-disable camelcase */
  looking_for_single_investor: boolean;
  looking_for_group_of_investors: boolean;
  looking_for_single_investor_investments: boolean;
  looking_for_group_investors_investments: boolean;
  looking_for_network_of_contacts: boolean;
  specialized_in_ready_investments: boolean;
  specialized_in_rebuilding_investments: boolean;
  the_most_important_is_price: boolean;
  the_most_important_is_location: boolean;
  the_most_important_is_year_of_construction: boolean;
  the_most_important_is_area: boolean;
  the_most_important_is_roi: boolean;
  the_most_important_are_photos: boolean;
  networking_for_scaling_through_regions: boolean;
  networking_for_creating_a_bigger_investments: boolean;
  networking_for_different_reason: boolean;
  /* eslint-enable camelcase */
}

interface IExperienceAndSpecializationForm {
  currentUser: IUser;
  onCloseForm: (val: number) => void;
}

export const ExpectationsAndPreferencesForm: React.FC<IExperienceAndSpecializationForm> =
  ({ currentUser, onCloseForm }) => {
    const dispatch = useDispatch();

    const [defaultValues, setDefaultValues] = useState<IFormData>({
      looking_for_single_investor: false,
      looking_for_group_of_investors: false,
      looking_for_single_investor_investments: false,
      looking_for_group_investors_investments: false,
      looking_for_network_of_contacts: false,
      specialized_in_ready_investments: false,
      specialized_in_rebuilding_investments: false,
      the_most_important_is_price: false,
      the_most_important_is_location: false,
      the_most_important_is_year_of_construction: false,
      the_most_important_is_area: false,
      the_most_important_is_roi: false,
      the_most_important_are_photos: false,
      networking_for_scaling_through_regions: false,
      networking_for_creating_a_bigger_investments: false,
      networking_for_different_reason: false,
    });

    const { register, handleSubmit, reset } = useForm<IFormData>({
      defaultValues,
      resolver: yupResolver(profileExpectationsAndPreferencesSchema),
    });

    const onSubmit = async (data: IFormData) => {
      setDefaultValues({ ...defaultValues, ...data });
      dispatch(updatePreferencesAction(data));
      onCloseForm(0);
    };

    useEffect(() => {
      setDefaultValues({ ...defaultValues, ...currentUser?.preference });
    }, [currentUser]);
    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues]);

    return (
      <>
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={6}>
              <Typography variant="h3" mb={3}>
                Do czego potrzebna jest Tobie nasza platforma?
              </Typography>
              {lookingForFields.map(({ label, value }) => (
                <Box key={value} mb={2}>
                  <Checkbox ref={register} name={value} label={label} />
                </Box>
              ))}
            </Box>
            <Box mb={6}>
              <Typography variant="h3" mb={3}>
                W jakiego rodzaju inwestycjach się specjalizujesz?
              </Typography>
              {specializedInFields.map(({ label, value }) => (
                <Box key={value} mb={2}>
                  <Checkbox ref={register} label={label} name={value} />
                </Box>
              ))}
            </Box>
            <Box mb={6}>
              <Typography variant="h3" mb={3}>
                Co jest dla Ciebie najważniejsze w ogłoszeniach inwestycyjnych?
              </Typography>
              {mostImportantFields.map(({ label, value }) => (
                <Box key={value} mb={2}>
                  <Checkbox ref={register} label={label} name={value} />
                </Box>
              ))}
            </Box>
            <Box mb={6}>
              <Typography variant="h3" mb={3}>
                Czego oczekujesz od sieci znajomych inwestorów?
              </Typography>
              {networkingForFields.map(({ label, value }) => (
                <Box key={value} mb={2}>
                  <Checkbox ref={register} label={label} name={value} />
                </Box>
              ))}
            </Box>

            <Box mt={6}>
              <ButtonContainer>
                <Button type="submit" label="Zapisz" />
                <Button
                  onClick={() => {
                    onCloseForm(0);
                  }}
                  label="Anuluj"
                  variant="secondary"
                />
              </ButtonContainer>
            </Box>
          </form>
        </FormContainer>
      </>
    );
  };
