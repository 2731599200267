import { useEffect, useRef, useState } from "react";

export const useDropdown = (initialIsVisible: boolean) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const buttonRef = useRef<any>(null);
  const optionsRef = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (!optionsRef.current) return;

    if (
      !optionsRef.current?.contains(event.target) &&
      !buttonRef.current?.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  const handleButtonClick = (event: MouseEvent) => {
    if (!buttonRef.current) return;

    if (buttonRef.current?.contains(event.target)) {
      setIsVisible((prevState) => !prevState);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("click", handleButtonClick, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("click", handleButtonClick, true);
    };
  }, []);

  return { buttonRef, optionsRef, isVisible, setIsVisible };
};
