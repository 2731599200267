import { Flex } from "components/Flex";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const StyledFooter = styled.footer`
width: 100%;
height: min-content;
background-color: #fff;
padding: 0 5vw;
padding-top: 5px;
position: relative;
bottom: 0;
left: 0;
display: flex;
`;
const Container = styled(Flex)`
flex-wrap: wrap;
justify-content: space-between;
align-items: start;
position: relative;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #fff;
color: #545d61;
`;
const ContactContainer = styled(Flex)`
position: relative;
flex-direction: column;
min-width: 100px;
width: 25%;
background-color: #fff;
justify-content: center;
max-width: 500px;
`;
const FooterHeader = styled.h4`
color: #007cfb;
font-size: 1.1em;
`;
const EmailLink = styled.a`
color: #545d61;
text-decoration: none;
`;
const ParpLogo = styled.img`
height: 10%;
width: 100%;
float: left;
max-width: 500px;
margin-top: 35px;
`;
export const Footer = () => {
  const history = useHistory();
  const onParpLogoClick = () => {
    history.push("/parp-info");
  }
  return (
    <StyledFooter>
      <Container>
        <ContactContainer>
          <FooterHeader>Kontakt</FooterHeader>
          <EmailLink href="mailto:biuro@investments4you.biz">
            email: biuro@investments4you.pl
          </EmailLink>
          <p>tel: 531 212 349 </p>
        </ContactContainer>
        <ContactContainer>
          <FooterHeader>Zgłoś problem</FooterHeader>
          <EmailLink href="https://forms.gle/cEqLJiaqdmKU1LQP9"  target="blank">
            Kliknij, aby zgłosić problem.
          </EmailLink>
          <p><br/></p>
        </ContactContainer>
        <ParpLogo src="https://www.parp.gov.pl/templates/clean-bootstrapv2/img/ue-logo.jpg"
            alt="Logotyp Fundusze Europejskie, Logotyp flagi Polski Rzeczpospolita Polska, Logotyp Unia Europejska, Europejskie Fundusze Strukturalne i Inwestycyjne z flagą Unii Europejskiej" 
            onClick={onParpLogoClick}/>
      </Container>
    </StyledFooter>
  );
};
