import React, { useContext, useState } from "react";

export interface ISearchContactContext {
  query: string;
  setQuery: (value: string) => void;
}

const SearchContactContext = React.createContext<
  ISearchContactContext | undefined
>(undefined);

export const SearchContactContextProvider: React.FC = ({ children }) => {
  const [query, setQuery] = useState("");

  return (
    <SearchContactContext.Provider value={{ query, setQuery }}>
      {children}
    </SearchContactContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContactContext);

  if (!context) {
    throw new Error(
      "useSearchContext must be inside a SearchContactContextProvider with a value"
    );
  }

  return context;
};
