import "index.css";

import { App } from "App";
import { gridConfig, theme } from "config";
import { GlobalStyles } from "GlobalStyles";
import React from "react";
import ReactDOM from "react-dom";
import { setConfiguration } from "react-grid-system";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { reportWebVitals } from "reportWebVitals";
import { ThemeProvider } from "styled-components";

import { persistor, store } from "./store";

setConfiguration(gridConfig);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <GlobalStyles />
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
