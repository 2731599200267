import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
}

const StyledLink = styled(RouterLink)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
  `};
`;

const Link: React.FC<ILink> = ({ children, to }) => (
  <StyledLink to={to}>{children}</StyledLink>
);

export { Link };
