import { Button, Typography } from "components";
import { Avatar } from "components/Avatar/Avatar";
import { Box } from "components/Box";
import { useProfile } from "hooks/profile";
import React, { useEffect, useState } from "react";

import { ButtonContainer } from "../ProfileInfo";
import { PersonalDataForm } from "./PersonalDataForm";

export const PersonalDataView: React.FC = () => {
  const [variant, setVariant] = useState(0);
  const { user } = useProfile();
  const [avatarUrl, setAvatarUrl] = useState({});

  useEffect(() => {
    setAvatarUrl(`${user?.data?.profile?.avatar}`);
  }, [user]);

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2" color="primary">
          Dane osobowe
        </Typography>
      </Box>
      <Box mb={variant === 0 ? 6 : 4}>
        <Avatar url={avatarUrl || {}} />
      </Box>
      {variant === 0 && (
        <>
          <Typography variant="body2" color="secondary">
            Imię
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.first_name}
          </Typography>

          <Typography variant="body2" color="secondary">
            Nazwisko
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.last_name}
          </Typography>

          <Typography variant="body2" color="secondary">
            Adres
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {`${user?.data?.profile?.address}, ${user?.data?.profile?.postal_code} ${user?.data?.profile?.city}`}
          </Typography>

          <Typography variant="body2" color="secondary">
            Nr telefonu
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.telephone_number}
          </Typography>

          <Typography variant="body2" color="secondary">
            opis
          </Typography>
          <Typography variant="h3" mb={4} fontWeight={400}>
            {user?.data?.profile?.description}
          </Typography>
          <ButtonContainer>
            <Button
              label="Edytuj"
              onClick={() => {
                setVariant(1);
              }}
            />
          </ButtonContainer>
        </>
      )}
      {variant === 1 && user && (
        <PersonalDataForm
          setAvatarUrl={setAvatarUrl}
          currentUser={user.data}
          onCloseForm={setVariant}
        />
      )}
    </>
  );
};
