import { theme as themeConfig } from "config";
import React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps, variant } from "styled-system";

export type TypographyType = {
  variant: keyof typeof themeConfig.textStyles;
  children: React.ReactNode;
  color?: "primary" | "secondary";
  fontWeight?: 400 | 600 | 700;
} & SpaceProps;

export const Typography = styled.div<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight }) => css`
    ${color === "primary" ? `color: ${theme.palette.primary.main};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "red" ? `color: ${theme.palette.text.red};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
  `,
  space
);
