import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

export interface IList {
  data: Array<string>;
}

const Root = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  ${({ theme }) => `
    position: relative;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
      height: 4px;
      width: 4px;
      margin-top: -2px;
      background-color: ${theme.palette.text.secondary};
      border-radius: 50%;
    }
  `};
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const List: React.FC<IList> = ({ data }) => (
  <Root>
    {data.map((item) => (
      <Item key={item}>
        <StyledTypography variant="body">{item}</StyledTypography>
      </Item>
    ))}
  </Root>
);
