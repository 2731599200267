export const http = async <T>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const response = await fetch(url, options);

  if (!response.ok) {
    try {
      const error = await response.clone().json();
      throw error;
    } catch (e) {
      throw await response.clone().text()
    }
  }

  if (response.statusText === 'No Content') {
    return new Promise((resolve, reject) => {
        resolve(undefined);
    })
  }
  return response.json();
};
