import {
  ADVERTISEMENTS_URL,
  API_URL,
  getAdvertisementCommentsUrl,
  PUBLIC_ADVERTISEMENTS_URL,
} from "constants/paths";
import { serialize } from "object-to-formdata";
import qs from "qs";
import {
  AdvertisementFilterType,
  IAdvertisement,
  IPublicAdvertisement,
} from "types/advertisement";
import { AdvertisementCategoryType } from "types/advertisement";
import { IPagination } from "types/pagination";
import { fetchAPI } from "utilities/fetchAPI";

export type IFetchAdvertisementsResponse = {
  pagination: IPagination;
  data: Array<IAdvertisement>;
};

export type IFetchPublicAdvertisementsResponse = {
  pagination: IPagination;
  data: Array<IPublicAdvertisement>;
};

export type IFetchAdvertisementResponse = {
  data: IAdvertisement;
};

export type IFetchPublicAdvertisementResponse = {
  data: IPublicAdvertisement;
};

interface IFetchAdvertisements {
  finished_status?: boolean;
  my_advertisements?: boolean;
  my_negotiations?: boolean;
  most_popular_advertisements?: boolean;
  my_favourite_advertisements?: boolean;
  per_page?: number;
  filter?: AdvertisementFilterType;
  user_id?: number;
  page?: number;
}

interface IPublicFetchAdvertisements {
  finished_status?: boolean;
  my_advertisements?: boolean;
  most_popular_advertisements?: boolean;
  my_favourite_advertisements?: boolean;
  per_page?: number;
  filter?: AdvertisementFilterType;
}

export const fetchAdvertisements = async ({
  finished_status,
  my_advertisements,
  my_negotiations,
  per_page,
  filter,
  user_id,
  page,
}: IFetchAdvertisements) => {
  const query = qs.stringify({
    finished_status,
    my_advertisements,
    my_negotiations,
    per_page,
    filter,
    user_id,
    page,
  });

  const path = `${ADVERTISEMENTS_URL}?${query}`;

  const advertisementsData = await fetchAPI<IFetchAdvertisementsResponse>(
    path,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return advertisementsData;
};

export const fetchPublicAdvertisements = async ({
  finished_status,
  my_advertisements,
  per_page,
  filter,
}: IPublicFetchAdvertisements) => {
  const query = qs.stringify({
    finished_status,
    my_advertisements,
    per_page,
    filter,
  });

  const path = `${PUBLIC_ADVERTISEMENTS_URL}?${query}`;

  const advertisementsData = await fetchAPI<IFetchPublicAdvertisementsResponse>(
    path,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return advertisementsData;
};

export const fetchAdvertisement = async ({ id = 0 } = {}) => {
  const response = await fetchAPI<IFetchAdvertisementResponse>(
    `${ADVERTISEMENTS_URL}/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const fetchPublicAdvertisement = async ({ id = 0 } = {}) => {
  const response = await fetchAPI<IFetchPublicAdvertisementResponse>(
    `${PUBLIC_ADVERTISEMENTS_URL}/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

interface IAddAdvertisement {
  advertisement: Partial<{
    name: string;
    category: AdvertisementCategoryType;
    description: string;
    price: number;
    year_of_construction: number;
    area: number;
    floor: number;
    number_of_apartments_on_floor: number;
    legal_status_of_the_facility: string;
    roi: string;
    description_of_the_roi_calculation_method: string;
    number_of_floors: number;
    number_of_apartments: number;
    min_area_of_apartments: number;
    max_area_of_apartments: number;
    dimension_x: number;
    dimension_y: number;
    number_of_plot: string;
    development_plan: boolean;
    water: boolean;
    gas: boolean;
    electricity: boolean;
    canalization: boolean;
    building_conditions: boolean;
    voivodship: string;
    finished: boolean;
    address: string;
    longitude: number;
    latitude: number;
    user: string;
    images: Array<File>;
  }>;
}

export interface IAddAdvertisementResponse {
  data: IAdvertisement;
}

export const addAdvertisement = async (data: IAddAdvertisement) => {
  const formData = serialize(data);
  const response = await fetchAPI<IAddAdvertisementResponse>(
    ADVERTISEMENTS_URL,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    }
  );

  return response;
};

interface IAddCommentToAdvertisementResponse {
  data: {
    id: number;
    message: string;
    created_at: string;
    user: {
      profile: {
        first_name: string;
        last_name: string;
      };
    };
  };
}

export const addCommentToAdvertisement = async ({
  id,
  message,
}: {
  id: string;
  message: string;
}) => {
  const response = await fetchAPI<IAddCommentToAdvertisementResponse>(
    getAdvertisementCommentsUrl(id),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ comment: { message } }),
    }
  );

  return response.data;
};

interface IToggleAdvertisementToFavouriteResponse {
  data: {
    id: number;
    advertisement_id: number;
    user_id: number;
  };
}

export const addAdvertisementToFavourite = async ({
  advertisement_id,
}: {
  advertisement_id: number;
}) => {
  const response = await fetchAPI<IToggleAdvertisementToFavouriteResponse>(
    `${API_URL}/favourite_advertisements`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ favourite_advertisement: { advertisement_id } }),
    }
  );

  return response.data;
};

export const removeAdvertisementFromFavourite = async ({
  advertisement_id,
}: {
  advertisement_id: number;
}) => {
  fetchAPI<IToggleAdvertisementToFavouriteResponse>(
    `${API_URL}/favourite_advertisements/${advertisement_id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const editAdvertisement = async (
  advertisement_id: string,
  dataForm: any
) => {
  // const formData = serialize(dataForm);
  fetchAPI(`${API_URL}/advertisements/${advertisement_id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ advertisement: { ...dataForm } }),
  });
};


export const shareWithFriends = async (advertisement_id: string, users_ids: number[]) => {
  fetchAPI(`${API_URL}/advertisements/${advertisement_id}/share_with_friends`, {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({users_ids})
  })
}