import { Box } from "components/Box";
import { Investment } from "components/Investment";
import { Pagination } from "components/Pagination";
import { useInvestments } from "hooks/investments";
import { usePagination } from "hooks/pagination";
import { useWindowWidth } from "hooks/useWindowWidth";
import React, { useRef } from "react";
import { Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertisements } from "store/advertisement/actions";
import { IAdvertisementsState } from "store/advertisement/types";
import { IRootState } from "store/rootReducer";

export const NegotiationsFinishedView = () => {
  const investmentsRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const advertisements: IAdvertisementsState = useSelector(
    (data: IRootState) => data.advertisements
  );

  const windowWidth = useWindowWidth();

  const { investments } = useInvestments({
    advertisements: advertisements.data,
    view: "horizontal",
    isPrivate: true,
  });

  const { handleChangePage } = usePagination({
    paginatedElRef: investmentsRef,
    updateFn: (pageNumber) => {
      dispatch(
        getAdvertisements({
          finished_status: true,
          filter: "my_negotiations",
          page: pageNumber,
        })
      );
    },
  });

  return (
    <>
      <div ref={investmentsRef}>
        {investments.map((investment) => (
          <Box key={investment.id} mb={4}>
            <Investment
              {...investment}
              view={windowWidth < 1000 ? "vertical" : "horizontal"}
            />
          </Box>
        ))}
      </div>
      <Row justify="end">
        <Pagination
          current={advertisements.pagination.current}
          pages={advertisements.pagination.pages}
          onPageClick={handleChangePage}
        />
      </Row>
    </>
  );
};
