import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: sans-serif;
      font-size: 14px;
      line-height: 1.4;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background};
    }

    select {
      font-family: sans-serif;
    }

    .Toastify__toast--error {
      background-color: ${theme.palette.accent.red};
    }
  `}
`;
