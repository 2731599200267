import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

interface IUseNavbar {
  logged: boolean;
}

const links: Array<{ to: string; label: string; isPublic?: boolean }> = [
  {
    to: "/search",
    label: "Przeglądaj ogłoszenia",
    isPublic: true,
  },
  {
    to: "/advertisements/current",
    label: "Moje ogłoszenia",
  },
  {
    to: "/negotiations/current",
    label: "Moje negocjacje",
  },
  {
    to: "/messages",
    label: "Rozmowy",
  },
  {
    to: "/contacts/my",
    label: "Kontakty",
  },
];

export const useNavbar = ({ logged }: IUseNavbar) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT_USER",
    });
    history.push("/");
  };

  const handleMyProfileClick = () => {
    history.push("/profile/info");
  };

  const navbarLinks: Array<{
    to: string;
    match: boolean;
    label: string;
  }> = links
    .filter(({ isPublic }) => (logged ? true : isPublic))
    .map(({ label, to }) => {
      const match = !!useRouteMatch({
        path: to,
      });

      return {
        label,
        to,
        match,
      };
    });

  return { handleLogout, handleMyProfileClick, navbarLinks };
};
