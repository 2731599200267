import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "components";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { TextField } from "components/TextField";
import React from "react";
import { Col, Row } from "react-grid-system";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { profilePersonalDataSchema } from "../../schemas/profileSettings";

interface IProfileFormFirstStep {
  defaultValues: IProfileFormFirstStepData;
  onSubmit: (data: IProfileFormFirstStepData) => any;
}

export interface IProfileFormFirstStepData {
  /* eslint-disable camelcase */
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  postal_code: string;
  telephone_number: string;
  company_name: string;
  company_nip: string;
  company_regon: string;
  company_address: string;
  company_city: string;
  company_postal_code: string;
  description?: string;
  /* eslint-enable camelcase */
}

export const ProfileFormFirstStep: React.FC<IProfileFormFirstStep> = ({
  onSubmit: onSubmitCallback,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProfileFormFirstStepData>({
    defaultValues,
    resolver: yupResolver(profilePersonalDataSchema),
  });

  const onSubmit = async (data: IProfileFormFirstStepData) => {
    onSubmitCallback(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" color="primary" mb={2}>
        Dane osobowe
      </Typography>
      <Box mb={3}>
        <TextField
          label="Imię"
          name="first_name"
          ref={register}
          error={errors.first_name?.message}
        />
      </Box>
      <Box mb={3}>
        <TextField
          label="Nazwisko"
          name="last_name"
          ref={register}
          error={errors?.last_name?.message}
        />
      </Box>
      <Box mb={3}>
        <TextField
          label="Adres"
          name="address"
          ref={register}
          error={errors?.address?.message}
        />
      </Box>
      <Row>
        <Col sm={5}>
          <Box mb={3}>
            <TextField
              label="Kod pocztowy"
              name="postal_code"
              ref={register}
              error={errors?.postal_code?.message}
            />
          </Box>
        </Col>
        <Col>
          <Box mb={3}>
            <TextField
              label="Miejscowość"
              name="city"
              ref={register}
              error={errors?.city?.message}
            />
          </Box>
        </Col>
      </Row>
      <Box mb={3}>
        <TextField
          label="Nr telefonu"
          name="telephone_number"
          ref={register}
          minLength={9}
          error={errors?.telephone_number?.message}
        />
      </Box>
      <Box mb={3}>
        <TextField
          label="Opis"
          name="description"
          ref={register}
          error={errors?.description?.message}
        />
      </Box>
      <Box mt={4}>
        <Typography variant="h1" color="primary" mb={2}>
          Dane firmy
        </Typography>
        <Box mb={3}>
          <TextField label="Nazwa firmy" name="company_name" ref={register} />
        </Box>
        <Box mb={3}>
          <TextField label="NIP" name="company_nip" ref={register} />
        </Box>
        <Box mb={3}>
          <TextField label="REGON" name="company_regon" ref={register} />
        </Box>
        <Box mb={3}>
          <TextField label="Adres" name="company_address" ref={register} />
        </Box>
        <Row>
          <Col sm={5}>
            <Box mb={3}>
              <TextField
                label="Kod pocztowy"
                name="company_postal_code"
                ref={register}
              />
            </Box>
          </Col>
          <Col>
            <Box mb={3}>
              <TextField
                label="Miejscowość"
                name="company_city"
                ref={register}
              />
            </Box>
          </Col>
        </Row>
      </Box>
      <Flex justifyContent="flex-end" mt={6}>
        <Button type="submit" label="Następny krok" />
      </Flex>
    </form>
  );
};
