export const eventBus = {
  on(event: string, callback: any): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener(event, (e: CustomEvent) => callback(e.detail));
  },
  dispatch(event: string, data: any): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: () => void): void {
    document.removeEventListener(event, callback);
  },
};
