import { Typography } from "components";
import { Box } from "components/Box";
import { ContactSuggestions } from "components/ContactSuggestions";
import { JoinToService } from "components/JoinToService";
import { LatestNotifications } from "components/LatestNotifications";
import { SearchAdvertisement } from "components/SearchAdvertisement";
import { useLogout } from "hooks/logout";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchRecommendedContacts } from "services/contacts";
import { getNotifications } from "services/notifications";
import { IRootState } from "store/rootReducer";
import { IContact } from "types/contact";
import { INotification } from "types/notifications";

import { RecommendedInvestmentsTabs } from "./RecommendedInvestmentsTabs";

export const HomeView = () => {
  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const history = useHistory();
  const [contacts, setContacts] = useState<Array<IContact>>([]);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const { logout } = useLogout();

  const retrieveContacts = async () => {
    try {
      const response = await fetchRecommendedContacts({
        scope: "bigger_investments",
      });
      setContacts(response.data);
    } catch (error) {
      toast.error("Nie udało się pobrać kontaktów.");
    }
  };

  const retrieveNotifications = async () => {
    try {
      const notificationLength = 5;
      const { data } = await getNotifications(notificationLength);
      setNotifications(data);
    } catch (error) {
      logout(error);
      toast.error("Nie udało się pobrać powiadomień.");
    }
  };

  useEffect(() => {
    if (!currentUser.token) return;
    retrieveContacts();
    retrieveNotifications();
  }, []);

  const onProfileClick = (id: number) => {
    history.push(`/profiles/${id}/info/`);
  };

  return (
    <>
      <Box mb={4}>
        <Typography variant="h1" color="primary">
          Dashboard
        </Typography>
      </Box>
      <Row>
        <Col md={7} lg={8}>
          <Box mb={7}>
            <SearchAdvertisement />
          </Box>
          <RecommendedInvestmentsTabs />
        </Col>

        <Col md={5} lg={4}>
          {!currentUser.token ? (
            <JoinToService goToLoginPage={() => history.push("/register")} />
          ) : (
            <>
              <Box mb={6}>
                <LatestNotifications
                  notifications={notifications}
                  goToNotificationsPage={() => {
                    history.push("/notifications");
                  }}
                  notificationRedirect={(path) => {
                    history.push(`/${path}`);
                  }}
                />
              </Box>

              <ContactSuggestions
                items={contacts}
                onProfileClick={onProfileClick}
                goToMyContactsPage={() => {
                  history.push("/contacts/my");
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
