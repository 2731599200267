export const GET_NEGOTIATIONS = "GET_NEGOTIATIONS";

export interface INegotiation {
  id: number;
  advertisement_id: number;
  finished: boolean;
  mutual_contacts_count: number;
  price: number | null;
  user: {
    id: number;
    avatar: string | null;
    profile: {
      first_name: string;
      last_name: string;
    };
  };
}

export interface IFetchNegotiationsResponse {
  pagination: {
    current: number;
    previous: null;
    next: null;
    per_page: number;
    pages: number;
    count: number;
  };
  data: Array<INegotiation>;
}

interface GetNegotiationsActionType {
  type: typeof GET_NEGOTIATIONS;
  payload: IFetchNegotiationsResponse;
}

export type ActionType = GetNegotiationsActionType;
