import { Box } from "components/Box";
import { Investment } from "components/Investment";
import { Menu } from "components/Menu";
import { Pagination } from "components/Pagination";
import { Select } from "components/Select";
import { Typography } from "components/Typography";
import { useInvestments } from "hooks/investments";
import { usePrevious } from "hooks/usePrevious";
import { useWindowWidth } from "hooks/useWindowWidth";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { IAddAdvertisementResponse } from "services/advertisements";
import {
  IFetchAdvertisementsResponse,
  ISearchParams,
  search,
  searchPublic,
} from "services/search";
import { IRootState } from "store/rootReducer";
import styled from "styled-components";
import { eventBus } from "utilities/eventBus";

import { Filters } from "./Filters";

export const cleanFilters = (filters: ISearchParams) => {
  return Object.fromEntries(
    Object.entries({ ...filters }).filter(
      ([key, value]) => value && value !== ""
    )
  );
};

const FlexRow = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space[3]}px;

    flex-wrap: wrap;
  `}
`;

export const Search: React.FC = () => {
  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const [results, setResults] = useState<IFetchAdvertisementsResponse>();
  const [filters, setFilters] = useState<ISearchParams | null>();
  const history = useHistory();
  const location = useLocation();
  const previousCategory = usePrevious(filters?.selected_category);
  const previousSorting = usePrevious(filters?.order_by);
  const previousSortingType = usePrevious(filters?.order_sort);
  const previousPage = usePrevious(filters?.page);
  const investmentsRef = useRef<HTMLDivElement>(null);

  const windowWidth = useWindowWidth();

  const { investments } = useInvestments({
    advertisements: results?.data ?? [],
    view: "horizontal",
    isPrivate: !!currentUser.token,
  });

  const handleSetCategoryAndFav = (
    category: ISearchParams["selected_category"],
    favourite: ISearchParams["my_favourite_advertisements"]
  ) => {
    setFilters((prevState) => {
      return {
        selected_category: category,
        my_favourite_advertisements: favourite,
        order_by: prevState?.order_by,
        order_sort: prevState?.order_sort,
      };
    });
  };

  const handleSetFilters = (newFilters: ISearchParams) => {
    setFilters((prevState) => {
      return { ...prevState, ...newFilters };
    });
  };

  const handleChangePage = (pageNumber: number | null) => {
    if (pageNumber) {
      handleSetFilters({ page: pageNumber });
      if (investmentsRef.current !== null) {
        investmentsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const filtersFromUrl = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (Object.keys(filtersFromUrl).length > 0) {
      setFilters(filtersFromUrl);
    } else {
      setFilters({
        selected_category: "",
        order_by: "date",
        order_sort: "desc",
      });
    }

    eventBus.on(
      "addNewAdvertisement",
      ({ data }: IAddAdvertisementResponse) => {
        let filtersValue: ISearchParams | null = {};
        setFilters((prevState) => {
          filtersValue = { ...prevState };
          return prevState;
        });
        if (
          (filtersValue.selected_category !== "" &&
            data.category !== filtersValue.selected_category) ||
          filtersValue.order_sort !== "desc" ||
          filtersValue.order_by !== "date"
        )
          return;
        setResults((prevState) => {
          const result = prevState;
          if (!prevState) return prevState;
          const oldData = [...prevState.data];
          if (oldData.length > 14) oldData.pop();
          if (result) result.data = [data, ...oldData];
          return result;
        });
      }
    );

    return () => {
      eventBus.remove("addNewAdvertisement", () => null);
    };
  }, []);

  useEffect(() => {
    if (
      previousCategory !== filters?.selected_category &&
      previousSorting !== filters?.order_by &&
      previousSortingType !== filters?.order_sort &&
      previousPage !== filters?.page
    ) {
      setFilters({
        selected_category: filters?.selected_category,
        order_by: filters?.order_by,
        order_sort: filters?.order_sort,
        page: filters?.page,
      });
    } else if (filters) {
      if (
        filters.selected_category === undefined &&
        filters.my_favourite_advertisements === undefined
      ) {
        setFilters((prevState) => ({ ...prevState, selected_category: "" }));
      }
      history.push(`search?${qs.stringify(cleanFilters(filters))}`);
      if (currentUser.token) {
        search({ ...filters })
          .then((result) => {
            setResults(result);
          })
          .catch(() => toast.error("Nie udało się pobrać powiadomień."));
      } else {
        searchPublic({ ...filters })
          .then((result) => {
            setResults(result);
          })
          .catch(() => toast.error("Nie udało się pobrać powiadomień."));
      }
    }
  }, [filters]);

  const menuItems = [
    { label: "Wszystkie ogłoszenia", category: "" },
    { label: "Mieszkanie/mieszkania", category: "apartment" },
    { label: "Kamienica", category: "tenement_house" },
    { label: "Lokal usługowy", category: "service_premises" },
    { label: "Magazyny i hale", category: "warehouse" },
    { label: "Działka pod inwestycję", category: "plot" },
    { label: "Obserwowane", favourite: true, separated: true },
  ].map(({ label, category, favourite, separated }) => ({
    label,
    onClick: () => handleSetCategoryAndFav(category, favourite),
    current: filters?.selected_category === category,
    separated,
  }));

  return (
    <>
      <FlexRow ref={investmentsRef}>
        <Typography variant="h1" color="primary">
          Wyszukaj ogłoszenia
        </Typography>
        <Col sm={3}>
          <Select
            transparent
            options={[
              {
                value: "date-asc",
                label: "sortuj po: dacie dodania (rosnąco)",
              },
              {
                value: "date-desc",
                label: "sortuj po: dacie dodania (malejąco)",
              },
              {
                value: "price-asc",
                label: "sortuj po: cenie (rosnąco)",
              },
              {
                value: "price-desc",
                label: "sortuj po: cenie (malejąco)",
              },
              { value: "name-asc", label: "sortuj po: nazwie (rosnąco)" },
              { value: "name-desc", label: "sortuj po: nazwie (malejąco)" },
            ]}
            selected="date-desc"
            onSelected={(data) => {
              const [order_by, order_sort] =
                typeof data === "string" ? data.split("-") : data;
              return (
                data &&
                handleSetFilters({
                  order_by,
                  order_sort,
                })
              );
            }}
          />
        </Col>
      </FlexRow>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <Menu items={menuItems} title="Filtry" />
          </Box>
          {filters !== null && (
            <Box mb={6}>
              <Filters filters={filters} handleSetFilters={handleSetFilters} />
            </Box>
          )}
        </Col>
        <Col sm={8}>
          {investments.map((investment) => (
            <Box mb={4} key={`box_${investment.id}`}>
              <Investment
                {...investment}
                view={windowWidth < 1000 ? "vertical" : "horizontal"}
              />
            </Box>
          ))}
        </Col>
      </Row>
      {results && (
        <Row justify="end">
          <Pagination
            current={results?.pagination.current}
            pages={results?.pagination.pages}
            onPageClick={handleChangePage}
          />
        </Row>
      )}
    </>
  );
};
