import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "components";
import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import { ErrorHelperText } from "components/ErrorHelperText";
import { Flex } from "components/Flex";
import { LoginLayout } from "components/LoginLayout";
import { PolicyModal } from "components/PolicyModal";
import { RegulationModal } from "components/RegulationModal";
import { TextField } from "components/TextField";
import qs from "qs";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { registerSchema } from "schemas/register";
import { registerUserAction } from "store/currentUser/actions";
import styled from "styled-components";

interface IRegisterFormData {
  email: string;
  password: string;
  password_confirmation: string;
  invited_by_id?: string | null;
  // agreements: boolean;
  "fresh_user?"?: boolean
}

const ClicableTypography = styled(Typography)`
  ${({ theme }) => `
  cursor: pointer;
  font-size: 11px;
  :hover {
    color: ${theme.palette.primary.main};
    text-decoration: underline;
  }
  `}
`;

const DownloadLink = styled.a`
  margin: 0 4px;
  text-decoration: none;
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
  `}
`

const content =
  "Pamiętaj, wypełniając prawidłowo i starannie poniższy formularz zgłoszeniowy, w którym określasz swoje doświadczenie zawodowe, oczekiwania i preferencje biznesowe zwiększasz swoje szanse na nawiązanie ciekawych kontaktów biznesowych.";

export const RegisterView = () => {
  const [emailExists, setEmailExists] = React.useState(false);
  const [registerIsOpen, setRegisterIsOpen] = React.useState(false);
  const [policyIsOpen, setPolicyIsOpen] = React.useState(false);
  const location = useLocation();

  const { invited_by_id } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  if (invited_by_id)
    localStorage.setItem("invited_by_id", invited_by_id.toString());

  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm<IRegisterFormData>({
    resolver: yupResolver(registerSchema),
  });
  const history = useHistory();

  const onSubmit = async (data: IRegisterFormData) => {
    if (localStorage.getItem("invited_by_id"))
      // eslint-disable-next-line no-param-reassign
      data.invited_by_id = localStorage.getItem("invited_by_id");

    // eslint-disable-next-line no-param-reassign
    data["fresh_user?"] = true
    try {
      await dispatch(registerUserAction(data));
      // alert("Wysłano link aktywacyjny na podany adres email");
      history.push("/login?register=success");
      // history.push("/register/profile");
    } catch (error) {
      setEmailExists(true);
      if (error.error) {
        toast.error(error.error);
      }
    }
  };

  return (
    <>
      <LoginLayout content={content}>
        <Typography variant="h1" color="primary" mb={2}>
          Dane logowania
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <TextField
              label="Adres e-mail"
              name="email"
              ref={register()}
              error={errors.email?.message}
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="password"
              label="Hasło"
              name="password"
              ref={register()}
              error={errors.password?.message}
            />
          </Box>
          <Box mb={6}>
            <TextField
              type="password"
              label="Powtórz Hasło"
              name="password_confirmation"
              ref={register()}
              error={errors.password_confirmation?.message}
            />
          </Box>
           <Flex alignItems="flex-start">
            <Checkbox
              label=""
              name="agreements"
              ref={register()}
              error={!!errors.agreements?.message}
            />
            <Typography
              mb={2}
              variant="body2"
              style={{ display: "flex", fontSize: "10px" }}
            >
              Akceptacja
              {/* <ClicableTypography */}
              {/*  variant="body2" */}
              {/*  onClick={() => setRegisterIsOpen(true)} */}
              {/*  mx={1} */}
              {/* > */}
              {/*  Regulamin */}
              {/* </ClicableTypography> */}
              {"  "}
              <DownloadLink href="/REGULAMIN_strony_WWW_I4Y_v2.docx" target="_blank">Regulamin</DownloadLink>
              oraz
              {/* <ClicableTypography */}
              {/*  variant="body2" */}
              {/*  onClick={() => setPolicyIsOpen(true)} */}
              {/*  ml={1} */}
              {/* > */}
              {/*  polityki prywatności */}
              {/* </ClicableTypography> */}
              <DownloadLink href="/POLITYKA_PRYWATNOSCI_strony_WWW_I4Y_v2.docx" target="_blank">Polityki prywatności</DownloadLink>
            </Typography>
           </Flex>
          {emailExists && <ErrorHelperText>Email zajęty.</ErrorHelperText>}
          <Flex justifyContent="flex-end">
            <Button type="submit" label="Załóż konto" />
          </Flex>
        </form>
      </LoginLayout>

      <RegulationModal
        isOpen={registerIsOpen}
        onCancelClick={() => setRegisterIsOpen(false)}
      />
      <PolicyModal
        isOpen={policyIsOpen}
        onCancelClick={() => setPolicyIsOpen(false)}
      />
    </>
  );
};
