import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { LoginLayout } from "components/LoginLayout";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { sendEmail } from "services/passwordReset";
import styled from "styled-components";

interface IEmail {
  email: string;
}

const StyledForm = styled.form`
  height: 100%;
`;
const StyledFlex = styled(Flex)`
  ${({ theme }) => `
  height: 100%;
  padding-bottom: ${theme.space[8]}px;
`}
`;

// eslint-disable-next-line
const emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PasswordResetView = () => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = async (data: IEmail) => {
    try {
      await sendEmail(data);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <LoginLayout>
      <>
        <Box mb={2}>
          <Typography variant="h1" color="primary">
            Resetowanie hasła
          </Typography>
        </Box>
        {success ? (
          <Typography variant="body">
            Na podany adres e-mail została wysłana wiadomość z instrukcją resetu
            hasła.
          </Typography>
        ) : (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledFlex flexDirection="column" justifyContent="space-between">
              <div>
                <TextField
                  name="email"
                  ref={register({
                    required: true,
                    pattern: emailPattern,
                  })}
                  error={errors.email && "Niepoprawny format adresu e-mail"}
                  label="Adres e-mail podany przy rejestracji"
                  placeholder="jan.kowalski@mail.com"
                />
              </div>
              <Button
                type="submit"
                label="Wyślij e-mail do resetu hasła"
                fullWidth
              />
            </StyledFlex>
          </StyledForm>
        )}
      </>
    </LoginLayout>
  );
};
