import { Card } from "components";
import { Box } from "components/Box";
// eslint-disable-next-line import/no-cycle
import { Router } from "components/Router/Router";
import { useWindowWidth } from "hooks/useWindowWidth";
import React from "react";
import { IRoute } from "types/route";

export const ProfileSettingsView: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  const windowWidth = useWindowWidth();

  return (
    <Card>
      <Box px={windowWidth > 1000 ? 24 : 5} pt={6} pb={8}>
        <Router routes={subRoutes} />
      </Box>
    </Card>
  );
};
