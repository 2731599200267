import { Flex } from "components/Flex";
import { Notification } from "components/Notification";
import { INotification } from "components/Notification/Notification";
import { Typography } from "components/Typography";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { getNotifications } from "services/notifications";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[3]}px;
  `}
`;

const WrapperNotification = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.space[2]}px;
  `}
`;

const StyledRouterLink = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
`;

export const NotificationsView = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const retrieveNotifications = async () => {
    try {
      const { data } = await getNotifications();
      setNotifications(data);
    } catch (err) {
      toast.error("Nie udało się pobrać powiadomień.");
    }
  };

  useEffect(() => {
    retrieveNotifications();
  }, []);

  return (
    <Row justify="center">
      <Col sm={8}>
        <StyledFlex justifyContent="space-between" alignItems="center">
          <Typography variant="h1" color="primary">
            Powiadomienia
          </Typography>
        </StyledFlex>
        {notifications.map((notifi) => (
          <WrapperNotification>
            <StyledRouterLink to={notifi.front_path}>
              <Notification {...notifi} />
            </StyledRouterLink>
          </WrapperNotification>
        ))}
      </Col>
    </Row>
  );
};
