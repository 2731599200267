import { ActionType, StateI } from "./types";

const initialState = null;

export const currentUser = (
  state: StateI = initialState,
  action: ActionType
): StateI => {
  switch (action.type) {
    case "GET_CURRENT_USER":
      if (state) {
        return { ...action.payload, token: state?.token };
      }
      return state;
      break;
    case "LOGIN_USER":
      return { ...action.payload };
    case "LOGOUT_USER": {
      return initialState;
    }
    case "UPDATE_PROFILE": {
      if (state) {
        return { ...state, profile: action.payload };
      }
      return state;
    }
    case "UPDATE_PREFERENCES": {
      if (state) {
        return { ...state, preference: action.payload };
      }
      return state;
    }
    default:
      return state;
  }
};
