import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { UsersList } from "components/UsersList";
import { IUsersList, IUsersListItem } from "components/UsersList/UsersList";
import { ReactComponent as ContactAcceptIcon } from "icons/contact-accept.svg";
import { ReactComponent as ContactIgnoreIcon } from "icons/contact-ignore.svg";
import React from "react";
import styled from "styled-components";

export interface IContactInvitations {
  contacts: IUsersList["items"];
  onAcceptUserClick: (contact: IUsersListItem) => any;
  onIgnoreUserClick: (contact: IUsersListItem) => any;
}

const AcceptUserButton = styled(ContactAcceptIcon)`
  cursor: pointer;
`;

const IgnoreUserButton = styled(ContactIgnoreIcon)`
  cursor: pointer;
`;

export const ContactInvitations: React.FC<IContactInvitations> = ({
  contacts,
  onAcceptUserClick,
  onIgnoreUserClick,
}) => {
  const renderButtons: IUsersList["extraRender"] = (contact) => {
    return (
      <Flex>
        <Box mr={2}>
          <AcceptUserButton onClick={() => onAcceptUserClick(contact)} />
        </Box>
        <Box>
          <IgnoreUserButton onClick={() => onIgnoreUserClick(contact)} />
        </Box>
      </Flex>
    );
  };

  return <UsersList items={contacts} extraRender={renderButtons} />;
};
