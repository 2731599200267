import { Typography } from "components";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Investment } from "components/Investment/Investment";
import { useInvestments } from "hooks/investments";
import { useWindowWidth } from "hooks/useWindowWidth";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchAdvertisements,
  fetchPublicAdvertisements,
  IAddAdvertisementResponse,
} from "services/advertisements";
import { IRootState } from "store/rootReducer";
import styled from "styled-components";
import {
  AdvertisementFilterType,
  IAdvertisement,
  IPublicAdvertisement,
} from "types/advertisement";
import { eventBus } from "utilities/eventBus";

interface ITab {
  label: string;
  value: AdvertisementFilterType;
}

const Header = styled(Typography).attrs({
  variant: "h2",
})`
  ${({ theme }) => `
    margin-bottom: ${theme.space[3]}px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: ${theme.space[6]}px;
    }
  `}
`;

const StyledFlex = styled(Flex)`
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const RecommendedInvestmentsTabs = () => {
  const currentUser = useSelector((state: IRootState) => state.currentUser);
  const tabs: ITab[] = currentUser.token
    ? [
        {
          label: "Przygotowane dla Ciebie",
          value: "prepared_for_you",
        },
        {
          label: "Obserwowane",
          value: "my_favourite_advertisements",
        },
        {
          label: "Najnowsze",
          value: "newest_advertisements",
        },
        {
          label: "Najpopularniejsze",
          value: "most_popular_advertisements",
        },
      ]
    : [
        {
          label: "Najnowsze",
          value: "newest_advertisements",
        },
        {
          label: "Najpopularniejsze",
          value: "most_popular_advertisements",
        },
      ];

  const [activeTab, setActiveTab] = useState<ITab>(tabs[0]);
  const [advertisements, setAdvertisements] = useState<
    IAdvertisement[] | IPublicAdvertisement[]
  >([]);

  const windowWidth = useWindowWidth();

  const retrieveAdvertisements = async (tab: ITab) => {
    if (currentUser.token) {
      const response = await fetchAdvertisements({
        per_page: 5,
        filter: tab.value,
      });
      setAdvertisements(response.data);
    } else {
      const response = await fetchPublicAdvertisements({
        per_page: 5,
        filter: tab.value,
      });
      setAdvertisements(response.data);
    }
  };

  const handleHeaderClick = (tab: ITab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setAdvertisements([]);
    retrieveAdvertisements(activeTab);
  }, [activeTab]);

  const isAdvertisement = (
    arg: IAdvertisement[] | IPublicAdvertisement[]
  ): arg is IAdvertisement[] => {
    return arg && "user" in arg[0];
  };

  useEffect(() => {
    eventBus.on("addNewAdvertisement", (data: IAddAdvertisementResponse) => {
      let tabValue = "";
      setActiveTab((prevState) => {
        tabValue = prevState.value;
        return prevState;
      });
      if (!["newest_advertisements"].includes(tabValue)) return;
      setAdvertisements((prevState) => {
        if (prevState.length < 1) return [data.data];
        if (isAdvertisement(prevState)) {
          const oldData = [...prevState];
          if (prevState.length > 4) oldData.pop();
          return [data.data, ...oldData];
        }
        return [...prevState];
      });
    });

    return () => {
      eventBus.remove("addNewAdvertisement", () => null);
    };
  }, []);

  const { investments } = useInvestments({
    advertisements,
    view: "horizontal",
    isPrivate: !!currentUser.token,
  });

  return (
    <div>
      <StyledFlex>
        {tabs.map((tab) => (
          <Header
            key={tab.value}
            color={activeTab.value === tab.value ? "primary" : undefined}
            onClick={() => handleHeaderClick(tab)}
          >
            {tab.label}
          </Header>
        ))}
      </StyledFlex>
      {investments.map((investment) => (
        <Box key={investment.id} mb={4}>
          <Investment
            {...investment}
            view={windowWidth < 1000 ? "vertical" : "horizontal"}
          />
        </Box>
      ))}
    </div>
  );
};
