export const cities = [
  { label: "Aleksandrów Kujawski", value: "Aleksandrów Kujawski" },
  { label: "Aleksandrów Łódzki", value: "Aleksandrów Łódzki" },
  { label: "Alwernia", value: "Alwernia" },
  { label: "Andrychów", value: "Andrychów" },
  { label: "Annopol", value: "Annopol" },
  { label: "Augustów", value: "Augustów" },
  { label: "Babimost", value: "Babimost" },
  { label: "Baborów", value: "Baborów" },
  { label: "Baranów Sandomierski", value: "Baranów Sandomierski" },
  { label: "Barcin", value: "Barcin" },
  { label: "Barczewo", value: "Barczewo" },
  { label: "Bardo", value: "Bardo" },
  { label: "Barlinek", value: "Barlinek" },
  { label: "Bartoszyce", value: "Bartoszyce" },
  { label: "Barwice", value: "Barwice" },
  { label: "Bełchatów", value: "Bełchatów" },
  { label: "Bełżyce", value: "Bełżyce" },
  { label: "Biała", value: "Biała" },
  { label: "Biała Piska", value: "Biała Piska" },
  { label: "Biała Podlaska", value: "Biała Podlaska" },
  { label: "Biała Rawska", value: "Biała Rawska" },
  { label: "Białobrzegi", value: "Białobrzegi" },
  { label: "Białogard", value: "Białogard" },
  { label: "Biały Bór", value: "Biały Bór" },
  { label: "Białystok", value: "Białystok" },
  { label: "Biecz", value: "Biecz" },
  { label: "Bielawa", value: "Bielawa" },
  { label: "Bielsk Podlaski", value: "Bielsk Podlaski" },
  { label: "Bielsko-Biała", value: "Bielsko-Biała" },
  { label: "Bierutów", value: "Bierutów" },
  { label: "Bieruń", value: "Bieruń" },
  { label: "Bieżuń", value: "Bieżuń" },
  { label: "Biskupiec", value: "Biskupiec" },
  { label: "Bisztynek", value: "Bisztynek" },
  { label: "Biłgoraj", value: "Biłgoraj" },
  { label: "Blachownia", value: "Blachownia" },
  { label: "Bobolice", value: "Bobolice" },
  { label: "Bobowa", value: "Bobowa" },
  { label: "Bochnia", value: "Bochnia" },
  { label: "Bodzentyn", value: "Bodzentyn" },
  { label: "Bogatynia", value: "Bogatynia" },
  { label: "Boguchwała", value: "Boguchwała" },
  { label: "Boguszów-Gorce", value: "Boguszów-Gorce" },
  { label: "Bojanowo", value: "Bojanowo" },
  { label: "Bolesławiec", value: "Bolesławiec" },
  { label: "Bolków", value: "Bolków" },
  { label: "Borek Wielkopolski", value: "Borek Wielkopolski" },
  { label: "Borne Sulinowo", value: "Borne Sulinowo" },
  { label: "Braniewo", value: "Braniewo" },
  { label: "Brańsk", value: "Brańsk" },
  { label: "Brodnica", value: "Brodnica" },
  { label: "Brok", value: "Brok" },
  { label: "Brusy", value: "Brusy" },
  { label: "Brwinów", value: "Brwinów" },
  { label: "Brzeg", value: "Brzeg" },
  { label: "Brzeg Dolny", value: "Brzeg Dolny" },
  { label: "Brzesko", value: "Brzesko" },
  { label: "Brzeszcze", value: "Brzeszcze" },
  { label: "Brzeziny", value: "Brzeziny" },
  { label: "Brześć Kujawski", value: "Brześć Kujawski" },
  { label: "Brzostek", value: "Brzostek" },
  { label: "Brzozów", value: "Brzozów" },
  { label: "Budzyń", value: "Budzyń" },
  { label: "Buk", value: "Buk" },
  { label: "Bukowno", value: "Bukowno" },
  { label: "Busko-Zdrój", value: "Busko-Zdrój" },
  { label: "Bychawa", value: "Bychawa" },
  { label: "Byczyna", value: "Byczyna" },
  { label: "Bydgoszcz", value: "Bydgoszcz" },
  { label: "Bystrzyca Kłodzka", value: "Bystrzyca Kłodzka" },
  { label: "Bytom", value: "Bytom" },
  { label: "Bytom Odrzański", value: "Bytom Odrzański" },
  { label: "Bytów", value: "Bytów" },
  { label: "Będzin", value: "Będzin" },
  { label: "Błaszki", value: "Błaszki" },
  { label: "Błażowa", value: "Błażowa" },
  { label: "Błonie", value: "Błonie" },
  { label: "Cedynia", value: "Cedynia" },
  { label: "Chełm", value: "Chełm" },
  { label: "Chełmek", value: "Chełmek" },
  { label: "Chełmno", value: "Chełmno" },
  { label: "Chełmża", value: "Chełmża" },
  { label: "Chmielnik", value: "Chmielnik" },
  { label: "Chocianów", value: "Chocianów" },
  { label: "Chociwel", value: "Chociwel" },
  { label: "Chocz", value: "Chocz" },
  { label: "Chodecz", value: "Chodecz" },
  { label: "Chodzież", value: "Chodzież" },
  { label: "Chojna", value: "Chojna" },
  { label: "Chojnice", value: "Chojnice" },
  { label: "Chojnów", value: "Chojnów" },
  { label: "Choroszcz", value: "Choroszcz" },
  { label: "Chorzele", value: "Chorzele" },
  { label: "Chorzów", value: "Chorzów" },
  { label: "Choszczno", value: "Choszczno" },
  { label: "Chrzanów", value: "Chrzanów" },
  { label: "Chęciny", value: "Chęciny" },
  { label: "Ciechanowiec", value: "Ciechanowiec" },
  { label: "Ciechanów", value: "Ciechanów" },
  { label: "Ciechocinek", value: "Ciechocinek" },
  { label: "Cieszanów", value: "Cieszanów" },
  { label: "Cieszyn", value: "Cieszyn" },
  { label: "Ciężkowice", value: "Ciężkowice" },
  { label: "Cybinka", value: "Cybinka" },
  { label: "Czaplinek", value: "Czaplinek" },
  { label: "Czarna Białostocka", value: "Czarna Białostocka" },
  { label: "Czarna Woda", value: "Czarna Woda" },
  { label: "Czarne", value: "Czarne" },
  { label: "Czarnków", value: "Czarnków" },
  { label: "Czchów", value: "Czchów" },
  { label: "Czechowice-Dziedzice", value: "Czechowice-Dziedzice" },
  { label: "Czeladź", value: "Czeladź" },
  { label: "Czempiń", value: "Czempiń" },
  { label: "Czerniejewo", value: "Czerniejewo" },
  { label: "Czersk", value: "Czersk" },
  { label: "Czerwieńsk", value: "Czerwieńsk" },
  { label: "Czerwionka-Leszczyny", value: "Czerwionka-Leszczyny" },
  { label: "Czerwińsk nad Wisłą", value: "Czerwińsk nad Wisłą" },
  { label: "Czyżew", value: "Czyżew" },
  { label: "Częstochowa", value: "Częstochowa" },
  { label: "Człopa", value: "Człopa" },
  { label: "Człuchów", value: "Człuchów" },
  { label: "Daleszyce", value: "Daleszyce" },
  { label: "Darłowo", value: "Darłowo" },
  { label: "Debrzno", value: "Debrzno" },
  { label: "Dobczyce", value: "Dobczyce" },
  { label: "Dobiegniew", value: "Dobiegniew" },
  { label: "Dobra", value: "Dobra" },
  { label: "Dobra", value: "Dobra" },
  { label: "Dobre Miasto", value: "Dobre Miasto" },
  { label: "Dobrodzień", value: "Dobrodzień" },
  { label: "Dobrzany", value: "Dobrzany" },
  { label: "Dobrzyca", value: "Dobrzyca" },
  { label: "Dobrzyń nad Wisłą", value: "Dobrzyń nad Wisłą" },
  { label: "Dolsk", value: "Dolsk" },
  { label: "Drawno", value: "Drawno" },
  { label: "Drawsko Pomorskie", value: "Drawsko Pomorskie" },
  { label: "Drezdenko", value: "Drezdenko" },
  { label: "Drobin", value: "Drobin" },
  { label: "Drohiczyn", value: "Drohiczyn" },
  { label: "Drzewica", value: "Drzewica" },
  { label: "Dubiecko", value: "Dubiecko" },
  { label: "Dukla", value: "Dukla" },
  { label: "Duszniki-Zdrój", value: "Duszniki-Zdrój" },
  { label: "Dynów", value: "Dynów" },
  { label: "Działdowo", value: "Działdowo" },
  { label: "Działoszyce", value: "Działoszyce" },
  { label: "Działoszyn", value: "Działoszyn" },
  { label: "Dzierzgoń", value: "Dzierzgoń" },
  { label: "Dzierżoniów", value: "Dzierżoniów" },
  { label: "Dziwnów", value: "Dziwnów" },
  { label: "Dąbie", value: "Dąbie" },
  { label: "Dąbrowa Białostocka", value: "Dąbrowa Białostocka" },
  { label: "Dąbrowa Górnicza", value: "Dąbrowa Górnicza" },
  { label: "Dąbrowa Tarnowska", value: "Dąbrowa Tarnowska" },
  { label: "Dębica", value: "Dębica" },
  { label: "Dęblin", value: "Dęblin" },
  { label: "Dębno", value: "Dębno" },
  { label: "Elbląg", value: "Elbląg" },
  { label: "Ełk", value: "Ełk" },
  { label: "Frampol", value: "Frampol" },
  { label: "Frombork", value: "Frombork" },
  { label: "Garwolin", value: "Garwolin" },
  { label: "Gdańsk", value: "Gdańsk" },
  { label: "Gdynia", value: "Gdynia" },
  { label: "Giżycko", value: "Giżycko" },
  { label: "Glinojeck", value: "Glinojeck" },
  { label: "Gliwice", value: "Gliwice" },
  { label: "Gniew", value: "Gniew" },
  { label: "Gniewkowo", value: "Gniewkowo" },
  { label: "Gniezno", value: "Gniezno" },
  { label: "Gogolin", value: "Gogolin" },
  { label: "Golczewo", value: "Golczewo" },
  { label: "Goleniów", value: "Goleniów" },
  { label: "Golina", value: "Golina" },
  { label: "Golub-Dobrzyń", value: "Golub-Dobrzyń" },
  { label: "Goniądz", value: "Goniądz" },
  { label: "Goraj", value: "Goraj" },
  { label: "Gorlice", value: "Gorlice" },
  { label: "Gorzów Wielkopolski", value: "Gorzów Wielkopolski" },
  { label: "Gorzów Śląski", value: "Gorzów Śląski" },
  { label: "Gostynin", value: "Gostynin" },
  { label: "Gostyń", value: "Gostyń" },
  { label: "Gozdnica", value: "Gozdnica" },
  { label: "Gołańcz", value: "Gołańcz" },
  { label: "Gołdap", value: "Gołdap" },
  { label: "Gościno", value: "Gościno" },
  { label: "Grabów nad Prosną", value: "Grabów nad Prosną" },
  { label: "Grajewo", value: "Grajewo" },
  { label: "Grodków", value: "Grodków" },
  { label: "Grodzisk Mazowiecki", value: "Grodzisk Mazowiecki" },
  { label: "Grodzisk Wielkopolski", value: "Grodzisk Wielkopolski" },
  { label: "Grudziądz", value: "Grudziądz" },
  { label: "Grybów", value: "Grybów" },
  { label: "Gryfice", value: "Gryfice" },
  { label: "Gryfino", value: "Gryfino" },
  { label: "Gryfów Śląski", value: "Gryfów Śląski" },
  { label: "Grójec", value: "Grójec" },
  { label: "Gubin", value: "Gubin" },
  { label: "Góra", value: "Góra" },
  { label: "Góra Kalwaria", value: "Góra Kalwaria" },
  { label: "Górowo Iławeckie", value: "Górowo Iławeckie" },
  { label: "Górzno", value: "Górzno" },
  { label: "Gąbin", value: "Gąbin" },
  { label: "Głogów", value: "Głogów" },
  { label: "Głogów Małopolski", value: "Głogów Małopolski" },
  { label: "Głogówek", value: "Głogówek" },
  { label: "Głowno", value: "Głowno" },
  { label: "Głubczyce", value: "Głubczyce" },
  { label: "Głuchołazy", value: "Głuchołazy" },
  { label: "Głuszyca", value: "Głuszyca" },
  { label: "Hajnówka", value: "Hajnówka" },
  { label: "Halinów", value: "Halinów" },
  { label: "Hel", value: "Hel" },
  { label: "Hrubieszów", value: "Hrubieszów" },
  { label: "Imielin", value: "Imielin" },
  { label: "Inowrocław", value: "Inowrocław" },
  { label: "Iwonicz-Zdrój", value: "Iwonicz-Zdrój" },
  { label: "Izbica Kujawska", value: "Izbica Kujawska" },
  { label: "Iława", value: "Iława" },
  { label: "Iłowa", value: "Iłowa" },
  { label: "Iłża", value: "Iłża" },
  { label: "Ińsko", value: "Ińsko" },
  { label: "Jabłonowo Pomorskie", value: "Jabłonowo Pomorskie" },
  { label: "Janikowo", value: "Janikowo" },
  { label: "Janowiec Wielkopolski", value: "Janowiec Wielkopolski" },
  { label: "Janów Lubelski", value: "Janów Lubelski" },
  { label: "Jaraczewo", value: "Jaraczewo" },
  { label: "Jarocin", value: "Jarocin" },
  { label: "Jarosław", value: "Jarosław" },
  { label: "Jasień", value: "Jasień" },
  { label: "Jastarnia", value: "Jastarnia" },
  { label: "Jastrowie", value: "Jastrowie" },
  { label: "Jastrzębie-Zdrój", value: "Jastrzębie-Zdrój" },
  { label: "Jasło", value: "Jasło" },
  { label: "Jawor", value: "Jawor" },
  { label: "Jaworzno", value: "Jaworzno" },
  { label: "Jaworzyna Śląska", value: "Jaworzyna Śląska" },
  { label: "Jedlicze", value: "Jedlicze" },
  { label: "Jedlina-Zdrój", value: "Jedlina-Zdrój" },
  { label: "Jedwabne", value: "Jedwabne" },
  { label: "Jelcz-Laskowice", value: "Jelcz-Laskowice" },
  { label: "Jelenia Góra", value: "Jelenia Góra" },
  { label: "Jeziorany", value: "Jeziorany" },
  { label: "Jordanów", value: "Jordanów" },
  { label: "Jutrosin", value: "Jutrosin" },
  { label: "Józefów", value: "Józefów" },
  { label: "Józefów", value: "Józefów" },
  { label: "Józefów nad Wisłą", value: "Józefów nad Wisłą" },
  { label: "Jędrzejów", value: "Jędrzejów" },
  { label: "Kalety", value: "Kalety" },
  { label: "Kalisz", value: "Kalisz" },
  { label: "Kalisz Pomorski", value: "Kalisz Pomorski" },
  { label: "Kalwaria Zebrzydowska", value: "Kalwaria Zebrzydowska" },
  { label: "Kamieniec Ząbkowicki", value: "Kamieniec Ząbkowicki" },
  { label: "Kamienna Góra", value: "Kamienna Góra" },
  { label: "Kamień Krajeński", value: "Kamień Krajeński" },
  { label: "Kamień Pomorski", value: "Kamień Pomorski" },
  { label: "Kamieńsk", value: "Kamieńsk" },
  { label: "Kamionka", value: "Kamionka" },
  { label: "Karczew", value: "Karczew" },
  { label: "Kargowa", value: "Kargowa" },
  { label: "Karlino", value: "Karlino" },
  { label: "Karpacz", value: "Karpacz" },
  { label: "Kartuzy", value: "Kartuzy" },
  { label: "Katowice", value: "Katowice" },
  { label: "Kazimierz Dolny", value: "Kazimierz Dolny" },
  { label: "Kazimierza Wielka", value: "Kazimierza Wielka" },
  { label: "Kałuszyn", value: "Kałuszyn" },
  { label: "Kańczuga", value: "Kańczuga" },
  { label: "Kcynia", value: "Kcynia" },
  { label: "Kielce", value: "Kielce" },
  { label: "Kietrz", value: "Kietrz" },
  { label: "Kisielice", value: "Kisielice" },
  { label: "Kleczew", value: "Kleczew" },
  { label: "Kleszczele", value: "Kleszczele" },
  { label: "Klimontów", value: "Klimontów" },
  { label: "Kluczbork", value: "Kluczbork" },
  { label: "Knurów", value: "Knurów" },
  { label: "Knyszyn", value: "Knyszyn" },
  { label: "Kobylin", value: "Kobylin" },
  { label: "Kobyłka", value: "Kobyłka" },
  { label: "Kock", value: "Kock" },
  { label: "Kolbuszowa", value: "Kolbuszowa" },
  { label: "Kolno", value: "Kolno" },
  { label: "Kolonowskie", value: "Kolonowskie" },
  { label: "Koluszki", value: "Koluszki" },
  { label: "Koniecpol", value: "Koniecpol" },
  { label: "Konin", value: "Konin" },
  { label: "Konstancin-Jeziorna", value: "Konstancin-Jeziorna" },
  { label: "Konstantynów Łódzki", value: "Konstantynów Łódzki" },
  { label: "Koprzywnica", value: "Koprzywnica" },
  { label: "Korfantów", value: "Korfantów" },
  { label: "Koronowo", value: "Koronowo" },
  { label: "Korsze", value: "Korsze" },
  { label: "Kostrzyn", value: "Kostrzyn" },
  { label: "Kostrzyn nad Odrą", value: "Kostrzyn nad Odrą" },
  { label: "Koszalin", value: "Koszalin" },
  { label: "Koszyce", value: "Koszyce" },
  { label: "Kosów Lacki", value: "Kosów Lacki" },
  { label: "Kowal", value: "Kowal" },
  { label: "Kowalewo Pomorskie", value: "Kowalewo Pomorskie" },
  { label: "Kowary", value: "Kowary" },
  { label: "Koziegłowy", value: "Koziegłowy" },
  { label: "Kozienice", value: "Kozienice" },
  { label: "Kołaczyce", value: "Kołaczyce" },
  { label: "Koło", value: "Koło" },
  { label: "Kołobrzeg", value: "Kołobrzeg" },
  { label: "Końskie", value: "Końskie" },
  { label: "Kościan", value: "Kościan" },
  { label: "Kościerzyna", value: "Kościerzyna" },
  { label: "Koźmin Wielkopolski", value: "Koźmin Wielkopolski" },
  { label: "Koźminek", value: "Koźminek" },
  { label: "Kożuchów", value: "Kożuchów" },
  { label: "Krajenka", value: "Krajenka" },
  { label: "Kraków", value: "Kraków" },
  { label: "Krapkowice", value: "Krapkowice" },
  { label: "Krasnobród", value: "Krasnobród" },
  { label: "Krasnystaw", value: "Krasnystaw" },
  { label: "Kraśnik", value: "Kraśnik" },
  { label: "Krobia", value: "Krobia" },
  { label: "Krosno", value: "Krosno" },
  { label: "Krosno Odrzańskie", value: "Krosno Odrzańskie" },
  { label: "Krotoszyn", value: "Krotoszyn" },
  { label: "Krośniewice", value: "Krośniewice" },
  { label: "Kruszwica", value: "Kruszwica" },
  { label: "Krynica Morska", value: "Krynica Morska" },
  { label: "Krynica-Zdrój", value: "Krynica-Zdrój" },
  { label: "Krynki", value: "Krynki" },
  { label: "Krzanowice", value: "Krzanowice" },
  { label: "Krzepice", value: "Krzepice" },
  { label: "Krzeszowice", value: "Krzeszowice" },
  { label: "Krzywiń", value: "Krzywiń" },
  { label: "Krzyż Wielkopolski", value: "Krzyż Wielkopolski" },
  { label: "Książ Wielkopolski", value: "Książ Wielkopolski" },
  { label: "Kudowa-Zdrój", value: "Kudowa-Zdrój" },
  { label: "Kunów", value: "Kunów" },
  { label: "Kutno", value: "Kutno" },
  { label: "Kuźnia Raciborska", value: "Kuźnia Raciborska" },
  { label: "Kwidzyn", value: "Kwidzyn" },
  { label: "Kórnik", value: "Kórnik" },
  { label: "Kąty Wrocławskie", value: "Kąty Wrocławskie" },
  { label: "Kędzierzyn-Koźle", value: "Kędzierzyn-Koźle" },
  { label: "Kępice", value: "Kępice" },
  { label: "Kępno", value: "Kępno" },
  { label: "Kętrzyn", value: "Kętrzyn" },
  { label: "Kęty", value: "Kęty" },
  { label: "Kłecko", value: "Kłecko" },
  { label: "Kłobuck", value: "Kłobuck" },
  { label: "Kłodawa", value: "Kłodawa" },
  { label: "Kłodzko", value: "Kłodzko" },
  { label: "Legionowo", value: "Legionowo" },
  { label: "Legnica", value: "Legnica" },
  { label: "Lesko", value: "Lesko" },
  { label: "Leszno", value: "Leszno" },
  { label: "Lewin Brzeski", value: "Lewin Brzeski" },
  { label: "Leśna", value: "Leśna" },
  { label: "Leśnica", value: "Leśnica" },
  { label: "Leżajsk", value: "Leżajsk" },
  { label: "Libiąż", value: "Libiąż" },
  { label: "Lidzbark", value: "Lidzbark" },
  { label: "Lidzbark Warmiński", value: "Lidzbark Warmiński" },
  { label: "Limanowa", value: "Limanowa" },
  { label: "Lipiany", value: "Lipiany" },
  { label: "Lipno", value: "Lipno" },
  { label: "Lipsk", value: "Lipsk" },
  { label: "Lipsko", value: "Lipsko" },
  { label: "Lubaczów", value: "Lubaczów" },
  { label: "Lubartów", value: "Lubartów" },
  { label: "Lubawa", value: "Lubawa" },
  { label: "Lubawka", value: "Lubawka" },
  { label: "Lubań", value: "Lubań" },
  { label: "Lubień Kujawski", value: "Lubień Kujawski" },
  { label: "Lubin", value: "Lubin" },
  { label: "Lublin", value: "Lublin" },
  { label: "Lubliniec", value: "Lubliniec" },
  { label: "Lubniewice", value: "Lubniewice" },
  { label: "Lubomierz", value: "Lubomierz" },
  { label: "Lubowidz", value: "Lubowidz" },
  { label: "Luboń", value: "Luboń" },
  { label: "Lubraniec", value: "Lubraniec" },
  { label: "Lubsko", value: "Lubsko" },
  { label: "Lubycza Królewska", value: "Lubycza Królewska" },
  { label: "Lututów", value: "Lututów" },
  { label: "Lwówek", value: "Lwówek" },
  { label: "Lwówek Śląski", value: "Lwówek Śląski" },
  { label: "Lądek-Zdrój", value: "Lądek-Zdrój" },
  { label: "Lębork", value: "Lębork" },
  { label: "Lędziny", value: "Lędziny" },
  { label: "Maków Mazowiecki", value: "Maków Mazowiecki" },
  { label: "Maków Podhalański", value: "Maków Podhalański" },
  { label: "Malbork", value: "Malbork" },
  { label: "Margonin", value: "Margonin" },
  { label: "Marki", value: "Marki" },
  { label: "Maszewo", value: "Maszewo" },
  { label: "Małogoszcz", value: "Małogoszcz" },
  { label: "Małomice", value: "Małomice" },
  { label: "Miasteczko Śląskie", value: "Miasteczko Śląskie" },
  { label: "Miastko", value: "Miastko" },
  { label: "Michałowo", value: "Michałowo" },
  { label: "Miechów", value: "Miechów" },
  { label: "Miejska Górka", value: "Miejska Górka" },
  { label: "Mielec", value: "Mielec" },
  { label: "Mielno", value: "Mielno" },
  { label: "Mieroszów", value: "Mieroszów" },
  { label: "Mieszkowice", value: "Mieszkowice" },
  { label: "Mikołajki", value: "Mikołajki" },
  { label: "Mikołów", value: "Mikołów" },
  { label: "Mikstat", value: "Mikstat" },
  { label: "Milanówek", value: "Milanówek" },
  { label: "Milicz", value: "Milicz" },
  { label: "Mirosławiec", value: "Mirosławiec" },
  { label: "Mirsk", value: "Mirsk" },
  { label: "Międzybórz", value: "Międzybórz" },
  { label: "Międzychód", value: "Międzychód" },
  { label: "Międzylesie", value: "Międzylesie" },
  { label: "Międzyrzec Podlaski", value: "Międzyrzec Podlaski" },
  { label: "Międzyrzecz", value: "Międzyrzecz" },
  { label: "Międzyzdroje", value: "Międzyzdroje" },
  { label: "Miłakowo", value: "Miłakowo" },
  { label: "Miłomłyn", value: "Miłomłyn" },
  { label: "Miłosław", value: "Miłosław" },
  { label: "Mińsk Mazowiecki", value: "Mińsk Mazowiecki" },
  { label: "Modliborzyce", value: "Modliborzyce" },
  { label: "Mogielnica", value: "Mogielnica" },
  { label: "Mogilno", value: "Mogilno" },
  { label: "Morawica", value: "Morawica" },
  { label: "Mordy", value: "Mordy" },
  { label: "Moryń", value: "Moryń" },
  { label: "Morąg", value: "Morąg" },
  { label: "Mosina", value: "Mosina" },
  { label: "Mońki", value: "Mońki" },
  { label: "Mrocza", value: "Mrocza" },
  { label: "Mrozy", value: "Mrozy" },
  { label: "Mrągowo", value: "Mrągowo" },
  { label: "Mszana Dolna", value: "Mszana Dolna" },
  { label: "Mszczonów", value: "Mszczonów" },
  { label: "Murowana Goślina", value: "Murowana Goślina" },
  { label: "Muszyna", value: "Muszyna" },
  { label: "Myszków", value: "Myszków" },
  { label: "Myszyniec", value: "Myszyniec" },
  { label: "Mysłowice", value: "Mysłowice" },
  { label: "Myślenice", value: "Myślenice" },
  { label: "Myślibórz", value: "Myślibórz" },
  { label: "Mława", value: "Mława" },
  { label: "Młynary", value: "Młynary" },
  { label: "Nakło nad Notecią", value: "Nakło nad Notecią" },
  { label: "Namysłów", value: "Namysłów" },
  { label: "Narol", value: "Narol" },
  { label: "Nasielsk", value: "Nasielsk" },
  { label: "Nałęczów", value: "Nałęczów" },
  { label: "Nekla", value: "Nekla" },
  { label: "Nidzica", value: "Nidzica" },
  { label: "Niemcza", value: "Niemcza" },
  { label: "Niemodlin", value: "Niemodlin" },
  { label: "Niepołomice", value: "Niepołomice" },
  { label: "Nieszawa", value: "Nieszawa" },
  { label: "Nisko", value: "Nisko" },
  { label: "Nowa Dęba", value: "Nowa Dęba" },
  { label: "Nowa Ruda", value: "Nowa Ruda" },
  { label: "Nowa Sarzyna", value: "Nowa Sarzyna" },
  { label: "Nowa Sól", value: "Nowa Sól" },
  { label: "Nowa Słupia", value: "Nowa Słupia" },
  { label: "Nowe", value: "Nowe" },
  { label: "Nowe Brzesko", value: "Nowe Brzesko" },
  { label: "Nowe Miasteczko", value: "Nowe Miasteczko" },
  { label: "Nowe Miasto Lubawskie", value: "Nowe Miasto Lubawskie" },
  { label: "Nowe Miasto nad Pilicą", value: "Nowe Miasto nad Pilicą" },
  { label: "Nowe Skalmierzyce", value: "Nowe Skalmierzyce" },
  { label: "Nowe Warpno", value: "Nowe Warpno" },
  { label: "Nowogard", value: "Nowogard" },
  { label: "Nowogrodziec", value: "Nowogrodziec" },
  { label: "Nowogród", value: "Nowogród" },
  { label: "Nowogród Bobrzański", value: "Nowogród Bobrzański" },
  { label: "Nowy Dwór Gdański", value: "Nowy Dwór Gdański" },
  { label: "Nowy Dwór Mazowiecki", value: "Nowy Dwór Mazowiecki" },
  { label: "Nowy Korczyn", value: "Nowy Korczyn" },
  { label: "Nowy Staw", value: "Nowy Staw" },
  { label: "Nowy Sącz", value: "Nowy Sącz" },
  { label: "Nowy Targ", value: "Nowy Targ" },
  { label: "Nowy Tomyśl", value: "Nowy Tomyśl" },
  { label: "Nowy Wiśnicz", value: "Nowy Wiśnicz" },
  { label: "Nysa", value: "Nysa" },
  { label: "Oborniki", value: "Oborniki" },
  { label: "Oborniki Śląskie", value: "Oborniki Śląskie" },
  { label: "Obrzycko", value: "Obrzycko" },
  { label: "Odolanów", value: "Odolanów" },
  { label: "Ogrodzieniec", value: "Ogrodzieniec" },
  { label: "Okonek", value: "Okonek" },
  { label: "Olecko", value: "Olecko" },
  { label: "Olesno", value: "Olesno" },
  { label: "Oleszyce", value: "Oleszyce" },
  { label: "Oleśnica", value: "Oleśnica" },
  { label: "Oleśnica", value: "Oleśnica" },
  { label: "Olkusz", value: "Olkusz" },
  { label: "Olsztyn", value: "Olsztyn" },
  { label: "Olsztynek", value: "Olsztynek" },
  { label: "Olszyna", value: "Olszyna" },
  { label: "Opalenica", value: "Opalenica" },
  { label: "Opatowiec", value: "Opatowiec" },
  { label: "Opatów", value: "Opatów" },
  { label: "Opatówek", value: "Opatówek" },
  { label: "Opoczno", value: "Opoczno" },
  { label: "Opole", value: "Opole" },
  { label: "Opole Lubelskie", value: "Opole Lubelskie" },
  { label: "Orneta", value: "Orneta" },
  { label: "Orzesze", value: "Orzesze" },
  { label: "Orzysz", value: "Orzysz" },
  { label: "Osieczna", value: "Osieczna" },
  { label: "Osiek", value: "Osiek" },
  { label: "Ostroróg", value: "Ostroróg" },
  { label: "Ostrowiec Świętokrzyski", value: "Ostrowiec Świętokrzyski" },
  { label: "Ostrołęka", value: "Ostrołęka" },
  { label: "Ostrzeszów", value: "Ostrzeszów" },
  { label: "Ostróda", value: "Ostróda" },
  { label: "Ostrów Lubelski", value: "Ostrów Lubelski" },
  { label: "Ostrów Mazowiecka", value: "Ostrów Mazowiecka" },
  { label: "Ostrów Wielkopolski", value: "Ostrów Wielkopolski" },
  { label: "Otmuchów", value: "Otmuchów" },
  { label: "Otwock", value: "Otwock" },
  { label: "Otyń", value: "Otyń" },
  { label: "Ozimek", value: "Ozimek" },
  { label: "Ozorków", value: "Ozorków" },
  { label: "Oława", value: "Oława" },
  { label: "Ośno Lubuskie", value: "Ośno Lubuskie" },
  { label: "Oświęcim", value: "Oświęcim" },
  { label: "Ożarów", value: "Ożarów" },
  { label: "Ożarów Mazowiecki", value: "Ożarów Mazowiecki" },
  { label: "Pabianice", value: "Pabianice" },
  { label: "Pacanów", value: "Pacanów" },
  { label: "Paczków", value: "Paczków" },
  { label: "Pajęczno", value: "Pajęczno" },
  { label: "Pakość", value: "Pakość" },
  { label: "Parczew", value: "Parczew" },
  { label: "Pasym", value: "Pasym" },
  { label: "Pasłęk", value: "Pasłęk" },
  { label: "Pelplin", value: "Pelplin" },
  { label: "Pełczyce", value: "Pełczyce" },
  { label: "Piaseczno", value: "Piaseczno" },
  { label: "Piaski", value: "Piaski" },
  { label: "Piastów", value: "Piastów" },
  { label: "Piechowice", value: "Piechowice" },
  { label: "Piekary Śląskie", value: "Piekary Śląskie" },
  { label: "Pieniężno", value: "Pieniężno" },
  { label: "Pierzchnica", value: "Pierzchnica" },
  { label: "Pieszyce", value: "Pieszyce" },
  { label: "Pieńsk", value: "Pieńsk" },
  { label: "Pilawa", value: "Pilawa" },
  { label: "Pilica", value: "Pilica" },
  { label: "Pilzno", value: "Pilzno" },
  { label: "Pionki", value: "Pionki" },
  { label: "Piotrków Kujawski", value: "Piotrków Kujawski" },
  { label: "Piotrków Trybunalski", value: "Piotrków Trybunalski" },
  { label: "Pisz", value: "Pisz" },
  { label: "Piwniczna-Zdrój", value: "Piwniczna-Zdrój" },
  { label: "Piątek", value: "Piątek" },
  { label: "Piła", value: "Piła" },
  { label: "Piława Górna", value: "Piława Górna" },
  { label: "Pińczów", value: "Pińczów" },
  { label: "Pleszew", value: "Pleszew" },
  { label: "Pniewy", value: "Pniewy" },
  { label: "Pobiedziska", value: "Pobiedziska" },
  { label: "Poddębice", value: "Poddębice" },
  { label: "Podkowa Leśna", value: "Podkowa Leśna" },
  { label: "Pogorzela", value: "Pogorzela" },
  { label: "Polanica-Zdrój", value: "Polanica-Zdrój" },
  { label: "Polanów", value: "Polanów" },
  { label: "Police", value: "Police" },
  { label: "Polkowice", value: "Polkowice" },
  { label: "Poniatowa", value: "Poniatowa" },
  { label: "Poniec", value: "Poniec" },
  { label: "Poręba", value: "Poręba" },
  { label: "Poznań", value: "Poznań" },
  { label: "Połaniec", value: "Połaniec" },
  { label: "Połczyn-Zdrój", value: "Połczyn-Zdrój" },
  { label: "Prabuty", value: "Prabuty" },
  { label: "Praszka", value: "Praszka" },
  { label: "Prochowice", value: "Prochowice" },
  { label: "Proszowice", value: "Proszowice" },
  { label: "Pruchnik", value: "Pruchnik" },
  { label: "Prudnik", value: "Prudnik" },
  { label: "Prusice", value: "Prusice" },
  { label: "Pruszcz Gdański", value: "Pruszcz Gdański" },
  { label: "Pruszków", value: "Pruszków" },
  { label: "Przasnysz", value: "Przasnysz" },
  { label: "Przecław", value: "Przecław" },
  { label: "Przedbórz", value: "Przedbórz" },
  { label: "Przedecz", value: "Przedecz" },
  { label: "Przemków", value: "Przemków" },
  { label: "Przemyśl", value: "Przemyśl" },
  { label: "Przeworsk", value: "Przeworsk" },
  { label: "Przysucha", value: "Przysucha" },
  { label: "Prószków", value: "Prószków" },
  { label: "Pszczyna", value: "Pszczyna" },
  { label: "Pszów", value: "Pszów" },
  { label: "Puck", value: "Puck" },
  { label: "Puszczykowo", value: "Puszczykowo" },
  { label: "Puławy", value: "Puławy" },
  { label: "Pułtusk", value: "Pułtusk" },
  { label: "Pyrzyce", value: "Pyrzyce" },
  { label: "Pyskowice", value: "Pyskowice" },
  { label: "Pyzdry", value: "Pyzdry" },
  { label: "Płock", value: "Płock" },
  { label: "Płoty", value: "Płoty" },
  { label: "Płońsk", value: "Płońsk" },
  { label: "Rabka-Zdrój", value: "Rabka-Zdrój" },
  { label: "Racibórz", value: "Racibórz" },
  { label: "Raciąż", value: "Raciąż" },
  { label: "Radków", value: "Radków" },
  { label: "Radlin", value: "Radlin" },
  { label: "Radom", value: "Radom" },
  { label: "Radomsko", value: "Radomsko" },
  { label: "Radomyśl Wielki", value: "Radomyśl Wielki" },
  { label: "Radoszyce", value: "Radoszyce" },
  { label: "Radymno", value: "Radymno" },
  { label: "Radziejów", value: "Radziejów" },
  { label: "Radzionków", value: "Radzionków" },
  { label: "Radzymin", value: "Radzymin" },
  { label: "Radzyń Chełmiński", value: "Radzyń Chełmiński" },
  { label: "Radzyń Podlaski", value: "Radzyń Podlaski" },
  { label: "Radłów", value: "Radłów" },
  { label: "Rajgród", value: "Rajgród" },
  { label: "Rakoniewice", value: "Rakoniewice" },
  { label: "Raszków", value: "Raszków" },
  { label: "Rawa Mazowiecka", value: "Rawa Mazowiecka" },
  { label: "Rawicz", value: "Rawicz" },
  { label: "Recz", value: "Recz" },
  { label: "Reda", value: "Reda" },
  { label: "Rejowiec", value: "Rejowiec" },
  { label: "Rejowiec Fabryczny", value: "Rejowiec Fabryczny" },
  { label: "Resko", value: "Resko" },
  { label: "Reszel", value: "Reszel" },
  { label: "Rogoźno", value: "Rogoźno" },
  { label: "Ropczyce", value: "Ropczyce" },
  { label: "Ruciane-Nida", value: "Ruciane-Nida" },
  { label: "Ruda Śląska", value: "Ruda Śląska" },
  { label: "Rudnik nad Sanem", value: "Rudnik nad Sanem" },
  { label: "Rumia", value: "Rumia" },
  { label: "Rybnik", value: "Rybnik" },
  { label: "Rychwał", value: "Rychwał" },
  { label: "Rydułtowy", value: "Rydułtowy" },
  { label: "Rydzyna", value: "Rydzyna" },
  { label: "Ryglice", value: "Ryglice" },
  { label: "Ryki", value: "Ryki" },
  { label: "Rymanów", value: "Rymanów" },
  { label: "Ryn", value: "Ryn" },
  { label: "Rypin", value: "Rypin" },
  { label: "Rzepin", value: "Rzepin" },
  { label: "Rzeszów", value: "Rzeszów" },
  { label: "Rzgów", value: "Rzgów" },
  { label: "Różan", value: "Różan" },
  { label: "Sandomierz", value: "Sandomierz" },
  { label: "Sanniki", value: "Sanniki" },
  { label: "Sanok", value: "Sanok" },
  { label: "Sejny", value: "Sejny" },
  { label: "Serock", value: "Serock" },
  { label: "Sianów", value: "Sianów" },
  { label: "Siechnice", value: "Siechnice" },
  { label: "Siedlce", value: "Siedlce" },
  { label: "Siedliszcze", value: "Siedliszcze" },
  { label: "Siemianowice Śląskie", value: "Siemianowice Śląskie" },
  { label: "Siemiatycze", value: "Siemiatycze" },
  { label: "Sieniawa", value: "Sieniawa" },
  { label: "Sieradz", value: "Sieradz" },
  { label: "Sieraków", value: "Sieraków" },
  { label: "Sierpc", value: "Sierpc" },
  { label: "Siewierz", value: "Siewierz" },
  { label: "Skalbmierz", value: "Skalbmierz" },
  { label: "Skarszewy", value: "Skarszewy" },
  { label: "Skaryszew", value: "Skaryszew" },
  { label: "Skarżysko-Kamienna", value: "Skarżysko-Kamienna" },
  { label: "Skawina", value: "Skawina" },
  { label: "Skała", value: "Skała" },
  { label: "Skierniewice", value: "Skierniewice" },
  { label: "Skoczów", value: "Skoczów" },
  { label: "Skoki", value: "Skoki" },
  { label: "Skwierzyna", value: "Skwierzyna" },
  { label: "Skórcz", value: "Skórcz" },
  { label: "Skępe", value: "Skępe" },
  { label: "Sobótka", value: "Sobótka" },
  { label: "Sochaczew", value: "Sochaczew" },
  { label: "Sochocin", value: "Sochocin" },
  { label: "Sokołów Małopolski", value: "Sokołów Małopolski" },
  { label: "Sokołów Podlaski", value: "Sokołów Podlaski" },
  { label: "Sokółka", value: "Sokółka" },
  { label: "Solec Kujawski", value: "Solec Kujawski" },
  { label: "Solec nad Wisłą", value: "Solec nad Wisłą" },
  { label: "Sompolno", value: "Sompolno" },
  { label: "Sopot", value: "Sopot" },
  { label: "Sosnowiec", value: "Sosnowiec" },
  { label: "Sośnicowice", value: "Sośnicowice" },
  { label: "Stalowa Wola", value: "Stalowa Wola" },
  { label: "Starachowice", value: "Starachowice" },
  { label: "Stargard", value: "Stargard" },
  { label: "Starogard Gdański", value: "Starogard Gdański" },
  { label: "Stary Sącz", value: "Stary Sącz" },
  { label: "Staszów", value: "Staszów" },
  { label: "Stawiski", value: "Stawiski" },
  { label: "Stawiszyn", value: "Stawiszyn" },
  { label: "Stepnica", value: "Stepnica" },
  { label: "Stoczek Łukowski", value: "Stoczek Łukowski" },
  { label: "Stopnica", value: "Stopnica" },
  { label: "Stronie Śląskie", value: "Stronie Śląskie" },
  { label: "Strumień", value: "Strumień" },
  { label: "Stryków", value: "Stryków" },
  { label: "Strzegom", value: "Strzegom" },
  { label: "Strzelce Krajeńskie", value: "Strzelce Krajeńskie" },
  { label: "Strzelce Opolskie", value: "Strzelce Opolskie" },
  { label: "Strzelin", value: "Strzelin" },
  { label: "Strzelno", value: "Strzelno" },
  { label: "Strzyżów", value: "Strzyżów" },
  { label: "Stąporków", value: "Stąporków" },
  { label: "Stęszew", value: "Stęszew" },
  { label: "Sucha Beskidzka", value: "Sucha Beskidzka" },
  { label: "Suchań", value: "Suchań" },
  { label: "Suchedniów", value: "Suchedniów" },
  { label: "Suchowola", value: "Suchowola" },
  { label: "Sulechów", value: "Sulechów" },
  { label: "Sulejów", value: "Sulejów" },
  { label: "Sulejówek", value: "Sulejówek" },
  { label: "Sulmierzyce", value: "Sulmierzyce" },
  { label: "Sulęcin", value: "Sulęcin" },
  { label: "Supraśl", value: "Supraśl" },
  { label: "Suraż", value: "Suraż" },
  { label: "Susz", value: "Susz" },
  { label: "Suwałki", value: "Suwałki" },
  { label: "Sułkowice", value: "Sułkowice" },
  { label: "Swarzędz", value: "Swarzędz" },
  { label: "Syców", value: "Syców" },
  { label: "Szadek", value: "Szadek" },
  { label: "Szamocin", value: "Szamocin" },
  { label: "Szamotuły", value: "Szamotuły" },
  { label: "Szczawnica", value: "Szczawnica" },
  { label: "Szczawno-Zdrój", value: "Szczawno-Zdrój" },
  { label: "Szczebrzeszyn", value: "Szczebrzeszyn" },
  { label: "Szczecin", value: "Szczecin" },
  { label: "Szczecinek", value: "Szczecinek" },
  { label: "Szczekociny", value: "Szczekociny" },
  { label: "Szczucin", value: "Szczucin" },
  { label: "Szczuczyn", value: "Szczuczyn" },
  { label: "Szczyrk", value: "Szczyrk" },
  { label: "Szczytna", value: "Szczytna" },
  { label: "Szczytno", value: "Szczytno" },
  { label: "Szepietowo", value: "Szepietowo" },
  { label: "Szklarska Poręba", value: "Szklarska Poręba" },
  { label: "Szlichtyngowa", value: "Szlichtyngowa" },
  { label: "Szprotawa", value: "Szprotawa" },
  { label: "Sztum", value: "Sztum" },
  { label: "Szubin", value: "Szubin" },
  { label: "Szydłowiec", value: "Szydłowiec" },
  { label: "Szydłów", value: "Szydłów" },
  { label: "Sędziszów", value: "Sędziszów" },
  { label: "Sędziszów Małopolski", value: "Sędziszów Małopolski" },
  { label: "Sępopol", value: "Sępopol" },
  { label: "Sępólno Krajeńskie", value: "Sępólno Krajeńskie" },
  { label: "Sława", value: "Sława" },
  { label: "Sławków", value: "Sławków" },
  { label: "Sławno", value: "Sławno" },
  { label: "Słomniki", value: "Słomniki" },
  { label: "Słubice", value: "Słubice" },
  { label: "Słupca", value: "Słupca" },
  { label: "Słupsk", value: "Słupsk" },
  { label: "Tarczyn", value: "Tarczyn" },
  { label: "Tarnobrzeg", value: "Tarnobrzeg" },
  { label: "Tarnogród", value: "Tarnogród" },
  { label: "Tarnowskie Góry", value: "Tarnowskie Góry" },
  { label: "Tarnów", value: "Tarnów" },
  { label: "Tczew", value: "Tczew" },
  { label: "Terespol", value: "Terespol" },
  { label: "Tolkmicko", value: "Tolkmicko" },
  { label: "Tomaszów Lubelski", value: "Tomaszów Lubelski" },
  { label: "Tomaszów Mazowiecki", value: "Tomaszów Mazowiecki" },
  { label: "Toruń", value: "Toruń" },
  { label: "Torzym", value: "Torzym" },
  { label: "Toszek", value: "Toszek" },
  { label: "Trzcianka", value: "Trzcianka" },
  { label: "Trzciel", value: "Trzciel" },
  { label: "Trzcińsko-Zdrój", value: "Trzcińsko-Zdrój" },
  { label: "Trzebiatów", value: "Trzebiatów" },
  { label: "Trzebinia", value: "Trzebinia" },
  { label: "Trzebnica", value: "Trzebnica" },
  { label: "Trzemeszno", value: "Trzemeszno" },
  { label: "Tuchola", value: "Tuchola" },
  { label: "Tuchów", value: "Tuchów" },
  { label: "Tuczno", value: "Tuczno" },
  { label: "Tuliszków", value: "Tuliszków" },
  { label: "Turek", value: "Turek" },
  { label: "Tuszyn", value: "Tuszyn" },
  { label: "Tułowice", value: "Tułowice" },
  { label: "Twardogóra", value: "Twardogóra" },
  { label: "Tychowo", value: "Tychowo" },
  { label: "Tychy", value: "Tychy" },
  { label: "Tyczyn", value: "Tyczyn" },
  { label: "Tykocin", value: "Tykocin" },
  { label: "Tyszowce", value: "Tyszowce" },
  { label: "Tłuszcz", value: "Tłuszcz" },
  { label: "Ujazd", value: "Ujazd" },
  { label: "Ujście", value: "Ujście" },
  { label: "Ulanów", value: "Ulanów" },
  { label: "Uniejów", value: "Uniejów" },
  { label: "Urzędów", value: "Urzędów" },
  { label: "Ustka", value: "Ustka" },
  { label: "Ustroń", value: "Ustroń" },
  { label: "Ustrzyki Dolne", value: "Ustrzyki Dolne" },
  { label: "Wadowice", value: "Wadowice" },
  { label: "Warka", value: "Warka" },
  { label: "Warszawa", value: "Warszawa" },
  { label: "Warta", value: "Warta" },
  { label: "Wasilków", value: "Wasilków" },
  { label: "Wałbrzych", value: "Wałbrzych" },
  { label: "Wałcz", value: "Wałcz" },
  { label: "Wejherowo", value: "Wejherowo" },
  { label: "Wielbark", value: "Wielbark" },
  { label: "Wieleń", value: "Wieleń" },
  { label: "Wielichowo", value: "Wielichowo" },
  { label: "Wieliczka", value: "Wieliczka" },
  { label: "Wieluń", value: "Wieluń" },
  { label: "Wieruszów", value: "Wieruszów" },
  { label: "Wilamowice", value: "Wilamowice" },
  { label: "Wiskitki", value: "Wiskitki" },
  { label: "Wisła", value: "Wisła" },
  { label: "Witkowo", value: "Witkowo" },
  { label: "Witnica", value: "Witnica" },
  { label: "Wiązów", value: "Wiązów" },
  { label: "Więcbork", value: "Więcbork" },
  { label: "Wiślica", value: "Wiślica" },
  { label: "Wleń", value: "Wleń" },
  { label: "Wodzisław", value: "Wodzisław" },
  { label: "Wodzisław Śląski", value: "Wodzisław Śląski" },
  { label: "Wojcieszów", value: "Wojcieszów" },
  { label: "Wojkowice", value: "Wojkowice" },
  { label: "Wojnicz", value: "Wojnicz" },
  { label: "Wolbrom", value: "Wolbrom" },
  { label: "Wolbórz", value: "Wolbórz" },
  { label: "Wolin", value: "Wolin" },
  { label: "Wolsztyn", value: "Wolsztyn" },
  { label: "Wołczyn", value: "Wołczyn" },
  { label: "Wołomin", value: "Wołomin" },
  { label: "Wołów", value: "Wołów" },
  { label: "Woźniki", value: "Woźniki" },
  { label: "Wrocław", value: "Wrocław" },
  { label: "Wronki", value: "Wronki" },
  { label: "Września", value: "Września" },
  { label: "Wschowa", value: "Wschowa" },
  { label: "Wyrzysk", value: "Wyrzysk" },
  { label: "Wysoka", value: "Wysoka" },
  { label: "Wysokie Mazowieckie", value: "Wysokie Mazowieckie" },
  { label: "Wyszków", value: "Wyszków" },
  { label: "Wyszogród", value: "Wyszogród" },
  { label: "Wyśmierzyce", value: "Wyśmierzyce" },
  { label: "Wąbrzeźno", value: "Wąbrzeźno" },
  { label: "Wąchock", value: "Wąchock" },
  { label: "Wągrowiec", value: "Wągrowiec" },
  { label: "Wąsosz", value: "Wąsosz" },
  { label: "Węgliniec", value: "Węgliniec" },
  { label: "Węgorzewo", value: "Węgorzewo" },
  { label: "Węgorzyno", value: "Węgorzyno" },
  { label: "Węgrów", value: "Węgrów" },
  { label: "Władysławowo", value: "Władysławowo" },
  { label: "Włocławek", value: "Włocławek" },
  { label: "Włodawa", value: "Włodawa" },
  { label: "Włoszczowa", value: "Włoszczowa" },
  { label: "Zabrze", value: "Zabrze" },
  { label: "Zabłudów", value: "Zabłudów" },
  { label: "Zagórz", value: "Zagórz" },
  { label: "Zagórów", value: "Zagórów" },
  { label: "Zakliczyn", value: "Zakliczyn" },
  { label: "Zaklików", value: "Zaklików" },
  { label: "Zakopane", value: "Zakopane" },
  { label: "Zakroczym", value: "Zakroczym" },
  { label: "Zalewo", value: "Zalewo" },
  { label: "Zambrów", value: "Zambrów" },
  { label: "Zamość", value: "Zamość" },
  { label: "Zator", value: "Zator" },
  { label: "Zawadzkie", value: "Zawadzkie" },
  { label: "Zawichost", value: "Zawichost" },
  { label: "Zawidów", value: "Zawidów" },
  { label: "Zawiercie", value: "Zawiercie" },
  { label: "Zbąszynek", value: "Zbąszynek" },
  { label: "Zbąszyń", value: "Zbąszyń" },
  { label: "Zduny", value: "Zduny" },
  { label: "Zduńska Wola", value: "Zduńska Wola" },
  { label: "Zdzieszowice", value: "Zdzieszowice" },
  { label: "Zelów", value: "Zelów" },
  { label: "Zgierz", value: "Zgierz" },
  { label: "Zgorzelec", value: "Zgorzelec" },
  { label: "Zielona Góra", value: "Zielona Góra" },
  { label: "Zielonka", value: "Zielonka" },
  { label: "Ziębice", value: "Ziębice" },
  { label: "Zwierzyniec", value: "Zwierzyniec" },
  { label: "Zwoleń", value: "Zwoleń" },
  { label: "Ząbki", value: "Ząbki" },
  { label: "Ząbkowice Śląskie", value: "Ząbkowice Śląskie" },
  { label: "Złocieniec", value: "Złocieniec" },
  { label: "Złoczew", value: "Złoczew" },
  { label: "Złotoryja", value: "Złotoryja" },
  { label: "Złoty Stok", value: "Złoty Stok" },
  { label: "Złotów", value: "Złotów" },
  { label: "Ćmielów", value: "Ćmielów" },
  { label: "Łabiszyn", value: "Łabiszyn" },
  { label: "Łagów", value: "Łagów" },
  { label: "Łapy", value: "Łapy" },
  { label: "Łasin", value: "Łasin" },
  { label: "Łask", value: "Łask" },
  { label: "Łaskarzew", value: "Łaskarzew" },
  { label: "Łaszczów", value: "Łaszczów" },
  { label: "Łaziska Górne", value: "Łaziska Górne" },
  { label: "Łazy", value: "Łazy" },
  { label: "Łańcut", value: "Łańcut" },
  { label: "Łeba", value: "Łeba" },
  { label: "Łobez", value: "Łobez" },
  { label: "Łobżenica", value: "Łobżenica" },
  { label: "Łochów", value: "Łochów" },
  { label: "Łomianki", value: "Łomianki" },
  { label: "Łomża", value: "Łomża" },
  { label: "Łosice", value: "Łosice" },
  { label: "Łowicz", value: "Łowicz" },
  { label: "Łuków", value: "Łuków" },
  { label: "Łódź", value: "Łódź" },
  { label: "Łęczna", value: "Łęczna" },
  { label: "Łęczyca", value: "Łęczyca" },
  { label: "Łęknica", value: "Łęknica" },
  { label: "Ścinawa", value: "Ścinawa" },
  { label: "Ślesin", value: "Ślesin" },
  { label: "Śmigiel", value: "Śmigiel" },
  { label: "Śrem", value: "Śrem" },
  { label: "Środa Wielkopolska", value: "Środa Wielkopolska" },
  { label: "Środa Śląska", value: "Środa Śląska" },
  { label: "Świdnica", value: "Świdnica" },
  { label: "Świdnik", value: "Świdnik" },
  { label: "Świdwin", value: "Świdwin" },
  { label: "Świebodzice", value: "Świebodzice" },
  { label: "Świebodzin", value: "Świebodzin" },
  { label: "Świecie", value: "Świecie" },
  { label: "Świeradów-Zdrój", value: "Świeradów-Zdrój" },
  { label: "Świerzawa", value: "Świerzawa" },
  { label: "Świnoujście", value: "Świnoujście" },
  { label: "Świątniki Górne", value: "Świątniki Górne" },
  { label: "Świętochłowice", value: "Świętochłowice" },
  { label: "Żabno", value: "Żabno" },
  { label: "Żagań", value: "Żagań" },
  { label: "Żarki", value: "Żarki" },
  { label: "Żary", value: "Żary" },
  { label: "Żarów", value: "Żarów" },
  { label: "Żelechów", value: "Żelechów" },
  { label: "Żerków", value: "Żerków" },
  { label: "Żmigród", value: "Żmigród" },
  { label: "Żnin", value: "Żnin" },
  { label: "Żory", value: "Żory" },
  { label: "Żukowo", value: "Żukowo" },
  { label: "Żuromin", value: "Żuromin" },
  { label: "Żychlin", value: "Żychlin" },
  { label: "Żyrardów", value: "Żyrardów" },
  { label: "Żywiec", value: "Żywiec" },
];
