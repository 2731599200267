import {
  CREATE_NEGOTIATION,
  getNegotiationsUrl,
  getNegotiationUrl,
} from "constants/paths";
import qs from "qs";
import { parseNegotiations } from "store/negotiations/parser";
import { IFetchNegotiationsResponse } from "store/negotiations/types";
import { fetchAPI } from "utilities/fetchAPI";

interface IGetNegotiations {
  advertisementId: number;
  finishedStatus?: boolean;
}

export const fetchNegotiations = async ({
  advertisementId,
  finishedStatus,
}: IGetNegotiations) => {
  const query = qs.stringify({ finished_status: finishedStatus });
  const path = `${getNegotiationsUrl(advertisementId.toString())}?${query}`;
  const response = await fetchAPI<IFetchNegotiationsResponse>(path, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response;
};

export interface INegotiation {
  data: {
    advertisement_creator: {
      first_name: string;
      id: number;
      last_name: string;
      description: string | null;
    };
    advertisement_id: number;
    conversation: {
      id: number;
      created_at: string;
      updated_at: string;
    };
    finished: boolean;
    id: number;
    mutual_contacts_count: number;
    price: number;
    state: string;
    user: {
      avatar: string;
      id: number;
      profile: {
        first_name: string;
        last_name: string;
        description: string | null;
      };
    };
  };
}

export const fetchNegotiation = async (id: string) => {
  const response = await fetchAPI<INegotiation>(`${getNegotiationUrl(id)}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response;
};

export const createNegotiaion = async (id: string) => {
  const response = await fetchAPI(CREATE_NEGOTIATION, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      negotiation: {
        advertisement_id: id,
      },
    }),
  });

  return response;
};

type IState = "created" | "offered" | "accepted";
interface IUpdateNegotiation {
  id: string;
  advertisementId: number;
  price?: number;
  state?: IState;
}

export const updateNegotiations = async ({
  id,
  advertisementId,
  price,
  state,
}: IUpdateNegotiation) => {
  const response = await fetchAPI(`${getNegotiationUrl(id)}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      negotiation: {
        advertisement_id: advertisementId,
        price,
        state,
      },
    }),
  });
};
