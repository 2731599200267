export const lookingForFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label:
      "Miewam okazje inwestycyjne, poszukuję finansowania przez pojedynczego Inwestora",
    value: "looking_for_single_investor",
  },
  {
    label:
      "Miewam okazje inwestycyjne, poszukuję finansowania przez grupę Inwestorów",
    value: "looking_for_group_of_investors",
  },
  {
    label:
      "Miewam środki finansowe, poszukuję okazji inwestycyjnych dla siebie",
    value: "looking_for_single_investor_investments",
  },
  {
    label:
      "Miewam środki finansowe, poszukuję okazji inwestycyjnych jako współinwestor",
    value: "looking_for_group_investors_investments",
  },
  {
    label: "Chcę budować sieć znajomych inwestorów",
    value: "looking_for_network_of_contacts",
  },
];

export const specializedInFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label: "Gotowce inwestycyjne",
    value: "specialized_in_ready_investments",
  },
  {
    label: "Inwestycje do aranżacji lub przebudowy",
    value: "specialized_in_rebuilding_investments",
  },
];

export const mostImportantFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label: "Cena",
    value: "the_most_important_is_price",
  },
  {
    label: "Lokalizacja",
    value: "the_most_important_is_location",
  },
  {
    label: "Rok budowy",
    value: "the_most_important_is_year_of_construction",
  },
  {
    label: "Powierzchnia",
    value: "the_most_important_is_area",
  },
  {
    label: "ROI",
    value: "the_most_important_is_roi",
  },
  {
    label: "Zdjęcia",
    value: "the_most_important_are_photos",
  },
];

export const networkingForFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label:
      "Chcę skalować swoją  działalność na nowe miasta i regiony wspólnie z nowymi znajomymi",
    value: "networking_for_scaling_through_regions",
  },
  {
    label:
      "Chcę wejść na wyższy poziom finansowy inwestycji niż dotychczas wspólnie z nowymi znajomymi",
    value: "networking_for_creating_a_bigger_investments",
  },
];

export const realEstateExperienceInMonthsFields: Array<{
  label: string;
  value: number | string;
}> = [
  {
    label: "< 1 rok",
    value: "6",
  },
  {
    label: "1-5 lat",
    value: "30",
  },
  {
    label: "5-10 lat",
    value: "90",
  },
  {
    label: "10-20 lat",
    value: "180",
  },
  {
    label: "> 20 lat",
    value: "360",
  },
];

export const investmentsSpecializationsFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label: "Mieszkania",
    value: "Mieszkania",
  },
  {
    label: "Kamienice",
    value: "Kamienice",
  },
  {
    label: "Lokale usługowe",
    value: "Lokale_uslugowe",
  },
  {
    label: "Magazyny i hale",
    value: "Magazyny_i_hale",
  },
  {
    label: "Działki pod inwestycje",
    value: "Dzialki_pod_inwestycje",
  },
  {
    label: "Inne",
    value: "inne",
  },
];

export const investmentsLevelsFields: Array<{
  label: string;
  value: string;
}> = [
  {
    label: "< 200 000 PLN",
    value: "< 200 000 PLN",
  },
  {
    label: "200 000 - 500 000 PLN",
    value: "200 000 - 500 000 PLN",
  },
  {
    label: "500 000 - 1 000 000 PLN",
    value: "500 000 - 1 000 000 PLN",
  },
  {
    label: "1 000 000 - 2 000 000 PLN",
    value: "1 000 000 - 2 000 000 PLN",
  },
  {
    label: "> 2 000 000 PLN",
    value: "> 2 000 000 PLN",
  },
];
