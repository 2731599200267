import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

interface IRequireAuthModal {
  isOpen: boolean;
  closeAuthModal: () => void;
  goToLogin: () => void;
  goToRegister: () => void;
}

const H1 = styled(Typography)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[4]}px;
  `}
`;
const Body = styled(Typography)`
  font-size: 14px;
`;

export const RequireAuthModal: React.FC<IRequireAuthModal> = ({
  isOpen,
  closeAuthModal,
  goToLogin,
  goToRegister,
}) => {
  return (
    <Modal onCancelClick={closeAuthModal} isOpen={isOpen}>
      <Box p={8}>
        <H1 variant="h1" color="primary">
          Aby skorzystać z pełnej wersji serwisu musisz się zalogować
        </H1>
        <Flex mt={5} justifyContent="flex-end">
          <Button label="Anuluj" onClick={closeAuthModal} />
          <Box ml={6}>
            <Button label="Zarejestruj się" onClick={goToRegister} />
          </Box>
          <Box ml={6}>
            <Button label="Zaloguj się" onClick={goToLogin} />
          </Box>
        </Flex>
      </Box>
    </Modal>
  );
};
