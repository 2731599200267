import { Box } from "components/Box";
import { Menu } from "components/Menu";
import { Router } from "components/Router";
import { Typography } from "components/Typography";
import { useMenu } from "hooks/menu";
import React from "react";
import { Col, Row } from "react-grid-system";
import { IRoute } from "types/route";

export const NegotiationsView: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  const menuItems = useMenu([
    {
      label: "Aktualne",
      path: `/negotiations/current`,
    },
    {
      label: "Zakończone",
      path: `/negotiations/finished`,
    },
  ]);

  return (
    <>
      <Typography variant="h1" color="primary" mb={3}>
        Moje negocjacje
      </Typography>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <Menu items={menuItems} />
          </Box>
        </Col>
        <Col sm={8}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
