import { voivodships } from "database/voivodship";
import { AdvertisementCategoryType } from "types/advertisement";

import { cities } from "./cities";

export interface IOption {
  value: string;
  label: string;
}

interface IVariant {
  name: string;
  label: string;
  options?: Array<IOption>;
  type: "text" | "dropdown" | "check";
}

export const generateDataForFields = (
  type: AdvertisementCategoryType
): Array<IVariant> => {
  switch (type) {
    case "apartment":
      return [
        {
          name: "price",
          label: "Cena",
          type: "text",
        },
        {
          name: "year_of_construction",
          label: "Rok budowy",
          type: "text",
        },
        {
          name: "number_of_apartments",
          label: "Ilość pokoi",
          type: "text",
        },
        {
          name: "area",
          label: "Powierzchnia",
          type: "text",
        },
        {
          name: "floor",
          label: "Piętro",
          type: "text",
        },
        {
          name: "number_of_apartments_on_floor",
          label: "Ilość mieszkań na piętrze",
          type: "text",
        },
        {
          name: "voivodship",
          label: "Województwo",
          options: voivodships,
          type: "dropdown",
        },
        {
          name: "city",
          label: "Miasto",
          options: cities,
          type: "dropdown",
        },
        {
          name: "legal_status_of_the_facility",
          label: "Stan prawny obiektu",
          type: "text",
        },
        {
          name: "roi",
          label: "ROI",
          type: "text",
        },
        {
          name: "description_of_the_roi_calculation_method",
          label: "Opis sposobu obliczenia ROI",
          type: "text",
        },
      ];
    case "tenement_house":
      return [
        {
          name: "price",
          label: "Cena Kamienicy",
          type: "text",
        },
        {
          name: "year_of_construction",
          label: "Rok budowy",
          type: "text",
        },
        {
          name: "area",
          label: "Powierzchnia budynku",
          type: "text",
        },
        {
          name: "number_of_floors",
          label: "Ilość pięter",
          type: "text",
        },
        {
          name: "number_of_apartments",
          label: "Ilość mieszkań",
          type: "text",
        },
        {
          name: "number_of_apartments_on_floor",
          label: "Ilość mieszkań na piętrze",
          type: "text",
        },
        {
          name: "min_area_of_apartments",
          label: "Powierzchnia mieszkań (od)",
          type: "text",
        },
        {
          name: "max_area_of_apartments",
          label: "Powierzchnia mieszkań (do)",
          type: "text",
        },
        {
          name: "voivodship",
          label: "Województwo",
          options: voivodships,
          type: "dropdown",
        },
        {
          name: "city",
          label: "Miasto",
          options: cities,
          type: "dropdown",
        },
        {
          name: "description",
          label: "Opis",
          type: "text",
        },
        {
          name: "legal_status_of_the_facility",
          label: "Stan prawny obiektu",
          type: "text",
        },
        {
          name: "roi",
          label: "ROI",
          type: "text",
        },
        {
          name: "description_of_the_roi_calculation_method",
          label: "Opis sposobu obliczenia ROI",
          type: "text",
        },
      ];
    case "service_premises":
      return [
        {
          name: "price",
          label: "Cena",
          type: "text",
        },
        {
          name: "area",
          label: "Powierzchnia",
          type: "text",
        },
        {
          name: "dimension_x",
          label: "Wymiary X",
          type: "text",
        },
        {
          name: "dimension_y",
          label: "Wymiary Y",
          type: "text",
        },
        {
          name: "voivodship",
          label: "Województwo",
          options: voivodships,
          type: "dropdown",
        },
        {
          name: "city",
          label: "Miasto",
          options: cities,
          type: "dropdown",
        },
        {
          name: "legal_status_of_the_facility",
          label: "Stan prawny obiektu",
          type: "text",
        },
        {
          name: "roi",
          label: "ROI",
          type: "text",
        },
        {
          name: "description_of_the_roi_calculation_method",
          label: "Opis sposobu obliczenia ROI",
          type: "text",
        },
      ];
    case "warehouse":
      return [
        {
          name: "price",
          label: "Cena",
          type: "text",
        },
        {
          name: "area",
          label: "Powierzchnia",
          type: "text",
        },
        {
          name: "dimension_x",
          label: "Wymiary X",
          type: "text",
        },
        {
          name: "dimension_y",
          label: "Wymiary Y",
          type: "text",
        },
        {
          name: "voivodship",
          label: "Województwo",
          options: voivodships,
          type: "dropdown",
        },
        {
          name: "city",
          label: "Miasto",
          options: cities,
          type: "dropdown",
        },
        {
          name: "legal_status_of_the_facility",
          label: "Stan prawny obiektu",
          type: "text",
        },
        {
          name: "roi",
          label: "ROI",
          type: "text",
        },
        {
          name: "description_of_the_roi_calculation_method",
          label: "Opis sposobu obliczenia ROI",
          type: "text",
        },
      ];
    case "plot":
      return [
        {
          name: "price",
          label: "Cena",
          type: "text",
        },
        {
          name: "number_of_plot",
          label: "Numer działki",
          type: "text",
        },
        {
          name: "area",
          label: "Powierzchnia",
          type: "text",
        },
        {
          name: "dimension_x",
          label: "Wymiary X",
          type: "text",
        },
        {
          name: "dimension_y",
          label: "Wymiary Y",
          type: "text",
        },
        {
          name: "voivodship",
          label: "Województwo",
          options: voivodships,
          type: "dropdown",
        },
        {
          name: "city",
          label: "Miasto",
          options: cities,
          type: "dropdown",
        },
        {
          name: "legal_status_of_the_facility",
          label: "Stan prawny obiektu",
          type: "text",
        },
        {
          name: "roi",
          label: "ROI",
          type: "text",
        },
        {
          name: "description_of_the_roi_calculation_method",
          label: "Opis sposobu obliczenia ROI",
          type: "text",
        },
        {
          name: "development_plan",
          label: "Plan zagospodarowania",
          type: "check",
        },
        {
          name: "water",
          label: "Woda",
          type: "check",
        },
        {
          name: "gas",
          label: "Gas",
          type: "check",
        },
        {
          name: "electricity",
          label: "Prąd",
          type: "check",
        },
        {
          name: "canalization",
          label: "Kanalizacja",
          type: "check",
        },
      ];
    default:
      return [];
  }
};
