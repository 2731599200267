import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { List } from "components/List";
import { Typography } from "components/Typography";
import {
  investmentsSpecializationsFields,
  realEstateExperienceInMonthsFields,
} from "constants/profileFormFields";
import { useWindowWidth } from "hooks/useWindowWidth";
import React from "react";
import { IContactInfo, IOtherUserProfile } from "services/otherProfile";
import styled from "styled-components";

interface IProfileInfo {
  user: IOtherUserProfile;
  activityCities: string[];
  mostImportantFeatures: string[];
  specializations: string[];
  investmentsLevels: string[];
  lookingFor: string[];
  investmentsSpecializations: string[];
  expectations: string[];
  renderInviteButton?: (contactInfo: IContactInfo) => React.ReactNode;
}

const Header = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.veryLight};
  `};
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const UserProfile: React.FC<IProfileInfo> = ({
  user,
  activityCities,
  mostImportantFeatures,
  specializations,
  investmentsLevels,
  lookingFor,
  investmentsSpecializations,
  expectations,
  renderInviteButton,
}) => {
  const windowWidth = useWindowWidth();
  return (
    <Card>
      <Header px={windowWidth > 1000 ? 24 : 5} pt={8} pb={6}>
        <Flex>
          <Avatar url={user?.data?.profile?.avatar || undefined} />
          <Flex flexDirection="column">
            <Typography variant="body2">Imię i nazwisko</Typography>
            <Typography variant="body" mb={6} fontWeight={600}>
              {user?.data?.profile?.first_name} {user?.data?.profile?.last_name}
            </Typography>
            <Typography variant="body" mb={2} fontWeight={400}>
              {user?.data?.profile?.description}
            </Typography>
          </Flex>
        </Flex>
        {renderInviteButton && user?.contact_info
          ? renderInviteButton(user?.contact_info)
          : null}
      </Header>
      <Box px={windowWidth > 1000 ? 24 : 5} pt={6} pb={8}>
        <Typography variant="h2" color="primary" mb={4}>
          Doświadczenie i specjalizacja
        </Typography>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Doświadczenie w inwestowaniu w nieruchomości
          </Typography>
          <StyledTypography variant="body">
            {
              realEstateExperienceInMonthsFields.find(
                ({ value }) =>
                  value ===
                  `${user?.data?.profile?.real_estate_experience_in_months}`
              )?.label
            }
          </StyledTypography>
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Miasta, w których działa
          </Typography>
          <Typography variant="body">
            <List data={activityCities} />
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Specjalizacja (typy inwestycji)
          </Typography>
          <List
            data={
              investmentsSpecializationsFields
                .filter((value) => specializations.includes(value.value))
                .map((val) => `${val.label}`) ?? []
            }
          />
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Średni poziom pojedynczej inwestycji
          </Typography>
          <List data={investmentsLevels} />
        </Box>
        <Typography variant="h2" color="primary" mt={6} mb={4}>
          Oczekiwania i preferencje
        </Typography>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Do czego używa platformy?
          </Typography>
          <List data={lookingFor} />
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Specjalizacja
          </Typography>
          <List data={investmentsSpecializations} />
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Najważniejsze cechy ogłoszeń inwestycyjnych
          </Typography>
          <List data={mostImportantFeatures} />
        </Box>
        <Box mb={4}>
          <Typography variant="body2" color="secondary">
            Oczekiwania od sieci znajomych inwestorów
          </Typography>
          <List data={expectations} />
        </Box>
      </Box>
    </Card>
  );
};
