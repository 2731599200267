import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { advertisements } from "./advertisement/reducer";
import { currentUser } from "./currentUser/reducer";
import { negotiations } from "./negotiations/reducer";

const persistConfig = {
  key: "token",
  storage,
  whitelist: ["token", "id"],
};

export const rootReducer = combineReducers({
  currentUser: persistReducer(persistConfig, currentUser),
  negotiations,
  advertisements,
});

export type IRootState = ReturnType<typeof rootReducer>;
