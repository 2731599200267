import { IUsersList, UsersList } from "components/UsersList/UsersList";
import { useContacts } from "hooks/contacts";
import { ReactComponent as ContactAddIcon } from "icons/contact-add.svg";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface ParamsType {
  id: string;
}

const AddUserButton = styled(ContactAddIcon)`
  cursor: pointer;
`;

export const OtherProfileContactsView = () => {
  const { id } = useParams<ParamsType>();
  const userId = parseInt(id, 10);

  const { contacts, handleAddUserClick, retrieveContacts } = useContacts({
    userId,
  });

  useEffect(() => {
    retrieveContacts({ page: 1 });
  }, []);

  const renderButtons: IUsersList["extraRender"] = (contact) => {
    return <AddUserButton onClick={() => handleAddUserClick(contact)} />;
  };

  return <UsersList items={contacts} extraRender={renderButtons} />;
};
