import { Box } from "components/Box";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import { Investment } from "components/Investment";
import { Modal } from "components/Modal/Modal";
import { IPerson, Person } from "components/Person/Person";
import { Typography } from "components/Typography";
import React from "react";
import { Col, Row } from "react-grid-system";
import { IFetchAdvertisementResponse } from "services/advertisements";
import styled from "styled-components";

interface IInvestor {
  id: number;
  avatar?: string;
  profile: {
    first_name: string;
    last_name: string;
  };
}

interface ISummaryInvestorModal {
  isOpen: boolean;
  onCancelClick: () => void;
  advertisement?: IFetchAdvertisementResponse["data"];
  price?: number;
  investor: IInvestor;
  createOffer?: (errorConfirm1: boolean, errorConfirm2: boolean) => void;
  acceptOffer?: (errorConfirm1: boolean, errorConfirm2: boolean) => void;
}

const StyledBox = styled(Box)`
  width: 780px;

  @media only screen and (max-width: 1000px) {
    width: 300px;
  }
`;

export const SummaryInvestorModal: React.FC<ISummaryInvestorModal> = ({
  isOpen,
  onCancelClick,
  advertisement,
  createOffer,
  acceptOffer,
  price = 0,
  investor,
}) => {
  const [errorConfirm1, setErrorConfirm1] = React.useState<boolean>(false);
  const [errorConfirm2, setErrorConfirm2] = React.useState<boolean>(false);

  const parseAdvCreator = (
    user: IFetchAdvertisementResponse["data"]["user"]
  ): IPerson => {
    return {
      id: user.id,
      avatar: user?.avatar || "",
      firstName: user?.profile?.first_name || "",
      lastName: user?.profile?.last_name || "",
      investment: true,
    };
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <StyledBox px={8} py={6}>
        <Typography variant="h1" color="primary" mb={4}>
          Podsumowanie
        </Typography>
        <Row>
          <Col sm={6}>
            <Typography variant="h2" color="primary">
              Przedmiot oferty
            </Typography>
            {advertisement && (
              <Investment
                favourite={null}
                id={advertisement.id}
                view="vertical"
                images={advertisement.images}
                name={advertisement.name}
                price={price}
                roi={advertisement.roi}
                area={advertisement.area}
                localization={advertisement.voivodship}
                type={advertisement.category}
                owner={parseAdvCreator(advertisement.user)}
                showShare={false}
                legalStatus={advertisement.legal_status_of_the_facility}
              />
            )}
          </Col>
          <Col sm={6}>
            <Box mb={10}>
              <Typography variant="h2" color="primary" mb={3}>
                Strona sprzedająca
              </Typography>
              {advertisement && (
                <Person {...parseAdvCreator(advertisement.user)} />
              )}
            </Box>
            <Typography variant="h2" color="primary" mb={3}>
              Strona kupująca
            </Typography>
            <Person {...parseAdvCreator(investor)} />
          </Col>
        </Row>
        <Flex justifyContent="flex-end">
          <Box mr={4}>
            <Button
              label="Anuluj"
              variant="secondary"
              onClick={onCancelClick}
            />
          </Box>

          {createOffer && (
            <Button
              label="Złóż ofertę"
              variant="primary"
              onClick={() => createOffer(errorConfirm1, errorConfirm2)}
            />
          )}

          {acceptOffer && (
            <Button
              label="Akceptuj ofertę"
              variant="primary"
              onClick={() => acceptOffer(errorConfirm1, errorConfirm2)}
            />
          )}
        </Flex>
      </StyledBox>
    </Modal>
  );
};
