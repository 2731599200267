import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Link } from "components";
import { Box } from "components/Box";
import { ErrorHelperText } from "components/ErrorHelperText";
import { LoginLayout } from "components/LoginLayout";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { loginSchema } from "schemas/login";
import { loginUserAction } from "store/currentUser/actions";

import { IRootState } from "../store/currentUser/types";

interface ILoginFormData {
  email: string;
  password: string;
}

const content = `to miejsce, w którym znajdziesz, sprawdzonych i wiarygodnych inwestorów, ciekawe i atrakcyjne inwestycje oraz inne produkty i usługi.I4U umożliwi Ci kontakty i spotkania biznesowe oraz pomoże znaleźć rzetelnego partnera w interesach. DOŁĄCZ DO NASZEGO GRONA I CZERP RADOŚĆ Z INWESTOWANIA.`;

export const LoginView: React.FC = () => {
  const location = useLocation();
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm<ILoginFormData>({
    resolver: yupResolver(loginSchema),
  });
  const history = useHistory();

  const onSubmit = async (data: ILoginFormData) => {
    try {
      await dispatch(loginUserAction(data));
      history.push("/");
    } catch (error) {
      setInvalidCredentials(true);
      if (error.error) {
        toast.error(error.error);
      }
    }
  };

  useEffect(() => {
    if (location.search[0] && location.search[0] === '?') {
      const [name, value] = location.search.split('=')
      if (name === '?register' && value === 'success') {
        toast.success(`Proszę zweryfikuj swój email.`);
      }
    }
  }, [location.search])

  return (
    <LoginLayout content={content}>
      <Typography variant="h1" color="primary" mb={2}>
        Zaloguj się do serwisu
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <TextField
            label="Adres e-mail"
            name="email"
            ref={register()}
            error={errors.email?.message}
          />
        </Box>
        <Box mb={4}>
          <TextField
            type="password"
            label="Hasło"
            name="password"
            ref={register()}
            error={errors.password?.message}
          />
        </Box>
        {invalidCredentials && (
          <Box mb={4}>
            <ErrorHelperText>Błędny email lub hasło, lub Twój email nie został potwierdzony.</ErrorHelperText>
          </Box>
        )}
        <Button type="submit" label="Zaloguj się" fullWidth />
        <Typography variant="body2" mt={5}>
          Nie masz konta? <Link to="/register">Zarejestruj się</Link>
        </Typography>
        <Typography variant="body2" mt={2}>
          Zapomniałeś hasła? <Link to="/password-reset">Zresetuj hasło</Link>
        </Typography>
      </form>
    </LoginLayout>
  );
};
