import { ErrorHelperText } from "components/ErrorHelperText";
import { FieldLabel as Label } from "components/FieldLabel";
import { useOnClickOutside } from "hooks/onClickOutside";
import React, { InputHTMLAttributes, useRef, useState } from "react";
import styled from "styled-components";
import { setConstantValue } from "typescript";
import { rgba } from "utilities/rgba";

export interface IHint {
  label: string;
  value: string;
}

export interface TextFieldType extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
  error?: string;
  unit?: string;
  icon?: React.ReactNode;
  hints?: Array<IHint>;
  onHintClick?: (hint: IHint) => any;
  transparent?: boolean;
}

interface StyledInputI {
  error?: TextFieldType["error"];
  unit?: TextFieldType["unit"];
  icon?: TextFieldType["icon"];
  displayHints?: boolean;
  transparent?: boolean;
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const Root = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledInput = styled.input<StyledInputI>`
  ${({ theme, error, unit, icon, displayHints, transparent }) => `
    background-color: ${transparent ? 'transparent' : '#ffffff'};
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ""};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ""};
    font-size: 14px;
    color: ${theme.palette.text.primary};
    border: 1px solid ${
      error ? theme.palette.accent.red : theme.palette.neutral.medium
    };
    border-radius: 4px;
    outline: none;

    ${
      displayHints
        ? `border-bottom-left-radius: 0; border-bottom-right-radius: 0;`
        : ""
    }

    &:focus {
      border-radius: 4px;
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

const Hints = styled.div`
  ${({ theme }) => `
    positon: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    border: 1px solid ${theme.palette.primary.main};
    border-top: none;
  `};
`;

const Hint = styled.div`
  ${({ theme }) => `
    overflow: hidden;
    height: 36px;
    color: ${theme.palette.text.primary};
    border-top: none;
    border-bottom: none;
    outline: none;
    background-color: ${theme.palette.neutral.white};
    padding: 0 ${theme.space[4]}px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: ${theme.palette.primary.main};
    }
    &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    };
  `}
`;

export const TextField = React.forwardRef<HTMLInputElement, TextFieldType>(
  (
    {
      label,
      error,
      unit,
      icon,
      disabled,
      hints,
      onHintClick,
      transparent,
      ...props
    },
    ref
  ) => {
    const [displayHints, setDisplayHints] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleFocus = () => {
      setDisplayHints(true);
    };

    const handleHintClick = (hint: IHint) => {
      if (onHintClick) onHintClick(hint);
    };

    useOnClickOutside(wrapperRef, () => {
      setDisplayHints(false);
    });

    return (
      <Root>
        {label ? (
          <Label error={error} disabled={disabled}>
            {label}
          </Label>
        ) : null}
        <InputWrapper ref={wrapperRef}>
          <StyledInput
            ref={ref}
            type="text"
            error={error}
            unit={unit}
            icon={icon}
            disabled={disabled}
            onFocus={handleFocus}
            displayHints={displayHints}
            transparent={transparent}
            {...props}
          />
          {unit && <Unit>{unit}</Unit>}
          {icon && <Icon>{icon}</Icon>}
          {displayHints && hints && hints.length !== 0 && (
            <Hints>
              {hints.map((hint) => (
                <Hint onClick={() => handleHintClick(hint)}>{hint.label}</Hint>
              ))}
            </Hints>
          )}
        </InputWrapper>
        {error && <ErrorHelperText>{error}</ErrorHelperText>}
      </Root>
    );
  }
);
