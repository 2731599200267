import { ProfileContextProvider } from "contexts/profileContext";
import React from "react";
import { IRoute } from "types/route";

import { OtherProfile } from "./OtherProfile";

export const OtherProfileView: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  return (
    <ProfileContextProvider>
      <OtherProfile routes={subRoutes} />
    </ProfileContextProvider>
  );
};
