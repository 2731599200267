import { Typography } from "components";
import { Box } from "components/Box";
import { Menu } from "components/Menu";
import { Router } from "components/Router/Router";
import { useUserProfileContext } from "contexts/profileContext";
import { useMenu } from "hooks/menu";
import React from "react";
import { Col, Row } from "react-grid-system";
import { useParams } from "react-router-dom";
import { getOtherUserPforileInfo } from "services/otherProfile";
import { IRoute } from "types/route";

interface ParamsType {
  id: string;
}

export const OtherProfile: React.FC<{
  routes: Array<IRoute>;
}> = ({ routes: subRoutes }) => {
  const { id } = useParams<ParamsType>();
  const { userProfile, setUserProfile } = useUserProfileContext();

  const getProfilesData = async () => {
    const response = await getOtherUserPforileInfo(id);
    setUserProfile(response);
  };

  React.useEffect(() => {
    getProfilesData();
  }, []);

  const menuItems = useMenu([
    {
      label: "O użytkowniku",
      path: `/profiles/${id}/info/`,
    },
    {
      label: "Ogłoszenia użytkownika",
      path: `/profiles/${id}/advertisements/`,
    },
    {
      label: "Kontakty",
      path: `/profiles/${id}/contacts`,
    },
  ]);

  return (
    <>
      <Typography variant="h1" color="primary" mb={3}>
        Profil użytkownika: {userProfile?.data?.profile.first_name}{" "}
        {userProfile?.data?.profile.last_name}
      </Typography>
      <Row>
        <Col sm={4}>
          <Box mb={6}>
            <Menu items={menuItems} />
          </Box>
        </Col>
        <Col sm={8}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
