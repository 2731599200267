export const investmentType = [
  {
    label: "Mieszkania",
    value: "Mieszkania",
  },
  {
    label: "Kamienice",
    value: "Kamienice",
  },
  {
    label: "Lokale usługowe",
    value: "Lokale_uslugowe",
  },
  {
    label: "Magazyny i hale",
    value: "Magazyny_i_hale",
  },
  {
    label: "Działki pod inwestycje",
    value: "Dzialki_pod_inwestycje",
  },
  {
    label: "Inne",
    value: "inne",
  },
];
