import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import React from "react";
import styled from "styled-components";

interface IConfirmModal {
  isOpen: boolean;
  onCancelClick: () => void;
  handleConfirm: () => void;
}

const StyledBox = styled(Box)`
  max-width: 550px;
`;

export const ConfirmModal: React.FC<IConfirmModal> = ({
  isOpen,
  onCancelClick,
  handleConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <StyledBox p={8}>
        <Typography variant="h1" color="primary">
          Jesteś pewien że chcesz przenieść do zakończonych?
        </Typography>

        <Typography variant="body" color="secondary" mt={3}>
          Twoje ogłoszenie zniknie z list, a negocjacje zostaną zamknięte.
        </Typography>

        <Flex mt={8} justifyContent="flex-end">
          <Box mr={6}>
            <Button
              label="Przenieś"
              variant="primary"
              onClick={handleConfirm}
            />
          </Box>
          <Button label="Anuluj" variant="secondary" onClick={onCancelClick} />
        </Flex>
      </StyledBox>
    </Modal>
  );
};
